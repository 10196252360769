.not-found {
    padding-bottom: 80px;
    padding-top: 186px;
    position: relative;

    @include lg {
        padding-top: 170px;
        padding-bottom: 58px;
    }

    @include md {
        padding-top: 180px;
        padding-bottom: 44px;
    }

    @include sm {
        padding-top: 166px;
        padding-bottom: 38px;
    }

    @include xsm {
        padding-top: 100px;
        padding-bottom: 32px;
    }

    &::after {
        content: '';
        width: calc(100% + 120px);
        height: 590px;
        background: url('../pic/404_fon.png') center/cover no-repeat;
        position: absolute;
        left: -120px;
        // top: -40px;
        top: 20px;
        text-align: center;

        @include lg {
            height: 545px;
            width: calc(100% + 92px);
            left: -92px;
            top: 0px;
        }

        @include md {
            height: 452px;
            top: 50px;
            left: -50px;
            width: 100%;
            width: calc(100% + 50px);
        }

        @include sm {
            height: 432px;
            top: 34px;
            left: -40px;
            width: calc(100% + 40px);
        }

        @include xsm {
            height: 225px;
            top: 30px;
        }
    }

    .container {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    // .not-found__img
    &__img {
        max-width: 935px;
        position: relative;
        z-index: 1;

        @include lg {
            max-width: 715px;
        }

        @include md {
            max-width: 618px;
        }

        @include sm {
            max-width: 602px;
        }

        @include xsm {
            max-width: 288px;
        }

        img {
            max-width: 100%;
        }
    }

    // .not-found__text
    &__text {
        color: #E7D186;
        text-align: center;
        font-size: 16px;
        font-weight: 700;
        line-height: calc(20 / 16);
        letter-spacing: 3.2px;
        text-transform: uppercase;
        margin-top: 90px;

        @include lg {
            margin-top: 70px;
            font-size: 14px;
        }

        @include md {
            margin-top: 60px;
        }

        @include sm {
            font-size: 12px;

        }

        @include xsm {
            margin-top: 32px;
        }
    }
}