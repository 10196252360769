.header {
    background: #ffffff;
    position: fixed;
    width: 120px;
    height: 100%;
    z-index: 9999;

    @include lg {
        width: 91px;
    }

    @include md {
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: auto;
        height: auto;
    }

    &--wrap {
        height: 100%;
        padding: 24px 0 50px 0;
        z-index: 2;
        position: relative;
        background: #e7d186;

        @include lg {
            padding: 24px 0;
        }

        @include md {
            flex-direction: row;
            padding: 6px 16px;
            justify-content: flex-start;
        }
    }

    &--menu {
        @include md {
            order: 5;
            margin-left: 40px;
            margin-right: 19px;
        }

        @include sm {
            margin-left: 33px;
            margin-right: 0;
        }
    }

    &--social {
        @include md {
            order: 4;
            margin-left: 70px;
        }

        @include vm {
            display: none;
        }

        &:hover {
            svg {
                color: #50c878;
            }
        }
    }

    &--youtube {
        @include md {
            order: 4;
            margin-left: 37px;
        }

        @include vm {
            display: none;
        }

        &:hover {
            svg {
                color: #50c878;
            }
        }
    }

    &--logo {
        @include lg {
            img {
                width: 59px;
                height: 75px;
            }
        }

        @include md {
            img {
                width: 32px;
                height: 40px;
            }
        }

        @include mdFix {
            img {
                height: 28px;
            }
        }
    }

    &--main {
        &-wrap {
            height: 100%;
            position: relative;

            nav {
                max-height: 100%;
                padding-right: 55px;

                @include lg {
                    max-height: 100%;
                    padding-right: 42px;
                }

                @include md {
                    max-height: 100%;
                    height: auto;
                    padding: 0;

                    .simplebar-content {
                        display: flex;
                        flex-wrap: wrap;
                    }
                }

                @include vm {
                    .simplebar-content {
                        flex-direction: column;
                        align-items: center;
                    }
                }
            }

            button {
                font-family: Noah, sans-serif;
                font-size: 24px;
                line-height: 32px;
                z-index: 2;
                text-align: left;
                font-weight: 500;
                color: #051838;
                margin-top: 40px;

                @include lg {
                    font-size: 16px;
                    line-height: 24px;
                }

                @include md {
                    display: none;
                }
            }
        }
    }
}

.bh {
    position: relative;

    &:before {
        content: "";
        width: 100%;
        height: 1px;
        background: #52739a;
        transition: 0.3s all ease;
        opacity: 0;
        visibility: hidden;
        position: absolute;
        display: block;
        top: 100%;
    }

    &:hover {
        &:before {
            opacity: 1;
            visibility: visible;
        }
    }
}

.menu {
    &--block {
        margin-bottom: 32px;

        @include lg {
            margin-bottom: 24px;
        }

        @include md {
            width: 33.33333%;
        }

        @include vm {
            width: auto;
            text-align: center;
        }

        >div:first-child {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 20px;

            @include lg {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 12px;
            }
        }

        span {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            display: block;
            margin-bottom: 20px;

            @include lg {
                font-size: 20px;
                line-height: 32px;
                margin-bottom: 12px;
            }
        }

        &.links {
            ul {
                li {
                    a {
                        font-weight: 500;
                        font-size: 20px;
                        line-height: 28px;

                        /* @include lg {
              font-size: 16px;
              line-height: 24px;
            } */
                    }
                }
            }
        }

        ul {
            li {
                margin-bottom: 16px;

                &:last-child {
                    margin-bottom: 0;
                }

                a {
                    font-size: 20px;
                    line-height: 28px;
                    position: relative;

                    @include lg {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
            }
        }
    }

    &--link {
        &.big {
            font-weight: 500;
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 44px;
            display: inline-block;

            @include lg {
                font-size: 20px;
                line-height: 32px;
            }

            @include md {
                margin-bottom: 24px;
                font-size: 20px;
                line-height: 32px;
            }

            @include vm {
                width: auto;
            }
        }
    }

    &--main {
        &-wrap {
            position: absolute;
            top: 0;
            bottom: 0;
            left: -9999px;
            opacity: 0;
            visibility: hidden;
            min-width: 520px;
            background: #ffffff;
            padding: 50px 45px 50px 120px;
            color: #051838;
            transition: 0.3s all ease;
            z-index: 1;

            @include xl {
                min-width: 376px;
                padding: 32px 25px 32px 91px;
            }

            @include md {
                top: -9999px;
                right: 0;
                bottom: auto;
                padding: 24px 16px 32px 72px;
            }

            @include sm {
                padding: 24px 16px 32px 56px;
            }

            &.active {
                left: 100%;
                opacity: 1;
                visibility: visible;
            }

            @include md {
                &.active {
                    left: 0;
                    top: 100%;
                }
            }

            @include vm {
                bottom: 0;
                height: calc(100vh - 52px);
                min-width: auto;
                padding: 24px 8px 32px 16px;
            }
        }
    }

    &--wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;

        @include md {
            flex-direction: row-reverse;
        }

        @include vm {
            flex-direction: column;
        }
    }
}

.menu-btn {
    --icon-width: 32px;
    position: relative;
    width: var(--icon-width);
    flex-direction: column;
    transition: 0.3s all ease;
    cursor: pointer;
    z-index: 10;

    &:hover {
        .line {
            background: #50c878;

            @include md {
                background: #051c38;
            }
        }
    }

    @include lg {
        --icon-width: 28px;
    }

    @include md {
        --icon-width: 16px;
    }

    &.close {
        .line {
            &__1 {
                transform: rotate(45deg) translate(2px, -3px);
                opacity: 0;
                visibility: hidden;
            }

            &__2 {
                transform: rotate(-45deg);
            }

            &__3 {
                transform: rotate(45deg) translate(-5px, 1px);

                @include lg {
                    transform: rotate(45deg) translate(-2px, 2px);
                }
            }
        }
    }

    .line {
        width: var(--icon-width);
        background: #051c38;
        height: 2px;
        margin-bottom: 12px;
        transition: 0.3s all ease;

        @include lg {
            margin-bottom: 8px;
        }

        @include md {
            margin-bottom: 4px;
        }

        &:last-child {
            margin-bottom: 0;
        }

        &__1 {
            width: var(--icon-width);
            transform-origin: left;
        }

        &__2 {
            width: var(--icon-width);
            transform-origin: center;
        }

        &__3 {
            width: var(--icon-width);
            transform-origin: right;
        }
    }
}

.header {
    &--lk {
        text-align: center;

        &:hover {
            span {
                color: #50c878;
            }

            svg {
                color: #50c878;
            }
        }

        svg {
            color: #000000;
        }

        @include md {
            order: 3;
            margin-left: auto;

            a {
                flex-direction: row-reverse;

                svg {
                    width: 7px;
                    height: 12px;
                }
            }
        }

        span {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.14em;
            text-transform: uppercase;
            color: #051838;
            display: block;
            margin-top: 16px;

            @include lg {
                font-size: 10px;
                line-height: 14px;
            }

            @include md {
                margin: 0 16px 0 0;
                line-height: 12px;
            }

            @include mdFix {
                display: none;
            }
        }
    }
}

/***********************************************************/
.header-basket {
    width: 80px;
    height: 80px;
    background: #ffffff;
    border-radius: 200px;
    display: inline-flex;
    align-items: center;
    flex-direction: column;
    padding: 8px;

    @include lg {
        width: 64px;
        height: 64px;
        padding: 6px;
    }

    @include md {
        display: none;
    }

    // .header-basket__icon
    &__icon {
        width: 32px;
        height: 24px;



        img {
            max-width: 100%;
        }
    }

    // .header-basket__text
    &__text {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #051838;


    }

    // .header-basket__count
    &__count {
        margin-top: 8px;
        width: 28px;
        height: 16px;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        background: #50c878;
        border-radius: 32px;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        color: #051838;

        @include lg {
            margin-top: 2px;
            width: 26px;
            height: 12px;
            color: #fff;
            font-size: 8px;
            line-height: 12px;
        }


    }
}