.friends {
    .lk-banner {
        margin: 44px 0px;

        @include lg {
            margin: 32px 0px;
        }

        @include md {
            margin: 28px 0px;
        }

        @include sm {
            margin: 24px 0px;
        }

        @include mdFix {
            margin: 20px 0px;
        }
    }

    // .friends__list
    &__list {}

    // .friends__item
    &__item {
        border-bottom: 1px solid #52739A;
        padding-bottom: 32px;

        @include lg {
            padding-bottom: 28px;
        }

        @include md {
            padding-bottom: 24px;
        }

        @include sm {
            flex-wrap: wrap;
            padding-bottom: 20px;
        }

        @include mdFix {
            padding-bottom: 16px;
        }

        &:not(:first-child) {
            padding-top: 32px;

            @include lg {
                padding-top: 28px;
            }

            @include md {
                padding-top: 24px;
            }

            @include sm {
                padding-top: 20px;
            }

            @include mdFix {
                padding-top: 16px;
            }
        }
    }

    // .friends__body
    &__body {
        flex: 0 0 296px;

        @include lg {
            flex: 0 0 210px;
        }

        @include md {
            flex: 0 0 214px;
        }

        @include sm {
            order: 1;
            margin-right: auto;
            flex: 0 0 calc(100% - 284px);
        }

        @include mdFix {
            flex: 0 0 100%;

        }
    }

    // .friends__ava
    &__ava {
        flex: 0 0 88px;
        aspect-ratio: 1/1;
        border-radius: 50%;
        border: 2px solid #fff;
        overflow: hidden;

        @include lg {
            flex: 0 0 59px;
        }

        @include md {
            flex: 0 0 47px;
        }

        @include mdFix {
            flex: 0 0 34px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    // .friends__info
    &__info {
        flex: 1 1 auto;
        margin-left: 16px;

        @include sm {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            margin-left: 8px;
            gap: 8px
        }

        @include mdFix {
            justify-content: space-between;
        }

        .status-friends {
            margin-top: 8px;

            @include sm {
                margin: 0px;
            }
        }
    }

    // .friends__name
    &__name {
        color: #E7D186;
        font-size: 24px;
        font-family: Oranienbaum;
        line-height: calc(32 / 24);

        @include lg {
            font-size: 20px;
        }

        @include md {
            font-size: 16px;
        }
    }

    // .friends__text
    &__text {
        margin-left: 16px;

        @include md {
            margin-left: 8px;
        }

        @include sm {
            order: 3;
            margin-top: 12px;
        }
    }


    // .friends__message
    &__message {
        overflow: hidden;
        color: #52739A;
        font-size: 16px;
        line-height: calc(20 / 16);
        -webkit-line-clamp: 2;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;

        @include lg {
            font-size: 14px;
        }

        @include sm {
            font-size: 12px;
        }
    }

    // .friends__time
    &__time {
        margin-left: 32px;
        color: #52739A;
        text-align: right;
        font-size: 10px;
        font-weight: 500;
        line-height: calc(16 / 10);

        @include lg {
            margin-left: 28px;
        }

        @include md {
            margin-left: 22px;
        }

        @include sm {
            margin-left: 49px;
        }

        @include mdFix {
            margin-left: 20px;
        }
    }

    // .friends__controll
    &__controll {
        margin-left: 120px;
        gap: 16px;

        @include lg {
            margin-left: 90px;
        }

        @include md {
            margin-left: 37px;
            gap: 8px;
        }

        @include sm {
            order: 2;
            margin-left: 12px;

        }

        @include mdFix {
            order: 4;
            gap: 4px;
            flex: 0 0 100%;
            margin: 12px 0px 0px 0px;
        }

        .btn {
            width: 192px;
            padding: 0;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            height: 56px;

            @include lg {
                width: 144px;
                height: 42px;
                border-radius: 10px;
            }

            @include md {
                width: 132px;
                height: 36px;
                border-radius: 8px;
            }

            @include mdFix {
                width: calc(50% - 2px);
            }

            &.btn-default {
                background: #50c878;
                color: #051838;

                @media (any-hover: hover) {
                    &:hover {
                        color: #50c878;
                        background: 0 0;
                    }
                }
            }
        }
    }
}

.status-friends {
    padding: 6px 16px;
    font-size: 12px;
    font-weight: 700;
    line-height: calc(16 / 12);
    letter-spacing: 1.92px;
    text-transform: uppercase;
    display: inline-flex;
    align-items: center;
    gap: 12px;
    border-radius: 20px;

    @include lg {
        padding: 4px 12px;
        font-size: 10px;
        gap: 8px;
    }

    &::before {
        content: '';
        width: 8px;
        height: 8px;
        border-radius: 50%;

    }

    // .status-friends_on
    &_on {
        color: #50C878;
        border: 1px solid #50C878;

        &::before {
            background: #50C878;
        }
    }

    // .status-friends_off
    &_off {
        color: #E84E1D;
        border: 1px solid #E84E1D;

        &::before {
            background: #E84E1D;
        }
    }
}