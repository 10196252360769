html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
header,
hgroup,
menu,
nav,
output,
ruby,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
}

section {
  border: 0;
  padding: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

h1,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

*:focus {
  outline: none;
}

input {
  font: initial;
  box-shadow: none;
}

button::-moz-focus-inner,
input[type="reset"]::-moz-focus-inner,
input[type="button"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="submit"]::-moz-focus-inner,
input[type="file"] > input[type="button"]::-moz-focus-inner {
  border: none;
}

input[type="checkbox"]:focus {
  -moz-outline-offset: -1px !important;
  -moz-outline: 1px solid #000 !important;
}

a {
  text-decoration: none;
  color: inherit;
}

img {
  max-width: 100%;
}

img,
svg {
  display: block;
}

a,
button,
svg,
span {
  transition: 0.3s all ease;
  outline: none;
}

a:hover,
a:focus,
a:active,
button:hover,
button:focus,
button:active,
svg:hover,
svg:focus,
svg:active,
span:hover,
span:focus,
span:active {
  outline: none !important;
}

body.admin {
  padding: 0;
}

#bx-panel {
  z-index: 9999 !important;
}

.center {
  text-align: center;
}

button {
  border: none;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  font: inherit;
}

.bold {
  font-weight: 700;
}

input,
textarea {
  padding: 0;
  border: none;
  background-color: transparent;
  font-family: inherit;
  resize: none;
}

*,
::after,
::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

hr {
  border: 0;
  border-top: 1px solid #eee;
}

input[type="checkbox"] {
  margin: 0;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.flex-center-vertical {
  align-items: center;
}

.flex-center-horizontal {
  justify-content: center;
}

.flex-space-between {
  justify-content: space-between;
}

.flex-start {
  align-items: flex-start;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.flex-end {
  align-items: flex-end;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-column {
  flex-direction: column;
}

.grid {
  display: grid;
}

.relative {
  position: relative;
}

.grecaptcha-badge {
  display: none !important;
}

.bg-i {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: block;
}

.bg-i.top {
  background-size: 100%;
  background-position: top;
}

.bg-i.fixed-i {
  background-size: contain;
}

@font-face {
  font-family: 'Noah';
  src: local("Noah Regular"), local("Noah-Regular"), url("../fonts/Noahregular.woff2") format("woff2"), url("../fonts/Noahregular.woff") format("woff"), url("../fonts/Noahregular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noah';
  src: local("Noah Medium"), local("Noah-Medium"), url("../fonts/Noah-Medium.woff2") format("woff2"), url("../fonts/Noah-Medium.woff") format("woff"), url("../fonts/Noah-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Noah';
  src: local("Noah Bold"), local("Noah-Bold"), url("../fonts/Noah-Bold.woff2") format("woff2"), url("../fonts/Noah-Bold.woff") format("woff"), url("../fonts/Noah-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Oranienbaum';
  src: local("Oranienbaum Regular"), local("Oranienbaum-Regular"), url("../fonts/Oranienbaum-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

.datepicker__wrapper {
  width: 100%;
  margin-top: 16px;
  background: #000712;
  color: #e7d186;
  padding: 16px 12px 24px 20px;
  border: 2px solid #e7d186;
  border-radius: 12px;
}

.datepicker__day {
  width: 36px;
  height: 36px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  border: 1px solid #e7d186;
  text-align: center;
  cursor: pointer;
  font-family: Noah;
}

.datepicker__day.is-otherMonth {
  opacity: 0;
  border: none;
}

.datepicker__day.is-today {
  background: #e7d186;
  color: #051838;
}

.datepicker__day:hover {
  background: #50c878;
  color: #fff;
}

.datepicker__day.is-selected {
  background: #50c878;
  color: #fff;
}

.datepicker__header {
  text-align: center;
  margin-bottom: 22px;
  display: flex;
  justify-content: space-around;
}

.datepicker__cal {
  margin: 0 auto;
}

.datepicker__cal th {
  font-size: 12px;
  line-height: 16px;
  color: #fff;
  padding-bottom: 22px;
  text-transform: uppercase;
}

.datepicker__title {
  display: flex;
  align-items: center;
  font-family: Oranienbaum;
  font-size: 20px;
  line-height: 32px;
}

.datepicker__title select {
  width: 100px !important;
}

.datepicker__title > span {
  margin-right: 18px;
  position: relative;
}

.datepicker__title > span::after {
  content: "";
  display: block;
  width: 20px;
  height: 12px;
  background: url(../pic/datepicker-date-bottom.svg) center center/cover no-repeat;
  position: absolute;
  right: -32px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
}

.datepicker.filled ~ label {
  font-size: 9px;
  line-height: 16px;
  font-weight: bold;
  color: #004a30;
  border-color: #004a30;
  top: 3px;
  transform: translate(-50%, 0);
}

.datepicker.filled ~ label > svg {
  position: relative;
  top: 14px;
}

.datepicker.filled ~ label .date-block--text {
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
}

body.compensate-for-scrollbar {
  overflow: hidden;
}

.fancybox-active {
  height: auto;
}

.fancybox-is-hidden {
  left: -9999px;
  margin: 0;
  position: absolute !important;
  top: -9999px;
  visibility: hidden;
}

.fancybox-container {
  -webkit-backface-visibility: hidden;
  height: 100%;
  left: 0;
  outline: none;
  position: fixed;
  -webkit-tap-highlight-color: transparent;
  top: 0;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  transform: translateZ(0);
  width: 100%;
  z-index: 99992;
}

.fancybox-container * {
  box-sizing: border-box;
}

.fancybox-outer,
.fancybox-inner,
.fancybox-bg,
.fancybox-stage {
  bottom: 0;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.fancybox-outer {
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}

.fancybox-bg {
  background: #02003C;
  opacity: 0;
  transition-duration: inherit;
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.47, 0, 0.74, 0.71);
}

.fancybox-is-open .fancybox-bg {
  opacity: 0.5;
  transition-timing-function: cubic-bezier(0.22, 0.61, 0.36, 1);
}

.fancybox-infobar,
.fancybox-toolbar,
.fancybox-caption,
.fancybox-navigation .fancybox-button {
  direction: ltr;
  opacity: 0;
  position: absolute;
  transition: opacity .25s ease, visibility 0s ease .25s;
  visibility: hidden;
  z-index: 99997;
}

.fancybox-show-infobar .fancybox-infobar,
.fancybox-show-toolbar .fancybox-toolbar,
.fancybox-show-caption .fancybox-caption,
.fancybox-show-nav .fancybox-navigation .fancybox-button {
  opacity: 1;
  transition: opacity .25s ease 0s, visibility 0s ease 0s;
  visibility: visible;
}

.fancybox-infobar {
  color: #ccc;
  font-size: 13px;
  -webkit-font-smoothing: subpixel-antialiased;
  height: 44px;
  left: 0;
  line-height: 44px;
  min-width: 44px;
  mix-blend-mode: difference;
  padding: 0 10px;
  pointer-events: none;
  top: 0;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.fancybox-toolbar {
  right: 0;
  top: 0;
}

.fancybox-stage {
  direction: ltr;
  overflow: visible;
  transform: translateZ(0);
  z-index: 99994;
}

.fancybox-is-open .fancybox-stage {
  overflow: hidden;
}

.fancybox-slide {
  -webkit-backface-visibility: hidden;
  /* Using without prefix would break IE11 */
  display: none;
  height: 100%;
  left: 0;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: absolute;
  text-align: center;
  top: 0;
  transition-property: transform, opacity;
  white-space: normal;
  width: 100%;
  z-index: 99994;
}

.fancybox-slide::before {
  content: '';
  display: inline-block;
  font-size: 0;
  height: 100%;
  vertical-align: middle;
  width: 0;
}

.fancybox-is-sliding .fancybox-slide,
.fancybox-slide--previous,
.fancybox-slide--current,
.fancybox-slide--next {
  display: block;
}

.fancybox-slide--image {
  overflow: hidden;
  padding: 44px 0;
}

.fancybox-slide--image::before {
  display: none;
}

.modal {
  display: none;
}

.fancybox-content {
  display: inline-block;
  margin: 0;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  position: relative;
  text-align: left;
  vertical-align: middle;
}

.fancybox-slide--image .fancybox-content {
  animation-timing-function: cubic-bezier(0.5, 0, 0.14, 1);
  -webkit-backface-visibility: hidden;
  background: transparent;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  left: 0;
  max-width: none;
  overflow: visible;
  padding: 0;
  position: absolute;
  top: 0;
  -ms-transform-origin: top left;
  transform-origin: top left;
  transition-property: transform, opacity;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  z-index: 99995;
}

.fancybox-can-zoomOut .fancybox-content {
  cursor: zoom-out;
}

.fancybox-can-zoomIn .fancybox-content {
  cursor: zoom-in;
}

.fancybox-can-swipe .fancybox-content,
.fancybox-can-pan .fancybox-content {
  cursor: grab;
}

.fancybox-is-grabbing .fancybox-content {
  cursor: grabbing;
}

.fancybox-container [data-selectable='true'] {
  cursor: text;
}

.fancybox-image,
.fancybox-spaceball {
  background: transparent;
  border: 0;
  height: 100%;
  left: 0;
  margin: 0;
  max-height: none;
  max-width: none;
  padding: 0;
  position: absolute;
  top: 0;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  width: 100%;
}

.fancybox-spaceball {
  z-index: 1;
}

.fancybox-slide--video .fancybox-content,
.fancybox-slide--map .fancybox-content,
.fancybox-slide--pdf .fancybox-content,
.fancybox-slide--iframe .fancybox-content {
  height: 100%;
  overflow: visible;
  padding: 0;
  width: 100%;
}

.fancybox-slide--video .fancybox-content {
  background: #000;
}

.fancybox-slide--map .fancybox-content {
  background: #e5e3df;
}

.fancybox-slide--iframe .fancybox-content {
  background: #fff;
}

.fancybox-video,
.fancybox-iframe {
  background: transparent;
  border: 0;
  display: block;
  height: 100%;
  margin: 0;
  overflow: hidden;
  padding: 0;
  width: 100%;
}

/* Fix iOS */

.fancybox-iframe {
  left: 0;
  position: absolute;
  top: 0;
}

.fancybox-error {
  background: #fff;
  cursor: default;
  max-width: 400px;
  padding: 40px;
  width: 100%;
}

.fancybox-error p {
  color: #444;
  font-size: 16px;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

/* Buttons */

.fancybox-button {
  background: rgba(30, 30, 30, 0.6);
  border: 0;
  border-radius: 0;
  box-shadow: none;
  cursor: pointer;
  display: inline-block;
  height: 44px;
  margin: 0;
  padding: 10px;
  position: relative;
  transition: color .2s;
  vertical-align: top;
  visibility: inherit;
  width: 44px;
}

.fancybox-button,
.fancybox-button:visited,
.fancybox-button:link {
  color: #ccc;
}

.fancybox-button:hover {
  color: #fff;
}

.fancybox-button:focus {
  outline: none;
}

.fancybox-button.fancybox-focus {
  outline: 1px dotted;
}

.fancybox-button[disabled],
.fancybox-button[disabled]:hover {
  color: #888;
  cursor: default;
  outline: none;
}

/* Fix IE11 */

.fancybox-button div {
  height: 100%;
}

.fancybox-button svg {
  display: block;
  height: 100%;
  overflow: visible;
  position: relative;
  width: 100%;
}

.fancybox-button svg path {
  fill: currentColor;
  stroke-width: 0;
}

.fancybox-button--play svg:nth-child(2),
.fancybox-button--fsenter svg:nth-child(2) {
  display: none;
}

.fancybox-button--pause svg:nth-child(1),
.fancybox-button--fsexit svg:nth-child(1) {
  display: none;
}

.fancybox-progress {
  background: #ff5268;
  height: 2px;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  -ms-transform: scaleX(0);
  transform: scaleX(0);
  -ms-transform-origin: 0;
  transform-origin: 0;
  transition-property: transform;
  transition-timing-function: linear;
  z-index: 99998;
}

/* Close button on the top right corner of html content */

.fancybox-close-small {
  background: transparent;
  border: 0;
  border-radius: 0;
  color: #ccc;
  cursor: pointer;
  opacity: .8;
  padding: 8px;
  position: absolute;
  right: -12px;
  top: -44px;
  z-index: 401;
}

.fancybox-close-small:hover {
  color: #fff;
  opacity: 1;
}

.fancybox-slide--html .fancybox-close-small {
  color: currentColor;
  padding: 10px;
  right: 10px;
  top: 10px;
}

.fancybox-slide--image.fancybox-is-scaling .fancybox-content {
  overflow: hidden;
}

.fancybox-is-scaling .fancybox-close-small,
.fancybox-is-zoomable.fancybox-can-pan .fancybox-close-small {
  display: none;
}

/* Navigation arrows */

.fancybox-navigation .fancybox-button {
  background-clip: content-box;
  height: 100px;
  opacity: 0;
  position: absolute;
  top: calc(50% - 50px);
  width: 70px;
}

.fancybox-navigation .fancybox-button div {
  padding: 7px;
}

.fancybox-navigation .fancybox-button--arrow_left {
  left: 0;
  padding: 31px 26px 31px 6px;
}

.fancybox-navigation .fancybox-button--arrow_right {
  padding: 31px 6px 31px 26px;
  right: 0;
}

/* Caption */

.fancybox-caption {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.85) 0%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.15) 65%, rgba(0, 0, 0, 0.075) 75.5%, rgba(0, 0, 0, 0.037) 82.85%, rgba(0, 0, 0, 0.019) 88%, rgba(0, 0, 0, 0) 100%);
  bottom: 0;
  color: #eee;
  font-size: 14px;
  font-weight: 400;
  left: 0;
  line-height: 1.5;
  padding: 75px 44px 25px 44px;
  pointer-events: none;
  right: 0;
  text-align: center;
  z-index: 99996;
}

@supports (padding: 0px) {
  .fancybox-caption {
    padding: 75px 44px 25px 44px;
  }
}

.fancybox-caption--separate {
  margin-top: -50px;
}

.fancybox-caption__body {
  max-height: 50vh;
  overflow: auto;
  pointer-events: all;
}

.fancybox-caption a,
.fancybox-caption a:link,
.fancybox-caption a:visited {
  color: #ccc;
  text-decoration: none;
}

.fancybox-caption a:hover {
  color: #fff;
  text-decoration: underline;
}

/* Loading indicator */

.fancybox-loading {
  animation: fancybox-rotate 1s linear infinite;
  background: transparent;
  border: 4px solid #888;
  border-bottom-color: #fff;
  border-radius: 50%;
  height: 50px;
  left: 50%;
  margin: -25px 0 0 -25px;
  opacity: .7;
  padding: 0;
  position: absolute;
  top: 50%;
  width: 50px;
  z-index: 99999;
}

@keyframes fancybox-rotate {
  100% {
    transform: rotate(360deg);
  }
}

/* Transition effects */

.fancybox-animated {
  transition-timing-function: cubic-bezier(0, 0, 0.25, 1);
}

/* transitionEffect: slide */

.fancybox-fx-slide.fancybox-slide--previous {
  opacity: 0;
  transform: translate3d(-100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--next {
  opacity: 0;
  transform: translate3d(100%, 0, 0);
}

.fancybox-fx-slide.fancybox-slide--current {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

/* transitionEffect: fade */

.fancybox-fx-fade.fancybox-slide--previous,
.fancybox-fx-fade.fancybox-slide--next {
  opacity: 0;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}

.fancybox-fx-fade.fancybox-slide--current {
  opacity: 1;
}

/* transitionEffect: zoom-in-out */

.fancybox-fx-zoom-in-out.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(1.5, 1.5, 1.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 0.5);
}

.fancybox-fx-zoom-in-out.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1);
}

/* transitionEffect: rotate */

.fancybox-fx-rotate.fancybox-slide--previous {
  opacity: 0;
  -ms-transform: rotate(-360deg);
  transform: rotate(-360deg);
}

.fancybox-fx-rotate.fancybox-slide--next {
  opacity: 0;
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}

.fancybox-fx-rotate.fancybox-slide--current {
  opacity: 1;
  -ms-transform: rotate(0deg);
  transform: rotate(0deg);
}

/* transitionEffect: circular */

.fancybox-fx-circular.fancybox-slide--previous {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(-100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--next {
  opacity: 0;
  transform: scale3d(0, 0, 0) translate3d(100%, 0, 0);
}

.fancybox-fx-circular.fancybox-slide--current {
  opacity: 1;
  transform: scale3d(1, 1, 1) translate3d(0, 0, 0);
}

/* transitionEffect: tube */

.fancybox-fx-tube.fancybox-slide--previous {
  transform: translate3d(-100%, 0, 0) scale(0.1) skew(-10deg);
}

.fancybox-fx-tube.fancybox-slide--next {
  transform: translate3d(100%, 0, 0) scale(0.1) skew(10deg);
}

.fancybox-fx-tube.fancybox-slide--current {
  transform: translate3d(0, 0, 0) scale(1);
}

/* Styling for Small-Screen Devices */

/* Share */

.fancybox-share {
  background: #f4f4f4;
  border-radius: 3px;
  max-width: 90%;
  padding: 30px;
  text-align: center;
}

.fancybox-share h1 {
  color: #222;
  font-size: 35px;
  font-weight: 700;
  margin: 0 0 20px 0;
}

.fancybox-share p {
  margin: 0;
  padding: 0;
}

.fancybox-share__button {
  border: 0;
  border-radius: 3px;
  display: inline-block;
  font-size: 14px;
  font-weight: 700;
  line-height: 40px;
  margin: 0 5px 10px 5px;
  min-width: 130px;
  padding: 0 15px;
  text-decoration: none;
  transition: all .2s;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  white-space: nowrap;
}

.fancybox-share__button:visited,
.fancybox-share__button:link {
  color: #fff;
}

.fancybox-share__button:hover {
  text-decoration: none;
}

.fancybox-share__button--fb {
  background: #3b5998;
}

.fancybox-share__button--fb:hover {
  background: #344e86;
}

.fancybox-share__button--pt {
  background: #bd081d;
}

.fancybox-share__button--pt:hover {
  background: #aa0719;
}

.fancybox-share__button--tw {
  background: #1da1f2;
}

.fancybox-share__button--tw:hover {
  background: #0d95e8;
}

.fancybox-share__button svg {
  height: 25px;
  margin-right: 7px;
  position: relative;
  top: -1px;
  vertical-align: middle;
  width: 25px;
}

.fancybox-share__button svg path {
  fill: #fff;
}

.fancybox-share__input {
  background: transparent;
  border: 0;
  border-bottom: 1px solid #d7d7d7;
  border-radius: 0;
  color: #5d5b5b;
  font-size: 14px;
  margin: 10px 0 0 0;
  outline: none;
  padding: 10px 15px;
  width: 100%;
}

/* Thumbs */

.fancybox-thumbs {
  background: #ddd;
  bottom: 0;
  display: none;
  margin: 0;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
  padding: 2px 2px 4px 2px;
  position: absolute;
  right: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  top: 0;
  width: 212px;
  z-index: 99995;
}

.fancybox-thumbs-x {
  overflow-x: auto;
  overflow-y: hidden;
}

.fancybox-show-thumbs .fancybox-thumbs {
  display: block;
}

.fancybox-show-thumbs .fancybox-inner {
  right: 212px;
}

.fancybox-thumbs__list {
  font-size: 0;
  height: 100%;
  list-style: none;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 0;
  position: absolute;
  position: relative;
  white-space: nowrap;
  width: 100%;
}

.fancybox-thumbs-x .fancybox-thumbs__list {
  overflow: hidden;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar {
  width: 7px;
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-track {
  background: #fff;
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.fancybox-thumbs-y .fancybox-thumbs__list::-webkit-scrollbar-thumb {
  background: #2a2a2a;
  border-radius: 10px;
}

.fancybox-thumbs__list a {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  background-color: rgba(0, 0, 0, 0.1);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
  float: left;
  height: 75px;
  margin: 2px;
  max-height: calc(100% - 8px);
  max-width: calc(50% - 4px);
  outline: none;
  overflow: hidden;
  padding: 0;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  width: 100px;
}

.fancybox-thumbs__list a::before {
  border: 6px solid #ff5268;
  bottom: 0;
  content: '';
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  z-index: 99991;
}

.fancybox-thumbs__list a:focus::before {
  opacity: .5;
}

.fancybox-thumbs__list a.fancybox-thumbs-active::before {
  opacity: 1;
}

/* Styling for Small-Screen Devices */

[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}

.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}

.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}

.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  /* Required for horizontal native scrollbar to not appear if parent is taller than natural height */
  width: auto;
  max-width: 100%;
  /* Not required for horizontal scroll to trigger */
  max-height: 100%;
  /* Needed for vertical scroll to trigger */
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content-wrapper::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.simplebar-content:before,
.simplebar-content:after {
  content: ' ';
  display: table;
}

.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}

.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}

.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}

.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}

[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}

[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}

.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}

.simplebar-scrollbar:before {
  position: absolute;
  content: '';
  background: black;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}

.simplebar-scrollbar.simplebar-visible:before {
  /* When hovered, remove all transitions from drag handle */
  opacity: 0.5;
  transition: opacity 0s linear;
}

.simplebar-track.simplebar-vertical {
  top: 0;
  width: 8px;
}

.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}

.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}

.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}

/* Rtl support */

[data-simplebar-direction='rtl'] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}

.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}

.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* Slider */

.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  position: relative;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

.modal {
  background: #ffffff;
}

.modal--auth {
  background: #e7d186;
  max-width: 816px;
  padding: 36px 84px 40px;
  width: 100%;
}

.modal--auth-block {
  display: none;
}

.modal--auth-block.active {
  display: block;
}

.modal--auth-footer {
  margin-top: 44px;
}

.modal--auth-pass {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  margin-right: 24px;
}

.modal--auth-register {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}

.modal--auth-register::before {
  content: "|";
  margin-right: 24px;
  font-size: 20px;
}

.modal--enter-form .inputs--block {
  margin: 0 8px;
  background: #ffffff;
  border-radius: 12px;
  width: 100%;
}

.modal--enter-form .inputs--block:not(:last-child) {
  margin-bottom: 20px;
}

.modal--enter-form .inputs--block input {
  color: #051838;
  border: none;
  width: 100%;
}

.modal--enter-form .inputs--block label {
  color: #051838;
}

.modal--enter-form .more--btn {
  background: #051838;
  color: #ffffff;
  border-color: #051838;
  margin: 32px 0 0 0;
  justify-content: center;
}

.modal--enter-form .more--btn:hover {
  background: #50c878;
  color: #051838;
  border-color: #50c878;
}

.modal--enter-form .inpCheck {
  color: #051838;
}

.modal--enter-form .inpCheck span:before {
  border-color: #051838;
}

.modal--enter-form .inpCheck span:after {
  background-image: url(../pic/check_black.svg);
}

.modal--enter-form .inpCheck > p {
  color: #051838;
}

.modal--callback {
  background: #e7d186;
  max-width: 1128px;
  padding: 78px 164px 98px 164px;
  width: 100%;
}

.modal--callback-title {
  font-size: 44px;
  line-height: 52px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #051838;
  display: block;
  text-align: center;
  margin-bottom: 32px;
  font-family: "Oranienbaum", sans-serif;
}

.modal--callback-subtitle {
  display: block;
  text-align: center;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  color: #051838;
  text-transform: uppercase;
}

.modal--callback-main {
  padding: 0 104px;
}

.modal--callback-main .inputs--wrap {
  width: 100%;
}

.modal--callback-main .inputs--block {
  width: calc(33.33333% - 8px);
  background: #ffffff;
}

.modal--callback-main .inputs--block input {
  color: #051838;
  border-color: #051838;
}

.modal--callback-main .inputs--block label {
  color: #051838;
}

.modal--callback-main .inputs--textarea {
  background: #ffffff;
  margin-top: 16px;
  width: 100%;
  border-radius: 12px;
  border: none;
}

.modal--callback-main .inputs--textarea textarea {
  color: #051838;
  height: 112px;
  resize: none;
}

.modal--callback-main .inputs--textarea label {
  color: #051838;
  transform: translate(-50%, 0);
  top: 20px;
}

.modal--callback-main .inpCheck p {
  color: #051838;
}

.modal--callback-main .more--btn {
  background: #051838;
  color: #ffffff;
  border-color: #051838;
  margin: 32px 0 0 0;
}

.modal--callback-main .more--btn:hover {
  background: #50c878;
  color: #051838;
  border-color: #50c878;
}

.modal--callback-main .inpCheck {
  color: #051838;
}

.modal--callback-main .inpCheck span:before {
  border-color: #051838;
}

.modal--callback-main .inpCheck span:after {
  background-image: url(../pic/check_black.svg);
}

.modal--callback-success {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #e7d186;
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s all ease;
}

.modal--callback-success.active {
  opacity: 1;
  visibility: visible;
}

.modal--callback-success > div {
  width: 100%;
  height: 100%;
}

.ms--title {
  font-size: 44px;
  line-height: 52px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #051838;
  font-family: "Oranienbaum", sans-serif;
  display: block;
  margin-bottom: 16px;
}

.ms--text {
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #051838;
  padding: 0 207px;
  margin-bottom: 32px;
}

.ms--text a {
  font-weight: bold;
}

.modal--forgot {
  max-width: 712px;
  max-height: 500px;
  width: 100%;
  background: #E7D186;
  padding: 36px 84px 40px;
}

.modal--forgot-success {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: #E7D186;
  z-index: 20;
  opacity: 0;
  visibility: hidden;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.modal--forgot-success .more--btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
}

.modal--forgot-success .modal--callback-main {
  width: 100%;
  max-width: 507px;
}

.modal--forgot-success.active {
  opacity: 1;
  visibility: visible;
}

.modal--forgot-wrapper {
  background: #E7D186;
  height: 100%;
}

.modal--forgot-wrapper .ms--title {
  margin-bottom: 0;
}

.modal--forgot .ms--text {
  padding: 0;
}

.modal--forgot .inputs--block {
  width: 100%;
  border-radius: 12px;
}

.modal--forgot .inputs--block .phone-mask {
  color: #051838;
  border: none;
}

.modal--forgot .inputs--block input {
  border: none;
}

.modal--forgot .inpCheck {
  margin-top: 24px;
}

.modal--callback-main {
  padding: 0;
}

.modal--auth-register {
  font-size: 14px;
}

.modal--auth-pass {
  font-size: 14px;
}

.modal--online-success {
  width: 712px;
  height: 500px;
}

.modal-basket__wrap {
  width: 712px;
  background: #E7D186;
  padding: 36px 60px 40px;
  text-align: center;
  color: #051838;
}

.modal-basket__title {
  font-family: Oranienbaum, sans-serif;
  font-size: 44px;
  line-height: calc(52 / 44);
  letter-spacing: 0.04em;
  text-transform: uppercase;
}

.modal-basket__btns {
  margin-top: 20px;
  gap: 24px;
  padding: 0px 44px;
}

.modal-basket__btns .btn-n {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0px;
  height: 56px;
  border-radius: 12px;
  font-family: Oranienbaum, sans-serif;
  font-size: 20px;
  transition: all 0.3s ease 0s;
}

.modal-basket__btns .btn-n-default {
  background: #50C878;
  color: #051838;
  border: 2px solid #50C878;
}

.modal-basket__btns .btn-n-dark {
  background: #051838;
  color: #FFFFFF;
  border: 2px solid #051838;
}

.modal-basket__bottom {
  margin-top: 40px;
  padding-top: 24px;
  border-top: 1px solid #000000;
}

.modal-basket__bottom div {
  font-weight: 700;
  font-size: 20px;
  line-height: calc(28 / 20);
}

.modal-basket__bottom p {
  margin-top: 8px;
  font-size: 16px;
  line-height: calc(20 / 16);
  padding: 0px 20px;
}

.header {
  background: #ffffff;
  position: fixed;
  width: 120px;
  height: 100%;
  z-index: 9999;
}

.header--wrap {
  height: 100%;
  padding: 24px 0 50px 0;
  z-index: 2;
  position: relative;
  background: #e7d186;
}

.header--social:hover svg {
  color: #50c878;
}

.header--youtube:hover svg {
  color: #50c878;
}

.header--main-wrap {
  height: 100%;
  position: relative;
}

.header--main-wrap nav {
  max-height: 100%;
  padding-right: 55px;
}

.header--main-wrap button {
  font-family: Noah, sans-serif;
  font-size: 24px;
  line-height: 32px;
  z-index: 2;
  text-align: left;
  font-weight: 500;
  color: #051838;
  margin-top: 40px;
}

.bh {
  position: relative;
}

.bh:before {
  content: "";
  width: 100%;
  height: 1px;
  background: #52739a;
  transition: 0.3s all ease;
  opacity: 0;
  visibility: hidden;
  position: absolute;
  display: block;
  top: 100%;
}

.bh:hover:before {
  opacity: 1;
  visibility: visible;
}

.menu--block {
  margin-bottom: 32px;
}

.menu--block > div:first-child {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 20px;
}

.menu--block span {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  display: block;
  margin-bottom: 20px;
}

.menu--block.links ul li a {
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  /* @include lg {
              font-size: 16px;
              line-height: 24px;
            } */
}

.menu--block ul li {
  margin-bottom: 16px;
}

.menu--block ul li:last-child {
  margin-bottom: 0;
}

.menu--block ul li a {
  font-size: 20px;
  line-height: 28px;
  position: relative;
}

.menu--link.big {
  font-weight: 500;
  font-size: 28px;
  line-height: 32px;
  margin-bottom: 44px;
  display: inline-block;
}

.menu--main-wrap {
  position: absolute;
  top: 0;
  bottom: 0;
  left: -9999px;
  opacity: 0;
  visibility: hidden;
  min-width: 520px;
  background: #ffffff;
  padding: 50px 45px 50px 120px;
  color: #051838;
  transition: 0.3s all ease;
  z-index: 1;
}

.menu--main-wrap.active {
  left: 100%;
  opacity: 1;
  visibility: visible;
}

.menu--wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.menu-btn {
  --icon-width: 32px;
  position: relative;
  width: var(--icon-width);
  flex-direction: column;
  transition: 0.3s all ease;
  cursor: pointer;
  z-index: 10;
}

.menu-btn:hover .line {
  background: #50c878;
}

.menu-btn.close .line__1 {
  transform: rotate(45deg) translate(2px, -3px);
  opacity: 0;
  visibility: hidden;
}

.menu-btn.close .line__2 {
  transform: rotate(-45deg);
}

.menu-btn.close .line__3 {
  transform: rotate(45deg) translate(-5px, 1px);
}

.menu-btn .line {
  width: var(--icon-width);
  background: #051c38;
  height: 2px;
  margin-bottom: 12px;
  transition: 0.3s all ease;
}

.menu-btn .line:last-child {
  margin-bottom: 0;
}

.menu-btn .line__1 {
  width: var(--icon-width);
  transform-origin: left;
}

.menu-btn .line__2 {
  width: var(--icon-width);
  transform-origin: center;
}

.menu-btn .line__3 {
  width: var(--icon-width);
  transform-origin: right;
}

.header--lk {
  text-align: center;
}

.header--lk:hover span {
  color: #50c878;
}

.header--lk:hover svg {
  color: #50c878;
}

.header--lk svg {
  color: #000000;
}

.header--lk span {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #051838;
  display: block;
  margin-top: 16px;
}

/***********************************************************/

.header-basket {
  width: 80px;
  height: 80px;
  background: #ffffff;
  border-radius: 200px;
  display: inline-flex;
  align-items: center;
  flex-direction: column;
  padding: 8px;
}

.header-basket__icon {
  width: 32px;
  height: 24px;
}

.header-basket__icon img {
  max-width: 100%;
}

.header-basket__text {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #051838;
}

.header-basket__count {
  margin-top: 8px;
  width: 28px;
  height: 16px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: #50c878;
  border-radius: 32px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #051838;
}

.home--main {
  height: 918px;
  position: relative;
  overflow: hidden;
  margin-left: auto;
  background: radial-gradient(67.35% 67.35% at 50% 0%, #083d79 0%, #051838 100%), #051c38;
  max-width: 1920px;
  border-bottom: 1px solid #52739a;
}

.home--menu {
  position: relative;
}

.home--menu ul {
  flex: 1 auto;
  margin: 0 -8px 0 -8px;
}

.home--menu-wrap {
  bottom: -100px;
  position: absolute;
  left: 0;
  right: 0;
}

.home--question {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 144px;
  height: 144px;
  background: rgba(5, 24, 56, 0.5);
  border-radius: 50%;
  top: 75px;
  z-index: 2;
}

.home--question:hover span {
  color: #e7d186;
}

.home--question:hover svg {
  color: #e7d186;
}

.home--question svg {
  color: #50c878;
}

.home--question span {
  display: block;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  color: #50c878;
  margin-top: 12px;
}

.home--question a {
  width: 100%;
  height: 100%;
  padding: 0 42px;
  text-align: center;
}

.menu--desc {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #ffffff;
  display: inline-flex;
  margin: 0 32px 0 32px;
  width: 100%;
}

.menu--line {
  width: 100%;
  height: 1px;
  flex: 1 auto;
  background: #ffffff;
  display: block;
}

.menu--center {
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.menu--center div {
  margin: 15px 0 0 0;
  width: 100%;
}

.menu--center p {
  font-family: "Oranienbaum", sans-serif;
  font-size: 96px;
  line-height: 96px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #e7d186;
}

.menu--item {
  width: 192px;
  height: 163px;
  margin: 0 8px;
}

.menu--item:nth-child(2) {
  margin-right: 360px;
}

.menu--item:nth-child(3) {
  margin-left: 360px;
}

.menu--item a {
  width: 100%;
  height: 100%;
}

.menu--item span {
  display: block;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  color: #50c878;
  margin-top: 12px;
}

.skull {
  position: absolute;
  top: -60px;
  left: 50%;
  transform: translate(-50%, 0);
  min-width: 2727px;
}

.skull img {
  width: 100%;
  height: 100%;
}

.about--left {
  width: 40%;
}

.about--left > div {
  padding-right: 113px;
  padding-top: 16px;
}

.about--top {
  font-family: "Oranienbaum", sans-serif;
  position: relative;
}

.about--top a.about--link {
  font-family: "Noah", sans-serif;
}

.about--top a.about--inst {
  position: absolute;
  right: 0;
  top: 18px;
}

.about--top a.about--inst svg {
  width: 20px;
  height: 20px;
}

.about--top a.about--youtube {
  position: absolute;
  right: 0;
  top: 60px;
}

.about--top a.about--youtube svg {
  width: 20px;
  height: 20px;
}

.about--top svg {
  color: #e7d186;
}

.about--top svg:hover {
  color: #50c878;
}

.about--title {
  font-size: 44px;
  line-height: 52px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #e7d186;
  display: block;
}

.about--text {
  font-size: 32px;
  line-height: 40px;
  color: #e7d186;
  margin-top: 8px;
  display: block;
}

.about--right {
  width: 60%;
  position: relative;
  display: flex;
}

.about--right video {
  max-height: 517px;
  width: 100%;
  height: 100%;
}

.about--link {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #50c878;
  display: inline-flex;
  margin-top: 24px;
}

.about--links-wrap {
  margin-top: 16px;
  font-size: 20px;
  line-height: 28px;
  color: #50c878;
  font-family: Oranienbaum, sans-serif;
}

.about--links-wrap span {
  margin: 0 24px;
  display: block;
}

.about--bottom {
  border: 2px solid #50c878;
  border-radius: 20px;
  padding: 32px 32px 44px 32px;
  margin-top: 44px;
}

.about--bottom svg {
  color: #e7d186;
  margin-bottom: 24px;
}

.about--bottom > span {
  font-size: 32px;
  line-height: 40px;
  color: #e7d186;
  font-family: Oranienbaum, sans-serif;
}

.reviews--main-wrap {
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
  height: 100%;
}

.reviews--main-bg {
  position: absolute;
  width: 23.33333%;
  right: 0;
  top: 0;
  bottom: 0;
  background: linear-gradient(270deg, #051838 0%, rgba(5, 24, 56, 0) 100%);
}

.reviews--main .slick-track {
  margin: 0 -60px;
}

.reviews--main .slick-track {
  display: flex;
}

.reviews--main .slick-list {
  padding-right: 20%;
  overflow: visible !important;
}

.reviews--main .slick-list:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #051838;
  left: -100%;
  z-index: 2;
}

.reviews--block {
  margin: 0 60px;
}

.reviews--block p {
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  margin-top: 14px;
}

.reviews--name {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #50c878;
}

.reviews--tag {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #50c878;
  padding: 6px 16px;
  background: #1c4d4b;
  border-radius: 12px;
  margin-top: 24px;
}

.callback--text {
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
  margin: 16px auto 32px auto;
  max-width: 1130px;
}

.callback--main {
  width: 60%;
  text-align: center;
}

.callback--main > p {
  font-family: "Oranienbaum", sans-serif;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #e7d186;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
  margin-bottom: 20px;
}

.callback--main .custom--select {
  width: 100%;
  margin: 0 auto;
}

.callback--main .inputs--wrap {
  width: 100%;
  margin-top: 24px;
  margin-bottom: 32px;
}

.callback--main .inputs--wrap > div {
  width: calc(33.33333% - 8px);
}

.callback--main-wrap {
  padding: 40px 0 60px 0;
  background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #000712;
  border: 1px solid #52739a;
  border-radius: 20px;
}

.callback--main .more--btn {
  background: #50c878;
  color: #051838;
}

.callback--main .more--btn:hover {
  color: #50c878;
  background: transparent;
}

.video--slider-wrap {
  position: relative;
}

.video--slider-wrap .slick-list {
  overflow: visible !important;
}

.video--slider-wrap .slick-list:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #051838;
  left: -100%;
  z-index: 2;
}

.video--slider-wrap video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.video--slider-wrap .container {
  position: relative;
}

.video--slider-main .slick-slide {
  max-width: 240px;
}

.video--block {
  position: relative;
  margin: 0 8px;
}

.video--block .video--play {
  width: 60px;
  height: 60px;
  opacity: 0;
  visibility: hidden;
  transition: 1.5s opacity ease, 0.3s background-color ease;
}

.video--block > .video--main {
  display: flex;
  min-height: 343px;
}

.video--block .no-video {
  background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #051838;
  border: 1px solid #52739a;
  overflow: hidden;
  position: relative;
}

.video--block .no-video--title {
  font-family: Oranienbaum;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #e7d186;
  padding: 0 0 40px 0;
  margin: 20px auto 0;
  display: block;
  max-width: 90%;
}

.video--block .no-video--title:hover {
  color: #50c878;
}

.video--block .no-video--image {
  margin-top: 20px;
}

.video--block .no-video--image img {
  max-height: 180px;
}

.video--block .no-video--counter {
  position: absolute;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #051838;
  background: #50c878;
  border-radius: 50%;
  width: 52px;
  height: 52px;
  font-family: Oranienbaum;
  font-weight: normal;
  padding-top: 6px;
  transform: translateX(-50%);
  top: 53px;
  left: 50%;
}

.video-modal-window .fancybox-content {
  max-width: 712px;
  width: 100%;
  max-height: 500px;
}

.video-modal-window .fancybox-content .fancybox-button--close {
  right: 0;
  z-index: 1;
  position: absolute;
}

.skull--pages {
  position: absolute;
  top: -65px;
  left: 48%;
  transform: translate(-50%, 0);
  min-width: 1978px;
}

.skull--pages-wrap {
  min-height: 500px;
  position: relative;
  background: radial-gradient(67.35% 67.35% at 50% 0, #083d79 0, #051838 100%), #051c38;
  margin-bottom: 44px;
}

.skull--pages-wrap.detail_event {
  min-height: 590px;
}

.skull--pages img {
  width: 100%;
  height: 100%;
}

.pages--title {
  width: 100%;
}

.pages--title h1 {
  margin: 0 32px;
  font-size: 44px;
  line-height: 52px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #e7d186;
  font-weight: normal;
  font-family: "Oranienbaum", sans-serif;
  text-align: center;
}

.pages--title span {
  display: block;
  flex: 1 auto;
  height: 1px;
  background: #e7d186;
}

.pages--title_white {
  color: #ffffff;
  background-color: transparent;
  display: block;
}

.pages--bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.pages--question {
  position: absolute;
  left: 50%;
  transform: translate(-50%, 0);
  width: 144px;
  height: 144px;
  background: rgba(5, 24, 56, 0.5);
  border-radius: 50%;
  top: -68px;
  z-index: 2;
}

.pages--question:hover span {
  color: #e7d186;
}

.pages--question:hover svg {
  color: #e7d186;
}

.pages--question svg {
  color: #50c878;
  opacity: 0;
}

.pages--question span {
  display: block;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.21em;
  text-transform: uppercase;
  color: #50c878;
  margin-top: 12px;
}

.pages--question a {
  width: 100%;
  height: 100%;
  padding: 0 42px;
  text-align: center;
}

.pages--desc-wrap {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  margin-bottom: -16px;
}

.pages--desc-wrap p {
  margin-bottom: 16px;
}

.group--top {
  border: 1px solid #e7d186;
  border-radius: 20px;
}

.group--bottom {
  padding: 0 6.66667%;
  margin-top: 32px;
}

.group--block {
  margin: 0 10px;
  min-height: 180px;
}

.group--block:first-child {
  margin-left: 0;
}

.group--block:last-child {
  margin-right: 0;
}

.group--block-desc {
  margin-top: 12px;
  padding: 0 10%;
  text-align: center;
}

.group--block-row {
  padding: 0 24px;
}

.group--block-row.row_1:before {
  content: "";
  width: 1px;
  height: 100%;
  position: absolute;
  right: -1px;
  background: #e7d186;
  top: 0;
  bottom: 0;
}

.group--block span {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #50c878;
  padding: 4px 16px;
  display: inline-flex;
  background: #1c4d4b;
  border-radius: 12px;
  margin-top: auto;
}

.group--block-wrap {
  width: 50%;
  position: relative;
  padding: 32px 0;
}

.group--title-main {
  font-size: 32px;
  line-height: 44px;
  color: #e7d186;
  font-family: "Oranienbaum", sans-serif;
  display: block;
  text-align: center;
  margin-bottom: 8px;
}

.mbg {
  margin-bottom: -60px;
}

.mbg .itog {
  font-family: "Oranienbaum", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: #50c878;
  width: calc(100% - 208px);
  margin-left: auto;
}

.mbg--title {
  font-family: "Oranienbaum", sans-serif;
  font-size: 44px;
  line-height: 52px;
  color: #e7d186;
  display: block;
  margin-bottom: 20px;
}

.mbg--block {
  width: calc(50% - 60px);
  margin-bottom: 60px;
}

.mbg--text {
  width: calc(100% - 208px);
  margin-top: 24px;
  margin-left: auto;
}

.abp {
  padding: 60px 6.66667%;
  background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #000712;
  border: 1px solid #52739a;
  border-radius: 20px;
}

.abp--title {
  font-family: "Oranienbaum", sans-serif;
  font-size: 44px;
  line-height: 52px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #e7d186;
  display: block;
  margin-bottom: 32px;
}

.abp--block {
  color: #ffffff;
}

.abp--block span {
  font-size: 32px;
  line-height: 40px;
  font-family: "Oranienbaum", sans-serif;
  display: block;
  margin-bottom: 20px;
}

.online--container {
  border-bottom: 1px solid #50c878;
  padding-bottom: 60px;
}

.online--container.last {
  border: 0;
  padding: 0;
}

.online--main-wrap {
  display: none;
}

.online--main-wrap .section--main {
  padding: 0;
}

.online--main-wrap .event--card-wrap {
  background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%);
  border: 1px solid #52739a;
}

.online--main-wrap.active {
  margin-top: 40px;
}

.online--right {
  width: calc(100% - 209px);
  margin: 20px 0 0 auto;
}

.online--right-descr {
  font-size: 20px;
  line-height: 32px;
}

.online--title {
  font-family: "Oranienbaum", sans-serif;
}

.online--title.big {
  font-size: 36px;
  line-height: 48px;
  color: #e7d186;
  display: block;
  margin-bottom: 16px;
}

.online--title.small {
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;
}

.online--title-closest {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #50c878;
  margin-left: 209px;
  display: block;
  margin-bottom: 30px;
}

.online--btns {
  margin-left: 209px;
  margin-top: 40px;
  font-family: "Oranienbaum", sans-serif;
  color: #50c878;
}

.online--btns.active {
  margin-top: 32px;
}

.online--btns .slide--toggle + a {
  margin-left: 24px;
}

.online--more-link.btn {
  padding: 12px 42px;
}

.slide--toggle {
  position: relative;
  overflow: hidden;
}

.slide--toggle.active svg {
  transform: translate(0, -50%) rotate(180deg);
}

.slide--toggle span {
  font-size: 20px;
  line-height: 28px;
  padding: 12px 42px;
  text-transform: none;
  letter-spacing: normal;
  font-weight: normal;
  color: #50c878;
  position: relative;
  z-index: 2;
  display: block;
  transition: 0.3s all ease;
  border-radius: 12px;
  border: 2px solid #50c878;
}

.slide--toggle svg {
  right: 44px;
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  color: #50c878;
}

.modal--event {
  width: 1128px;
  padding: 12px 48px 80px 12px;
  height: calc(100vh - 366px);
  overflow: hidden;
}

.modal--event-wrap {
  height: 100%;
}

.modal--event-tags {
  margin-bottom: 4px;
}

.modal--event-tags > * {
  margin: 0 2px;
}

.modal--event-tags > *:first-child {
  margin-left: 0;
}

.modal--event-tags > *:last-child {
  margin-right: 0;
}

.modal--event-title {
  font-size: 44px;
  line-height: 52px;
  color: #051838;
  font-family: "Oranienbaum", sans-serif;
}

.modal--event-top_main {
  padding-left: 52px;
}

.modal--event-top_right {
  margin-top: 20px;
}

.modal--event-top_bottom {
  margin-top: 8px;
}

.modal--event-top_bottom p {
  font-size: 20px;
  line-height: 32px;
  color: #000000;
  margin-left: 12px;
}

.modal--event-icon {
  margin-right: 12px;
}

.modal--event-date span {
  font-size: 20px;
  line-height: 32px;
  font-weight: bold;
  color: #051838;
}

.modal--event-date div {
  width: 16px;
  height: 1px;
  background: #051838;
  margin: 0 12px;
}

.modal--event-block {
  margin: 0 0 40px 75px;
}

.modal--event-block:last-child {
  margin-bottom: 0;
}

.modal--event-block_title {
  font-family: "Oranienbaum", sans-serif;
  font-size: 32px;
  line-height: 40px;
  color: #051838;
  display: block;
  margin: 0 0 12px 44px;
}

.modal--event-block p,
.modal--event-block li {
  font-size: 20px;
  line-height: 32px;
  color: #000712;
}

.modal--event-block .mst {
  padding: 0 16px;
}

.modal--event-block .mst span {
  margin-left: 12px;
}

.modal--event-block .mst div {
  margin-left: 12px;
}

.modal--event-block .mst p {
  margin-bottom: 12px;
}

.modal--event-block .mst p:last-child {
  margin-bottom: 0;
}

.modal--event-block ul {
  padding: 0 16px 12px 16px;
  margin-bottom: 32px;
  border-bottom: 1px solid #051838;
}

.modal--event-block ul.nt {
  border-bottom: 0;
  margin: 0 0 12px 0;
}

.modal--event-block ul.nt li {
  width: 100%;
}

.modal--event-block ul li {
  position: relative;
  width: calc(33.33333% - 8px);
  margin: 0 0 20px 0;
  padding-left: 28px;
}

.modal--event-block ul li:before {
  content: "";
  background-image: url(../pic/star_green.svg);
  width: 12px;
  height: 11px;
  background-size: cover;
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
}

.modal--event-block.block_1 {
  border: 1px solid #50c878;
  border-radius: 20px;
  padding: 12px 12px;
}

.modal--event-block.block_1 p {
  display: inline-flex;
  margin: 0 12px 8px 12px;
}

.modal--event-block.block_3 p {
  font-family: "Oranienbaum", sans-serif;
  font-size: 32px;
  line-height: 40px;
  color: #50c878;
  padding: 24px 28px 32px 28px;
  border: 1px solid #50c878;
  border-width: 1px 0 1px 0;
}

.modal--event-main {
  margin-top: 24px;
  overflow-y: auto;
  height: 100%;
  max-height: 100%;
  padding-right: 32px;
}

.about--page-top {
  position: relative;
  margin-bottom: 32px;
}

.about--page-top video {
  height: 100%;
}

.about--page-top .video--play {
  width: 60px;
  height: 60px;
}

.about--page-title {
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 16px;
  font-family: "Oranienbaum", sans-serif;
}

.about--page-bottom {
  text-align: center;
}

.about--page-bottom span {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #50c878;
  display: block;
  margin-bottom: 40px;
}

.about--bottom-bg {
  margin-left: -120px;
  width: 100%;
  position: relative;
}

.about--bottom-bg img {
  width: calc(100% + 120px);
  height: auto;
  right: 0;
  top: 0;
  max-width: initial;
}

.about--gallery-wrap {
  padding: 0 107px;
  position: relative;
}

.about--gallery-wrap .slick-list {
  overflow: visible;
}

.about--gallery-wrap .slick-track {
  display: flex;
}

.about--gallery-wrap .slider--left {
  left: 20px;
}

.about--gallery-wrap .slider--right {
  right: 20px;
}

.about--gallery-main {
  overflow: hidden;
}

.about--gallery-main div.gallery--slide {
  width: calc(100% / 8);
}

.about--gallery-main div.gallery--slide img {
  width: 100%;
  height: 100%;
}

.pagination {
  font-family: Oranienbaum;
  font-weight: normal;
  margin: 0 auto;
  font-size: 20px;
  line-height: 28px;
  color: #e7d186;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination--pages {
  margin: 0px 110px;
}

.pagination--pages-list {
  display: flex;
}

.pagination--pages-list > li {
  width: 32px;
  height: 28px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0px 40px;
}

.pagination--pages-list > li._activ-page {
  color: #50c878;
}

.pagination--pages-link:hover,
.pagination--pages-link.selected {
  color: #50c878;
}

.pagination-left--link {
  display: flex;
  align-items: center;
}

.pagination-left--link span {
  margin-left: 32px;
}

.pagination-left:hover {
  color: #50c878;
}

.pagination-right--link {
  display: flex;
  align-items: center;
}

.pagination-right--link span {
  margin-right: 32px;
}

.pagination-right:hover {
  color: #50c878;
}

.show-more-cards {
  white-space: nowrap;
}

.page-bottom {
  margin-top: 32px;
}

.page-bottom .pagination {
  margin-left: auto;
  margin-right: 0;
}

.detail--event {
  color: #fff;
}

.detail--event-wrap {
  max-width: 1160px;
  margin: 0 auto;
}

.detail--event-wrap .event--date,
.detail--event-wrap .event--date-time,
.detail--event-wrap .event--date-days {
  width: 100%;
  text-align: center;
}

.detail--event-top {
  border: 1px solid #e7d186;
  border-radius: 20px;
  padding: 42px 64px;
}

.detail--event-top .event--date {
  margin-top: 32px;
}

.detail--event .section--tag {
  width: fit-content;
}

.detail--event-tags {
  margin-left: 41px;
}

.detail--event-tags .section--tag,
.detail--event-tags .event--leading-online {
  margin-right: 4px;
}

.detail--event-tags .section--tag,
.detail--event-tags .event--leading-online,
.detail--event-tags .event--leading {
  margin-top: 4px;
  margin-left: 0;
}

.detail--event-tags .event--leading {
  margin-top: 12px;
}

.detail--event-bottom {
  margin-top: 60px;
  padding: 40px 64px;
  border: 2px solid #50c878;
  border-bottom: none;
  border-radius: 20px 20px 0 0;
}

.detail--event-bottom_border {
  border-bottom: 2px solid #50c878;
  border-radius: 20px;
  margin-bottom: 32px;
}

.detail--event-bottom .event--date,
.detail--event-bottom .event--date-time,
.detail--event-bottom .event--date-days {
  width: initial;
}

.detail--event-bottom .event--date-days {
  padding: 3px 10px 3px 6px;
}

.detail--event-bottom_top {
  padding-bottom: 28px;
  margin-bottom: 28px;
  border-bottom: 1px solid #50c878;
}

.detail--event-bottom_top .event--date,
.detail--event-bottom_top .event--leading {
  margin-top: 0;
  margin-left: 16px;
}

.detail--event-bottom_top .detail--event-bottom_item:last-child {
  margin-left: 268px;
  margin-right: auto;
}

.detail--event-bottom_item:last-child {
  margin-left: 102px;
}

.detail--event-bottom_item .event--price-timer {
  height: auto;
  border-radius: 22px;
  padding: 5px 10px;
  width: fit-content;
  min-width: auto;
  min-height: 28px;
}

.detail--event-bottom_item .event--price-timer > svg {
  margin-right: 6px;
  width: 12px;
  height: 12px;
}

.detail--event-bottom_item .event--price-timer .price-timer {
  font-size: 14px;
  line-height: 16px;
}

.detail--event-bottom_item .more--btn {
  background: #50c878;
  color: #051838;
  margin-top: 0;
  padding-left: 65px;
  padding-right: 65px;
}

.detail--event-bottom_item .more--btn:hover {
  color: #50c878;
  background: transparent;
}

.detail--event-bottom_item .event--price-price {
  margin-top: 4px;
  font-size: 28px;
  line-height: 36px;
  margin-left: 0;
}

.detail--event-bottom_item .event--price-old {
  font-size: 16px;
  line-height: 24px;
  margin-left: 0;
  margin-top: 4px;
}

.detail--event-bottom_item .section--tag.section--tag_level {
  margin-left: 16px;
}

.detail--event-bottom_item .section--tag.section--tag_level span {
  margin-left: 12px;
}

.detail--event-bottom_item .detail--event-bottom_price {
  margin-bottom: 32px;
  font-weight: 700;
  font-size: 16px;
  line-height: calc(20 / 16);
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #50c878;
}

.detail--event-bottom_rate {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #50c878;
  margin-bottom: 32px;
}

.detail--event-bottom_installment {
  font-size: 16px;
  line-height: 24px;
  color: #50c878;
  margin-left: 16px;
}

.detail--event-title {
  font-size: 44px;
  line-height: 52px;
  color: #051838;
  font-family: "Oranienbaum", sans-serif;
}

.detail--event-top_main .event--price-wrapper {
  margin: 0;
  align-items: start;
}

.detail--event-top_main .event--price-timer {
  border-radius: 16px;
  padding: 2px 16px 2px 12px;
  height: 25px;
  margin-bottom: 15px;
  min-width: 130px;
}

.detail--event-top_main .event--price-timer .price-timer {
  font-size: 16px;
}

.detail--event-top_main .event--price-btn {
  background: #50c878;
  border-radius: 12px;
  width: 100%;
  max-width: 191px;
  color: #051838;
  justify-content: center;
}

.detail--event-top_main .event--price-title {
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

.detail--event-top_main .event--price-price {
  font-size: 32px;
  line-height: 28px;
  margin-left: 0;
}

.detail--event-top_main .event--price-old {
  margin-left: 12px;
}

.detail--event-main {
  width: 100%;
  display: flex;
}

.detail--event-text {
  padding: 60px 0 0 0;
}

.detail--event-wrapper {
  max-width: 1160px;
  margin: 0 auto;
  position: relative;
}

.detail--event-descr {
  max-width: 560px;
  margin-left: 48px;
  font-size: 20px;
  line-height: 32px;
  color: #e7d186;
  width: 100%;
}

.detail--event-top_title {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
  margin-top: 32px;
}

.detail--event-top_leader {
  display: flex;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
}

.detail--event-top_leader-name {
  margin-left: 16px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #50c878;
  background: #1c4d4b;
  border-radius: 20px;
  padding: 6px 16px;
}

.detail--event-top_bottom {
  margin-top: 8px;
}

.detail--event-icon {
  margin-right: 12px;
}

.detail--event-date {
  border: 1px solid #ffffff;
  border-radius: 16px;
  padding-left: 12px;
  padding-right: 16px;
  max-width: fit-content;
  height: 28px;
}

.detail--event-date svg {
  margin-right: 8px;
}

.detail--event-date span {
  font-size: 16px;
  line-height: 20px;
  font-weight: bold;
  color: #ffffff;
}

.detail--event-date div {
  width: 16px;
  height: 1px;
  background: #ffffff;
  margin: 0 12px;
}

.detail--event-block {
  margin-bottom: 32px;
}

.detail--event-block_gold {
  color: #e7d186;
  font-weight: 700;
}

.detail--event-block:last-child {
  margin-bottom: 0;
}

.detail--event-block_title {
  font-family: "Oranienbaum", sans-serif;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  display: block;
  margin: 0 0 12px 30px;
}

.detail--event-block p,
.detail--event-block li {
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
}

.detail--event-block .mst p {
  margin-bottom: 12px;
}

.detail--event-block .mst p.re {
  color: #e7d186;
}

.detail--event-block .mst p:last-child {
  margin-bottom: 0;
}

.detail--event-block ul {
  margin-bottom: 12px;
  border-bottom: 1px solid #051838;
}

.detail--event-block ul.nt {
  border-bottom: 0;
  margin-bottom: 32px;
}

.detail--event-block ul.nt li {
  width: auto;
}

.detail--event-block ul li {
  position: relative;
  width: calc(33.33333% - 8px);
  margin: 0 0 20px 0;
  padding-left: 28px;
}

.detail--event-block ul li:before {
  content: "";
  background-image: url(../pic/star_green.svg);
  width: 12px;
  height: 11px;
  background-size: cover;
  display: block;
  position: absolute;
  left: 0;
  top: 8px;
}

.detail--event-block.block_1 > div {
  padding: 0 16px;
}

.detail--event-block.block_2 .detail--event-block_title {
  margin-left: 0;
}

.detail--event-block.block_2 .mst {
  padding: 0 16px;
}

.detail--event-block.block_3 {
  margin: 40px 0;
  border-top: 1px solid #ffffff;
  border-bottom: 1px solid #ffffff;
}

.detail--event-block.block_3 p {
  font-family: "Oranienbaum", sans-serif;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  padding: 24px 16px;
}

.detail--event-block.block_4 {
  padding: 0 16px;
}

.detail--event-know {
  margin-bottom: 32px;
  border-top: 1px solid #b3e4ff;
}

.detail--event-buttons :first-child {
  margin-right: 16px;
}

.detail--event-buttons a {
  margin-top: 0;
}

.events-slider {
  padding: 80px 0;
}

.events-slider--wrapper {
  position: relative;
}

.events-slider--wrapper .container {
  position: relative;
}

.events-slider--title {
  font-family: Oranienbaum;
  font-style: normal;
  font-weight: normal;
  font-size: 44px;
  line-height: 52px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #e7d186;
  margin-bottom: 28px;
}

.events-slider .slick-list {
  overflow: visible !important;
}

.events-slider .section--main {
  min-height: 293px;
}

.events-slider .section--main::before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 125%;
  background: #051838;
  left: -100%;
  z-index: 2;
}

.events-slider .slick-slide {
  margin: 0 8px;
}

.events-slider .slick-track {
  min-width: max-content;
}

.d-n {
  display: none;
}

.detail-vip {
  background: #e7d186;
  border-radius: 0px 0px 20px 20px;
  padding: 40px 64px;
  border: 2px solid #50c878;
  border-top: none;
  margin-bottom: 32px;
}

.detail-vip__label {
  padding: 4px 16px;
  background: #50c878;
  border-radius: 20px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #051838;
  margin-bottom: 20px;
  width: max-content;
}

.detail-vip__list {
  padding-bottom: 32px;
  margin-bottom: 32px;
  border-bottom: 1px solid #051838;
}

.detail-vip__list > li {
  color: #051838;
  width: calc(33% - 12px);
  display: flex;
  font-size: 20px;
  line-height: 32px;
}

.detail-vip__list > li::before {
  content: url(../pic/star-dark.svg);
  margin-right: 16px;
}

.detail-vip__list > li:not(:nth-child(n + 3)) {
  margin-bottom: 20px;
}

.detail-vip__price {
  font-weight: 700;
  font-size: 32px;
  line-height: 44px;
  color: #051838;
}

.detail-vip__btn.btn {
  color: #051838;
  border: 2px solid #051838;
  margin-left: 88px;
  padding-left: 65px;
  padding-right: 65px;
}

.detail-vip__installment {
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  color: #051838;
  margin-left: 16px;
}

.register {
  padding-bottom: 80px;
}

.register--title {
  font-family: Oranienbaum;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  color: #e7d186;
  margin-bottom: 20px;
}

.register--form {
  padding: 0 208px;
}

.register--inputs-block .inputs--block {
  width: 100%;
  margin-bottom: 32px;
  border-radius: 12px;
}

.register--inputs-block .inputs--block:not(:last-child) {
  margin-right: 16px;
}

.register--inputs-block .inputs--block input {
  padding: 22px 11px 14px;
}

.register--inputs-block .inputs--block .custom-select-opener {
  padding: 18px 62px 18px 26px;
}

.register--inputs-block .inputs--block.select-block {
  z-index: 1;
}

.register--inputs-block .inputs--block.select-block:not(:last-child) {
  z-index: 2;
}

.register--inputs-block .inputs--block.inputs--block_col {
  flex: 0 0 calc(50% - 8px);
}

.register--submit {
  text-align: center;
}

.register--agree .parsley-errors-list {
  display: none;
}

.register .more--btn {
  margin-top: 0;
  color: #051838;
  background: #50c878;
}

.register .more--btn:hover {
  background: transparent;
  color: #50c878;
}

.register .custom-select-opener,
.register .custom-select-option {
  text-align: left;
  font-size: 12px;
  line-height: 16px;
}

.register .inputs--block,
.register .inputs--textarea,
.register .inpCheck {
  z-index: 0;
}

.register label {
  width: 90%;
  text-align: center;
  justify-content: center;
}

.register label > svg {
  margin-left: auto;
}

.register .date-block {
  z-index: 3;
}

.register .custom-select-container.is-open .custom-select-panel {
  max-height: 200px;
  overflow: visible;
}

.register .simplebar-track {
  background: #f2f2f2;
  right: 25px;
  top: 15px;
  bottom: 15px;
}

.register .simplebar-track .simplebar-scrollbar::before {
  border-radius: 0;
  background: #e7d186;
  left: 0;
  right: 0;
  opacity: 1;
  top: 0;
  bottom: 0;
}

.modal-register {
  height: 500px;
  width: 712px;
}

.modal-register--success {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
}

.modal-register .ms--title {
  margin-bottom: 0;
}

.drop-help.inputs--block {
  position: relative;
  z-index: 2;
}

.drop-help._active {
  z-index: 4;
}

.drop-help._active input {
  border-bottom-color: #000712 !important;
  border-radius: 12px 12px 0 0;
}

.drop-help._active .drop-help__list {
  border-width: 0 2px 2px 2px;
  border-style: solid;
  border-color: #50c878;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  max-height: 200px;
}

.drop-help._active .drop-help__list:before {
  content: "";
  display: block;
  width: calc(100% - 40px);
  height: 1px;
  background: #50c878;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.drop-help__list {
  position: absolute;
  left: 0;
  right: 0;
  top: calc(100% - 1px);
  background: #000712;
  max-height: 0;
  overflow: hidden;
  z-index: 2;
  overflow: visible;
}

.drop-help__list .simplebar-content div {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: .2em;
  text-transform: uppercase;
  color: #e7d186;
  margin: 14px 0;
  padding: 10px 64px 10px 26px;
  cursor: pointer;
  transition: all 0.3s ease 0s;
}

.reviews {
  color: #fff;
}

.reviews__descr {
  max-width: 1333px;
  margin: 0 auto;
  font-family: Oranienbaum;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
}

.reviews__tags {
  margin-top: 24px;
}

.reviews__tags-item {
  display: flex;
  align-items: center;
  padding: 6px 16px;
  background: #1c4d4b;
  border-radius: 20px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #50c878;
  margin-bottom: 4px;
}

.reviews__tags-item:not(:last-child) {
  margin-right: 12px;
}

.reviews__warn {
  text-align: center;
  margin: 32px 0;
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #50c878;
}

.reviews__item {
  padding: 32px;
  border: 1px solid #e7d186;
  border-radius: 20px;
}

.reviews__item:not(:last-child) {
  margin-bottom: 20px;
}

.reviews__date {
  font-size: 16px;
  line-height: 20px;
  color: #e7d186;
}

.reviews__name {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #50c878;
  margin-top: 24px;
}

.reviews__text {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  margin-top: 12px;
}

.reviews__wrapper {
  max-width: 1333px;
  margin: 0 auto;
  padding: 40px 0 60px;
}

.reviews__link {
  text-align: center;
}

.reviews__form {
  padding-bottom: 80px;
}

.reviews__form .inputs--wrap .inputs--block {
  width: 100%;
}

.reviews__form button {
  background: #50c878;
  color: #051838;
}

.reviews__form .custom-select-option,
.reviews__form .custom-select-opener > span {
  justify-content: center;
}

.reviews__block-inputs > div:last-child {
  margin-left: 16px;
}

.reviews__textarea {
  height: 148px;
}

.reviews__block-textarea.inputs--wrap {
  margin-top: 0;
}

.reviews__block-textarea.inputs--wrap label {
  top: 20px;
}

.reviews__answer {
  padding-top: 32px;
  margin-top: 32px;
  border-top: 1px solid #52739a;
  width: calc(100% - 76px);
  margin-left: auto;
}

.reviews__answer-name {
  font-weight: bold;
  font-size: 12px;
  position: relative;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #50c878;
  margin-bottom: 12px;
}

.reviews__answer-name::before {
  content: "";
  background: url(../pic/reviews-name-arrow.svg) center/cover;
  width: 30px;
  height: 20px;
  position: absolute;
  left: -59px;
  top: -5px;
}

.reviews__answer-descr {
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
}

.modal--feedback {
  width: 1128px;
  height: 492px;
}

.modal--feedback-success {
  width: 100%;
  height: 100%;
}

.modal--feedback-wrapper {
  width: 100%;
  height: 100%;
}

.partners__descr {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 67px;
}

.partners__container {
  padding-bottom: 80px;
}

.partners__wrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 32px;
}

.partners__card {
  background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%);
  border: 1px solid #52739a;
  border-radius: 20px;
  padding: 44px 32px 28px;
  margin-bottom: 16px;
  width: calc(20% - 16px);
  position: relative;
}

.partners__card::after {
  content: "";
  pointer-events: none;
  position: absolute;
  width: calc(100% + 2px);
  height: calc(100% + 2px);
  left: -1px;
  right: -1px;
  top: -1px;
  bottom: -1px;
  border-radius: 20px;
  transition: all 0.3s ease 0s;
  border: 2px solid transparent;
}

.partners__card:hover {
  border-color: transparent;
}

.partners__card:hover::after {
  border-color: #e7d186;
}

.partners__card:not(:nth-child(5n)) {
  margin-right: 16px;
}

.partners__card-img {
  border-radius: 8px;
  overflow: hidden;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

.partners__card-title {
  font-family: Oranienbaum;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  word-break: break-word;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #e7d186;
  min-height: 64px;
  margin-top: 28px;
}

.detail-partner__info {
  background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #000712;
  border: 1px solid #52739a;
  border-radius: 20px;
  padding: 60px 104px;
  display: flex;
}

.detail-partner__title {
  font-family: Oranienbaum;
  font-weight: normal;
  font-size: 32px;
  line-height: 40px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #e7d186;
  margin-bottom: 8px;
}

.detail-partner__image {
  min-width: 400px;
  margin-right: 120px;
}

.detail-partner__post {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #e7d186;
  margin-bottom: 24px;
}

.detail-partner__descr {
  font-weight: normal;
  color: #ffffff;
  text-shadow: 0px 0px 2.8801px rgba(0, 0, 0, 0.8);
  margin-bottom: 32px;
  font-size: 20px;
  line-height: 32px;
}

.detail-partner__descr > p:not(:last-child) {
  margin-bottom: 20px;
}

.detail-partner__link a {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #e7d186;
  border-bottom: 1px solid currentColor;
  padding-bottom: 6px;
  margin-bottom: 32px;
}

.detail-partner__link a:hover {
  color: #50c878;
}

.detail-partner__video {
  position: relative;
  margin-top: 80px;
}

.detail-partner__video video {
  object-fit: cover;
  height: 392px;
}

.personally__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.personally__link.more--btn {
  position: relative;
  background: #50c878;
  color: #051838;
  max-width: max-content;
  margin: 0;
}

.personally__link-wrap {
  border: 1px solid #e7d186;
  box-sizing: border-box;
  border-radius: 13px;
  padding: 4px;
  margin-bottom: 20px;
  text-align: center;
  margin-top: 32px;
  display: inline-block;
}

.personally__item {
  flex: 1 0 calc(33% - 56px);
  text-align: center;
  padding-right: 56px;
  position: relative;
  border-bottom: 1px solid #50c878;
  padding-bottom: 60px;
  margin-bottom: 60px;
}

.personally__item:nth-child(3n) {
  padding-right: 0;
}

.personally__item:nth-last-child(-n + 3) {
  border-bottom: none;
  padding-bottom: 0;
  margin-bottom: 0;
}

.personally__descr {
  color: #fff;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}

.personally__hours {
  max-width: max-content;
  margin: 0 auto;
  padding: 4px 12px;
  background: #1c4d4b;
  border-radius: 16px;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.12em;
  text-transform: uppercase;
  color: #50c878;
  margin-bottom: 20px;
}

.personally__image {
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
}

.personally__image svg {
  margin: 0 auto 34px;
}

.personally__subtitle {
  font-size: 16px;
  line-height: 20px;
  color: #50c878;
  margin-bottom: 30px;
  min-height: 40px;
}

.personally__title {
  font-family: Oranienbaum;
  font-weight: normal;
  font-size: 44px;
  line-height: 52px;
  color: #e7d186;
  margin-bottom: 8px;
}

.personally__warn {
  font-size: 16px;
  line-height: 20px;
  color: #50c878;
}

.personally__line {
  border-bottom: 1px solid #50c878;
  margin: 60px 0;
  flex: 1 0 100%;
}

.personally-descr {
  margin-bottom: 0;
}

.personally-archive {
  max-width: max-content;
  margin: 40px auto 60px;
}

.personally-archive .more--btn {
  margin-top: 0;
  margin-left: 0;
}

.question-form__form {
  background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #000712;
  border: 1px solid #52739a;
  border-radius: 20px;
  padding: 40px 104px 60px;
}

.question-form__title {
  font-family: Oranienbaum;
  font-size: 32px;
  line-height: 40px;
  text-align: center;
  color: #e7d186;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
}

.question-form__quest {
  display: block;
  margin-top: 20px;
  margin-bottom: 32px;
  background: #000712;
  border: 2px solid #e7d186;
  border-radius: 12px;
  width: 100%;
  min-height: 148px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #e7d186;
  padding: 20px;
  resize: none;
}

.question-form__quest.parsley-error {
  border-color: #e84e1d;
}

.question-form__btn.btn {
  background: #50c878;
  color: #051838;
  margin: 0 auto;
  display: block;
}

.question-form__btn.btn:hover {
  color: #50c878;
  background: transparent;
}

.question-modal {
  background: #e7d186;
}

.question-modal .modal--callback-success {
  min-height: 492px;
}

.taplink__page {
  margin: 0 auto;
  padding: 20px 0;
  width: 360px;
  height: 650px;
  background: #051838;
}

.taplink__list-item {
  border: 2px solid #50c878;
  border-radius: 20px;
  padding: 8px 0;
  display: block;
}

.taplink__list-item:not(:last-child) {
  margin-bottom: 8px;
}

.taplink__list-link {
  font-family: Noah;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  color: #ffffff;
  text-align: center;
  display: block;
}

.taplink__list-name {
  font-family: Noah;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  line-height: 14px;
  letter-spacing: 0.22em;
  text-transform: uppercase;
  color: #50c878;
  text-align: center;
}

.taplink__logo {
  border: 4px solid #e7d186;
  border-radius: 50%;
  width: 129px;
  width: 129px;
  overflow: hidden;
  margin: 0 auto 16px;
}

.taplink__title {
  font-family: Oranienbaum;
  font-size: 32px;
  line-height: 48px;
  text-align: center;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #e7d186;
  margin-bottom: 24px;
}

.not-found {
  padding-bottom: 80px;
  padding-top: 186px;
  position: relative;
}

.not-found::after {
  content: '';
  width: calc(100% + 120px);
  height: 590px;
  background: url("../pic/404_fon.png") center/cover no-repeat;
  position: absolute;
  left: -120px;
  top: 20px;
  text-align: center;
}

.not-found .container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.not-found__img {
  max-width: 935px;
  position: relative;
  z-index: 1;
}

.not-found__img img {
  max-width: 100%;
}

.not-found__text {
  color: #E7D186;
  text-align: center;
  font-size: 16px;
  font-weight: 700;
  line-height: calc(20 / 16);
  letter-spacing: 3.2px;
  text-transform: uppercase;
  margin-top: 90px;
}

.profile__wrap {
  max-width: 1128px;
  margin: 0 auto;
}

.profile-personal__title {
  font-family: "Oranienbaum";
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #ffffff;
  text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
  margin-bottom: 20px;
}

.profile-personal__section {
  margin-bottom: 32px;
}

.profile-personal__selects .select-block {
  width: 100%;
  border-radius: 12px;
  z-index: 4;
}

.profile-personal__selects .select-block:not(:last-child) {
  margin-right: 16px;
}

.profile-personal__selects .select-block .custom-select-opener,
.profile-personal__selects .select-block .custom-select-option {
  font-size: 11px;
  line-height: 16px;
}

.profile-personal__selects .custom-select-container.is-open .custom-select-panel {
  max-height: 200px;
  overflow: visible;
}

.profile-personal__selects .simplebar-track {
  background: #f2f2f2;
  right: 25px;
  top: 15px;
  bottom: 15px;
}

.profile-personal__selects .simplebar-track .simplebar-scrollbar::before {
  border-radius: 0;
  background: #e7d186;
  left: 0;
  right: 0;
  opacity: 1;
  top: 0;
  bottom: 0;
}

.profile-personal__inputs .inputs--block {
  width: 100%;
  border-radius: 12px;
  z-index: initial;
}

.profile-personal__inputs .inputs--block:not(:last-child) {
  margin-right: 16px;
}

.profile-personal__inputs .inputs--block.filled input {
  border-color: #e7d186;
  color: #e7d186;
  font-size: 12px;
  line-height: 16px;
}

.profile-personal__inputs .inputs--block.filled label {
  top: 10px;
}

.profile-personal__inputs .inputs--block input {
  padding: 22px 20px 14px;
}

.profile-personal__inputs .inputs--block label {
  width: 100%;
  text-align: center;
  font-size: 10px;
  line-height: 12px;
}

.profile-personal__inputs .date-block label {
  width: 90%;
  justify-content: end;
}

.profile-personal__inputs .date-block .datepicker.filled ~ label {
  color: #e7d186;
}

.profile-personal__radio {
  margin-top: 32px;
}

.profile-personal__radio-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #e7d186;
  margin-left: 28px;
  position: relative;
  margin-right: 12px;
  cursor: pointer;
}

.profile-personal__radio-label::after {
  content: "";
  border: 1px solid #e7d186;
  position: absolute;
  left: -28px;
  width: 16px;
  height: 16px;
  display: block;
  border-radius: 50%;
  top: 0;
}

.profile-personal__radio-label::before {
  content: "";
  background: transparent;
  width: 8px;
  height: 8px;
  position: absolute;
  left: -24px;
  top: 4px;
  border-radius: 50%;
}

.profile-personal__radio-input {
  position: absolute;
  width: 0;
  height: 0;
  overflow: hidden;
}

.profile-personal__radio-input:checked + label::before {
  background: #e7d186;
}

.profile-personal__submit {
  padding: 0 70px;
}

.profile-about {
  padding-bottom: 44px;
  margin-bottom: 44px;
  border-bottom: 1px solid #52739a;
}

.profile-about__photo {
  max-width: 244px;
}

.profile-about__photo-input {
  display: none;
}

.profile-about__photo-label {
  position: absolute;
  width: 60px;
  height: 60px;
  cursor: pointer;
  top: 50%;
  left: 50%;
  color: #50c878;
  transform: translate(-50%, -50%);
}

.profile-about__photo-label:hover {
  color: #e7d186;
}

.profile-about__photo-wrap {
  position: relative;
  margin-bottom: 26px;
  border-radius: 50%;
  overflow: hidden;
  border: 2px solid #fff;
  aspect-ratio: 1/1;
}

.profile-about__photo-wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.profile-about__wrap {
  margin-bottom: 44px;
}

.profile-about__info {
  max-width: 816px;
  width: 100%;
}

.profile-about__nik {
  margin-bottom: 32px;
}

.profile-about__nik .inputs--block {
  width: 100%;
  max-width: 400px;
  margin-right: 16px;
  border-radius: 8px;
}

.profile-about__textarea.inputs--textarea {
  border-radius: 8px;
}

.profile-about__textarea.inputs--textarea textarea {
  height: 146px;
  padding: 20px;
  text-align: left;
}

.profile-about__textarea.inputs--textarea textarea:focus ~ label {
  left: 20px;
  top: 5px;
  transform: none;
}

.profile-about__textarea.inputs--textarea.filled label {
  left: 20px;
  top: 5px;
  transform: none;
}

.profile-about__textarea.inputs--textarea label {
  left: 20px;
  top: 20px;
  transform: none;
}

.profile-about__warn {
  color: #52739a;
}

.profile-about__warn-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
}

.profile-about__warn-text_big {
  font-weight: 700;
  margin-bottom: 4px;
}

.profile-about__warn-text_error {
  font-size: 14px;
  display: none;
}

.profile-about__warn-text_error.active {
  margin: 0px 0px 12px 0px;
  color: #E84E1D;
  display: block;
}

.profile-about__warn-ico {
  margin-right: 16px;
}

.chat-container__subtitle {
  font-family: "Oranienbaum";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #50c878;
  margin-bottom: 8px;
  display: none;
}

.chat {
  border: 1px solid #50c878;
  border-radius: 20px;
  max-height: 754px;
  min-height: 754px;
}

.chat__bottom {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #051838;
  padding: 8px 16px;
  background: #50c878;
  border: 1px solid #50c878;
  border-radius: 0px 0px 20px 20px;
  text-align: center;
}

.chat-sidebar {
  padding: 24px 0 40px 24px;
  background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #000712;
  border-radius: 20px 0px 0px 20px;
  max-width: 254px;
  width: 100%;
  max-height: 718px;
  min-height: 718px;
}

.chat-sidebar .simplebar-vertical {
  background: #f2f2f2;
  width: 4px;
}

.chat-sidebar .simplebar-scrollbar,
.chat-sidebar .simplebar-scrollbar:before {
  background: #e7d186;
}

.chat-sidebar__wrap {
  height: 100%;
  padding-right: 24px;
}

.chat-sidebar__title {
  margin-bottom: 12px;
  font-family: "Oranienbaum";
  font-weight: 400;
  font-size: 32px;
  line-height: 40px;
  color: #50c878;
  text-align: center;
}

.chat-sidebar__subtitle {
  padding: 6px 6px 6px 12px;
  background: rgba(82, 115, 154, 0.3);
  border-radius: 20px;
  margin-bottom: 20px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #50c878;
  cursor: pointer;
}

.chat-sidebar__subtitle-new {
  padding: 0px 4px;
  background: #50c878;
  border-radius: 32px;
  font-size: 10px;
  line-height: 16px;
  color: #ffffff;
  margin-left: auto;
}

.chat-list {
  border-top: 1px solid #52739a;
}

.chat-list_feedback-list {
  min-height: initial;
}

.chat-list_feedback-list .chat-list__item:last-child {
  border-bottom: none;
}

.chat-list_private .chat-list__item {
  cursor: pointer;
}

.chat-list:not(.opened) .chat-list__title-arrow > svg {
  transform: rotate(180deg);
}

.chat-list:not(.opened) > .chat-list__item {
  display: none;
}

.chat-list.opened {
  padding-bottom: 20px;
}

.chat-list.opened .chat-list__edit {
  display: flex;
}

.chat-list.opened .chat-list__title {
  padding-bottom: 8px;
}

.chat-list__title {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #ffffff;
  padding: 20px 0 20px 8px;
  transition: none;
}

.chat-list__title-arrow {
  transition: 0.3s;
}

.chat-list__new-mess {
  height: 16px;
  padding: 0px 5px 0px 7px;
  background: #50c878;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  color: #ffffff;
  border-radius: 32px;
  margin: 0 12px;
}

.chat-list__item {
  padding: 8px 0 0 8px;
  min-height: 44px;
  display: flex;
  align-items: center;
}

.chat-list__name {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #50c878;
}

.chat-list__name-role {
  font-size: 12px;
  line-height: 16px;
  color: #52739a;
  text-transform: initial;
  letter-spacing: 0;
}

.chat-list__image {
  margin-right: 16px;
  border: 2px solid #ffffff;
  border-radius: 50%;
  position: relative;
}

.chat-list__image::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 7px;
  height: 7px;
  background: #50c878;
  border-radius: 50%;
}

.chat-list__image-new {
  padding: 0px 6px;
  background: #50c878;
  border-radius: 32px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  position: absolute;
  top: -6px;
  right: -6px;
}

.chat-list__open {
  border: 1px solid #50c878;
  box-sizing: border-box;
  border-radius: 20px;
  font-size: 12px;
  line-height: 16px;
  color: #50c878;
  padding: 8px 12px;
  width: 100%;
  display: flex;
  margin-top: 8px;
  display: none;
}

.chat-list__add.lk-btn {
  width: 100%;
  background: transparent;
  color: #50c878;
}

.chat-list__add.lk-btn:hover {
  background: #50c878;
  color: #051838;
  border: 2px solid #50c878;
}

.chat-list__edit {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #50c878;
  padding: 8px 12px;
  background: #1c4d4b;
  border-radius: 20px;
  width: 100%;
  margin-top: 8px;
  display: none;
}

.chat-window {
  padding: 24px 32px 24px 32px;
  width: 100%;
  position: relative;
}

.chat-window__form {
  padding-top: 32px;
  position: absolute;
  bottom: 24px;
  width: calc(100% - 88px);
  left: 32px;
  background: #051838;
}

.chat-window__wrapper {
  max-height: 574px;
  padding-right: 32px;
}

.chat-window__wrapper .simplebar-vertical {
  background: #f2f2f2;
  width: 4px;
}

.chat-window__wrapper .simplebar-scrollbar,
.chat-window__wrapper .simplebar-scrollbar:before {
  background: #e7d186;
}

.chat-window__mess:not(:last-child) {
  margin-bottom: 24px;
}

.chat-window__mess.received .chat-window__mess-header {
  flex-direction: row-reverse;
}

.chat-window__mess.received .chat-window__mess-text {
  background: rgba(82, 115, 154, 0.3);
  border-radius: 0px 12px 12px 12px;
  margin-left: auto;
}

.chat-window__mess.received .chat-window__mess-avatar {
  flex-direction: row-reverse;
}

.chat-window__mess.received .chat-window__mess-date,
.chat-window__mess.received .chat-window__mess-name {
  text-align: left;
}

.chat-window__mess.received .chat-window__mess-img {
  margin-right: 12px;
  margin-left: 0;
}

.chat-window__mess-status {
  font-size: 12px;
  line-height: 16px;
  color: #52739a;
  padding: 6px 12px;
  border: 1px solid #52739a;
  border-radius: 20px;
  display: flex;
  align-items: center;
}

.chat-window__mess-status svg {
  margin-right: 6px;
}

.chat-window__mess-status_new {
  background: #50c878;
  color: #051838;
  border-color: #50c878;
}

.chat-window__mess-status_new svg {
  display: none;
}

.chat-window__mess-name {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  text-align: right;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #50c878;
}

.chat-window__mess-img {
  border: 2px solid #ffffff;
  border-radius: 50%;
  margin-left: 12px;
}

.chat-window__mess-header {
  margin-bottom: 12px;
  align-items: center;
}

.chat-window__mess-date {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: right;
  color: #52739a;
}

.chat-window__mess-text {
  font-weight: 400;
  font-size: 16px;
  line-height: 20px;
  color: #ffffff;
  padding: 16px;
  background: rgba(80, 200, 120, 0.3);
  border-radius: 12px 0px 12px 12px;
  max-width: calc(100% - 44px);
}

.chat-window__head {
  display: none;
  font-family: "Oranienbaum";
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  text-align: right;
  color: #50c878;
  padding: 4px;
  border-bottom: 1px solid #52739a;
  margin-bottom: 12px;
}

.chat-window__head-title {
  padding: 4px 8px;
}

.message-form .lk-btn {
  background: transparent;
  padding: 0 44px;
  color: #50c878;
  height: 56px;
}

.message-form .lk-btn svg {
  display: none;
}

.message-form .lk-btn:hover {
  background: #50c878;
  color: #051838;
}

.message-form__file {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  width: 0;
  height: 0;
}

.message-form__file-label {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

.message-form__wrap {
  align-items: flex-end;
}

.message-form__input {
  width: 100%;
  margin-right: 10px;
  position: relative;
  max-width: calc(100% - 180px);
}

.message-form__input .textarea {
  padding: 16px 12px 16px 20px;
  background: #000712;
  border: 2px solid #e7d186;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  color: #e7d186;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  min-height: 56px;
  max-height: 200px;
  overflow: auto;
}

.message-form__input .textarea:focus + label,
.message-form__input .textarea.not-empty + label {
  display: none;
}

.message-form__input-label {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #e7d186;
  position: absolute;
  top: 20px;
  left: 20px;
  pointer-events: none;
}

.chat-modal {
  padding: 20px 60px 40px 60px;
  background: #e7d186;
  max-width: 712px;
  width: 100%;
}

.chat-modal .inputs--block {
  background: #fff;
  border-radius: 12px;
  margin-bottom: 20px;
}

.chat-modal .inputs--block input {
  padding: 20px 10px;
}

.chat-modal .inputs--block input,
.chat-modal .inputs--block label {
  border: none;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #051838;
}

.chat-modal__wrap {
  padding: 16px 24px;
}

.chat-modal .lk-btn {
  background: #051838;
  padding: 0 44px;
  border: 2px solid #051838;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
  margin-top: 12px;
}

.chat-modal .lk-btn:hover {
  border: 2px solid #051838;
  color: #051838;
}

.chat-modal .custom-select-opener {
  padding: 20px 0;
  background: #ffffff;
  border: none;
}

.chat-modal .custom-select-opener::before {
  background-image: url(../pic/select-b.svg);
}

.chat-modal .custom-select-panel {
  border: none;
  background: #ffffff;
}

.chat-modal .custom-select-container.is-open .custom-select-panel::before {
  background: #051838;
}

.chat-modal .custom-select-option {
  justify-content: center;
  color: #051838;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  padding: 10px 26px 10px 26px;
  margin: 0;
}

.chat-modal .custom-select-opener > span {
  justify-content: center;
  color: #051838;
  font-size: 12px;
  line-height: 16px;
}

.basket__wrap {
  gap: 120px;
  justify-content: space-between;
  align-items: start;
}

.basket #form-basket .inputs--block .iText {
  color: #52739A;
  border-color: #52739A;
}

.basket__num {
  font-family: 'Oranienbaum';
  font-size: 32px;
  line-height: calc(40 / 32);
  color: #50C878;
  margin-top: 36px;
}

.basket__btn.lk-btn {
  margin-top: 40px;
  width: 269px;
  margin-bottom: 116px;
}

.product-basket {
  flex: 1 1 auto;
}

.product-basket__item {
  justify-content: space-between;
  position: relative;
  border-bottom: 1px solid #52739a;
  padding-bottom: 32px;
  padding-right: 200px;
}

.product-basket__item:not(:first-child) {
  margin-top: 32px;
}

.product-basket__section {
  font-weight: 700;
  font-size: 12px;
  line-height: calc(16 / 12);
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #50c878;
}

.product-basket__name {
  margin-top: 4px;
  font-family: "Oranienbaum";
  font-size: 24px;
  line-height: calc(32 / 24);
  color: #e7d186;
  display: inline-flex;
  transition: all 0.3s ease 0s;
}

.product-basket__bottom {
  display: flex;
  justify-content: space-between;
  gap: 32px;
}

.product-basket__tags {
  margin-top: 16px;
  gap: 16px 6px;
  flex-wrap: wrap;
}

.product-basket__tags .event--leading,
.product-basket__tags .event--leading-online,
.product-basket__tags .event--date {
  margin: 0;
}

.product-basket__control {
  align-items: flex-end;
  justify-content: flex-end;
  padding-right: 52px;
  flex: 0 0 auto;
  position: absolute;
  right: 0;
  bottom: 32px;
}

.product-basket__control .event--leading {
  display: inline-flex;
  width: max-content;
}

.product-basket__control .event--price-old {
  margin-top: 12px;
}

.product-basket__control .event--leading {
  line-height: calc(16 / 12);
  margin: 0px 0px 12px 0px;
}

.product-basket__control .event--price-old {
  margin: 0;
}

.product-basket__del {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 0;
  right: 0;
}

.product-basket__del svg {
  width: 100%;
  height: 100%;
  stroke: #52739a;
}

#form-basket {
  margin-top: 44px;
}

#form-basket .inputs--wrap {
  gap: 16px;
}

#form-basket .inputs--wrap:not(:last-child) {
  margin-bottom: 16px;
}

#form-basket .register--inputs-block .inputs--block {
  margin: 0;
}

#form-basket .register--inputs-block .inputs--block:not(:last-child) {
  margin: 0;
}

.order-basket {
  flex: 0 0 400px;
  background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #051838;
  border: 1px solid #52739a;
  border-radius: 20px;
  padding: 24px;
  position: sticky;
  top: 68px;
}

.order-basket__title {
  font-weight: 700;
  font-size: 20px;
  line-height: calc(32 / 20);
  color: #50c878;
  text-align: center;
}

.order-basket__list {
  margin-top: 28px;
  padding-top: 28px;
  border-top: 1px solid #52739a;
}

.order-basket__item {
  font-weight: 700;
  justify-content: space-between;
}

.order-basket__item:not(:last-child) {
  margin-bottom: 8px;
}

.order-basket__item:last-child div {
  color: #50c878;
  font-size: 20px;
  line-height: calc(32 / 20);
}

.order-basket__item div {
  color: #52739a;
}

.order-basket__item div:nth-child(1) {
  font-size: 16px;
  line-height: calc(20 / 16);
}

.order-basket__item div:nth-child(2) {
  font-size: 20px;
  line-height: calc(28 / 20);
}

.order-basket__btn {
  margin-top: 28px;
  width: 100%;
}

.order-basket .inpCheck {
  margin-top: 28px;
}

.order-basket .inpCheck p {
  white-space: nowrap;
}

.friends .lk-banner {
  margin: 44px 0px;
}

.friends__item {
  border-bottom: 1px solid #52739A;
  padding-bottom: 32px;
}

.friends__item:not(:first-child) {
  padding-top: 32px;
}

.friends__body {
  flex: 0 0 296px;
}

.friends__ava {
  flex: 0 0 88px;
  aspect-ratio: 1/1;
  border-radius: 50%;
  border: 2px solid #fff;
  overflow: hidden;
}

.friends__ava img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.friends__info {
  flex: 1 1 auto;
  margin-left: 16px;
}

.friends__info .status-friends {
  margin-top: 8px;
}

.friends__name {
  color: #E7D186;
  font-size: 24px;
  font-family: Oranienbaum;
  line-height: calc(32 / 24);
}

.friends__text {
  margin-left: 16px;
}

.friends__message {
  overflow: hidden;
  color: #52739A;
  font-size: 16px;
  line-height: calc(20 / 16);
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.friends__time {
  margin-left: 32px;
  color: #52739A;
  text-align: right;
  font-size: 10px;
  font-weight: 500;
  line-height: calc(16 / 10);
}

.friends__controll {
  margin-left: 120px;
  gap: 16px;
}

.friends__controll .btn {
  width: 192px;
  padding: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 56px;
}

.friends__controll .btn.btn-default {
  background: #50c878;
  color: #051838;
}

.status-friends {
  padding: 6px 16px;
  font-size: 12px;
  font-weight: 700;
  line-height: calc(16 / 12);
  letter-spacing: 1.92px;
  text-transform: uppercase;
  display: inline-flex;
  align-items: center;
  gap: 12px;
  border-radius: 20px;
}

.status-friends::before {
  content: '';
  width: 8px;
  height: 8px;
  border-radius: 50%;
}

.status-friends_on {
  color: #50C878;
  border: 1px solid #50C878;
}

.status-friends_on::before {
  background: #50C878;
}

.status-friends_off {
  color: #E84E1D;
  border: 1px solid #E84E1D;
}

.status-friends_off::before {
  background: #E84E1D;
}

.lk__wrap {
  padding-top: 80px;
}

.lk-container {
  margin: 0 auto;
  max-width: 1331px;
}

.lk-not-main .lk-banner {
  margin-bottom: 44px;
}

.lk-main__greet {
  font-family: "Oranienbaum";
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 20px;
}

.lk-main__descr {
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
}

.lk-main__list {
  display: flex;
  flex-wrap: wrap;
}

.lk-main__list-item {
  padding: 21px 22px;
  border: 2px solid #50c878;
  border-radius: 20px;
  width: calc(33% - 8px);
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
  position: relative;
}

.lk-main__list-item:hover {
  background: #50c878;
}

.lk-main__list-item:hover .lk-main__list-title {
  color: #051838;
}

.lk-main__list-item:hover .lk-main__list-count {
  color: #051838;
  border: 1px solid #051838;
}

.lk-main__list-item:hover .lk-main__list-mess {
  color: #051838;
  border: 1px solid #051838;
}

.lk-main__list-item:hover .lk-main__list-mess_new {
  background: #051838;
}

.lk-main__list-item:hover .lk-main__list-count_new {
  background: #051838;
}

.lk-main__list-item:not(:nth-child(3n)) {
  margin-right: 16px;
}

.lk-main__list-title {
  font-family: "Oranienbaum";
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #e7d186;
}

.lk-main__list-count {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: #50c878;
  border: 1px solid #50c878;
  width: 72px;
  height: 72px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.lk-main__list-count_new {
  background: #50c878;
  border-radius: 32px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding: 0 9px;
  position: absolute;
  top: 12px;
  right: -4px;
  transform: translateY(-50%);
}

.lk-main__list-mess {
  padding: 9px 25px 9px 16px;
  border-radius: 16px;
  border: 1px solid #50c878;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #50c878;
  position: relative;
}

.lk-main__list-mess:not(:last-child) {
  margin-bottom: 4px;
}

.lk-main__list-mess-wrap {
  position: absolute;
  right: 22px;
}

.lk-main__list-mess_count {
  margin-left: 12px;
  font-size: 16px;
  line-height: 20px;
}

.lk-main__list-mess_new {
  background: #50c878;
  border-radius: 32px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
  padding: 0 9px;
  position: absolute;
  top: 50%;
  right: -12px;
  transform: translateY(-50%);
}

.lk-banner {
  padding: 24px;
  background: #e7d186;
  border-radius: 20px;
}

.lk-banner__text {
  width: 100%;
}

.lk-banner__subtitle {
  font-size: 16px;
  line-height: 20px;
  color: #051838;
}

.lk-banner__title {
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  color: #051838;
  border-bottom: 1px solid #051838;
  padding-bottom: 8px;
  margin-bottom: 8px;
}

.lk-banner__ico {
  margin-right: 16px;
  margin-top: 4px;
}

.lk-menu__item {
  border: 1px solid #051838;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 80px;
  min-width: 96px;
}

.lk-menu__item:hover {
  background: #fff;
  border-color: #fff;
}

.lk-menu__item:not(:last-child) {
  margin-bottom: 4px;
}

.lk-menu__item_home {
  display: none;
}

.lk-menu__link {
  padding: 8px 4px;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.lk-menu__link_soc {
  display: none;
}

.lk-menu__link_md {
  display: none;
}

.lk-menu__link-count {
  background: #f2f2f2;
  border-radius: 32px;
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #051838;
  padding: 0 11px;
}

.lk-menu__link-count_green {
  background: #50c878;
  color: #fff;
}

.lk-menu__link-count_md {
  font-size: 10px;
  line-height: 16px;
  text-align: center;
  color: #051838;
  padding: 0px 4px;
  background: #f2f2f2;
  border-radius: 32px;
  margin-left: 10px;
}

.lk-menu__link-ico {
  margin-bottom: 6px;
}

.lk-menu__link-name {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: #051838;
  margin-bottom: 8px;
}

.lk-soc__item:first-child {
  margin-right: 24px;
}

.lk-avatar__image {
  border: 2px solid #ffffff;
  border-radius: 50%;
  display: block;
  overflow: hidden;
  width: 52px;
  height: 52px;
}

.lk-avatar__logout {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #051838;
  margin-top: 16px;
  display: block;
}

.lk-btn {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  min-height: 56px;
  font-family: "Oranienbaum";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;
  border-radius: 12px;
  background: #50c878;
  color: #051838;
  border: 2px solid #50c878;
}

.lk-btn:hover {
  background: transparent;
  color: #50c878;
}

.no-events {
  border: 1px solid #e7d186;
  border-radius: 20px;
  padding: 60px;
}

.no-events__link {
  padding: 0 44px;
}

.no-events__wrap {
  display: flex;
}

.no-events__title {
  font-family: "Oranienbaum";
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #ffffff;
  margin-bottom: 20px;
}

.lk-events__list-item:not(:last-child) {
  border-bottom: 1px solid #52739a;
  padding-bottom: 32px;
  margin-bottom: 32px;
}

.lk-events__list-item_archive .lk-events__list-leader {
  background: #52739a;
  color: #051838;
}

.lk-events__list-item_archive .lk-events__list-name {
  color: #52739a;
}

.lk-events__list-item_archive .lk-events__list-date {
  border: none;
}

.lk-events__list-item_archive .lk-events__list-date_start {
  background: #52739a;
  padding-right: 4px;
}

.lk-events__list-item_archive .lk-events__list-date_day {
  background: #52739a;
  padding-left: 4px;
}

.lk-events__list-date {
  display: flex;
  border: 1px solid #ffffff;
  margin-left: 12px;
  border-radius: 20px;
}

.lk-events__list-date_start {
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  color: #051838;
  border-radius: 20px 0 0 20px;
  background: #fff;
  padding: 1px 8px 1px 12px;
  display: flex;
  align-items: center;
}

.lk-events__list-date_day {
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #051838;
  background: #50c878;
  border-radius: 0px 20px 20px 0px;
  padding: 0 12px 0 8px;
  display: flex;
  align-items: center;
}

.lk-events__list-btn {
  padding: 0 47px;
}

.lk-events__list-btn_notbg {
  background: transparent;
  color: #50c878;
  padding: 0 24px;
  margin-left: 16px;
}

.lk-events__list-btn_notbg:hover {
  background: #50c878;
  color: #051838;
}

.lk-events__list-btn_sent {
  padding: 0 24px;
  margin-left: 16px;
  color: #52739a;
  border: 2px solid #52739a;
  background: none;
}

.lk-events__list-btn_sent:hover {
  color: #52739a;
}

.lk-events__list-leader {
  padding: 6px 12px;
  background: #1c4d4b;
  border-radius: 20px;
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #50c878;
}

.lk-events__list-level {
  border: 1px solid #e7d186;
  border-radius: 14px;
  padding: 5px 12px;
  margin-right: 12px;
}

.lk-events__list-level > span {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #50c878;
  margin-left: 12px;
}

.lk-events__list-name {
  font-family: "Oranienbaum";
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #e7d186;
  margin-bottom: 12px;
  display: block;
}

.lk-events__list-name:hover {
  color: #50c878;
}

.lk-events__list-status {
  font-weight: 700;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #051838;
  padding: 6px 12px;
  background: #e7d186;
  border-radius: 14px;
  margin-right: 12px;
}

.lk-events__list-status_going {
  background: #50c878;
}

.lk-events__list-status_closed {
  background: #52739a;
}

body {
  background: #051838;
  font-family: "Noah", sans-serif;
  will-change: scroll-position;
}

body._lock {
  overflow: hidden;
}

section p {
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
}

.container {
  max-width: 1784px;
  margin: 0 auto;
  padding: 0 120px;
}

.main--wrap {
  width: calc(100% - 120px);
  margin-left: auto;
}

.footer {
  width: calc(100% - 120px);
  margin-left: auto;
  overflow: hidden;
}

.footer--wrap {
  padding: 32px 0 80px 0;
  border-top: 1px solid #50c878;
}

.footer--bottom {
  width: 100%;
  margin-top: 40px;
  font-weight: normal;
  font-size: 20px;
  line-height: 32px;
  color: #50c878;
}

.footer--btn {
  width: 20%;
  margin-left: 6.66667%;
}

.footer--btn a {
  margin: 0;
  text-align: center;
  justify-content: center;
}

.footer--btn a + a {
  margin-top: 18px;
}

.footer--contacts {
  margin-left: auto;
}

.footer--contacts-icons a {
  display: none;
  color: #e7d186;
  margin-top: 16px;
}

.footer--contacts-icons a:first-child {
  margin-right: 24px;
}

.footer--phone {
  font-family: "Oranienbaum", sans-serif;
  font-size: 36px;
  line-height: 60px;
  letter-spacing: 0.05em;
  color: #50c878;
}

.footer--mail {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #50c878;
  margin-top: 12px;
}

.footer--copy {
  width: 26.66667%;
  max-width: 704px;
}

.footer--policy {
  width: 20%;
  margin-left: 7.33333%;
}

.footer--db {
  margin-left: auto;
  text-align: right;
}

.footer--db a p {
  transition: 0.3s all ease;
  color: #e7d186;
}

.footer--db a svg {
  color: #e7d186;
}

.footer--db:hover a p {
  color: #50c878;
}

.footer--db:hover a svg {
  color: #50c878;
}

.footer--db svg {
  margin-left: 16px;
}

.footer--menu {
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  color: #50c878;
  width: 50%;
  max-width: 763px;
}

.footer--menu li {
  margin-bottom: 20px;
  page-break-inside: avoid;
  break-inside: avoid;
}

.footer--menu ul {
  margin-bottom: -20px;
  column-count: 3;
}

.footer--app {
  width: auto;
  display: flex;
  margin-left: 3.33333%;
  margin-right: 3.33333%;
}

.footer--google-play {
  display: block;
  background: url(../pic/google-play.svg) center center/contain no-repeat;
  width: 155px;
  height: 52px;
  margin-right: 29px;
}

.footer--app-store {
  display: block;
  background: url(../pic/app-store.svg) center center/contain no-repeat;
  width: 155px;
  height: 52px;
}

.m-wrap {
  margin: 0 -6px;
}

.m-block {
  margin: 0 6px;
}

.video--play {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 54px;
  height: 54px;
  border-radius: 50%;
  background: #e7d186;
  transition: 0.3s all ease;
  display: flex;
  align-items: center;
  justify-content: center;
}

.video--play:hover {
  background: #50c878;
}

.video--play.hide {
  opacity: 0;
  visibility: hidden;
  z-index: 0;
}

.video--play svg {
  color: #051838;
  margin-left: 4px;
}

.section--title-small {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #50c878;
  margin-bottom: 12px;
}

.section--title-big {
  font-size: 44px;
  line-height: 52px;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #e7d186;
  font-family: "Oranienbaum", sans-serif;
}

.section--main {
  margin-bottom: -16px;
}

.inpCheck {
  display: inline-block;
  vertical-align: top;
  position: relative;
  z-index: 1;
  padding: 0 0 0 32px;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  color: #e7d186;
  margin-top: 32px;
}

.inpCheck p {
  color: #e7d186;
  font-size: 16px;
  line-height: 20px;
}

.inpCheck label.parsley-error span:before {
  border-color: #e84e1d;
}

.inpCheck a {
  text-decoration: underline;
}

.inpCheck a:hover {
  color: #50c878;
}

.inpCheck.parsley-error span:before {
  border-color: #e84e1d;
}

.inpCheck.active {
  color: #c7ab6f;
}

.inpCheck.active span:before {
  border-color: #c7ab6f;
}

.inpCheck input {
  position: absolute;
  z-index: -1;
  left: 0;
  top: 0;
  opacity: 0;
}

.inpCheck input:disabled + span:before {
  background: #f3f3f3;
}

.inpCheck input:checked + span:after {
  transform: scale(1) translate(0, -50%);
}

.inpCheck span {
  cursor: pointer;
}

.inpCheck span:before {
  content: "";
  width: 16px;
  height: 16px;
  border: 1px solid #e7d186;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 50%;
  transform: translate(0, -50%);
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.inpCheck span:after {
  content: "";
  background-image: url("../pic/check.svg");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  z-index: 1;
  left: 3px;
  top: 50%;
  transform: scale(0) translate(0, -50%);
  display: block;
  transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  width: 11px;
  height: 8px;
}

.gh:hover {
  color: #e7d186;
}

.more--btn {
  font-family: "Oranienbaum", sans-serif;
  font-size: 20px;
  line-height: 28px;
  color: #50c878;
  padding: 12px 42px;
  border: 2px solid #50c878;
  border-radius: 12px;
  margin-top: 40px;
  display: inline-flex;
}

.more--btn.form--btn {
  margin: 0 auto 0 auto;
}

.more--btn.mt32 {
  margin: 32px auto 0 auto;
}

.hg:hover {
  background: #50c878;
  color: #051838;
}

.custom-select-container {
  position: relative;
  box-sizing: border-box;
  transition: none;
}

.custom-select-container * {
  box-sizing: border-box;
}

.custom-select-container.is-disabled {
  opacity: 0.333;
}

.custom-select-opener {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #e7d186;
  display: block;
  cursor: pointer;
  width: 100%;
  background: #000712;
  border: 2px solid #e7d186;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 18px 62px 18px 26px;
  position: relative;
  transition: none;
  text-align: center;
}

.custom-select-opener:before {
  content: "";
  display: block;
  background-image: url("../pic/select.svg");
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  right: 22px;
  top: 50%;
  transform: translate(0, -50%);
  width: 18px;
  height: 11px;
  background-size: cover;
}

.custom-select-opener .select--price-price {
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  letter-spacing: 0;
}

.custom-select-opener .select--price-old {
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  letter-spacing: 0;
}

.custom-select-opener > span {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom-select-container select {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.custom-select-panel {
  max-height: 0;
  overflow: hidden;
  background: #000712;
  position: absolute;
  top: calc(100% - 2px);
  width: 100%;
  border-width: 0 2px 2px 2px;
  border-style: solid;
  border-color: #e7d186;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;
  visibility: hidden;
  z-index: 2;
}

.custom-select-panel .select--price-price {
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  letter-spacing: 0;
}

.custom-select-panel .select--price-old {
  font-size: 16px;
  line-height: 20px;
  text-transform: capitalize;
  letter-spacing: 0;
}

.custom-select-container.is-open .custom-select-panel {
  max-height: 400px;
  overflow-y: auto;
  visibility: visible;
}

.custom-select-container.is-open .custom-select-panel:before {
  content: "";
  display: block;
  width: calc(100% - 40px);
  height: 1px;
  background: #e7d186;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.custom-select-container.is-open .custom-select-opener {
  border-bottom-color: transparent;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

#add_group_chat .custom-select-container.is-open .custom-select-panel {
  max-height: 68px;
}

.custom-select-option {
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #e7d186;
  margin: 14px 0;
  padding: 10px 64px 10px 26px;
  text-align: center;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}

.custom-select-option:hover {
  background: rgba(82, 115, 154, 0.2);
  border-radius: 4px;
}

.custom-select-option:last-child {
  margin-bottom: 0;
}

.custom-select-option .select--text,
.custom-select-option .select--wrapper {
  position: relative;
  z-index: -1;
  text-align: left;
  flex: 1;
}

.custom-select-option .select--wrapper {
  text-align: right;
}

.custom-select-optgroup > .custom-select-option {
  padding-left: 2em;
}

.custom-select-optgroup::before {
  content: attr(data-label);
  display: block;
  padding: 0.5em;
  color: #888;
}

.slider--btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(80, 200, 120, 0.3);
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 2;
  display: none;
}

.slider--btn.slick-arrow {
  display: flex;
}

.slider--btn:active {
  background: #50c878;
}

.slider--left {
  left: 30px;
  padding-right: 2px;
}

.slider--right {
  right: 30px;
  transform: translate(0, -50%) rotate(180deg);
  padding-right: 2px;
}

.event--card-wrap {
  justify-content: space-between;
  padding: 24px 40px 26px 32px;
  border-radius: 20px;
  overflow: visible;
  width: calc(33.33333% - 8px);
  margin-bottom: 16px;
  position: relative;
  background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%);
  border: 1px solid #52739a;
}

.event--card-wrap_archive .event--archive {
  padding: 6px 24px;
  background: #e84e1d;
  border-radius: 14px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #fff;
}

.event--card-wrap_archive .event--type,
.event--card-wrap_archive .event--name,
.event--card-wrap_archive .event--leading-online,
.event--card-wrap_archive .event--price-price,
.event--card-wrap_archive .event--card-more {
  color: #52739a;
}

.event--card-wrap_archive .event--type:hover,
.event--card-wrap_archive .event--name:hover,
.event--card-wrap_archive .event--leading-online:hover,
.event--card-wrap_archive .event--price-price:hover,
.event--card-wrap_archive .event--card-more:hover {
  color: #52739a;
}

.event--card-wrap_archive .event--leading {
  background: #52739a;
  color: #051838;
}

.event--card-wrap_archive .event--leading-online {
  border: 1px solid #52739a;
}

.event--card-more {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #50c878;
}

.event--card-more:hover,
.event--card-more:active {
  color: #e7d186;
}

.event--type {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #50c878;
}

.event--date {
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 20px;
  font-size: 14px;
  line-height: 16px;
}

.event--date-time {
  font-weight: bold;
  color: #051838;
  font-size: 16px;
  line-height: 20px;
  padding: 0 8px 0 12px;
}

.event--date-days {
  color: #051838;
  padding: 5px 10px 5px 6px;
  background: #50c878;
  border-radius: 0px 20px 20px 0px;
}

.event--right-top {
  margin-bottom: 24px;
  width: 100%;
}

.event--name {
  font-size: 32px;
  line-height: 40px;
  color: #e7d186;
  font-family: "Oranienbaum", sans-serif;
  display: block;
  min-height: 80px;
}

.event--name:hover {
  color: #50c878;
}

.event--leading {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #50c878;
  padding: 6px 16px;
  display: inline-flex;
  background: #1c4d4b;
  border-radius: 12px;
  margin-top: 12px;
}

.event--leading-online {
  margin-top: 12px;
  margin-left: 6px;
  padding: 5px 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #50c878;
  box-sizing: border-box;
  border-radius: 20px;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #e7d186;
}

.event--leading-online svg {
  margin-right: 12px;
}

.event--left {
  width: 40px;
  background-image: url("../pic/sprite.svg#star");
}

.event--left svg {
  margin-bottom: 16px;
  width: 100%;
  height: auto;
}

.event--left svg:last-child {
  margin-bottom: 0;
}

.event--right a.gh {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.14em;
  text-transform: uppercase;
  color: #50c878;
  margin-top: auto;
  display: inline-flex;
  position: relative;
}

.event--right a.gh:before {
  content: "";
  transition: 0.3s all ease;
  width: 100%;
  height: 1px;
  position: absolute;
  top: 100%;
  background: #50c878;
  opacity: 0;
  visibility: hidden;
}

.event--right a.gh:hover:before {
  opacity: 1;
  visibility: visible;
}

.event--time {
  font-size: 20px;
  line-height: 28px;
  color: #e7d186;
  font-weight: normal;
  margin-left: 8px;
}

.event--price-info {
  height: 100%;
  align-items: flex-end;
}

.event--price-wrapper {
  display: flex;
  align-items: flex-end;
  margin-left: -32px;
  margin-right: -40px;
  margin-top: 24px;
  min-height: 56px;
  padding-right: 24px;
}

.event--price-wrapper_not-timer {
  padding-left: 80px;
}

.event--price-timer {
  background: #52739a;
  border-radius: 0px 22px 22px 0px;
  padding: 1px 0 1px 32px;
  display: flex;
  align-items: center;
  min-height: 48px;
  min-width: 185px;
}

.event--price-timer svg {
  margin-right: 20px;
}

.event--price-timer .price-timer {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;
}

.event--price-price {
  font-weight: bold;
  font-size: 20px;
  line-height: 28px;
  color: #50c878;
  margin-left: 24px;
}

.event--price-old {
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  text-decoration-line: line-through;
  color: #52739a;
  margin-left: 24px;
}

.esl--wrap {
  position: relative;
}

.esl--wrap .slick-list {
  overflow: visible !important;
}

.esl--wrap .slick-list:before {
  content: "";
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  background: #051838;
  left: -100%;
  z-index: 2;
}

.esl--wrap .slick-slider {
  margin: 0 -2px;
}

.esl--wrap .slider--left {
  left: -16px;
}

.esl--wrap .slider--right {
  right: -16px;
}

.esl--wrap .slick-slide {
  margin: 0 2px;
  display: flex;
}

.esl--wrap .slick-slide > div {
  width: 100%;
}

.mg80 {
  margin-bottom: 80px;
}

.mg60 {
  margin-bottom: 60px;
}

.mg20 {
  margin-bottom: 20px;
}

.mg32 {
  margin-bottom: 32px;
}

.mt32 {
  margin-top: 32px;
}

.inputs--wrap {
  position: relative;
  width: 100%;
}

.inputs--block,
.inputs--textarea {
  background: #000712;
  box-sizing: border-box;
  position: relative;
  z-index: 1;
}

.inputs--block.filled label,
.inputs--textarea.filled label {
  font-size: 10px;
  line-height: 16px;
  font-weight: bold;
  color: #004a30;
  top: 5px;
  transform: translate(-50%, 0);
}

.inputs--block.filled input,
.inputs--block.filled textarea,
.inputs--textarea.filled input,
.inputs--textarea.filled textarea {
  border-color: #50c878;
  color: #50c878;
}

.inputs--block label,
.inputs--textarea label {
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  position: absolute;
  top: 50%;
  left: 50%;
  color: #e7d186;
  transform: translate(-50%, -50%);
  transition: 0.3s all ease;
  z-index: 1;
  font-weight: bold;
}

.inputs--block textarea,
.inputs--textarea textarea {
  display: block;
}

.inputs--block input,
.inputs--block textarea,
.inputs--textarea input,
.inputs--textarea textarea {
  font-weight: bold;
  border: 2px solid #e7d186;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #e7d186;
  padding: 18px 10px;
  text-align: center;
  width: 100%;
  border-radius: 12px;
  z-index: 2;
  position: relative;
}

.inputs--block input:focus ~ label,
.inputs--block textarea:focus ~ label,
.inputs--textarea input:focus ~ label,
.inputs--textarea textarea:focus ~ label {
  font-size: 10px;
  line-height: 16px;
  font-weight: bold;
  color: #004a30;
  top: 5px;
  transform: translate(-50%, 0);
}

.inputs--block input.parsley-error,
.inputs--block textarea.parsley-error,
.inputs--textarea input.parsley-error,
.inputs--textarea textarea.parsley-error {
  border-color: #e84e1d;
}

.inputs--block input::placeholder,
.inputs--block textarea::placeholder,
.inputs--textarea input::placeholder,
.inputs--textarea textarea::placeholder {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  color: #e7d186;
}

.mask--bg {
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background: #051838;
  z-index: 100;
  opacity: 0;
  visibility: hidden;
  user-select: none;
  cursor: pointer;
  transition: 0.3s all ease;
}

.mask--bg.active {
  opacity: 0.5;
  visibility: visible;
  user-select: auto;
}

.btn {
  font-size: 20px;
  line-height: 28px;
  color: #50c878;
  font-family: "Oranienbaum", sans-serif;
  padding: 12px 44px 12px 44px;
  border: 2px solid #50c878;
  border-radius: 12px;
  display: inline-flex;
  align-items: center;
}

.btn--close {
  position: absolute;
  right: 23px;
  top: 23px;
  z-index: 21;
}

.btn--close svg {
  color: #051c38;
}

.btn--close svg:hover {
  color: #50c878;
}

.parsley-errors-list {
  position: absolute;
  top: calc(100% - 9px);
  left: 0;
  right: 0;
  margin: 0 auto;
  display: inline-flex;
  text-align: center;
  align-items: center;
  justify-content: center;
  z-index: 3;
}

.parsley-errors-list li {
  font-weight: 500;
  font-size: 10px;
  line-height: 16px;
  display: flex;
  align-items: flex-end;
  color: #ffffff;
  padding: 1px 12px;
  background: #e84e1d;
  border-radius: 8px;
}

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.scrolled_el .simplebar-track.simplebar-vertical {
  background: #f2f2f2;
  width: 8px;
}

.scrolled_el .simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 0;
  bottom: 0;
  opacity: 1;
  background: #e7d186;
  left: 0;
  right: 0;
  border-radius: 0;
}

.section--tag {
  padding: 5px 11px;
  border: 1px solid #e7d186;
  border-radius: 12px;
}

.section--tag.green {
  border-color: #50c878;
}

.section--tag span {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  color: #50c878;
  display: block;
  margin-left: 12px;
}

.section--tags-text {
  font-size: 20px;
  line-height: 32px;
  color: #ffffff;
  margin-right: 86px;
  flex: 1 auto;
}

.section--tags-text > p {
  font-size: 16px;
  line-height: 24px;
}

.section--tags-main > div {
  margin: 0 8px;
}

.section--tags-main > div:first-child {
  margin-left: 0;
}

.section--tags-main > div:last-child {
  margin-right: 0;
}

.stars {
  height: 14px;
}

.stars svg {
  margin: 0 2px;
}

.stars svg:first-child {
  margin-left: 0;
}

.stars svg:last-child {
  margin-right: 0;
}

.breadcrumbs {
  margin-bottom: 32px;
  position: relative;
}

.breadcrumbs--item {
  white-space: nowrap;
}

.breadcrumbs--item a,
.breadcrumbs--item span {
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  color: #e7d186;
}

.breadcrumbs--item a:hover {
  color: #50c878;
}

.breadcrumbs--dot {
  width: 4px;
  height: 4px;
  background: #e7d186;
  border-radius: 50%;
  margin: 0 40px;
}

.breadcrumbs--dots {
  position: absolute;
  right: 2px;
  font-size: 25px;
  color: #e7d186;
  margin-top: -7px;
  background: #051838;
}

.bottom-btn-top {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: rgba(80, 200, 120, 0.3);
  display: block;
  transform: rotate(90deg);
  padding: 0 0 0 22px;
  position: fixed;
  bottom: 76px;
  right: 38px;
  display: none;
}

.bottom-btn-top:hover {
  background: #50c878;
}

.cookie {
  position: fixed;
  width: 100%;
  max-width: 608px;
  background: #e7d186;
  right: 32px;
  bottom: 20px;
  padding: 32px 40px;
  display: flex;
  align-items: center;
}

.cookie__descr {
  font-size: 16px;
  line-height: 20px;
  color: #051838;
}

.cookie__btn {
  font-family: Oranienbaum;
  margin-left: 16px;
  width: 110px;
  height: 56px;
  background: #051838;
  border-radius: 12px;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  display: flex;
  padding: 12.5px 44px;
  color: #ffffff;
}

#pages_main,
#reviews,
#events-slider,
.skull--pages-wrap {
  overflow: hidden;
}

@media screen and (min-width: 1280px) {
  .slide--toggle:hover {
    background: #50c878;
  }

  .slide--toggle:hover span {
    color: #051838;
  }

  .slide--toggle:hover svg {
    color: #051838;
  }

  .slider--btn:hover {
    background: #50c878;
  }

  .btn:hover {
    background: #50c878;
    color: #051838;
  }
}

@media screen and (min-width: 1921px) {
  .home--main {
    margin: 0 auto;
    overflow: visible;
  }
}

@media screen and (max-width: 1921px) {
  .menu--main-wrap {
    min-width: 376px;
    padding: 32px 25px 32px 91px;
  }
}

@media screen and (max-width: 1700px) {
  .datepicker__wrapper {
    margin-top: 12px;
  }

  .datepicker__title > span::after {
    width: 13px;
    height: 8px;
  }

  .modal--auth {
    max-width: 587px;
    padding: 36px 76px 40px;
  }

  .modal--enter-form .inputs--block {
    margin: 0 6px;
  }

  .modal--enter-form .inputs--block:not(:last-child) {
    margin-bottom: 12px;
  }

  .modal--enter-form .more--btn {
    margin-top: 20px;
  }

  .modal--enter-form .inpCheck {
    margin-top: 20px;
  }

  .modal--callback {
    max-width: 812px;
    padding: 55px 0 75px 0;
  }

  .modal--callback-title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 20px;
  }

  .modal--callback-main {
    padding: 0 74px;
  }

  .modal--callback-main .inputs--textarea {
    margin-top: 20px;
  }

  .modal--callback-main .inputs--textarea textarea {
    height: 80px;
  }

  .modal--callback-main .inpCheck {
    margin-top: 20px;
  }

  .modal--callback-main .more--btn {
    margin-top: 20px;
  }

  .ms--title {
    font-size: 40px;
    line-height: 48px;
  }

  .ms--text {
    font-size: 14px;
    line-height: 20px;
    padding: 0 145px;
    margin-bottom: 20px;
  }

  .ms--inst svg {
    width: 18px;
    height: auto;
  }

  .modal--forgot {
    max-width: 587px;
    padding: 36px 76px 40px;
  }

  .modal--online-success {
    width: 584px;
    height: 411px;
  }

  .modal-basket__wrap {
    width: 509px;
    padding: 36px 76px 40px;
  }

  .modal-basket__title {
    font-size: 40px;
  }

  .modal-basket__btns {
    padding: 0;
    gap: 20px;
  }

  .modal-basket__btns .btn-n {
    height: 42px;
    font-size: 16px;
    border-radius: 10px;
  }

  .modal-basket__bottom {
    margin-top: 28px;
    padding-top: 20px;
  }

  .modal-basket__bottom div {
    font-size: 16px;
  }

  .modal-basket__bottom p {
    font-size: 14px;
    padding: 0px 30px;
  }

  .header {
    width: 91px;
  }

  .header--wrap {
    padding: 24px 0;
  }

  .header--logo img {
    width: 59px;
    height: 75px;
  }

  .header--main-wrap nav {
    max-height: 100%;
    padding-right: 42px;
  }

  .header--main-wrap button {
    font-size: 16px;
    line-height: 24px;
  }

  .menu--block {
    margin-bottom: 24px;
  }

  .menu--block > div:first-child {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  .menu--block span {
    font-size: 20px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  .menu--block ul li a {
    font-size: 16px;
    line-height: 24px;
  }

  .menu--link.big {
    font-size: 20px;
    line-height: 32px;
  }

  .menu-btn {
    --icon-width: 28px;
  }

  .menu-btn.close .line__3 {
    transform: rotate(45deg) translate(-2px, 2px);
  }

  .menu-btn .line {
    margin-bottom: 8px;
  }

  .header--lk span {
    font-size: 10px;
    line-height: 14px;
  }

  .header-basket {
    width: 64px;
    height: 64px;
    padding: 6px;
  }

  .header-basket__count {
    margin-top: 2px;
    width: 26px;
    height: 12px;
    color: #fff;
    font-size: 8px;
    line-height: 12px;
  }

  .home--main {
    height: 700px;
  }

  .home--question {
    width: 132px;
    height: 132px;
    top: calc(35px + 6%);
  }

  .home--question span {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.26em;
  }

  .home--question a {
    padding: 0 39px;
  }

  .menu--desc {
    font-size: 10px;
    line-height: 16px;
    margin: 0 24px;
  }

  .menu--center div {
    margin-top: 12px;
  }

  .menu--center p {
    font-size: 72px;
    line-height: 72px;
  }

  .menu--item {
    width: 105px;
    height: 118px;
  }

  .menu--item:nth-child(2) {
    margin-right: 240px;
  }

  .menu--item:nth-child(3) {
    margin-left: 240px;
  }

  .menu--item svg {
    width: 70px;
    height: 76px;
  }

  .menu--item span {
    font-size: 10px;
    line-height: 16px;
    margin-top: 8px;
  }

  .skull {
    min-width: calc((0.1 * 1988px) + 1988px);
    top: 37%;
    transform: translate(-50%, -50%);
  }

  .about--left > div {
    padding-right: 75px;
    padding-top: 32px;
  }

  .about--top a.about--inst {
    top: 13px;
  }

  .about--top svg {
    width: 20px;
    height: 20px;
  }

  .about--title {
    font-size: 40px;
    line-height: 48px;
  }

  .about--text {
    font-size: 24px;
    line-height: 28px;
    margin-top: 4px;
  }

  .about--link {
    font-size: 10px;
    line-height: 14px;
    margin-top: 24px;
  }

  .about--links-wrap {
    font-size: 16px;
    line-height: 20px;
    margin-top: 12px;
  }

  .about--links-wrap span {
    margin: 0 16px;
  }

  .about--bottom {
    padding: 24px 24px 32px 24px;
    margin-top: 32px;
    border-radius: 12px;
  }

  .about--bottom svg {
    margin-bottom: 16px;
  }

  .about--bottom > span {
    font-size: 24px;
    line-height: 28px;
  }

  .reviews--main-wrap .container {
    position: relative;
  }

  .reviews--block p {
    font-size: 16px;
    line-height: 24px;
  }

  .reviews--name {
    font-size: 10px;
    line-height: 14px;
  }

  .reviews--tag {
    font-size: 10px;
    line-height: 14px;
    margin-top: 20px;
    padding: 5px 16px;
  }

  .callback--text {
    font-size: 16px;
    line-height: 24px;
    margin: 12px 0 20px 0;
    max-width: 810px;
  }

  .callback--main > p {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  .callback--main .inputs--wrap {
    margin: 16px 0 24px 0;
  }

  .callback--main-wrap {
    padding: 28px 0 40px 0;
  }

  .video--block .video--play {
    width: 40px;
    height: 40px;
  }

  .video--block .video--play svg {
    width: 14px;
    height: 12px;
  }

  .video--block > .video--main {
    min-height: 220px;
  }

  .video--block .no-video--title {
    font-size: 24px;
    line-height: 28px;
    padding-bottom: 28px;
    margin-top: 16px;
  }

  .video--block .no-video--counter {
    width: 36px;
    height: 36px;
    top: 40px;
    font-size: 22px;
    line-height: 25px;
  }

  .skull--pages-wrap.detail_event {
    min-height: 520px;
  }

  .skull--pages-wrap {
    min-height: 470px;
    margin-bottom: 32px;
  }

  .skull--pages {
    left: 47.5%;
  }

  .pages--title h1 {
    font-size: 40px;
    line-height: 48px;
    margin: 0 24px;
  }

  .pages--question {
    width: 120px;
    height: 120px;
    top: -58px;
  }

  .pages--question svg {
    width: 29px;
    height: 29px;
  }

  .pages--question span {
    font-size: 10px;
    line-height: 16px;
    letter-spacing: 0.26em;
  }

  .pages--question a {
    padding: 0 39px;
  }

  .pages--desc-wrap {
    margin-bottom: -12px;
  }

  .pages--desc-wrap p {
    margin-bottom: 12px;
  }

  .group--bottom {
    margin-top: 20px;
  }

  .group--image {
    width: 78px;
  }

  .group--image svg {
    width: 100%;
    height: auto;
  }

  .group--block {
    min-height: 110px;
    margin: 0 10px;
  }

  .group--block span {
    font-size: 10px;
    line-height: 14px;
    padding: 4px 12px;
  }

  .group--block-wrap {
    padding: 20px 0;
  }

  .group--title-main {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 4px;
  }

  .mbg {
    margin-bottom: -28px;
  }

  .mbg .itog {
    font-size: 24px;
    line-height: 28px;
    width: calc(100% - 152px);
  }

  .mbg .itog {
    width: calc(100% - 112px);
  }

  .mbg--image {
    width: 78px;
  }

  .mbg--image svg {
    width: 100%;
    height: auto;
  }

  .mbg--title {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 12px;
  }

  .mbg--block {
    width: calc(50% - 44px);
    margin-bottom: 28px;
  }

  .mbg--text {
    margin-top: 12px;
    width: calc(100% - 152px);
  }

  .abp {
    border-radius: 16px;
    padding: 40px 6.66667%;
  }

  .abp--title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 20px;
  }

  .abp--block span {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  .online--container {
    padding-bottom: 40px;
  }

  .online--main-wrap.active {
    margin-top: 28px;
  }

  .online--left {
    width: 78px;
  }

  .online--left svg {
    width: 100%;
    height: auto;
  }

  .online--right {
    width: calc(100% - 150px);
    margin: 17px 0 0 auto;
  }

  .online--right-descr {
    font-size: 16px;
    line-height: 24px;
  }

  .online--title.big {
    font-size: 32px;
    line-height: 44px;
    margin-bottom: 12px;
  }

  .online--title.small {
    font-size: 20px;
    line-height: 28px;
  }

  .online--title-closest {
    margin-left: 150px;
    font-size: 14px;
    line-height: 17px;
    margin-bottom: 20px;
  }

  .online--btns {
    margin-left: 150px;
    margin-top: 28px;
  }

  .online--btns.active {
    margin-top: 20px;
  }

  .online--btns .slide--toggle + a {
    margin-left: 16px;
  }

  .online--more-link.btn {
    padding: 9px 42px;
  }

  .slide--toggle span {
    font-size: 16px;
    line-height: 20px;
    padding: 10px 30px;
  }

  .slide--toggle svg {
    width: 15px;
    height: auto;
    right: 32px;
  }

  .modal--event {
    height: calc(100vh - 74px);
    padding: 8px 24px 58px 18px;
    width: 961px;
  }

  .modal--event-tags {
    margin-bottom: 0;
  }

  .modal--event-tags > * {
    margin: 0 4px;
  }

  .modal--event-tags > * .stars svg {
    width: 12px;
    height: 12px;
  }

  .modal--event-title {
    font-size: 32px;
    line-height: 44px;
  }

  .modal--event-top_main {
    padding-left: 38px;
  }

  .modal--event-top_left {
    width: 78px;
  }

  .modal--event-top_left svg {
    width: 100%;
    height: auto;
  }

  .modal--event-top_right {
    margin-top: 13px;
    margin-left: 15px;
  }

  .modal--event-top_bottom {
    margin-top: 4px;
  }

  .modal--event-top_bottom p {
    font-size: 14px;
    line-height: 20px;
  }

  .modal--event-icon {
    margin-left: 5px;
  }

  .modal--event-icon svg {
    width: 18px;
    height: 12px;
  }

  .modal--event-date span {
    font-size: 14px;
    line-height: 20px;
  }

  .modal--event-date div {
    width: 14px;
  }

  .modal--event-block {
    margin: 0 0 28px 41px;
  }

  .modal--event-block_title {
    font-size: 24px;
    line-height: 28px;
    margin-left: 40px;
  }

  .modal--event-block p,
  .modal--event-block li {
    font-size: 14px;
    line-height: 20px;
  }

  .modal--event-block .mst span {
    margin-left: 10px;
  }

  .modal--event-block .mst div {
    margin-left: 10px;
  }

  .modal--event-block ul {
    padding-bottom: 8px;
    margin-bottom: 20px;
  }

  .modal--event-block ul.nt {
    margin: 0 0 8px 0;
  }

  .modal--event-block ul li {
    margin: 0 0 12px 0;
    padding-left: 26px;
  }

  .modal--event-block ul li:before {
    top: 4px;
    width: 11px;
    height: 10px;
  }

  .modal--event-block.block_1 {
    padding: 12px 16px 4px 16px;
    border-radius: 12px;
  }

  .modal--event-block.block_1 .br_1 {
    display: none;
  }

  .modal--event-block.block_1 .br_2 {
    display: none;
  }

  .modal--event-block.block_1 .br_3 {
    display: none;
  }

  .modal--event-block.block_1 p {
    margin: 0 10px 8px 10px;
  }

  .modal--event-block.block_3 p {
    font-size: 24px;
    line-height: 28px;
    padding: 20px 26px 27px 26px;
  }

  .modal--event-main {
    margin-top: 16px;
    padding-right: 28px;
  }

  .about--page-top {
    width: 660px;
    margin-bottom: 20px;
  }

  .about--page-top video {
    width: 100%;
  }

  .about--page-top .video--play {
    width: 40px;
    height: 40px;
  }

  .about--page-top .video--play svg {
    width: 9px;
    height: auto;
  }

  .about--page-description {
    font-size: 16px;
    line-height: 24px;
  }

  .about--page-title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 12px;
  }

  .about--page-bottom span {
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 28px;
  }

  .about--bottom-bg {
    margin-left: -91px;
  }

  .about--bottom-bg img {
    width: calc(100% + 91px);
  }

  .about--gallery-wrap {
    padding: 0 72px;
  }

  .pagination {
    font-size: 16px;
    line-height: 20px;
  }

  .pagination--pages {
    margin: 0px 60px;
  }

  .pagination--pages-list > li {
    margin: 0px 28px;
  }

  .pagination-left--link span {
    margin-left: 15px;
    margin-right: auto;
  }

  .pagination-right--link span {
    margin-left: auto;
    margin-right: 15px;
  }

  .show-more-cards {
    border-radius: 10px;
  }

  .page-bottom {
    margin-top: 20px;
  }

  .page-bottom .pagination--pages-list > li {
    margin: 0px 16px;
  }

  .detail--event-wrap {
    max-width: 992px;
  }

  .detail--event-top {
    padding: 28px 32px 40px 32px;
  }

  .detail--event-bottom_border {
    margin-bottom: 24px;
  }

  .detail--event-bottom {
    margin-top: 40px;
    padding: 28px 32px;
  }

  .detail--event-bottom_top {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .detail--event-bottom_top .event--leading {
    padding: 5px 8px;
  }

  .detail--event-bottom_top .detail--event-bottom_item:last-child {
    margin-left: 68px;
  }

  .detail--event-bottom_item:last-child {
    margin-left: 68px;
  }

  .detail--event-bottom_item .event--price-timer {
    padding: 3px 10px 4px;
    min-height: 24px;
  }

  .detail--event-bottom_item .more--btn {
    max-height: 42px;
    padding-left: 44px;
    padding-right: 44px;
  }

  .detail--event-bottom_rate {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 20px;
  }

  .detail--event-title {
    font-size: 32px;
    line-height: 44px;
  }

  .detail--event-top_main .event--price-timer {
    position: absolute;
    top: 0;
    height: 24px;
    min-width: 120px;
  }

  .detail--event-top_main .event--price-timer .price-timer {
    font-size: 14px;
    line-height: 16px;
  }

  .detail--event-top_main .event--price-btn {
    max-width: 148px;
    height: 34px;
  }

  .detail--event-top_main .event--price-title {
    font-size: 16px;
    line-height: 24px;
  }

  .detail--event-top_main .event--price-price {
    font-size: 20px;
    line-height: 20px;
  }

  .detail--event-text {
    padding: 40px 0 0 0;
  }

  .detail--event-wrapper {
    align-items: flex-start;
  }

  .detail--event-descr {
    font-size: 16px;
    line-height: 24px;
    max-width: 568px;
    margin-left: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .detail--event-top_title {
    font-size: 16px;
    line-height: 24px;
    margin-top: 24px;
  }

  .detail--event-top_leader {
    font-size: 16px;
    line-height: 24px;
  }

  .detail--event-top_leader-name {
    font-size: 10px;
    line-height: 14px;
    padding: 5px 12px;
  }

  .detail--event-top_bottom {
    margin-top: 0;
  }

  .detail--event-icon {
    margin-left: 5px;
  }

  .detail--event-icon svg {
    width: 18px;
    height: 12px;
  }

  .detail--event-date {
    height: 24px;
  }

  .detail--event-date span {
    font-size: 14px;
    line-height: 16px;
  }

  .detail--event-date div {
    width: 14px;
  }

  .detail--event-block {
    margin-bottom: 28px;
  }

  .detail--event-block_title {
    margin: 0 0 12px 0;
    font-size: 24px;
    line-height: 28px;
  }

  .detail--event-block p,
  .detail--event-block li {
    font-size: 16px;
    line-height: 24px;
  }

  .detail--event-block ul li {
    margin: 0 0 12px 0;
    padding-left: 26px;
  }

  .detail--event-block ul li:before {
    top: 4px;
    width: 11px;
    height: 10px;
  }

  .detail--event-block.block_3 {
    margin: 28px 0;
  }

  .detail--event-block.block_3 p {
    font-size: 24px;
    line-height: 28px;
    padding: 24px 16px 24px 16px;
  }

  .detail--event-know {
    margin-bottom: 20px;
  }

  .detail--event-buttons :first-child {
    margin-right: 12px;
  }

  .detail--event .section--tag span {
    margin-left: 0;
  }

  .events-slider {
    padding: 58px 0;
  }

  .events-slider--title {
    font-size: 40px;
    line-height: 48px;
    margin-bottom: 16px;
  }

  .detail-vip {
    padding: 28px 32px;
    margin-bottom: 20px;
  }

  .detail-vip__label {
    font-size: 14px;
    line-height: 16px;
    padding: 4px 10px;
  }

  .detail-vip__list {
    padding-bottom: 20px;
    margin-bottom: 20px;
  }

  .detail-vip__list > li {
    font-size: 16px;
    line-height: 24px;
  }

  .detail-vip__price {
    font-size: 28px;
    line-height: 36px;
  }

  .detail-vip__btn.btn {
    margin-left: 58px;
    padding-left: 44px;
    padding-right: 44px;
    border-radius: 10px;
  }

  .detail-vip__installment {
    font-size: 16px;
    line-height: 24px;
  }

  .register {
    padding-bottom: 58px;
  }

  .register--title {
    font-size: 24px;
    line-height: 28px;
  }

  .register--form {
    padding: 0;
  }

  .register .custom-select-container.is-open .custom-select-panel {
    max-height: 145px;
  }

  .modal-register {
    width: 584px;
    height: 411px;
  }

  .drop-help._active input {
    border-radius: 10px 10px 0 0;
  }

  .drop-help._active .drop-help__list {
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }

  .reviews__descr {
    font-size: 24px;
    line-height: 28px;
    max-width: 961px;
  }

  .reviews__tags-item:not(:last-child) {
    margin-right: 8px;
  }

  .reviews__tags-item {
    padding: 5px 10px;
    font-size: 10px;
    letter-spacing: 0.22em;
    line-height: 14px;
  }

  .reviews__warn {
    font-size: 10px;
    line-height: 16px;
    margin: 20px 0;
  }

  .reviews__item:not(:last-child) {
    margin-bottom: 16px;
  }

  .reviews__item {
    padding: 20px 24px;
  }

  .reviews__date {
    font-size: 12px;
    line-height: 16px;
  }

  .reviews__name {
    font-size: 10px;
    line-height: 14px;
    margin-top: 20px;
  }

  .reviews__text {
    font-size: 14px;
    line-height: 20px;
    margin-top: 8px;
  }

  .reviews__wrapper {
    padding: 28px 0 40px;
    max-width: 961px;
  }

  .reviews__form {
    padding-bottom: 58px;
  }

  .reviews__textarea {
    height: 108px;
  }

  .reviews .pagination {
    margin-top: 20px;
  }

  .reviews__answer {
    margin-top: 20px;
    padding-top: 24px;
    width: calc(100% - 68px);
  }

  .reviews__answer-name {
    font-size: 10px;
    line-height: 14px;
  }

  .reviews__answer-descr {
    font-size: 14px;
    line-height: 20px;
  }

  .modal--feedback {
    width: 811px;
    height: 360px;
  }

  .partners__descr {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 40px;
  }

  .partners__container {
    padding-bottom: 58px;
  }

  .partners__wrapper {
    margin-bottom: 20px;
  }

  .partners__card {
    padding: 32px 24px 36px;
  }

  .partners__card-title {
    font-size: 16px;
    line-height: 20px;
    min-height: 40px;
    margin-top: 24px;
  }

  .detail-partner__info {
    padding: 40px 75px;
  }

  .detail-partner__title {
    font-size: 24px;
    line-height: 28px;
    margin-bottom: 6px;
  }

  .detail-partner__image {
    min-width: 285px;
    margin-right: 90px;
  }

  .detail-partner__post {
    font-size: 14px;
    line-height: 16px;
  }

  .detail-partner__descr {
    margin-bottom: 28px;
    font-size: 16px;
    line-height: 24px;
  }

  .detail-partner__descr > p {
    font-size: 16px;
    line-height: 24px;
  }

  .detail-partner__link a {
    font-size: 10px;
    line-height: 16px;
  }

  .detail-partner__video video {
    height: 284px;
  }

  .detail-partner__video {
    margin-top: 58px;
  }

  .personally__link-wrap {
    margin-top: 24px;
    margin-bottom: 12px;
  }

  .personally__item {
    flex: 1 0 calc(33% - 48px);
    padding-right: 48px;
  }

  .personally__descr {
    font-size: 14px;
    line-height: 20px;
  }

  .personally__hours {
    line-height: 14px;
    margin-bottom: 12px;
  }

  .personally__image svg {
    max-width: 78px;
    max-height: 72px;
    margin: 0 auto 26px;
  }

  .personally__subtitle {
    font-size: 12px;
    margin-bottom: 22px;
    line-height: 16px;
  }

  .personally__title {
    font-size: 32px;
    line-height: 44px;
  }

  .personally__warn {
    font-size: 12px;
    line-height: 16px;
  }

  .personally__line {
    margin: 40px 0;
  }

  .personally-descr {
    font-size: 14px;
    line-height: 20px;
  }

  .personally-archive {
    margin: 28px auto 40px;
  }

  .question-form__form {
    padding: 28px 75px 40px;
  }

  .question-form__title {
    font-size: 24px;
    line-height: 28px;
  }

  .question-form__quest {
    font-size: 10px;
    line-height: 12px;
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .not-found {
    padding-top: 170px;
    padding-bottom: 58px;
  }

  .not-found::after {
    height: 545px;
    width: calc(100% + 92px);
    left: -92px;
    top: 0px;
  }

  .not-found__img {
    max-width: 715px;
  }

  .not-found__text {
    margin-top: 70px;
    font-size: 14px;
  }

  .profile .lk-btn {
    padding: 0 40px;
  }

  .profile-personal__title {
    margin-bottom: 16px;
    font-size: 24px;
    line-height: 28px;
  }

  .profile-personal__section {
    margin-bottom: 20px;
  }

  .profile-personal__inputs .inputs--block.filled input {
    font-size: 10px;
    line-height: 12px;
  }

  .profile-personal__inputs .inputs--block.filled label {
    top: 3px;
  }

  .profile-personal__inputs .inputs--block input {
    padding: 16px 20px 8px;
    font-size: 10px;
    line-height: 12px;
  }

  .profile-personal__inputs .date-block input {
    padding: 12px 20px 12px;
  }

  .profile-personal__inputs .date-block .datepicker.filled ~ label {
    top: -3px;
  }

  .profile-personal__radio {
    margin-top: 20px;
  }

  .profile-about {
    margin-bottom: 32px;
    padding-bottom: 32px;
  }

  .profile-about__photo {
    max-width: 176px;
    margin-right: 50px;
  }

  .profile-about__photo-label {
    width: 40px;
    height: 40px;
  }

  .profile-about__photo-label svg {
    width: 40px;
    height: 40px;
  }

  .profile-about__photo-wrap {
    margin-bottom: 20px;
  }

  .profile-about__wrap {
    margin-bottom: 32px;
  }

  .profile-about__nik {
    margin-bottom: 20px;
  }

  .profile-about__nik .inputs--block {
    max-width: 284px;
  }

  .profile-about__warn-text {
    font-size: 14px;
    line-height: 16px;
  }

  .profile-about__warn-text_error {
    font-size: 12px;
  }

  .profile-about__warn-text_error.active {
    margin: 0px 0px 8px 0px;
  }

  .profile-about .inputs--block label,
  .profile-about .inputs--textarea label {
    font-size: 10px;
    line-height: 12px;
  }

  .chat {
    max-height: 590px;
    min-height: 570px;
  }

  .chat__bottom {
    font-size: 10px;
    line-height: 14px;
  }

  .chat-sidebar {
    padding: 20px 0 20px 20px;
    max-height: 550px;
    min-height: 550px;
    max-width: 220px;
  }

  .chat-sidebar__title {
    font-size: 24px;
    line-height: 28px;
  }

  .chat-sidebar__subtitle {
    font-size: 10px;
    line-height: 14px;
    padding: 4px 0;
    margin-bottom: 16px;
  }

  .chat-list.opened {
    padding-bottom: 16px;
  }

  .chat-list__title {
    font-size: 10px;
    line-height: 14px;
    padding: 16px 0 16px 8px;
  }

  .chat-list__new-mess {
    font-size: 8px;
    line-height: 12px;
    height: 12px;
    padding: 0px 4px;
  }

  .chat-list__name {
    font-size: 10px;
    line-height: 14px;
  }

  .chat-window {
    padding: 20px 32px 20px 20px;
  }

  .chat-window__form {
    left: 20px;
    padding-top: 20px;
    width: calc(100% - 52px);
  }

  .chat-window__wrapper {
    padding-right: 24px;
    max-height: 430px;
  }

  .chat-window__mess-name {
    font-size: 10px;
    line-height: 14px;
  }

  .chat-window__mess-text {
    font-size: 14px;
    line-height: 16px;
  }

  .message-form .lk-btn {
    height: 42px;
  }

  .message-form__file-label {
    top: 11px;
  }

  .message-form__input .textarea {
    min-height: 42px;
    padding: 11px 44px 11px 20px;
  }

  .message-form__input-label {
    font-size: 10px;
    line-height: 12px;
    top: 15px;
  }

  .chat-modal {
    max-width: 509px;
  }

  .chat-modal .inputs--block {
    margin-bottom: 12px;
    border-radius: 10px;
  }

  .chat-modal .inputs--block input {
    padding: 14px 10px;
  }

  .chat-modal .inputs--block input,
  .chat-modal .inputs--block label {
    font-size: 10px;
    line-height: 12px;
  }

  .chat-modal__wrap {
    padding: 16px;
  }

  .chat-modal .lk-btn {
    margin-top: 8px;
    font-size: 16px;
    padding: 0 32px;
    line-height: 20px;
  }

  .chat-modal .custom-select-opener {
    border-radius: 10px;
    padding: 14px 0;
  }

  .chat-modal .custom-select-option {
    font-size: 10px;
    line-height: 12px;
  }

  .chat-modal .custom-select-opener > span {
    font-size: 10px;
    line-height: 12px;
  }

  .basket__wrap {
    gap: 90px;
  }

  .basket__num {
    margin-top: 28px;
    font-size: 24px;
  }

  .basket__btn.lk-btn {
    width: 209px;
    margin-bottom: 130px;
    padding: 0;
    margin-top: 28px;
  }

  .product-basket__item {
    padding-bottom: 24px;
    padding-right: 160px;
  }

  .product-basket__item:not(:first-child) {
    margin-top: 24px;
  }

  .product-basket__section {
    font-size: 10px;
  }

  .product-basket__name {
    font-size: 20px;
  }

  .product-basket__control {
    padding-right: 48px;
    bottom: 24px;
  }

  #form-basket {
    margin-top: 32px;
  }

  #form-basket .inputs--wrap {
    gap: 12px;
  }

  #form-basket .inputs--wrap:not(:last-child) {
    margin-bottom: 12px;
  }

  .order-basket {
    flex: 0 0 286px;
    padding: 20px;
  }

  .order-basket__title {
    font-size: 16px;
  }

  .order-basket__list {
    margin-top: 20px;
    padding-top: 20px;
  }

  .order-basket__item:last-child div {
    font-size: 16px;
  }

  .order-basket__item div:nth-child(1) {
    font-size: 14px;
  }

  .order-basket__item div:nth-child(2) {
    font-size: 16px;
  }

  .order-basket__btn {
    margin-top: 20px;
  }

  .order-basket .inpCheck {
    margin-top: 20px;
  }

  .order-basket .inpCheck p {
    white-space: normal;
  }

  .friends .lk-banner {
    margin: 32px 0px;
  }

  .friends__item {
    padding-bottom: 28px;
  }

  .friends__item:not(:first-child) {
    padding-top: 28px;
  }

  .friends__body {
    flex: 0 0 210px;
  }

  .friends__ava {
    flex: 0 0 59px;
  }

  .friends__name {
    font-size: 20px;
  }

  .friends__message {
    font-size: 14px;
  }

  .friends__time {
    margin-left: 28px;
  }

  .friends__controll {
    margin-left: 90px;
  }

  .friends__controll .btn {
    width: 144px;
    height: 42px;
    border-radius: 10px;
  }

  .status-friends {
    padding: 4px 12px;
    font-size: 10px;
    gap: 8px;
  }

  .lk .header {
    width: 110px;
  }

  .lk-container {
    max-width: 1036px;
  }

  .lk-not-main .lk-banner {
    margin-bottom: 32px;
  }

  .lk-main__greet {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 8px;
  }

  .lk-main__descr {
    font-size: 16px;
    line-height: 24px;
  }

  .lk-main__list-item {
    border: 1px solid #50c878;
  }

  .lk-main__list-title {
    font-size: 20px;
    line-height: 28px;
  }

  .lk-main__list-count {
    width: 52px;
    height: 52px;
    font-size: 14px;
    line-height: 16px;
  }

  .lk-main__list-count_new {
    top: 8px;
    right: -6px;
  }

  .lk-main__list-mess {
    font-size: 10px;
    line-height: 14px;
    padding: 8px 25px 8px 16px;
  }

  .lk-main__list-mess:not(:last-child) {
    margin-bottom: 8px;
  }

  .lk-main__list-mess_count {
    font-size: 14px;
    line-height: 16px;
  }

  .lk-banner {
    padding: 20px 16px;
  }

  .lk-banner__subtitle {
    font-size: 14px;
    line-height: 16px;
  }

  .lk-banner__title {
    font-size: 16px;
    line-height: 24px;
  }

  .lk-banner__ico {
    margin-right: 11px;
  }

  .lk-menu__item {
    min-width: 86px;
    min-height: 68px;
  }

  .lk-menu__link {
    padding: 4px;
  }

  .lk-menu__link-ico {
    margin-bottom: 0;
    max-height: 24px;
  }

  .lk-menu__link-name {
    margin-bottom: 4px;
  }

  .lk-btn {
    min-height: 42px;
    font-size: 16px;
    line-height: 20px;
    padding: 0 34px;
    border-radius: 10px;
  }

  .no-events {
    padding: 40px;
  }

  .no-events__title {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 16px;
  }

  .lk-events__list-item:not(:last-child) {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .lk-events__list-date_start {
    font-size: 14px;
    line-height: 16px;
  }

  .lk-events__list-date_day {
    font-size: 14px;
    line-height: 16px;
  }

  .lk-events__list-leader {
    letter-spacing: 0.22em;
    font-size: 10px;
    line-height: 14px;
    padding: 5px 10px;
  }

  .lk-events__list-level {
    padding: 4px 12px;
  }

  .lk-events__list-level > span {
    font-size: 10px;
    line-height: 14px;
  }

  .lk-events__list-name {
    font-size: 20px;
    line-height: 28px;
  }

  .lk-events__list-status {
    font-size: 10px;
    line-height: 14px;
    padding: 5px 10px;
    letter-spacing: 0.22em;
  }

  section p {
    font-size: 14px;
    line-height: 20px;
  }

  .container {
    max-width: 1290px;
    padding: 0 88px;
  }

  .main--wrap {
    width: calc(100% - 91px);
  }

  .footer {
    width: calc(100% - 91px);
  }

  .footer--wrap {
    padding: 20px 0 58px 0;
  }

  .footer--bottom {
    margin-top: 28px;
    font-size: 14px;
    line-height: 20px;
  }

  .footer--phone {
    font-size: 28px;
    line-height: 36px;
  }

  .footer--mail {
    font-size: 10px;
    line-height: 16px;
  }

  .footer--db a svg {
    width: 62px;
    height: 43px;
  }

  .footer--menu {
    font-size: 14px;
    line-height: 24px;
  }

  .footer--google-play {
    width: 107px;
    height: 36px;
  }

  .footer--app-store {
    width: 107px;
    height: 36px;
  }

  .section--title-small {
    font-size: 10px;
    line-height: 16px;
    margin-bottom: 8px;
  }

  .section--title-big {
    font-size: 40px;
    line-height: 48px;
  }

  .inpCheck p {
    font-size: 12px;
    line-height: 16px;
  }

  .inpCheck {
    font-size: 12px;
    line-height: 16px;
    margin-top: 24px;
  }

  .more--btn {
    margin-top: 28px;
    font-size: 16px;
    line-height: 20px;
    padding: 9px 32px 9px;
    border-radius: 10px;
  }

  .custom-select-opener {
    padding: 10px 62px 10px 26px;
    font-size: 12px;
    line-height: 16px;
  }

  .custom-select-option {
    font-size: 12px;
    line-height: 16px;
    margin: 12px 0;
  }

  .slider--btn {
    width: 40px;
    height: 40px;
  }

  .slider--btn svg {
    width: 9px;
    height: 14px;
  }

  .slider--left {
    left: 26px;
  }

  .slider--right {
    right: 26px;
  }

  .event--card-wrap {
    padding: 18px 20px;
    border-radius: 12px;
  }

  .event--card-wrap_archive .event--archive {
    padding: 4px 24px;
  }

  .event--card-more {
    font-size: 10px;
    line-height: 14px;
  }

  .event--type {
    font-size: 10px;
    line-height: 14px;
  }

  .event--date-time {
    font-size: 14px;
    line-height: 16px;
    padding: 1px 4px 1px 8px;
  }

  .event--date-days {
    padding: 3px 10px 3px 6px;
  }

  .event--right-top {
    margin-bottom: 16px;
  }

  .event--name {
    min-height: 60px;
    font-size: 24px;
    line-height: 28px;
  }

  .event--leading {
    font-size: 10px;
    padding: 4px 10px;
    line-height: 14px;
    margin-top: 12px;
    align-items: center;
  }

  .event--leading-online svg {
    width: 17px;
    height: 12px;
    margin-right: 6px;
  }

  .event--leading-online {
    padding: 4px 10px;
    font-size: 10px;
    line-height: 14px;
    margin-top: 12px;
  }

  .event--left svg {
    margin-bottom: 12px;
  }

  .event--right a.gh {
    font-size: 10px;
    line-height: 14px;
  }

  .event--time {
    font-size: 16px;
    line-height: 20px;
  }

  .event--price-wrapper {
    min-height: 36px;
    margin-left: -20px;
    margin-right: -24px;
    margin-top: 20px;
  }

  .event--price-wrapper_not-timer {
    padding-left: 60px;
  }

  .event--price-timer {
    padding: 1px 0 1px 20px;
    min-width: 128px;
    min-height: 36px;
  }

  .event--price-timer svg {
    width: 13px;
    height: 13px;
  }

  .event--price-timer .price-timer {
    font-size: 16px;
    line-height: 20px;
  }

  .event--price-price {
    font-size: 16px;
    line-height: 20px;
    margin-left: 8px;
  }

  .event--price-old {
    font-size: 12px;
    line-height: 16px;
    margin-left: 8px;
  }

  .mg80 {
    margin-bottom: 58px;
  }

  .mg60 {
    margin-bottom: 40px;
  }

  .mg20 {
    margin-bottom: 12px;
  }

  .mg32 {
    margin-bottom: 20px;
  }

  .mt32 {
    margin-top: 20px;
  }

  .inputs--block label,
  .inputs--textarea label {
    font-size: 12px;
    line-height: 16px;
  }

  .inputs--block input,
  .inputs--block textarea,
  .inputs--textarea input,
  .inputs--textarea textarea {
    border-radius: 10px;
  }

  .inputs--block input,
  .inputs--block textarea,
  .inputs--textarea input,
  .inputs--textarea textarea {
    padding: 10px;
    font-size: 12px;
    line-height: 16px;
  }

  .inputs--block input::placeholder,
  .inputs--block textarea::placeholder,
  .inputs--textarea input::placeholder,
  .inputs--textarea textarea::placeholder {
    font-size: 10px;
    line-height: 14px;
  }

  .btn {
    font-size: 16px;
    line-height: 20px;
    padding: 9px 30px 9px;
  }

  .btn--close {
    width: 24px;
    height: 24px;
    right: 15px;
    top: 15px;
  }

  .btn--close svg {
    width: 100%;
    height: auto;
  }

  .section--tag {
    padding: 3px 11px;
  }

  .section--tag span {
    font-size: 10px;
    line-height: 16px;
    margin-left: 10px;
  }

  .section--tags-text {
    font-size: 14px;
    line-height: 20px;
    margin-right: 16px;
  }

  .stars {
    height: 10px;
  }

  .stars svg {
    width: 8px;
    height: 8px;
  }

  .breadcrumbs {
    margin-bottom: 24px;
  }

  .breadcrumbs--dot {
    margin: 0 28px;
  }

  .bottom-btn-top {
    width: 40px;
    height: 40px;
    padding: 0 0 0 15px;
    bottom: 56px;
    right: 33px;
  }

  .bottom-btn-top > svg {
    height: 13px;
    width: 9px;
  }

  .cookie {
    max-width: 510px;
    padding: 24px 32px;
  }

  .cookie__descr {
    font-size: 12px;
    line-height: 16px;
  }

  .cookie__btn {
    padding: 8.5px 41px 11.5px;
    height: 40px;
    font-size: 16px;
    line-height: 20px;
  }
}

@media screen and (max-width: 1700px) and (max-width: 640px) {
  .modal--event-block.block_1 p {
    margin: 0 0 8px 0;
    width: 100%;
    text-align: center;
    justify-content: center;
  }
}

@media (max-width: 1348px) {
  .event--card-wrap_archive .event--archive {
    font-size: 10px;
    line-height: 14px;
  }
}

@media screen and (max-width: 1340px) {
  .group--block {
    margin: 10px;
  }

  .group--block:first-child {
    margin-left: 10px;
  }

  .group--block:last-child {
    width: 100%;
    margin-right: 10px;
  }
}

@media screen and (max-width: 1300px) {
  .mbg--text {
    width: calc(100% - 112px);
  }
}

@media screen and (max-width: 1280px) {
  .datepicker__wrapper {
    margin-top: 8px;
  }

  .datepicker.filled ~ label > svg {
    top: 9px;
  }

  .datepicker.filled ~ label .date-block--text {
    font-size: 9px;
    top: 3px;
    line-height: 1;
  }

  .modal--auth {
    max-width: 548px;
    padding: 32px 56px;
  }

  .modal--enter-form .inputs--block {
    margin: 0 4px;
  }

  .modal--enter-form .more--btn {
    margin-top: 16px;
  }

  .modal--enter-form .inpCheck {
    margin-top: 16px;
  }

  .modal--callback {
    max-width: 770px;
    padding: 70px 0 86px 0;
  }

  .modal--callback-title {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 16px;
  }

  .modal--callback-main {
    padding: 0 56px;
  }

  .modal--callback-main .inpCheck {
    margin-top: 16px;
  }

  .modal--callback-main .more--btn {
    margin-top: 16px;
  }

  .ms--title {
    font-size: 36px;
    line-height: 44px;
  }

  .ms--text {
    margin-bottom: 16px;
    padding: 0 125px;
  }

  .modal--forgot {
    max-width: 548px;
    padding: 32px 56px;
  }

  .modal--online-success {
    width: 436px;
    height: 367px;
  }

  .modal--online-success .ms--text {
    padding: 0;
  }

  .modal-basket__wrap {
    width: 436px;
    padding: 32px 56px;
  }

  .modal-basket__title {
    font-size: 36px;
  }

  .modal-basket__btns {
    margin-top: 16px;
    gap: 12px;
  }

  .modal-basket__btns .btn-n {
    height: 36px;
    font-size: 14px;
    border-radius: 8px;
  }

  .modal-basket__bottom p {
    padding: 0px 20px;
  }

  .header {
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: auto;
    height: auto;
  }

  .header--wrap {
    flex-direction: row;
    padding: 6px 16px;
    justify-content: flex-start;
  }

  .header--menu {
    order: 5;
    margin-left: 40px;
    margin-right: 19px;
  }

  .header--social {
    order: 4;
    margin-left: 70px;
  }

  .header--youtube {
    order: 4;
    margin-left: 37px;
  }

  .header--logo img {
    width: 32px;
    height: 40px;
  }

  .header--main-wrap nav {
    max-height: 100%;
    height: auto;
    padding: 0;
  }

  .header--main-wrap nav .simplebar-content {
    display: flex;
    flex-wrap: wrap;
  }

  .header--main-wrap button {
    display: none;
  }

  .menu--block {
    width: 33.33333%;
  }

  .menu--link.big {
    margin-bottom: 24px;
    font-size: 20px;
    line-height: 32px;
  }

  .menu--main-wrap {
    top: -9999px;
    right: 0;
    bottom: auto;
    padding: 24px 16px 32px 72px;
  }

  .menu--main-wrap.active {
    left: 0;
    top: 100%;
  }

  .menu--wrapper {
    flex-direction: row-reverse;
  }

  .menu-btn:hover .line {
    background: #051c38;
  }

  .menu-btn {
    --icon-width: 16px;
  }

  .menu-btn .line {
    margin-bottom: 4px;
  }

  .header--lk {
    order: 3;
    margin-left: auto;
  }

  .header--lk a {
    flex-direction: row-reverse;
  }

  .header--lk a svg {
    width: 7px;
    height: 12px;
  }

  .header--lk span {
    margin: 0 16px 0 0;
    line-height: 12px;
  }

  .header-basket {
    display: none;
  }

  .home--menu ul {
    width: 100%;
    margin: 0;
  }

  .home--menu-wrap {
    bottom: calc(-40px + 10%);
  }

  .home--question {
    width: 120px;
    height: 120px;
    top: calc(35px + 17%);
  }

  .home--question svg {
    width: 29px;
    height: 29px;
  }

  .home--question span {
    line-height: 12px;
    letter-spacing: 0.2em;
  }

  .home--question a {
    padding: 0 34px;
  }

  .menu--desc {
    line-height: 12px;
    margin: 0 16px;
  }

  .menu--center p {
    font-size: 62px;
    line-height: 62px;
  }

  .menu--item {
    width: 103px;
    height: 95px;
  }

  .menu--item:nth-child(2) {
    margin-right: 200px;
  }

  .menu--item:nth-child(3) {
    margin-left: 200px;
  }

  .menu--item svg {
    width: 52px;
    height: 58px;
    margin-top: 10px;
  }

  .menu--item span {
    line-height: 12px;
  }

  .skull {
    min-width: 1785px;
    top: 39%;
    height: 100%;
  }

  .about--left > div {
    padding-right: 44px;
    padding-top: 20px;
  }

  .about--top a.about--youtube {
    top: 50px;
  }

  .about--title {
    font-size: 36px;
    line-height: 44px;
  }

  .about--text {
    font-size: 20px;
    line-height: 24px;
  }

  .about--links-wrap {
    font-size: 14px;
    line-height: 14px;
  }

  .about--bottom svg {
    width: 7px;
    height: 13px;
  }

  .about--bottom > span {
    font-size: 20px;
    line-height: 24px;
  }

  .reviews--main .slick-track {
    margin: 0 -32px;
  }

  .reviews--block {
    margin: 0 32px;
  }

  .callback--text {
    max-width: 66.66667%;
    margin: 8px 0 20px 0;
  }

  .callback--main > p {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 12px;
  }

  .callback--main {
    width: 66.66667%;
  }

  .callback--main-wrap {
    padding: 20px 0 32px 0;
  }

  .video--slider-wrap .container {
    position: static;
  }

  .video--block {
    margin: 0 4px;
  }

  .video--block > .video--main {
    min-height: auto;
  }

  .video--block .no-video--title {
    font-size: 20px;
    line-height: 24px;
    margin-top: 0;
  }

  .video--block .no-video--image img {
    margin-top: 20px;
  }

  .video--block .no-video--counter {
    font-size: 20px;
    line-height: 23px;
    width: 32px;
    height: 32px;
    padding-top: 4px;
    top: 30px;
  }

  .skull--pages-wrap {
    margin-bottom: 28px;
  }

  .skull--pages {
    min-width: 1731px;
    top: 0;
    left: 47%;
  }

  .pages--title h1 {
    font-size: 36px;
    line-height: 44px;
  }

  .pages--question {
    top: -8px;
  }

  .pages--question span {
    line-height: 12px;
    letter-spacing: 0.2em;
  }

  .pages--question a {
    padding: 0 34px;
  }

  .group--bottom {
    margin-top: 16px;
    padding: 0 56px;
  }

  .group--block-wrap {
    padding: 16px 0;
  }

  .mbg {
    margin-bottom: -20px;
  }

  .mbg--title {
    font-size: 28px;
    line-height: 36px;
  }

  .mbg--block {
    width: calc(50% - 32px);
    margin-bottom: 20px;
  }

  .mbg--text {
    width: calc(100% - 112px);
  }

  .abp {
    padding: 32px 5.55556%;
  }

  .abp--title {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 16px;
  }

  .abp--block span {
    font-size: 20px;
    line-height: 24px;
  }

  .btn.online--more-link {
    padding: 10px 24px 10px;
    border-radius: 8px;
  }

  .online--wrap {
    padding: 0 56px;
  }

  .online--container {
    padding-bottom: 32px;
  }

  .online--main-wrap.active {
    margin-top: 24px;
  }

  .online--right {
    width: calc(100% - 110px);
    margin: 16px 0 0 auto;
  }

  .online--title.big {
    font-size: 24px;
    line-height: 32px;
  }

  .online--title.small {
    font-size: 16px;
    line-height: 24px;
  }

  .online--title-closest {
    margin-left: 110px;
    margin-bottom: 16px;
    padding-left: 56px;
  }

  .online--btns {
    margin-left: 110px;
    margin-top: 24px;
    padding-left: 56px;
  }

  .online--btns.active {
    margin-top: 16px;
  }

  .slide--toggle {
    border-width: 1px;
    border-radius: 8px;
  }

  .slide--toggle span {
    font-size: 14px;
    line-height: 14px;
    padding: 11px 24px 11px;
    border-radius: 8px;
  }

  .slide--toggle svg {
    right: 28px;
  }

  .slide--toggle:active {
    background: #50c878;
  }

  .slide--toggle:active span {
    color: #051838;
  }

  .slide--toggle:active svg {
    color: #051838;
  }

  .modal--event {
    height: calc(100vh - 146px);
    width: 880px;
    padding: 8px 20px 44px 8px;
  }

  .modal--event-tags {
    margin-bottom: 4px;
  }

  .modal--event-title {
    font-size: 28px;
    line-height: 36px;
  }

  .modal--event-top_main {
    padding-left: 33px;
  }

  .modal--event-top_right {
    margin-top: 12px;
    margin-left: -15px;
  }

  .modal--event-top_bottom {
    margin-top: 0;
  }

  .modal--event-block {
    margin: 0 0 24px 41px;
  }

  .modal--event-block_title {
    font-size: 20px;
    line-height: 24px;
    margin-left: 34px;
  }

  .modal--event-block p,
  .modal--event-block li {
    font-size: 12px;
    line-height: 17px;
  }

  .modal--event-block .mst {
    padding: 0 8px 0 8px;
  }

  .modal--event-block .mst span {
    margin-left: 0;
  }

  .modal--event-block .mst div {
    margin-left: 0;
  }

  .modal--event-block ul {
    padding: 0 8px 8px 8px;
    margin-bottom: 16px;
  }

  .modal--event-block.block_3 p {
    font-size: 20px;
    line-height: 24px;
    padding: 16px 8px 23px 8px;
  }

  .modal--event-main {
    margin-top: 8px;
    padding-right: 20px;
    height: auto;
  }

  .about--page-top {
    margin-bottom: 16px;
  }

  .about--page-top .video--play {
    width: 38px;
    height: 38px;
  }

  .about--page-title {
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 8px;
  }

  .about--page-bottom span {
    line-height: 12px;
    margin-bottom: 20px;
  }

  .about--bottom-bg {
    margin-left: -143px;
  }

  .about--bottom-bg img {
    width: calc(100% + 143px);
  }

  .about--gallery-wrap {
    padding: 0 16px;
  }

  .pagination {
    font-size: 14px;
    line-height: 14px;
  }

  .pagination--pages-list > li {
    height: 14px;
  }

  .pagination--pages-list > li:last-child {
    margin-right: 0;
  }

  .pagination--pages-list > li:first-child {
    margin-left: 0;
  }

  .pagination-left--link svg {
    height: 10px;
  }

  .pagination-right--link svg {
    height: 10px;
  }

  .show-more-cards {
    border-radius: 8px;
  }

  .page-bottom {
    margin-top: 16px;
  }

  .page-bottom .pagination--pages-list > li {
    margin: 0px 6px;
  }

  .page-bottom .pagination--pages {
    margin: 0px 92px;
  }

  .detail--event-top {
    padding: 28px 32px;
  }

  .detail--event-bottom_border {
    margin-bottom: 16px;
  }

  .detail--event-bottom {
    padding: 24px 32px;
  }

  .detail--event-bottom_top {
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  .detail--event-bottom_rate {
    margin-bottom: 16px;
    font-size: 12px;
    line-height: 14px;
  }

  .detail--event-title {
    font-size: 28px;
    line-height: 36px;
  }

  .detail--event-top_main .event--price-timer {
    height: 20px;
    margin-bottom: 10px;
    padding: 0 16px 0 12px;
  }

  .detail--event-descr {
    max-width: 460px;
  }

  .detail--event-block {
    margin-bottom: 24px;
  }

  .detail--event-block_title {
    font-size: 20px;
    line-height: 24px;
  }

  .detail--event-block.block_1 > div {
    padding: 0 32px;
  }

  .detail--event-block.block_2 .mst {
    padding: 0 32px;
  }

  .detail--event-block.block_3 {
    margin: 24px 0;
  }

  .detail--event-block.block_3 p {
    font-size: 20px;
    line-height: 24px;
    padding: 24px 32px;
  }

  .detail--event-block.block_4 {
    padding: 0 32px;
  }

  .detail--event-know {
    margin-bottom: 16px;
  }

  .detail--event-buttons :first-child {
    margin-right: 8px;
  }

  .detail--event-buttons {
    margin-top: 16px;
  }

  .detail--event-buttons .more--btn {
    margin-left: 0;
  }

  .detail--event .section--tag span {
    margin-left: 0;
    line-height: 14px;
  }

  .events-slider {
    padding: 44px 0;
  }

  .events-slider--wrapper .container {
    max-width: 912px;
  }

  .events-slider .event--type {
    max-width: min-content;
  }

  .events-slider .event--right-top {
    align-items: flex-start;
  }

  .events-slider--title {
    font-size: 36px;
    line-height: 44px;
    margin-bottom: 12px;
  }

  .events-slider .section--main {
    min-height: 215px;
  }

  .events-slider .slick-slide {
    margin: 0 4px;
  }

  .detail-vip {
    padding: 24px 32px;
    margin-bottom: 16px;
  }

  .detail-vip__label {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 16px;
  }

  .detail-vip__list {
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .detail-vip__list > li {
    width: calc(33% - 6px);
  }

  .detail-vip__btn.btn {
    border-radius: 8px;
  }

  .register {
    padding-bottom: 44px;
  }

  .register--title {
    font-size: 20px;
    line-height: 24px;
  }

  .register--inputs-block .inputs--block input {
    padding: 14px 11px 10px;
  }

  .register--inputs-block .inputs--block .custom-select-opener {
    padding: 11px 62px 11px 26px;
  }

  .register--inputs-block .inputs--block label {
    font-size: 12px;
    line-height: 16px;
  }

  .register--wrap > div:nth-child(1),
  .register--wrap > div:nth-child(2) {
    width: 28%;
  }

  .register--wrap > div:nth-child(3) {
    width: 44%;
  }

  .register .more--btn {
    margin-left: 0;
  }

  .register .inpCheck {
    margin-top: 28px;
  }

  .register .custom-select-container.is-open .custom-select-panel {
    max-height: 130px;
  }

  .modal-register {
    width: 436px;
    height: 367px;
  }

  .modal-register .ms--text {
    padding: 0;
  }

  .drop-help._active input {
    border-radius: 8px 8px 0 0;
  }

  .drop-help._active .drop-help__list {
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }

  .drop-help__list .simplebar-content div {
    font-size: 10px;
    line-height: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .reviews__descr {
    font-size: 20px;
    line-height: 24px;
  }

  .reviews__warn {
    line-height: 12px;
    margin: 12px -1px;
  }

  .reviews__item:not(:last-child) {
    margin-bottom: 8px;
  }

  .reviews__item {
    padding: 16px 24px;
  }

  .reviews__name {
    margin-top: 16px;
  }

  .reviews__wrapper {
    padding-bottom: 32px;
  }

  .reviews__form {
    padding-bottom: 44px;
  }

  .reviews .pagination {
    margin-top: 16px;
  }

  .modal--feedback {
    width: 658px;
    height: 352px;
  }

  .partners__container {
    padding-bottom: 44px;
  }

  .partners__wrapper {
    margin-bottom: 16px;
  }

  .partners__card:not(:nth-child(5n)) {
    margin-right: 8px;
  }

  .partners__card:not(:nth-child(4n)) {
    margin-right: 8px;
  }

  .partners__card {
    margin-bottom: 8px;
    width: calc(25% - 8px);
  }

  .detail-partner .container.smallx2 {
    padding: 0 16px;
  }

  .detail-partner__info {
    padding: 32px 55px;
  }

  .detail-partner__title {
    font-size: 20px;
    line-height: 24px;
  }

  .detail-partner__image {
    margin-right: 60px;
  }

  .detail-partner__post {
    margin-bottom: 16px;
  }

  .detail-partner__descr {
    margin-bottom: 24px;
  }

  .detail-partner__video video {
    height: 260px;
  }

  .detail-partner__video {
    margin-top: 44px;
  }

  .personally__link-wrap {
    margin-top: 24px;
    margin-bottom: 8px;
  }

  .personally__item {
    flex: 1 0 calc(50% - 40px);
    padding-right: 40px;
    padding-bottom: 32px;
    margin-bottom: 24px;
  }

  .personally__item:nth-child(3n) {
    padding-right: 40px;
  }

  .personally__item:nth-child(2n) {
    padding-right: 0;
  }

  .personally__item:nth-last-child(-n + 3) {
    border-bottom: 1px solid #50c878;
    padding-bottom: 32px;
    margin-bottom: 24px;
  }

  .personally__item:nth-last-child(-n + 2) {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .personally__image svg {
    margin: 0 auto 17px;
  }

  .personally__subtitle {
    min-height: 32px;
    margin-bottom: 12px;
  }

  .personally__title {
    font-size: 28px;
    line-height: 36px;
    margin-bottom: 4px;
  }

  .personally-archive {
    margin: 24px auto 24px;
  }

  .question-form__form {
    padding: 24px 63px 32px;
  }

  .question-form__title {
    font-size: 20px;
    line-height: 24px;
  }

  .question-form__quest {
    padding: 12px;
    margin-top: 12px;
    margin-bottom: 16px;
    border: 1px solid #e7d186;
  }

  .not-found {
    padding-top: 180px;
    padding-bottom: 44px;
  }

  .not-found::after {
    height: 452px;
    top: 50px;
    left: -50px;
    width: 100%;
    width: calc(100% + 50px);
  }

  .not-found__img {
    max-width: 618px;
  }

  .not-found__text {
    margin-top: 60px;
  }

  .profile .custom-select-opener {
    padding: 8px 62px 8px 12px;
    font-size: 12px;
  }

  .profile-personal__title {
    margin-bottom: 12px;
    font-size: 20px;
    line-height: 24px;
  }

  .profile-personal__section {
    margin-bottom: 16px;
  }

  .profile-personal__selects .select-block:not(:last-child) {
    margin-right: 8px;
  }

  .profile-personal__inputs .inputs--block:not(:last-child) {
    margin-right: 8px;
  }

  .profile-personal__inputs .inputs--block input {
    padding: 15px 20px 5px;
  }

  .profile-personal__inputs .date-block input {
    padding: 10px 20px 10px;
  }

  .profile-about {
    margin-bottom: 28px;
    padding-bottom: 28px;
  }

  .profile-about__wrap {
    margin-bottom: 28px;
  }

  .profile-personal__section .inputs--block input {
    padding: 12px 20px 5px;
  }

  .profile-personal__section .inputs--block label {
    font-size: 10px;
    line-height: 14px;
  }

  .message-form .lk-btn {
    height: 36px;
    padding: 0 24px;
  }

  .message-form__file-label {
    top: 7px;
  }

  .message-form__input {
    max-width: calc(100% - 128px);
  }

  .message-form__input .textarea {
    min-height: 36px;
    padding: 8px 44px 8px 20px;
    max-height: 120px;
  }

  .message-form__input-label {
    top: 12px;
  }

  .chat-modal {
    max-width: 436px;
    padding: 16px 56px 32px;
  }

  .chat-modal .inputs--block {
    margin-bottom: 8px;
    border-radius: 8px;
  }

  .chat-modal .inputs--block input {
    padding: 11px 10px;
  }

  .chat-modal__wrap {
    padding: 16px 0 0;
  }

  .chat-modal .lk-btn {
    font-size: 14px;
    line-height: 14px;
  }

  .chat-modal .custom-select-opener::before {
    width: 13px;
    height: 8px;
    right: 12px;
  }

  .chat-modal .custom-select-opener {
    padding: 11px 10px;
  }

  .basket__wrap {
    gap: 64px;
  }

  .basket__num {
    font-size: 20px;
    margin-top: 12px;
  }

  .basket__btn.lk-btn {
    margin-bottom: 25px;
    width: 175px;
  }

  .product-basket__item {
    padding-bottom: 16px;
  }

  .product-basket__item:not(:first-child) {
    margin-top: 16px;
  }

  .product-basket__name {
    font-size: 16px;
  }

  .product-basket__control {
    bottom: 16px;
  }

  #form-basket {
    margin-top: 28px;
  }

  #form-basket .inputs--wrap:not(:last-child) {
    margin-bottom: 16px;
  }

  #form-basket .register--inputs-block .inputs--block input {
    padding: 14px 11px 4px;
  }

  #form-basket .register--inputs-block .inputs--block label {
    font-size: 10px;
  }

  .order-basket {
    flex: 0 0 269px;
  }

  .order-basket .inpCheck {
    margin-right: -4px;
  }

  .friends .lk-banner {
    margin: 28px 0px;
  }

  .friends__item {
    padding-bottom: 24px;
  }

  .friends__item:not(:first-child) {
    padding-top: 24px;
  }

  .friends__body {
    flex: 0 0 214px;
  }

  .friends__ava {
    flex: 0 0 47px;
  }

  .friends__name {
    font-size: 16px;
  }

  .friends__text {
    margin-left: 8px;
  }

  .friends__time {
    margin-left: 22px;
  }

  .friends__controll {
    margin-left: 37px;
    gap: 8px;
  }

  .friends__controll .btn {
    width: 132px;
    height: 36px;
    border-radius: 8px;
  }

  .lk .header--logo {
    margin-right: auto;
  }

  .lk .header {
    width: 100%;
  }

  .lk .header .lk-soc__item .lk-menu__link {
    padding: 0;
  }

  .lk-container {
    max-width: 880px;
  }

  .lk-not-main .lk-banner {
    margin-bottom: 28px;
  }

  .lk-main__greet {
    font-size: 16px;
    line-height: 24px;
  }

  .lk-main__list-item {
    width: calc(33% - 4px);
    margin-bottom: 8px;
  }

  .lk-main__list-item:not(:nth-child(3n)) {
    margin-right: 8px;
  }

  .lk-main__list-title {
    font-size: 16px;
    line-height: 24px;
  }

  .lk-banner__subtitle {
    font-size: 12px;
    line-height: 16px;
  }

  .lk-menu {
    display: flex;
    position: fixed;
    left: 0;
    bottom: 0;
    background: #e7d186;
    width: 100%;
    padding: 8px 15px;
  }

  .lk-menu__item {
    width: 100%;
    min-height: 40px;
    border-radius: 4px;
    min-width: auto;
  }

  .lk-menu__item:not(:last-child) {
    margin-bottom: 0;
    margin-right: 8px;
  }

  .lk-menu__item_home {
    display: flex;
  }

  .lk-menu__item_friends,
  .lk-menu__item_mess {
    display: none;
  }

  .lk-menu__link {
    position: relative;
  }

  .lk-menu__link_soc {
    display: flex;
    flex-direction: row;
    padding: 4px;
  }

  .lk-menu__link_soc .lk-menu__link-ico {
    display: block;
    margin-right: 0px;
    margin-bottom: 0;
    flex: 0 0 26px;
    height: 20px;
  }

  .lk-menu__link_soc .lk-menu__link-ico svg {
    max-width: 100%;
    object-fit: contain;
  }

  .lk-menu__link_soc .lk-menu__link-count {
    display: block;
    padding: 0 3px;
    min-width: 12px;
    height: 12px;
    font-size: 8px;
    line-height: 12px;
  }

  .lk-menu__link_md {
    display: flex;
  }

  .lk-menu__link-count {
    display: none;
  }

  .lk-menu__link-ico {
    display: none;
  }

  .lk-menu__link-ico_md {
    width: 21px;
  }

  .lk-menu__link-name {
    margin-bottom: 0;
    font-size: 10px;
    line-height: 16px;
  }

  .lk-avatar {
    margin-left: 28px;
  }

  .lk-avatar__image {
    width: 28px;
    height: 28px;
  }

  .lk-avatar__logout {
    display: none;
  }

  .lk-btn {
    min-height: 36px;
    padding: 0 26px;
    font-size: 14px;
    line-height: 14px;
    border-width: 1px;
    border-radius: 8px;
  }

  .no-events__title {
    font-size: 16px;
    line-height: 24px;
  }

  .lk-events__list-item:not(:last-child) {
    margin-bottom: 16px;
    padding-bottom: 16px;
  }

  .lk-events__list-name {
    font-size: 16px;
    line-height: 24px;
  }

  .container {
    max-width: 912px;
    width: 100%;
    padding: 0 16px;
  }

  .container--menu {
    max-width: 972px;
  }

  .container.small {
    padding-left: 72px;
  }

  .container.smallxR,
  .container.smallxR2 {
    padding-right: 72px;
  }

  .container.smallx2 {
    padding: 0 72px;
  }

  .main--wrap {
    width: 100%;
  }

  .footer {
    width: 100%;
    padding: 0 56px;
  }

  .footer--wrap {
    padding: 16px 0 44px 0;
  }

  .footer--bottom {
    margin-top: 24px;
  }

  .footer--btn {
    align-items: flex-start;
  }

  .footer--btn a + a {
    margin-top: 12px;
  }

  .footer--copy {
    width: 20%;
    max-width: 140px;
  }

  .footer--policy {
    width: 15%;
  }

  .footer--db a svg {
    width: 46px;
    height: 32px;
    margin-left: 20px;
  }

  .footer--menu {
    max-width: 44.11765%;
    margin-bottom: 36px;
  }

  .footer--menu li {
    margin-bottom: 12px;
  }

  .footer--app {
    margin-left: auto;
    margin-right: 0;
  }

  .footer--google-play {
    width: 88px;
    height: 28px;
    margin-right: 8px;
  }

  .footer--app-store {
    width: 88px;
    height: 28px;
  }

  .section--title-small {
    line-height: 12px;
  }

  .section--title-big {
    font-size: 36px;
    line-height: 44px;
  }

  .section--main {
    margin-bottom: -8px;
  }

  .inpCheck {
    margin-top: 16px;
  }

  .more--btn {
    font-size: 14px;
    line-height: 14px;
    margin-top: 24px;
    border-width: 1px;
    padding: 10px 24px 10px;
    border-radius: 8px;
  }

  .custom-select-opener {
    padding: 11px 62px 11px 26px;
    border-width: 1px;
    font-size: 10px;
    line-height: 14px;
    border-radius: 8px;
  }

  .custom-select-panel {
    border-width: 0 1px 1px 1px;
  }

  .custom-select-option {
    font-size: 10px;
    line-height: 14px;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .slider--left {
    left: -36px;
  }

  .slider--right {
    right: -36px;
  }

  .event--card-wrap {
    margin-bottom: 8px;
    padding: 16px;
    width: calc(33.33333% - 4px);
  }

  .event--type {
    line-height: 12px;
  }

  .event--name {
    font-size: 20px;
    line-height: 24px;
    min-height: 48px;
  }

  .event--left {
    width: 32px;
  }

  .event--time {
    font-size: 14px;
  }

  .event--price-wrapper {
    margin-left: -16px;
    padding-right: 22px;
    margin-top: 12px;
  }

  .event--price-wrapper_not-timer {
    padding-left: 40px;
  }

  .event--price-timer {
    padding: 1px 0 1px 16px;
    min-width: 100px;
  }

  .event--price-timer svg {
    margin-right: 4px;
  }

  .event--price-timer .price-timer {
    font-size: 14px;
    margin-top: 1px;
  }

  .mg80 {
    margin-bottom: 44px;
  }

  .mg60 {
    margin-bottom: 32px;
  }

  .mg20 {
    margin-bottom: 8px;
  }

  .mg32 {
    margin-bottom: 16px;
  }

  .mt32 {
    margin-top: 16px;
  }

  .inputs--block.filled label,
  .inputs--textarea.filled label {
    font-size: 9px;
    top: 3px;
    line-height: 1;
  }

  .inputs--block label,
  .inputs--textarea label {
    font-size: 10px;
    line-height: 14px;
  }

  .inputs--block input,
  .inputs--block textarea,
  .inputs--textarea input,
  .inputs--textarea textarea {
    border-radius: 8px;
  }

  .inputs--block input:focus ~ label,
  .inputs--block textarea:focus ~ label,
  .inputs--textarea input:focus ~ label,
  .inputs--textarea textarea:focus ~ label {
    font-size: 9px;
    top: 3px;
    line-height: 1;
  }

  .inputs--block input,
  .inputs--block textarea,
  .inputs--textarea input,
  .inputs--textarea textarea {
    border-width: 1px;
    font-size: 10px;
    line-height: 14px;
    padding: 11px 10px;
  }

  .inputs--block input::placeholder,
  .inputs--block textarea::placeholder,
  .inputs--textarea input::placeholder,
  .inputs--textarea textarea::placeholder {
    font-size: 10px;
    line-height: 14px;
  }

  .btn {
    font-size: 14px;
    line-height: 14px;
    border-width: 1px;
    padding: 10px 30px 10px;
  }

  .btn:active {
    background: #50c878;
    color: #051838;
  }

  .btn--close {
    width: 20px;
    height: 20px;
    top: 5px;
    right: 5px;
  }

  .parsley-errors-list li {
    padding: 0 8px;
  }

  .section--tag {
    padding: 3px 7px;
  }

  .breadcrumbs {
    margin-bottom: 20px;
  }

  .breadcrumbs--item a,
  .breadcrumbs--item span {
    font-size: 10px;
    line-height: 16px;
  }

  .breadcrumbs--dot {
    width: 3px;
    height: 3px;
    margin: 2px 8px 0;
    padding: 3px 3px;
  }

  .bottom-btn-top {
    bottom: 40px;
    right: 15px;
  }

  .bottom-btn-top {
    bottom: 66px;
    right: 16px;
  }

  .cookie {
    max-width: 492px;
    padding: 24px 28px;
    right: 16px;
  }

  .cookie__descr {
    font-size: 12px;
    line-height: 16px;
  }

  .cookie__btn {
    font-size: 14px;
    line-height: 14px;
    padding: 12.5px 32px 13.5px;
  }
}

@media screen and (max-width: 990px) {
  .modal--auth {
    max-width: 488px;
    padding: 38px 56px;
  }

  .modal--enter-form .inpCheck {
    margin-top: 12px;
  }

  .modal--callback {
    max-width: 654px;
    padding: 65px 0 81px 0;
  }

  .modal--callback-title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 20px;
  }

  .modal--callback-main {
    padding: 0 41px;
  }

  .modal--callback-main .inpCheck {
    margin-top: 12px;
  }

  .modal--callback-main .more--btn {
    margin-top: 12px;
  }

  .ms--title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  .ms--text {
    padding: 0 66px;
    margin-bottom: 12px;
  }

  .modal--forgot {
    max-width: 488px;
    padding: 38px 56px;
  }

  .modal--forgot-success .more--btn {
    width: 100%;
  }

  .modal--online-success {
    width: 406px;
    height: 315px;
  }

  .modal-basket__wrap {
    width: 406px;
    padding: 48px 56px 32px;
  }

  .modal-basket__btns {
    gap: 8px;
  }

  .modal-basket__bottom {
    padding: 20px 20px 0px 20px;
  }

  .modal-basket__bottom p {
    padding: 0;
  }

  .header--menu {
    margin-left: 33px;
    margin-right: 0;
  }

  .menu--main-wrap {
    padding: 24px 16px 32px 56px;
  }

  .home--menu {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .home--menu-wrap {
    bottom: 32px;
  }

  .home--question {
    top: -196px;
  }

  .menu--center {
    position: relative;
    left: auto;
    top: auto;
    transform: none;
    margin-bottom: 27px;
  }

  .menu--center div {
    margin-top: 8px;
  }

  .menu--center p {
    font-size: 52px;
  }

  .menu--item {
    margin: 0;
    width: calc(25% - 4px);
  }

  .menu--item:nth-child(2) {
    margin-right: 0;
  }

  .menu--item:nth-child(3) {
    margin-left: 0;
  }

  .menu--item svg {
    margin-top: 0;
  }

  .menu--item span {
    margin-top: 12px;
  }

  .skull {
    min-width: 100%;
    transform: none;
    height: auto;
    left: 0;
    right: 0;
  }

  .skull img {
    min-width: calc((1275px * 0.1) + 1275px);
    transform: translate(-50%, -50%);
    left: 50%;
    top: 50%;
    position: relative;
  }

  .about--left > div {
    padding: 0;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 28px;
  }

  .about--top a.about--inst {
    display: none;
  }

  .about--top a.about--youtube {
    display: none;
  }

  .about--top {
    width: 43.75%;
  }

  .about--title {
    font-size: 28px;
    line-height: 32px;
  }

  .about--bottom {
    width: 50%;
    margin: 0;
  }

  .reviews--main-wrap .slider--left {
    left: 16px;
  }

  .reviews--main-wrap .slider--right {
    right: 16px;
  }

  .reviews--main .slick-list {
    padding-right: 43.75%;
  }

  .reviews--block {
    width: 100%;
    max-width: 380px;
  }

  .reviews--tag {
    padding: 3px 16px;
  }

  .callback--text {
    max-width: 87.5%;
  }

  .callback--main {
    width: 87.5%;
  }

  .callback--main .inputs--wrap {
    margin: 16px 0;
  }

  .callback--main-wrap {
    padding: 20px 0 28px 0;
  }

  .video--slider-wrap .slick-list {
    padding: 0;
  }

  .video--slider-wrap .slick-list:before {
    display: none;
  }

  .video--slider-wrap video {
    min-height: 162px;
  }

  .video--block > .video--main {
    height: auto;
    width: 100%;
    margin: 0 2px;
  }

  .video--block .no-video {
    width: 98%;
  }

  .video--block .no-video--title {
    font-size: 20px;
    line-height: 24px;
    padding: 0 0 29px 0;
  }

  .video--block .no-video--counter {
    width: 24px;
    height: 24px;
    font-size: 14px;
    line-height: 16px;
    top: 22px;
  }

  .skull--pages-wrap {
    margin-bottom: 24px;
  }

  .skull--pages {
    min-width: 1660px;
    left: 46.8%;
  }

  .pages--title h1 {
    font-size: 28px;
    line-height: 32px;
  }

  .pages--title span {
    width: 115px;
  }

  .group--top {
    flex-direction: column;
  }

  .group--bottom {
    margin-top: 12px;
    padding: 0 40px;
  }

  .group--image {
    width: 70px;
  }

  .group--block-row.row_1:before {
    width: 100%;
    height: 1px;
    bottom: -1px;
    right: 0;
    left: 0;
    top: auto;
  }

  .group--block {
    width: calc(33.3333% - 10px);
    margin: 0 auto;
    min-height: 103px;
  }

  .group--block:first-child {
    margin-left: auto;
  }

  .group--block:last-child {
    width: calc(33.3333% - 10px);
    margin-right: auto;
  }

  .group--block-wrap {
    padding: 12px 0;
    width: 100%;
  }

  .mbg {
    margin-bottom: -16px;
  }

  .mbg .itog {
    width: calc(100% - 123px);
  }

  .mbg--image {
    width: 71px;
  }

  .mbg--title {
    font-size: 24px;
    line-height: 32px;
  }

  .mbg--block {
    width: 100%;
    margin-bottom: 16px;
  }

  .mbg--text {
    width: calc(100% - 123px);
  }

  .abp {
    padding: 28px 56px;
  }

  .abp--title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 12px;
  }

  .online--wrap {
    padding: 4px 0 0 0;
  }

  .online--container {
    padding-bottom: 28px;
  }

  .online--main-wrap .event--card-wrap {
    border-width: 1px;
  }

  .online--main-wrap.active {
    margin-top: 20px;
  }

  .online--left {
    width: 71px;
  }

  .online--right {
    width: calc(100% - 85px);
  }

  .online--title.big {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 8px;
  }

  .online--title.small {
    margin-bottom: 4px;
  }

  .online--title-closest {
    margin-left: 85px;
    padding: 0;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 12px;
  }

  .online--btns {
    margin-left: 85px;
    padding: 0;
  }

  .online--btns.active {
    margin-top: 12px;
  }

  .modal--event {
    height: calc(100vh - 32px);
    padding: 4px 10px 32px 0;
    width: calc(100% - 32px);
  }

  .modal--event-title {
    font-size: 24px;
    line-height: 32px;
  }

  .modal--event-top_main {
    padding-left: 0;
  }

  .modal--event-top_left {
    width: 71px;
  }

  .modal--event-top_right {
    margin-left: 8px;
  }

  .modal--event-icon {
    margin-left: 0;
  }

  .modal--event-block {
    margin: 0 0 20px 34px;
  }

  .about--page-top {
    margin-bottom: 12px;
  }

  .about--page-top .video--play {
    width: 34px;
    height: 34px;
  }

  .about--page-bottom span {
    line-height: 12px;
  }

  .about--bottom-bg {
    margin-left: -115px;
  }

  .about--bottom-bg img {
    width: calc(100% + 115px);
  }

  .pagination {
    font-size: 16px;
    line-height: 28px;
  }

  .pagination--pages {
    margin: 0;
  }

  .pagination-left--link {
    width: 36px;
    height: 36px;
    justify-content: center;
  }

  .pagination-left--link span {
    display: none;
  }

  .pagination-left--link svg {
    width: 6px;
    height: 12px;
  }

  .pagination-right--link {
    width: 36px;
    height: 36px;
    justify-content: center;
  }

  .pagination-right--link span {
    display: none;
  }

  .pagination-right--link svg {
    width: 6px;
    height: 12px;
  }

  .show-more-cards {
    margin-right: 30px;
    padding: 10px 13px;
  }

  .page-bottom {
    margin-top: 12px;
  }

  .page-bottom .pagination--pages {
    margin: 0;
  }

  .detail--event-top {
    padding: 20px 41px 28px;
  }

  .detail--event-top .event--date {
    margin-top: 0;
  }

  .detail--event-top .event--date:not(.d-n) {
    display: none;
  }

  .detail--event-top .event--date.d-n {
    display: flex;
    margin-top: 12px;
  }

  .detail--event-tags {
    margin-left: 0;
    height: auto;
    justify-content: center;
  }

  .detail--event-tags .section--tag,
  .detail--event-tags .event--leading-online,
  .detail--event-tags .event--leading {
    padding: 3px 8px;
  }

  .detail--event-tags .event--leading {
    margin-left: 0;
    margin-top: 4px;
  }

  .detail--event-bottom_border {
    margin-bottom: 12px;
  }

  .detail--event-bottom {
    padding: 20px 32px;
  }

  .detail--event-bottom .event--date-days {
    padding: 1px 10px 1px 6px;
  }

  .detail--event-bottom_top {
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  .detail--event-bottom_top .event--leading {
    padding: 3px 8px;
  }

  .detail--event-bottom_item .event--price-timer {
    min-height: auto;
    padding: 1px 10px 2px;
  }

  .detail--event-bottom_item .event--price-price {
    font-size: 20px;
    line-height: 28px;
  }

  .detail--event-bottom_rate {
    margin-bottom: 12px;
  }

  .detail--event-title {
    font-size: 24px;
    line-height: 32px;
  }

  .detail--event-top_main .event--price-old {
    margin-left: 0;
    margin-top: 4px;
  }

  .detail--event-top_main .event--price-wrap {
    flex-direction: column;
    align-items: start;
  }

  .detail--event-top_left > svg {
    width: 51px;
    height: 69px;
    margin-right: 35px;
  }

  .detail--event-top_left {
    align-items: center;
  }

  .detail--event-main {
    flex-direction: column;
  }

  .detail--event-wrapper {
    margin: 0;
  }

  .detail--event-descr {
    margin-left: 0;
    margin-top: 20px;
    max-width: 100%;
  }

  .detail--event-top_title {
    margin-top: 16px;
    margin-bottom: 12px;
  }

  .detail--event-top_leader {
    flex-direction: column;
    align-items: flex-start;
  }

  .detail--event-top_leader-name {
    margin-left: 0;
    margin-top: 8px;
  }

  .detail--event-top_bottom {
    margin: 0;
  }

  .detail--event-icon {
    margin-left: 0;
  }

  .detail--event-date {
    padding-left: 6px;
    padding-right: 8px;
    height: 20px;
  }

  .detail--event-date div {
    width: 12px;
    margin: 0 6px;
  }

  .detail--event-block {
    margin: 0 0 20px 0;
  }

  .detail--event-block {
    margin: 0;
  }

  .detail--event-block ul.nt {
    margin-bottom: 20px;
  }

  .detail--event-block ul li {
    width: calc(50% - 8px);
  }

  .detail--event-block.block_1 > div {
    padding: 0 41px;
  }

  .detail--event-block.block_2 .mst {
    padding: 0 41px;
  }

  .detail--event-block.block_3 {
    margin: 20px 0;
  }

  .detail--event-block.block_4 {
    padding: 0;
    margin-bottom: 28px;
  }

  .detail--event-block.block_4 > p {
    padding: 0 41px;
  }

  .detail--event-buttons :first-child {
    margin-right: 4px;
  }

  .detail--event-buttons {
    margin-top: 12px;
  }

  .events-slider {
    padding: 38px 0;
  }

  .events-slider--wrapper .container {
    padding: 0 57px;
    max-width: 100%;
  }

  .events-slider--title {
    font-size: 28px;
    line-height: 32px;
    margin-bottom: 8px;
  }

  .events-slider .slick-active {
    min-width: auto;
  }

  .events-slider .slider--left {
    left: 16px;
  }

  .events-slider .slider--right {
    right: 16px;
  }

  .detail-vip {
    padding: 20px 32px;
    margin-bottom: 12px;
  }

  .detail-vip__list {
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  .detail-vip__list > li {
    width: calc(50% - 6px);
  }

  .detail-vip__list > li:not(:nth-child(n + 3)) {
    margin-bottom: 0;
  }

  .detail-vip__list > li:not(:nth-child(n + 5)) {
    margin-bottom: 16px;
  }

  .detail-vip__price {
    font-size: 20px;
    line-height: 28px;
  }

  .register {
    padding-bottom: 38px;
  }

  .register--inputs-block .inputs--block:not(:last-child) {
    margin-right: 8px;
  }

  .register--inputs-block .inputs--block.inputs--block_col {
    flex: 0 0 calc(50% - 4px);
  }

  .register .custom-select-container.is-open .custom-select-panel {
    max-height: 130px;
  }

  .datepicker__wrapper {
    left: auto !important;
    right: 0 !important;
    width: 150% !important;
  }

  .modal-register {
    width: 288px;
    height: 348px;
  }

  .reviews__tags-item {
    padding: 3px 8px;
  }

  .reviews__name {
    margin-top: 12px;
  }

  .reviews__wrapper {
    padding-top: 20px;
    padding-bottom: 28px;
  }

  .reviews__link a {
    padding: 12px 27px;
  }

  .reviews__form {
    padding-bottom: 38px;
  }

  .reviews .pagination {
    margin-top: 0;
  }

  .reviews__answer {
    padding-top: 16px;
    margin-top: 16px;
  }

  .reviews__answer-name::before {
    top: -3px;
  }

  .modal--feedback {
    width: 570px;
    height: 342px;
  }

  .partners .container {
    max-width: 1024px;
    padding: 0px 57px;
  }

  .partners__descr {
    margin-bottom: 28px;
  }

  .partners__container {
    padding-bottom: 38px;
  }

  .partners__wrapper {
    margin-bottom: 12px;
  }

  .partners__card:not(:nth-child(5n)) {
    margin-right: 8px;
  }

  .partners__card {
    padding: 28px 16px 20px;
    width: calc(33% - 8px);
  }

  .partners__card-title {
    font-size: 14px;
    line-height: 14px;
    margin-top: 16px;
  }

  .detail-partner__info {
    flex-direction: column;
    align-items: center;
    padding: 28px 42px;
  }

  .detail-partner__title {
    text-align: center;
    margin-top: 28px;
    font-size: 24px;
    line-height: 32px;
  }

  .detail-partner__image {
    margin-right: 0;
    min-width: auto;
    width: 158px;
  }

  .detail-partner__post {
    text-align: center;
    margin-bottom: 12px;
    font-size: 12px;
    line-height: 16px;
  }

  .detail-partner__descr {
    margin-bottom: 20px;
  }

  .detail-partner__descr > p {
    text-align: center;
  }

  .detail-partner__link {
    text-align: center;
  }

  .detail-partner__video video {
    height: 212px;
  }

  .detail-partner__video {
    margin-top: 38px;
  }

  .personally__link-wrap {
    margin-top: 20px;
  }

  .personally__image svg {
    margin: 0 auto 13px;
  }

  .personally-archive {
    margin: 20px auto 20px;
  }

  .question-form__form {
    padding: 20px 42px 28px;
  }

  .not-found {
    padding-top: 166px;
    padding-bottom: 38px;
  }

  .not-found::after {
    height: 432px;
    top: 34px;
    left: -40px;
    width: calc(100% + 40px);
  }

  .not-found__img {
    max-width: 602px;
  }

  .not-found__text {
    font-size: 12px;
  }

  .profile-personal__section {
    margin-bottom: 20px;
  }

  .profile-about {
    margin-bottom: 24px;
    padding-bottom: 24px;
  }

  .profile-about__photo {
    max-width: 158px;
    width: 100%;
    height: 158px;
    margin-right: 48px;
  }

  .profile-about__wrap {
    margin-bottom: 24px;
  }

  .profile-about__nik {
    flex-direction: column;
    margin-bottom: 12px;
  }

  .profile-about__nik .inputs--block {
    max-width: 100%;
    margin-bottom: 12px;
  }

  .profile-about__textarea.inputs--textarea textarea {
    height: 80px;
    padding: 12px;
  }

  .profile-about__textarea.inputs--textarea label {
    left: 12px;
    top: 12px;
  }

  .profile-about__warn-text {
    font-size: 12px;
    line-height: 16px;
  }

  .chat-container.lk-container {
    padding: 0 16px;
  }

  .basket__wrap {
    flex-direction: column;
    gap: 24px;
  }

  .basket__num {
    margin-top: 16px;
  }

  .basket__btn.lk-btn {
    margin-bottom: 130px;
  }

  .product-basket {
    width: 100%;
  }

  .product-basket__item {
    padding-bottom: 12px;
    padding: 0px 0px 12px 0px;
  }

  .product-basket__item:not(:first-child) {
    margin-top: 12px;
  }

  .product-basket__name {
    padding-right: 20px;
  }

  .product-basket__tags {
    gap: 6px;
    flex-wrap: wrap;
    align-items: start;
  }

  .product-basket__control {
    bottom: 12px;
    padding-right: 0;
    position: static;
  }

  #form-basket {
    margin-top: 24px;
  }

  #form-basket .inputs--wrap:not(:last-child) {
    margin-bottom: 8px;
  }

  .order-basket {
    width: 100%;
    text-align: center;
  }

  .order-basket__btn {
    max-width: 109px;
    margin: 20px auto 0px auto;
  }

  .friends .lk-banner {
    margin: 24px 0px;
  }

  .friends__item {
    flex-wrap: wrap;
    padding-bottom: 20px;
  }

  .friends__item:not(:first-child) {
    padding-top: 20px;
  }

  .friends__body {
    order: 1;
    margin-right: auto;
    flex: 0 0 calc(100% - 284px);
  }

  .friends__info {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-left: 8px;
    gap: 8px;
  }

  .friends__info .status-friends {
    margin: 0px;
  }

  .friends__text {
    order: 3;
    margin-top: 12px;
  }

  .friends__message {
    font-size: 12px;
  }

  .friends__time {
    margin-left: 49px;
  }

  .friends__controll {
    order: 2;
    margin-left: 12px;
  }

  .lk .header--menu {
    margin-left: 20px;
  }

  .lk-container {
    max-width: 1024px;
    padding: 0 57px;
  }

  .lk-not-main .lk-banner {
    margin-bottom: 24px;
  }

  .lk-main__list-item {
    width: calc(50% - 4px);
  }

  .lk-main__list-item:not(:nth-child(3n)) {
    margin-right: 0;
  }

  .lk-main__list-item:not(:nth-child(2n)) {
    margin-right: 8px;
  }

  .lk-events__list-item:not(:last-child) {
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  .lk-events__list-date_start {
    font-size: 12px;
    line-height: 16px;
  }

  .lk-events__list-date_day {
    font-size: 12px;
    line-height: 16px;
  }

  .lk-events__list-leader {
    padding: 3px 8px;
  }

  .lk-events__list-level {
    margin-right: 8px;
    padding: 2px 8px;
  }

  .lk-events__list-status {
    padding: 3px 8px;
    margin-right: 8px;
  }

  .container {
    max-width: 1024px;
    padding: 0 57px;
  }

  .container.small {
    padding: 0 57px;
  }

  .container.smfull {
    padding: 0 16px;
  }

  .container.smallxR {
    padding: 0 16px;
  }

  .container.smallx2 {
    padding: 0 56px;
  }

  .footer {
    padding: 0;
  }

  .footer--wrap {
    padding: 12px 0 38px 0;
  }

  .footer--bottom {
    font-size: 12px;
    line-height: 16px;
    margin-top: 20px;
  }

  .footer--btn {
    margin-left: 0;
    width: auto;
    flex-direction: row;
  }

  .footer--btn a + a {
    margin-top: 0;
    margin-left: 4px;
  }

  .footer--phone {
    font-size: 20px;
    line-height: 28px;
  }

  .footer--mail {
    line-height: 12px;
    margin-top: 8px;
  }

  .footer--copy {
    max-width: 110px;
  }

  .footer--menu {
    display: none;
  }

  .footer--menu ul {
    column-count: 2;
  }

  .m-wrap {
    margin: 0;
    width: 100%;
    flex-direction: column;
  }

  .m-block {
    width: 100%;
    margin: 0;
  }

  .section--title-big {
    font-size: 28px;
    line-height: 32px;
  }

  .more--btn {
    margin-left: 0;
    margin-top: 20px;
  }

  .custom-select-option .select--price-price {
    font-size: 14px;
    line-height: 16px;
  }

  .custom-select-option .select--price-old {
    font-size: 12px;
    line-height: 16px;
  }

  .slider--btn {
    width: 32px;
    height: 32px;
  }

  .slider--btn svg {
    width: 7px;
    height: 11px;
  }

  .event--card-wrap {
    width: calc(50% - 4px);
  }

  .event--card-wrap_archive .event--archive {
    padding: 2px 24px;
  }

  .event--date {
    font-size: 12px;
  }

  .event--date-days {
    padding: 1px 10px 1px 6px;
  }

  .event--price-timer {
    padding: 1px 0 1px 16px;
    min-height: 36px;
  }

  .event--price-price {
    line-height: 20px;
  }

  .mg80 {
    margin-bottom: 38px;
  }

  .mg60 {
    margin-bottom: 28px;
  }

  .mg20 {
    margin-bottom: 4px;
  }

  .mg32 {
    margin-bottom: 12px;
  }

  .mt32 {
    margin-top: 12px;
  }

  .section--tag {
    padding: 1px 7px;
  }

  .section--tags > div {
    flex-direction: column;
  }

  .section--tags-text {
    margin: 0;
  }

  .section--tags-main {
    margin-top: 20px;
  }

  .breadcrumbs {
    margin-bottom: 16px;
  }

  .breadcrumbs--dot {
    margin: 0 24px;
  }

  .bottom-btn-top {
    width: 33px;
    height: 33px;
    padding: 0px 0px 1px 12px;
    bottom: 60px;
    right: 10px;
  }

  .bottom-btn-top > svg {
    height: 10px;
    width: 8px;
  }

  .cookie {
    padding: 20px 28px;
  }
}

@media screen and (max-width: 768px) {
  .pagination--pages-list > li {
    width: 20px;
    margin: 0px 2px;
  }

  .not-found {
    padding-top: 100px;
    padding-bottom: 32px;
  }

  .not-found::after {
    height: 225px;
    top: 30px;
  }

  .not-found__img {
    max-width: 288px;
  }

  .not-found__text {
    margin-top: 32px;
  }

  .basket__num {
    margin-top: 12px;
  }

  .basket__btn.lk-btn {
    margin-bottom: 8px;
  }
}

@media (max-width: 768px) {
  .esl--wrap .event--price-wrapper {
    position: absolute;
    width: 100%;
    bottom: 12px;
  }
}

@media (max-width: 767px) {
  .video--slider-wrap .slider--left {
    left: 16px;
  }

  .video--slider-wrap .slider--right {
    right: 16px;
  }

  .esl--wrap .slick-list {
    max-width: 325px;
  }

  .esl--wrap .slider--left {
    left: -40px;
  }

  .esl--wrap .slider--right {
    right: -40px;
  }

  .esl--wrap .slick-slide {
    max-width: 325px;
  }
}

@media screen and (max-width: 640px) {
  .datepicker.filled ~ label {
    top: 0;
  }

  .modal--auth {
    max-width: 288px;
    padding: 32px;
  }

  .modal--enter-form {
    flex-direction: column;
  }

  .modal--enter-form .inputs--wrap {
    flex-direction: column;
  }

  .modal--enter-form .inputs--block {
    margin-bottom: 8px;
  }

  .modal--enter-form .inputs--block:last-child {
    margin-bottom: 0;
  }

  .modal--callback-title {
    font-size: 24px;
    line-height: 28px;
  }

  .modal--callback-subtitle {
    letter-spacing: 0.2em;
  }

  .modal--callback-main {
    padding: 0 24px;
  }

  .modal--callback-main .inputs--wrap {
    flex-direction: column;
  }

  .modal--callback-main .inputs--block {
    width: 100%;
    margin-bottom: 12px;
  }

  .modal--callback-main .inputs--block:last-child {
    margin-bottom: 0;
  }

  .modal--callback-main .inputs--textarea {
    margin-top: 8px;
  }

  .modal--callback-main .inputs--textarea textarea {
    height: 290px;
  }

  .modal--callback-main .inputs--textarea label {
    top: 12px;
  }

  .ms--title {
    font-size: 24px;
    line-height: 28px;
  }

  .ms--text {
    padding: 0 24px;
  }

  .modal--forgot {
    max-width: 288px;
    padding: 32px 0;
  }

  .modal--auth-footer {
    flex-direction: column;
    align-items: center;
  }

  .modal--auth-register {
    width: 100%;
    text-align: center;
  }

  .modal--auth-register::before {
    display: none;
  }

  .modal--auth-pass {
    margin-right: 0;
    width: 100%;
    text-align: center;
    border-bottom: 1px solid #051838;
    padding-bottom: 8px;
    margin-bottom: 8px;
  }

  .modal--online-success {
    width: 288px;
    height: 348px;
  }

  .modal-basket__wrap {
    width: 100%;
    height: 100vh;
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
  }

  .header--logo img {
    height: 28px;
  }

  .header--lk span {
    display: none;
  }

  .home--main {
    height: 630px;
  }

  .about--title {
    font-size: 24px;
    line-height: 28px;
  }

  .about--bottom > span {
    font-size: 24px;
    line-height: 28px;
  }

  .video--slider-main {
    max-width: 240px;
    margin: auto;
  }

  .video--block {
    width: 100%;
    margin: 0 2px;
  }

  .video--block .no-video--title {
    margin-top: 10px;
    padding: 0px 0 29px;
  }

  .skull--pages {
    left: 45%;
  }

  .pages--title span {
    width: auto;
  }

  .pages--question {
    top: -24px;
  }

  .group--image {
    width: 53px;
  }

  .group--block:first-child {
    margin-left: 10px;
  }

  .group--block:last-child {
    margin-right: 10px;
  }

  .group--block-row {
    margin: -6px 0;
    padding: 0;
  }

  .group--block-row.row_2 > div:first-child {
    width: 100%;
  }

  .group--block-row.row_2 > div:nth-child(2) {
    width: auto;
  }

  .group--block-row.row_2 > div:last-child {
    width: auto;
  }

  .group--block-row.row_1 > div:last-child {
    width: 100%;
  }

  .group--block-row.row_1:before {
    left: 10px;
    right: 10px;
    width: auto;
  }

  .group--block {
    width: auto;
    margin: 6px 10px;
    min-height: 86px;
  }

  .group--block:first-child {
    margin: 6px 10px;
  }

  .group--block:last-child {
    width: auto;
    margin: 6px 10px;
  }

  .group--block span {
    padding: 4px 10px;
  }

  .group--block-wrap {
    padding: 12px 24px;
    margin: 0 -10px;
    width: auto;
  }

  .group--title-main {
    margin-bottom: 0;
  }

  .online--main-wrap .event--card-wrap {
    width: 100%;
    margin-bottom: 4px;
  }

  .modal--event {
    padding: 20px 10px 32px 24px;
  }

  .modal--event-tags {
    justify-content: center;
  }

  .modal--event-top_left {
    display: none;
  }

  .modal--event-top_right {
    margin-left: 0;
    width: 100%;
    padding-right: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .modal--event-top_bottom {
    justify-content: center;
  }

  .modal--event-top_bottom p {
    width: 100%;
    margin: 4px 0 0 0;
    text-align: center;
  }

  .modal--event-block {
    margin: 0 0 16px 0;
  }

  .modal--event-block_title {
    text-align: center;
    margin: 0 0 12px 0;
  }

  .modal--event-block .mst {
    padding: 0;
  }

  .modal--event-block .mst p {
    margin-bottom: 8px;
  }

  .modal--event-block ul {
    padding: 0 0 4px 0;
  }

  .modal--event-block ul li {
    width: 100%;
    margin-bottom: 8px;
  }

  .modal--event-block.block_1 {
    padding: 12px 12px 4px 12px;
  }

  .modal--event-block.block_3 p {
    font-size: 18px;
    line-height: 20px;
    padding: 12px 0 20px 0;
  }

  .about--page-top {
    width: 100%;
  }

  .about--page-top video {
    width: 100%;
  }

  .show-more-cards {
    margin: 0;
    width: 100%;
    padding: 10px 13px;
    justify-content: center;
  }

  .page-bottom {
    flex-direction: column;
  }

  .page-bottom .pagination {
    margin: 12px 0px 0px 0px;
  }

  .detail--event-top .event--date:not(.d-n) {
    display: flex;
    margin-top: 17px;
  }

  .detail--event-top .event--date.d-n {
    display: none;
  }

  .detail--event-bottom_border {
    border-radius: 20px !important;
  }

  .detail--event-bottom {
    padding: 16px;
    border-radius: 10px 10px 0 0;
  }

  .detail--event-bottom .event--date,
  .detail--event-bottom .event--date-time,
  .detail--event-bottom .event--date-days {
    width: 100%;
  }

  .detail--event-bottom_top {
    padding-bottom: 16px;
    margin-bottom: 16px;
  }

  .detail--event-bottom_top .event--date,
  .detail--event-bottom_top .event--leading {
    margin-left: 0;
    margin-top: 8px;
  }

  .detail--event-bottom_top .detail--event-bottom_item:last-child {
    margin-left: 0;
  }

  .detail--event-bottom_item {
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;
  }

  .detail--event-bottom_item:not(:last-child) {
    margin-bottom: 16px;
  }

  .detail--event-bottom_item .event--leading {
    display: block;
    width: fit-content;
    margin: 8px auto 0;
  }

  .detail--event-bottom_item:last-child {
    margin-left: 0;
  }

  .detail--event-bottom_item .event--price-timer {
    margin: 8px auto 4px;
  }

  .detail--event-bottom_item .more--btn {
    width: 100%;
    max-width: 252px;
    margin: 0 auto;
  }

  .detail--event-bottom_item .section--tag.section--tag_level {
    margin: 4px auto;
  }

  .detail--event-bottom_line {
    flex-direction: column;
    justify-content: center;
  }

  .detail--event-bottom_rate {
    text-align: center;
  }

  .detail--event-bottom_installment {
    margin-top: 12px;
  }

  .detail--event-bottom_installment > div {
    display: inline;
  }

  .detail--event-top_main .event--price-wrapper {
    align-items: center;
  }

  .detail--event-top_main .event--price-timer {
    position: static;
  }

  .detail--event-top_main .event--price-btn {
    width: 100%;
  }

  .detail--event-top_main .event--price-old {
    margin-left: 16px;
    font-style: 12px;
  }

  .detail--event-top_main .event--price-wrap {
    flex-direction: row;
  }

  .detail--event-text {
    padding-top: 24px;
  }

  .detail--event-descr {
    margin-top: 20px;
    text-align: center;
  }

  .detail--event-top_leader {
    align-items: center;
  }

  .detail--event-top_bottom {
    justify-content: center;
    width: 100%;
    align-items: center;
    padding-top: 20px;
  }

  .detail--event-block_title {
    margin: 0 0 12px 0;
  }

  .detail--event-block .mst p {
    margin-bottom: 8px;
  }

  .detail--event-block ul {
    flex-direction: column;
  }

  .detail--event-block ul li {
    width: auto;
    margin-bottom: 8px;
  }

  .detail--event-block.block_1 > div {
    padding: 0;
  }

  .detail--event-block.block_2 .mst {
    padding-left: 26px;
  }

  .detail--event-block.block_3 {
    margin: 16px 0;
  }

  .detail--event-block.block_3 p {
    font-size: 18px;
    line-height: 20px;
    padding: 24px 16px;
  }

  .detail--event-block.block_4 > p {
    padding: 0;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }

  .detail--event-buttons {
    justify-content: center;
  }

  .events-slider--wrapper .container {
    max-width: 296px;
    margin: 0 auto;
    padding: 0;
    position: static;
  }

  .events-slider--title {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .events-slider .section--main::before {
    display: none;
  }

  .events-slider .slick-slide {
    max-width: 296px;
  }

  .events-slider .slider--left {
    left: 4px;
  }

  .events-slider .slider--right {
    right: 4px;
  }

  .detail-vip {
    padding: 16px;
    border-radius: 0px 0px 10px 10px;
  }

  .detail-vip__label {
    width: 100%;
    text-align: center;
  }

  .detail-vip__list {
    padding-bottom: 0;
    margin-bottom: 16px;
  }

  .detail-vip__list > li {
    width: 100%;
    margin-bottom: 16px;
  }

  .detail-vip__bottom {
    flex-direction: column;
  }

  .detail-vip__price {
    margin-bottom: 16px;
  }

  .detail-vip__btn.btn {
    margin-bottom: 12px;
    margin-left: 0;
    width: 100%;
    max-width: 252px;
    justify-content: center;
  }

  .detail-vip__installment {
    margin-left: 0;
    display: flex;
  }

  .register--title {
    margin-top: 28px;
    margin-bottom: 12px;
  }

  .register--inputs-block .inputs--block {
    margin-bottom: 8px;
  }

  .register--inputs-block .inputs--block:not(:last-child) {
    margin-right: 0;
  }

  .register--inputs-block .inputs--block.inputs--block_col {
    flex: 0 0 100%;
  }

  .register--wrap > div:nth-child(1),
  .register--wrap > div:nth-child(2),
  .register--wrap > div:nth-child(3) {
    width: 100%;
  }

  .register label > svg {
    margin-left: 18%;
    margin-right: -18%;
    width: 20px;
    height: 16px;
  }

  .register .custom-select-option {
    padding: 0 33px 0 26px;
  }

  .register .inputs--wrap {
    flex-direction: column;
  }

  .datepicker__wrapper {
    left: 0 !important;
    right: 0 !important;
    width: 100% !important;
  }

  .modal-register {
    width: 406px;
    height: 315px;
  }

  .reviews .pagination {
    margin-top: 12px;
  }

  .reviews__answer-name::before {
    top: -2px;
    width: 22px;
    height: 13px;
  }

  .modal--feedback {
    width: 288px;
    height: 360px;
  }

  .partners__descr {
    margin: 0 4px 24px;
  }

  .detail-partner__info {
    padding: 24px;
  }

  .personally__item {
    padding-right: 0;
    flex: 1 0 100%;
    padding-bottom: 24px;
    margin-bottom: 16px;
    padding-left: 16px;
    padding-right: 16px;
  }

  .personally__item:nth-child(3n) {
    padding-right: 0;
  }

  .personally__item:nth-last-child(-n + 2) {
    border-bottom: 1px solid #50c878;
    padding-bottom: 24px;
    margin-bottom: 16px;
  }

  .personally__item:nth-last-child(-n + 1) {
    border-bottom: none;
    padding-bottom: 0;
    margin-bottom: 0;
  }

  .personally__image svg {
    margin: 0 auto 5px;
  }

  .personally-archive {
    margin: 16px auto 16px;
  }

  .question-form__form {
    padding: 16px 12px 24px;
  }

  .profile-personal__section .inputs--wrap {
    flex-direction: column;
  }

  .profile-personal__selects .select-block:not(:last-child) {
    margin-right: 0;
    margin-bottom: 12px;
  }

  .profile-personal__inputs .inputs--block:not(:last-child) {
    margin-right: 0;
    margin-bottom: 12px;
  }

  .profile-personal__radio.inputs--wrap {
    flex-direction: row;
  }

  .profile-about {
    margin-bottom: 20px;
    padding-bottom: 20px;
  }

  .profile-about__photo {
    height: auto;
    margin-right: 0;
    max-width: 100%;
    display: flex;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #52739a;
    margin-bottom: 16px;
  }

  .profile-about__photo-wrap {
    max-width: 95px;
    margin-right: 20px;
    margin-bottom: 0;
  }

  .profile-about__wrap {
    flex-direction: column;
    margin-bottom: 20px;
  }

  .chat-container__subtitle {
    display: block;
  }

  .chat {
    border: none;
    border-radius: 0;
    max-height: initial;
    min-height: initial;
  }

  .chat__bottom {
    display: none;
  }

  .chat-sidebar {
    max-height: initial;
    min-height: auto;
    max-width: 100%;
    border-radius: 0;
    border: none;
    background: transparent;
    padding: 0;
  }

  .chat-sidebar__wrap {
    padding-right: 0;
  }

  .chat-sidebar__title {
    display: none;
  }

  .chat-sidebar__subtitle {
    display: none;
  }

  .chat-list {
    border: 1px solid #52739a;
    border-radius: 10px;
    margin-bottom: 4px;
  }

  .chat-list:not(.opened) .chat-list__open {
    display: none;
  }

  .chat-list:not(.opened) .chat-list__edit {
    display: none;
  }

  .chat-list.opened {
    padding-bottom: 4px;
  }

  .chat-list__title {
    letter-spacing: 0.22em;
    padding: 12px 20px;
  }

  .chat-list__item {
    padding: 8px 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .chat-list__name {
    letter-spacing: 0.22em;
  }

  .chat-list__open {
    display: flex;
  }

  .chat-list__edit {
    display: flex;
  }

  .chat-window {
    display: none;
    padding: 0;
    padding-right: 16px;
  }

  .chat-window.opened {
    display: block;
    position: fixed;
    z-index: 99;
    width: 100%;
    top: 44px;
    bottom: 50px;
    left: 0;
    right: 0;
    background: #051838;
  }

  .chat-window__form {
    padding-top: 8px;
    bottom: 8px;
    left: 16px;
    width: calc(100% - 48px);
  }

  .chat-window__wrapper {
    max-height: calc(100% - 92px);
    padding-right: 16px;
    padding-left: 16px;
  }

  .chat-window__mess:not(:last-child) {
    margin-bottom: 12px;
  }

  .chat-window__mess-status svg {
    margin-right: 0;
  }

  .chat-window__mess-status {
    padding: 2px 8px;
    height: 20px;
  }

  .chat-window__mess-status span {
    display: none;
  }

  .chat-window__mess-status_new svg {
    display: block;
    color: #051838;
  }

  .chat-window__mess-text {
    max-width: 100%;
  }

  .chat-window__head {
    display: flex;
  }

  .message-form .lk-btn {
    background: #50c878;
    padding: 0 12px;
  }

  .message-form .lk-btn span {
    display: none;
  }

  .message-form .lk-btn svg {
    display: block;
  }

  .message-form__file-label {
    right: 9px;
  }

  .message-form__input {
    margin-right: 4px;
    max-width: calc(100% - 36px);
  }

  .message-form__input .textarea {
    padding: 8px 34px 8px 9px;
  }

  .basket__wrap {
    gap: 20px;
  }

  #form-basket {
    margin-top: 20px;
  }

  #form-basket .inputs--wrap {
    flex-direction: column;
    gap: 8px;
  }

  .order-basket__btn {
    max-width: 240px;
  }

  .friends .lk-banner {
    margin: 20px 0px;
  }

  .friends__item {
    padding-bottom: 16px;
  }

  .friends__item:not(:first-child) {
    padding-top: 16px;
  }

  .friends__body {
    flex: 0 0 100%;
  }

  .friends__ava {
    flex: 0 0 34px;
  }

  .friends__info {
    justify-content: space-between;
  }

  .friends__time {
    margin-left: 20px;
  }

  .friends__controll {
    order: 4;
    gap: 4px;
    flex: 0 0 100%;
    margin: 12px 0px 0px 0px;
  }

  .friends__controll .btn {
    width: calc(50% - 2px);
  }

  .lk .header--menu {
    margin-left: 12px;
  }

  .lk-container {
    max-width: 100%;
  }

  .lk-not-main .lk-banner {
    margin-bottom: 20px;
  }

  .lk-main__greet {
    text-align: center;
  }

  .lk-main__descr {
    text-align: center;
  }

  .lk-main__list-item {
    width: 100%;
    min-height: 94px;
  }

  .lk-main__list-item:not(:nth-child(2n)) {
    margin-right: 0;
  }

  .lk-banner {
    padding: 8px 12px;
  }

  .lk-banner__subtitle {
    text-align: center;
  }

  .lk-banner__title {
    text-align: center;
  }

  .lk-banner__ico {
    display: none;
  }

  .lk-menu {
    padding: 4px;
  }

  .lk-menu__item:not(:last-child) {
    margin-right: 2px;
  }

  .lk-menu__link {
    padding: 4px 0;
  }

  .lk-avatar {
    margin-left: 24px;
  }

  .no-events {
    padding: 24px 23px;
  }

  .no-events__wrap {
    justify-content: center;
  }

  .no-events__title {
    text-align: center;
  }

  .lk-events__list-item {
    flex-direction: column;
    align-items: center;
  }

  .lk-events__list-info {
    margin-bottom: 16px;
  }

  .lk-events__list-date {
    margin-top: 12px;
  }

  .lk-events__list-tags {
    flex-wrap: wrap;
    justify-content: center;
  }

  .lk-events__list-btn {
    min-width: 140px;
    padding: 0;
  }

  .lk-events__list-btn_notbg {
    margin-left: 8px;
    min-width: 140px;
    padding: 0;
  }

  .lk-events__list-btn_sent {
    margin-left: 8px;
    min-width: 140px;
    padding: 0;
  }

  .lk-events__list-leader {
    margin-top: 12px;
  }

  .lk-events__list-level {
    margin-top: 12px;
  }

  .lk-events__list-name {
    text-align: center;
    margin-bottom: 0;
  }

  .lk-events__list-status {
    margin-top: 12px;
  }

  .footer {
    border-top: 1px solid #50c878;
  }

  .footer--wrap {
    padding: 24px 0 32px 0;
    border-top: 0;
  }

  .footer--bottom {
    order: 3;
    margin-top: 32px;
    flex-direction: column;
    text-align: center;
  }

  .footer--btn {
    order: 1;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 12px;
  }

  .footer--btn a {
    width: calc(50% - 6px);
    padding: 10px 0;
  }

  .footer--contacts {
    order: 2;
    margin: 0;
    width: 100%;
    align-items: center;
  }

  .footer--copy {
    max-width: 100%;
    width: 100%;
    margin-bottom: 8px;
  }

  .footer--policy {
    width: 100%;
    margin-left: 0;
  }

  .footer--db {
    width: 100%;
    margin-top: 16px;
  }

  .footer--db a {
    align-items: center;
    flex-direction: column-reverse;
  }

  .footer--db a p br {
    display: none;
  }

  .footer--db a svg {
    margin: 0 0 8px 0;
    width: 35px;
    height: 24px;
  }

  .footer--app {
    margin: 16px auto;
  }

  .section--title-big {
    font-size: 24px;
    line-height: 28px;
  }

  .custom-select-opener {
    padding: 11px 37px 11px 12px;
    align-items: center;
  }

  .custom-select-opener:before {
    width: 13px;
    height: 8px;
    right: 14px;
  }

  .custom-select-opener .select--price-price {
    font-size: 14px;
    line-height: 16px;
    text-align: right;
  }

  .custom-select-opener .select--price-old {
    font-size: 12px;
    line-height: 16px;
    text-align: right;
  }

  .custom-select-opener .select--wrapper {
    display: flex;
    flex-direction: column;
    flex: 1 0 35%;
  }

  .custom-select-opener > span {
    display: flex;
  }

  .custom-select-container.is-open .custom-select-panel:before {
    width: calc(100% - 22px);
  }

  .custom-select-option {
    padding: 0 33px 0 13px;
    align-items: center;
  }

  .custom-select-option .select--text,
  .custom-select-option .select--wrapper {
    display: flex;
    flex-direction: column;
  }

  .custom-select-option .select--price-price {
    text-align: right;
  }

  .custom-select-option .select--price-old {
    text-align: right;
  }

  .event--card-wrap {
    padding: 12px;
  }

  .event--leading {
    padding: 2px 8px;
    margin-top: 12px;
  }

  .event--leading-online {
    padding: 2px 8px;
  }

  .event--price-wrapper {
    margin-left: -12px;
  }

  .btn--close {
    width: 16px;
    height: 16px;
    top: 2px;
    right: 2px;
  }

  .section--tags > div {
    align-items: flex-start;
  }

  .section--tags-main {
    margin-top: 16px;
    flex-wrap: wrap;
    margin-bottom: -8px;
    justify-content: space-between;
  }

  .section--tags-main .section--tag {
    width: calc(50% - 4px);
    margin: 0 0 8px 0;
    justify-content: center;
  }

  .breadcrumbs {
    max-width: 100%;
    max-width: 94%;
    padding-left: 5px;
    overflow: hidden;
  }

  .breadcrumbs--dot {
    margin: 2px 8px 0;
  }

  .section-cards .event--card-wrap {
    width: 100%;
  }

  .section-cards .event--card-wrap:not(:last-child) {
    margin-bottom: 4px;
  }

  .cookie {
    right: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    padding: 16px;
  }

  .cookie__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    width: 60px;
  }
}

@media (max-width: 580px) {
  .partners__card {
    width: calc(50% - 8px);
  }
}

@media all and (max-width: 576px) {
  .fancybox-thumbs {
    width: 110px;
  }

  .fancybox-show-thumbs .fancybox-inner {
    right: 110px;
  }

  .fancybox-thumbs__list a {
    max-width: calc(100% - 10px);
  }
}

@media screen and (max-width: 480px) {
  .modal--auth {
    max-width: 100%;
    height: 100vh;
    padding: 160px 16px;
  }

  .modal--auth-pass {
    font-size: 14px;
    margin-right: 8px;
  }

  .modal--auth-register::before {
    font-size: 14px;
    margin-right: 8px;
  }

  .modal--auth-register {
    font-size: 14px;
  }

  .modal--callback {
    max-width: 100%;
    padding: 128px 16px;
  }

  .modal--callback .modal--callback-main {
    padding: 0;
  }

  .modal--forgot {
    max-width: 100%;
    height: 100%;
    max-height: 100%;
    padding: 160px 16px;
  }

  .header--social {
    display: none;
  }

  .header--youtube {
    display: none;
  }

  .header--main-wrap nav .simplebar-content {
    flex-direction: column;
    align-items: center;
  }

  .menu--block {
    width: auto;
    text-align: center;
  }

  .menu--link.big {
    width: auto;
  }

  .menu--main-wrap {
    bottom: 0;
    height: calc(100vh - 52px);
    min-width: auto;
    padding: 24px 8px 32px 16px;
  }

  .menu--wrapper {
    flex-direction: column;
  }

  .home--menu ul {
    justify-content: center;
    margin: 0 -9px -12px -9px;
  }

  .home--menu-wrap {
    bottom: -30px;
  }

  .home--question {
    width: 95px;
    height: 95px;
    top: -180px;
  }

  .home--question a {
    padding: 0 20px;
  }

  .menu--center {
    margin-bottom: 20px;
  }

  .menu--center p {
    font-size: 40px;
  }

  .menu--item {
    min-width: 70px;
    width: auto;
    height: auto;
    margin: 0 9px 12px 9px;
  }

  .menu--item:nth-child(2) {
    margin-right: 9px;
  }

  .menu--item:nth-child(3) {
    margin-left: 9px;
  }

  .menu--item svg {
    width: 35px;
    height: 39px;
  }

  .skull {
    top: 40%;
  }

  .skull img {
    min-width: calc((857px * 0.1) + 857px);
  }

  .about--left > div {
    flex-direction: column;
    margin-bottom: 24px;
  }

  .about--top {
    width: 100%;
    text-align: center;
  }

  .about--right video {
    min-height: 172px;
  }

  .about--link {
    margin-top: 16px;
  }

  .about--links-wrap {
    margin-top: 8px;
  }

  .about--bottom {
    width: 100%;
    margin-top: 16px;
    padding: 16px 24px 20px 24px;
  }

  .about--bottom svg {
    margin-bottom: 12px;
  }

  .reviews--main {
    flex-wrap: wrap;
    width: 100%;
    max-width: 100%;
  }

  .reviews--main-wrap {
    width: auto;
    height: auto;
    display: block;
    margin: 0 -8px;
  }

  .reviews--main-bg {
    display: none;
  }

  .reviews--main .slick-track {
    margin: 0;
  }

  .reviews--main .slick-list {
    padding: 0 32px;
  }

  .reviews--main .slick-list:before {
    display: none;
  }

  .reviews--block {
    margin: 0 8px;
    width: 100%;
    text-align: center;
  }

  .reviews--block p {
    padding: 0 5px;
  }

  .reviews--tag {
    margin-top: 16px;
  }

  .callback--text {
    max-width: 100%;
    margin: 8px 0 12px 0;
  }

  .callback--main {
    width: 100%;
  }

  .callback--main .inputs--wrap {
    flex-wrap: wrap;
    margin: 12px 0;
  }

  .callback--main .inputs--wrap > div {
    width: 100%;
    margin-bottom: 12px;
  }

  .callback--main .inputs--wrap > div:last-child {
    margin-bottom: 0;
  }

  .callback--main-wrap {
    border-radius: 14px;
    padding: 16px 12px 24px 12px;
    text-align: center;
  }

  .video--slider-wrap video {
    min-height: auto;
  }

  .video--slider-wrap .slider--left {
    left: 4px;
  }

  .video--slider-wrap .slider--right {
    right: 4px;
  }

  .video--block .no-video--image img {
    max-height: 100px;
    width: 100%;
  }

  .skull--pages-wrap.detail_event {
    min-height: 350px;
  }

  .skull--pages-wrap {
    margin-bottom: 20px;
    min-height: 300px;
  }

  .skull--pages {
    min-width: 767px;
    top: 52px;
    left: 47%;
  }

  .pages--title h1 {
    font-size: 24px;
    line-height: 28px;
    margin: 0;
    width: 100%;
    text-align: center;
  }

  .pages--title span {
    display: none;
  }

  .pages--question {
    width: 95px;
    height: 95px;
    top: -4px;
  }

  .pages--question svg {
    display: none;
  }

  .pages--question span {
    margin-top: 28px;
  }

  .pages--question a {
    padding: 0;
  }

  .group--bottom {
    padding: 0 12px;
  }

  .mbg {
    margin-bottom: -24px;
  }

  .mbg .itog {
    width: auto;
    font-size: 18px;
    line-height: 20px;
    text-align: center;
  }

  .mbg--image {
    width: 53px;
  }

  .mbg--block {
    flex-direction: column;
    align-items: center;
    margin-bottom: 24px;
  }

  .mbg--text {
    width: 100%;
    text-align: center;
    margin-top: 8px;
  }

  .abp {
    padding: 28px 12px;
  }

  .abp--title {
    font-size: 24px;
    line-height: 28px;
    text-align: center;
  }

  .abp--block span {
    font-size: 18px;
    line-height: 20px;
  }

  .abp--block {
    text-align: center;
  }

  .online--wrap {
    padding: 0;
    flex-direction: column;
    align-items: center;
  }

  .online--container {
    padding-bottom: 24px;
  }

  .online--main-wrap.active {
    margin-top: 16px;
  }

  .online--left {
    width: 52px;
  }

  .online--right {
    width: 100%;
    text-align: center;
  }

  .online--title-closest {
    margin-left: 0;
    text-align: center;
  }

  .online--btns {
    flex-direction: column;
    align-items: center;
    margin-left: 0;
  }

  .online--btns.active {
    margin-top: 8px;
  }

  .online--btns .slide--toggle + a {
    margin-left: 0;
    margin-top: 8px;
  }

  .about--page-title {
    font-size: 18px;
    line-height: 20px;
  }

  .about--bottom-bg {
    margin-left: -65px;
  }

  .about--bottom-bg img {
    width: calc(100% + 65px);
  }

  .about--gallery-wrap {
    padding: 0 36px;
  }

  .about--gallery-main {
    overflow: visible;
  }

  .pagination {
    font-size: 12px;
    line-height: 28px;
  }

  .detail--event-top {
    padding: 16px 21px 24px;
  }

  .detail--event-tags .section--tag,
  .detail--event-tags .event--leading-online,
  .detail--event-tags .event--leading {
    margin-left: 0;
    margin-bottom: 4px;
  }

  .detail--event-top_main .event--price {
    width: 100%;
  }

  .detail--event-top_left {
    align-items: center;
    width: 100%;
    flex-direction: row;
  }

  .detail--event-wrapper {
    flex-direction: column;
    align-items: center;
    padding-top: 0;
  }

  .detail--event-block.block_3 {
    text-align: center;
  }

  .detail--event-buttons :first-child {
    margin-right: 0;
    margin-bottom: 4px;
  }

  .detail--event-buttons {
    flex-direction: column;
  }

  .detail--event .section--tag {
    margin-bottom: 4px;
  }

  .events-slider {
    padding: 32px 0;
  }

  .events-slider--wrapper .container {
    max-width: 288px;
  }

  .events-slider--title {
    margin-bottom: 22px;
  }

  .register .more--btn {
    width: 100%;
  }

  .reviews__descr {
    font-size: 18px;
    line-height: 20px;
  }

  .reviews__tags {
    justify-content: center;
  }

  .reviews__date {
    text-align: center;
  }

  .reviews__name {
    text-align: center;
  }

  .reviews__text {
    text-align: center;
  }

  .reviews__wrapper {
    padding-top: 16px;
    padding-bottom: 24px;
  }

  .reviews__form {
    padding-bottom: 32px;
  }

  .reviews__block-inputs > div:last-child {
    margin-left: 0;
  }

  .reviews__textarea {
    height: 88px;
  }

  .reviews__answer {
    width: 100%;
    text-align: center;
  }

  .reviews__answer-name {
    margin-bottom: 17px;
  }

  .reviews__answer-name::before {
    left: 4px;
  }

  .partners .container {
    padding: 0 16px;
  }

  .partners__container {
    padding-bottom: 32px;
  }

  .partners__card:not(:nth-child(5n)) {
    margin-right: 4px;
  }

  .partners__card:not(:nth-child(4n)) {
    margin-right: 4px;
  }

  .partners__card {
    margin-bottom: 4px;
    margin-right: 4px;
  }

  .detail-partner__title {
    margin-top: 24px;
  }

  .detail-partner__image {
    width: 132px;
  }

  .detail-partner__video video {
    height: 180px;
  }

  .detail-partner__video {
    margin-top: 32px;
  }

  .question-form__btn.btn {
    width: auto;
  }

  .chat-modal {
    max-width: 100%;
    height: 100%;
    padding: 16px;
  }

  .chat-modal__wrap {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .lk-container {
    padding: 0 16px;
  }

  .container {
    padding: 0 16px;
  }

  .container.small {
    padding: 0 16px;
  }

  .container.smallx2 {
    padding: 0 16px;
  }

  .container.smallxR2 {
    padding-right: 16px;
  }

  .footer--contacts-icons a {
    display: flex;
  }

  .video--play {
    width: 32px;
    height: 32px;
  }

  .video--play svg {
    width: 9px;
    height: 11px;
  }

  .section--title {
    text-align: center;
  }

  .section--main {
    margin-bottom: 0;
    padding: 0;
  }

  .inpCheck {
    margin-top: 8px;
  }

  .more--btn {
    text-align: center;
    display: block;
  }

  .custom-select-opener {
    width: 100%;
    padding: 11px 30px 11px 13px;
  }

  .custom-select-opener .select--text {
    text-align: left;
  }

  .custom-select-opener:before {
    width: 10px;
    right: 10px;
    height: 5px;
  }

  .custom-select-opener .select--price-price {
    margin-left: 0;
  }

  .custom-select-opener .select--price-old {
    margin-left: 0;
  }

  .event--card-wrap {
    width: 100%;
    margin: 0;
    position: relative;
  }

  .event--right-top {
    margin-bottom: 8px;
  }

  .event--name {
    font-size: 18px;
    line-height: 20px;
  }

  .event--right a.gh {
    display: none;
  }

  .event--time {
    font-size: 12px;
    line-height: 16px;
  }

  .event--price-timer {
    padding: 0 0 0 12px;
    min-width: 100px;
  }

  .event--price-timer .price-timer {
    font-size: 12px;
    line-height: 16px;
  }

  .event--price-price {
    font-size: 14px;
    line-height: 16px;
  }

  .event--price-old {
    font-size: 10px;
    line-height: 14px;
  }

  .events .more--btn {
    width: 100%;
  }

  .esl--wrap .slick-list:before {
    display: none;
  }

  .esl--wrap .slick-list {
    max-width: 288px;
  }

  .esl--wrap .slider--left {
    left: -12px;
  }

  .esl--wrap .slider--right {
    right: -12px;
  }

  .mg80 {
    margin-bottom: 32px;
  }

  .mg60 {
    margin-bottom: 20px;
  }

  .mg20 {
    margin-bottom: 16px;
  }

  .btn--close {
    width: 24px;
    height: 24px;
    top: 27px;
    right: 27px;
  }

  .parsley-errors-list {
    top: calc(100% - 12px);
  }

  .section--tags-text {
    text-align: center;
  }

  .cookie__descr {
    font-size: 10px;
    line-height: 12px;
    text-align: left;
  }
}

@media (max-width: 430px) {
  .home--main {
    height: 660px;
  }

  .home--menu-wrap {
    bottom: -100px;
  }

  .skull {
    top: 37%;
  }
}

@media (max-width: 420px) {
  .esl--wrap .slick-list {
    margin: 0 auto;
  }
}

@media all and (max-height: 576px) {
  .fancybox-slide--image {
    padding: 6px 0;
  }

  .fancybox-close-small {
    right: -6px;
  }

  .fancybox-slide--image .fancybox-close-small {
    background: #4e4e4e;
    color: #f2f4f6;
    height: 36px;
    opacity: 1;
    padding: 6px;
    right: 0;
    top: 0;
    width: 36px;
  }

  .fancybox-caption {
    padding-left: 12px;
    padding-right: 12px;
  }

@supports (padding: 0px) {
    .fancybox-caption {
      padding-left: 12px;
      padding-right: 12px;
    }
}
}

@media (any-hover: hover) {
  .modal-basket__btns .btn-n-default:hover {
    color: #50C878;
    background: none;
  }

  .modal-basket__btns .btn-n-dark:hover {
    background: #50c878;
    color: #051838;
    border-color: #50c878;
  }

  .drop-help__list .simplebar-content div:hover {
    background: rgba(82, 115, 154, 0.2);
    border-radius: 4px;
  }

  .product-basket__name:hover {
    color: #50c878;
  }

  .friends__controll .btn.btn-default:hover {
    color: #50c878;
    background: 0 0;
  }
}