@import "../mixins";

.detail {
    &--event {
        color: #fff;
        &-wrap {
            max-width: 1160px;
            margin: 0 auto;
            @include lg {
                max-width: 992px;
            }
            .event--date,
            .event--date-time,
            .event--date-days {
                width: 100%;
                text-align: center;
            }
        }
        &-top {
            border: 1px solid #e7d186;
            border-radius: 20px;
            padding: 42px 64px;
            @include lg {
                padding: 28px 32px 40px 32px;
            }
            @include md {
                padding: 28px 32px;
            }
            @include sm {
                padding: 20px 41px 28px;
            }
            @include vm {
                padding: 16px 21px 24px;
            }
            .event--date {
                margin-top: 32px;
                @include sm {
                    margin-top: 0;
                    &:not(.d-n) {
                        display: none;
                    }
                    &.d-n {
                        display: flex;
                        margin-top: 12px;
                    }
                }
                @include mdFix {
                    &:not(.d-n) {
                        display: flex;
                        margin-top: 17px;
                    }
                    &.d-n {
                        display: none;
                    }
                }
            }
        }
        .section--tag {
            width: fit-content;
        }
        &-tags {
            margin-left: 41px;
            @include sm {
                margin-left: 0;
                height: auto;
                justify-content: center;
            }
            &_wrap {
            }
            .section--tag,
            .event--leading-online {
                margin-right: 4px;
            }
            .section--tag,
            .event--leading-online,
            .event--leading {
                margin-top: 4px;
                margin-left: 0;
                @include sm {
                    padding: 3px 8px;
                }
                @include vm {
                    margin-left: 0;
                    margin-bottom: 4px;
                }
            }
            .event--leading {
                margin-top: 12px;
                @include sm {
                    margin-left: 0;
                    margin-top: 4px;
                }
            }
        }
        &-bottom {
            margin-top: 60px;
            padding: 40px 64px;
            border: 2px solid #50c878;
            border-bottom: none;
            border-radius: 20px 20px 0 0;
            &_border {
                border-bottom: 2px solid #50c878;
                border-radius: 20px;
                margin-bottom: 32px;
                @include lg {
                    margin-bottom: 24px;
                }
                @include md {
                    margin-bottom: 16px;
                }
                @include sm {
                    margin-bottom: 12px;
                }
                @include mdFix {
                    border-radius: 20px !important;
                }
            }
            @include lg {
                margin-top: 40px;
                padding: 28px 32px;
            }
            @include md {
                padding: 24px 32px;
            }
            @include sm {
                padding: 20px 32px;
            }
            @include mdFix {
                padding: 16px;
                border-radius: 10px 10px 0 0;
            }
            .event--date,
            .event--date-time,
            .event--date-days {
                width: initial;
                @include mdFix {
                    width: 100%;
                }
            }
            .event--date-days {
                padding: 3px 10px 3px 6px;
                @include sm {
                    padding: 1px 10px 1px 6px;
                }
            }
            &_top {
                padding-bottom: 28px;
                margin-bottom: 28px;
                border-bottom: 1px solid #50c878;
                @include lg {
                    margin-bottom: 20px;
                    padding-bottom: 20px;
                }
                @include md {
                    margin-bottom: 16px;
                    padding-bottom: 16px;
                }
                @include sm {
                    margin-bottom: 12px;
                    padding-bottom: 12px;
                }
                @include mdFix {
                    padding-bottom: 16px;
                    margin-bottom: 16px;
                }
                .event--date,
                .event--leading {
                    margin-top: 0;
                    margin-left: 16px;
                    @include mdFix {
                        margin-left: 0;
                        margin-top: 8px;
                    }
                }
                .event--leading {
                    @include lg {
                        padding: 5px 8px;
                    }
                    @include sm {
                        padding: 3px 8px;
                    }
                }
                .detail--event-bottom_item {
                    &:last-child {
                        margin-left: 268px;
                        margin-right: auto;
                        @include lg {
                            margin-left: 68px;
                        }
                        @include mdFix {
                            margin-left: 0;
                        }
                    }
                }
            }
            &_item {
                @include mdFix {
                    flex-direction: column;
                    justify-content: center;
                    text-align: center;
                    width: 100%;
                    &:not(:last-child) {
                        margin-bottom: 16px;
                    }
                    .event--leading {
                        display: block;
                        width: fit-content;
                        margin: 8px auto 0;
                    }
                }
                &:last-child {
                    margin-left: 102px;

                    @include lg {
                        margin-left: 68px;
                    }
                    @include mdFix {
                        margin-left: 0;
                    }
                }
                .event--price-timer {
                    height: auto;
                    border-radius: 22px;
                    padding: 5px 10px;
                    width: fit-content;
                    min-width: auto;
                    min-height: 28px;
                    @include lg {
                        padding: 3px 10px 4px;
                        min-height: 24px;
                    }
                    @include sm {
                        min-height: auto;
                        padding: 1px 10px 2px;
                    }
                    @include mdFix {
                        margin: 8px auto 4px;
                    }
                    > svg {
                        margin-right: 6px;
                        width: 12px;
                        height: 12px;
                    }
                    .price-timer {
                        font-size: 14px;
                        line-height: 16px;
                    }
                }
                .more--btn {
                    background: #50c878;
                    color: #051838;
                    margin-top: 0;
                    padding-left: 65px;
                    padding-right: 65px;
                    &:hover {
                        color: #50c878;
                        background: transparent;
                    }
                    @include lg {
                        max-height: 42px;
                        padding-left: 44px;
                        padding-right: 44px;
                    }
                    @include mdFix {
                        width: 100%;
                        max-width: 252px;
                        margin: 0 auto;
                    }
                }
                .event--price-price {
                    margin-top: 4px;
                    font-size: 28px;
                    line-height: 36px;
                    margin-left: 0;
                    @include sm {
                        font-size: 20px;
                        line-height: 28px;
                    }
                }
                .event--price-old {
                    font-size: 16px;
                    line-height: 24px;
                    margin-left: 0;
                    margin-top: 4px;
                }
                .section--tag.section--tag_level {
                    margin-left: 16px;
                    @include mdFix {
                        margin: 4px auto;
                    }
                    span {
                        margin-left: 12px;
                    }
                }
                .detail--event-bottom_price {
                    margin-bottom: 32px;
                    font-weight: 700;
                    font-size: 16px;
                    line-height: calc(20 / 16);
                    letter-spacing: 0.2em;
                    text-transform: uppercase;
                    color: #50c878;
                }
            }
            &_line {
                @include mdFix {
                    flex-direction: column;
                    justify-content: center;
                }
            }
            &_rate {
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.2em;
                text-transform: uppercase;
                color: #50c878;
                margin-bottom: 32px;
                @include lg {
                    font-size: 14px;
                    line-height: 16px;
                    margin-bottom: 20px;
                }
                @include md {
                    margin-bottom: 16px;
                    font-size: 12px;
                    line-height: 14px;
                }
                @include sm {
                    margin-bottom: 12px;
                }
                @include mdFix {
                    text-align: center;
                }
            }
            &_installment {
                font-size: 16px;
                line-height: 24px;
                color: #50c878;
                margin-left: 16px;
                @include mdFix {
                    margin-top: 12px;
                    > div {
                        display: inline;
                    }
                }
            }
        }
        &-title {
            font-size: 44px;
            line-height: 52px;
            color: #051838;
            font-family: "Oranienbaum", sans-serif;
            @include lg {
                font-size: 32px;
                line-height: 44px;
            }
            @include md {
                font-size: 28px;
                line-height: 36px;
            }
            @include sm {
                font-size: 24px;
                line-height: 32px;
            }
        }

        &-top_main {
            .event--price {
                &-wrapper {
                    margin: 0;
                    align-items: start;
                    @include mdFix {
                        align-items: center;
                    }
                }
                &-timer {
                    border-radius: 16px;
                    padding: 2px 16px 2px 12px;
                    height: 25px;
                    margin-bottom: 15px;
                    min-width: 130px;
                    @include lg {
                        position: absolute;
                        top: 0;
                        height: 24px;
                        min-width: 120px;
                    }
                    @include md {
                        height: 20px;
                        margin-bottom: 10px;
                        padding: 0 16px 0 12px;
                    }
                    @include mdFix {
                        position: static;
                    }
                    .price-timer {
                        font-size: 16px;
                        @include lg {
                            font-size: 14px;
                            line-height: 16px;
                        }
                    }
                }
                &-btn {
                    background: #50c878;
                    border-radius: 12px;
                    width: 100%;
                    max-width: 191px;
                    color: #051838;
                    justify-content: center;
                    @include lg {
                        max-width: 148px;
                        height: 34px;
                    }
                    @include mdFix {
                        width: 100%;
                    }
                }
                &-title {
                    font-style: normal;
                    font-weight: bold;
                    font-size: 20px;
                    line-height: 28px;
                    @include lg {
                        font-size: 16px;
                        line-height: 24px;
                    }
                }
                &-price {
                    font-size: 32px;
                    line-height: 28px;
                    margin-left: 0;
                    @include lg {
                        font-size: 20px;
                        line-height: 20px;
                    }
                }
                &-old {
                    margin-left: 12px;
                    @include sm {
                        margin-left: 0;
                        margin-top: 4px;
                    }
                    @include mdFix {
                        margin-left: 16px;
                        font-style: 12px;
                    }
                }
                &-wrap {
                    @include sm {
                        flex-direction: column;
                        align-items: start;
                    }
                    @include mdFix {
                        flex-direction: row;
                    }
                }
                @include vm {
                    width: 100%;
                }
            }
        }

        &-top_left {
            > svg {
                @include sm {
                    width: 51px;
                    height: 69px;
                    margin-right: 35px;
                }
            }
            @include sm {
                align-items: center;
            }
            @include vm {
                align-items: center;
                width: 100%;
                flex-direction: row;
            }
        }

        &-main {
            width: 100%;
            display: flex;
            @include sm {
                flex-direction: column;
            }
        }
        &-text {
            padding: 60px 0 0 0;
            @include lg {
                padding: 40px 0 0 0;
            }
            @include mdFix {
                padding-top: 24px;
            }
        }
        &-wrapper {
            max-width: 1160px;
            margin: 0 auto;
            position: relative;
            @include lg {
                align-items: flex-start;
            }
            @include sm {
                margin: 0;
            }
            @include vm {
                flex-direction: column;
                align-items: center;
                padding-top: 0;
            }
        }

        &-descr {
            max-width: 560px;
            margin-left: 48px;
            font-size: 20px;
            line-height: 32px;
            color: #e7d186;
            width: 100%;
            @include lg {
                font-size: 16px;
                line-height: 24px;
                max-width: 568px;
                margin-left: 32px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            @include md {
                max-width: 460px;
            }
            @include sm {
                margin-left: 0;
                margin-top: 20px;
                max-width: 100%;
            }
            @include mdFix {
                margin-top: 20px;
                text-align: center;
            }
        }

        &-top_title {
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 16px;
            margin-top: 32px;
            @include lg {
                font-size: 16px;
                line-height: 24px;
                margin-top: 24px;
            }
            @include sm {
                margin-top: 16px;
                margin-bottom: 12px;
            }
        }
        &-top_leader {
            display: flex;
            font-style: normal;
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
            @include lg {
                font-size: 16px;
                line-height: 24px;
            }
            @include sm {
                flex-direction: column;
                align-items: flex-start;
            }
            @include mdFix {
                align-items: center;
            }
            &-name {
                margin-left: 16px;
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.14em;
                text-transform: uppercase;
                color: #50c878;
                background: #1c4d4b;
                border-radius: 20px;
                padding: 6px 16px;
                @include lg {
                    font-size: 10px;
                    line-height: 14px;
                    padding: 5px 12px;
                }
                @include sm {
                    margin-left: 0;
                    margin-top: 8px;
                }
            }
        }

        &-top_bottom {
            margin-top: 8px;
            @include lg {
                margin-top: 0;
            }
            @include sm {
                margin: 0;
            }
            @include mdFix {
                justify-content: center;
                width: 100%;
                align-items: center;
                padding-top: 20px;
            }
        }

        &-icon {
            margin-right: 12px;
            @include lg {
                margin-left: 5px;
                svg {
                    width: 18px;
                    height: 12px;
                }
            }
            @include sm {
                margin-left: 0;
            }
        }

        &-date {
            border: 1px solid #ffffff;
            border-radius: 16px;
            padding-left: 12px;
            padding-right: 16px;
            max-width: fit-content;
            height: 28px;
            @include lg {
                height: 24px;
            }
            @include sm {
                padding-left: 6px;
                padding-right: 8px;
                height: 20px;
            }
            svg {
                margin-right: 8px;
            }
            span {
                font-size: 16px;
                line-height: 20px;
                font-weight: bold;
                color: #ffffff;
                @include lg {
                    font-size: 14px;
                    line-height: 16px;
                }
            }

            div {
                width: 16px;
                height: 1px;
                background: #ffffff;
                margin: 0 12px;
                @include lg {
                    width: 14px;
                }
                @include sm {
                    width: 12px;
                    margin: 0 6px;
                }
            }
        }
        &-block {
            margin-bottom: 32px;
            @include lg {
                margin-bottom: 28px;
            }
            @include md {
                margin-bottom: 24px;
            }
            @include sm {
                margin: 0 0 20px 0;
            }
            @include sm {
                margin: 0;
            }
            &_gold {
                color: #e7d186;
                font-weight: 700;
            }
            &:last-child {
                margin-bottom: 0;
            }

            &_title {
                font-family: "Oranienbaum", sans-serif;
                font-size: 32px;
                line-height: 40px;
                color: #ffffff;
                display: block;
                margin: 0 0 12px 30px;
                @include lg {
                    margin: 0 0 12px 0;
                    font-size: 24px;
                    line-height: 28px;
                }
                @include md {
                    font-size: 20px;
                    line-height: 24px;
                }
                @include mdFix {
                    margin: 0 0 12px 0;
                }
            }

            p,
            li {
                font-size: 20px;
                line-height: 32px;
                color: #ffffff;
                @include lg {
                    font-size: 16px;
                    line-height: 24px;
                }
            }

            .mst {
                p {
                    margin-bottom: 12px;
                    &.re {
                        color: #e7d186;
                    }
                    &:last-child {
                        margin-bottom: 0;
                    }
                    @include mdFix {
                        margin-bottom: 8px;
                    }
                }
            }

            ul {
                margin-bottom: 12px;
                border-bottom: 1px solid #051838;
                @include mdFix {
                    flex-direction: column;
                }
                &.nt {
                    border-bottom: 0;
                    margin-bottom: 32px;
                    @include sm {
                        margin-bottom: 20px;
                    }

                    li {
                        width: auto;
                    }
                }

                li {
                    position: relative;
                    width: calc(33.33333% - 8px);
                    margin: 0 0 20px 0;
                    padding-left: 28px;
                    @include lg {
                        margin: 0 0 12px 0;
                        padding-left: 26px;
                    }
                    @include sm {
                        width: calc(50% - 8px);
                    }
                    @include mdFix {
                        width: auto;
                        margin-bottom: 8px;
                    }

                    &:before {
                        content: "";
                        background-image: url(../pic/star_green.svg);
                        width: 12px;
                        height: 11px;
                        background-size: cover;
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 8px;
                        @include lg {
                            top: 4px;
                            width: 11px;
                            height: 10px;
                        }
                    }
                }
            }

            &.block_1 > div {
                padding: 0 16px;
                @include md {
                    padding: 0 32px;
                }
                @include sm {
                    padding: 0 41px;
                }
                @include mdFix {
                    padding: 0;
                }
            }

            &.block_2 {
                .detail--event-block_title {
                    margin-left: 0;
                }
                .mst {
                    padding: 0 16px;
                    @include md {
                        padding: 0 32px;
                    }
                    @include sm {
                        padding: 0 41px;
                    }
                    @include mdFix {
                        padding-left: 26px;
                    }
                }
            }

            &.block_3 {
                margin: 40px 0;
                border-top: 1px solid #ffffff;
                border-bottom: 1px solid #ffffff;
                @include lg {
                    margin: 28px 0;
                }
                @include md {
                    margin: 24px 0;
                }
                @include sm {
                    margin: 20px 0;
                }
                @include mdFix {
                    margin: 16px 0;
                }
                @include vm {
                    text-align: center;
                }
                p {
                    font-family: "Oranienbaum", sans-serif;
                    font-size: 32px;
                    line-height: 40px;
                    color: #ffffff;
                    padding: 24px 16px;
                    @include lg {
                        font-size: 24px;
                        line-height: 28px;
                        padding: 24px 16px 24px 16px;
                    }
                    @include md {
                        font-size: 20px;
                        line-height: 24px;
                        padding: 24px 32px;
                    }
                    @include mdFix {
                        font-size: 18px;
                        line-height: 20px;
                        padding: 24px 16px;
                    }
                }
            }
            &.block_4 {
                padding: 0 16px;
                @include md {
                    padding: 0 32px;
                }
                @include sm {
                    padding: 0;
                    margin-bottom: 28px;
                    > p {
                        padding: 0 41px;
                    }
                }
                @include mdFix {
                    > p {
                        padding: 0;
                        font-size: 16px;
                        line-height: 24px;
                        text-align: center;
                    }
                }
            }
        }
        &-know {
            margin-bottom: 32px;
            border-top: 1px solid #b3e4ff;
            @include lg {
                margin-bottom: 20px;
            }
            @include md {
                margin-bottom: 16px;
            }
        }
        &-buttons {
            @include mdFix {
                justify-content: center;
            }
            :first-child {
                margin-right: 16px;
                @include lg {
                    margin-right: 12px;
                }
                @include md {
                    margin-right: 8px;
                }
                @include sm {
                    margin-right: 4px;
                }
                @include vm {
                    margin-right: 0;
                    margin-bottom: 4px;
                }
            }
            a {
                margin-top: 0;
            }
            @include md {
                margin-top: 16px;
            }
            @include sm {
                margin-top: 12px;
            }
            @include vm {
                flex-direction: column;
            }
            .more--btn {
                @include md {
                    margin-left: 0;
                }
            }
        }
        .section--tag {
            @include vm {
                margin-bottom: 4px;
            }
            span {
                @include lg {
                    margin-left: 0;
                }
                @include md {
                    margin-left: 0;
                    line-height: 14px;
                }
            }
        }
    }
}

.events-slider {
    padding: 80px 0;
    @include lg {
        padding: 58px 0;
    }
    @include md {
        padding: 44px 0;
    }
    @include sm {
        padding: 38px 0;
    }
    @include vm {
        padding: 32px 0;
    }
    &--wrapper {
        position: relative;
        .container {
            position: relative;
            @include md {
                max-width: 912px;
            }
            @include sm {
                padding: 0 57px;
                max-width: 100%;
            }
            @include mdFix {
                max-width: 296px;
                margin: 0 auto;
                padding: 0;
                position: static;
            }
            @include vm {
                max-width: 288px;
            }
        }
    }
    .event--type {
        @include md {
            max-width: min-content;
        }
    }
    .event--right-top {
        @include md {
            align-items: flex-start;
        }
    }
    &--title {
        font-family: Oranienbaum;
        font-style: normal;
        font-weight: normal;
        font-size: 44px;
        line-height: 52px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #e7d186;
        margin-bottom: 28px;
        @include lg {
            font-size: 40px;
            line-height: 48px;
            margin-bottom: 16px;
        }
        @include md {
            font-size: 36px;
            line-height: 44px;
            margin-bottom: 12px;
        }
        @include sm {
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 8px;
        }
        @include mdFix {
            font-size: 24px;
            line-height: 28px;
            text-align: center;
        }
        @include vm {
            margin-bottom: 22px;
        }
    }
    .slick-list {
        overflow: visible !important;
    }
    .section--main {
        min-height: 293px;
        @include md {
            min-height: 215px;
        }
        &::before {
            content: "";
            position: absolute;
            display: block;
            width: 100%;
            height: 125%;
            background: #051838;
            left: -100%;
            z-index: 2;
            @include mdFix {
                display: none;
            }
        }
    }
    .slick-slide {
        margin: 0 8px;
        @include md {
            margin: 0 4px;
        }
        @include mdFix {
            max-width: 296px;
        }
    }
    .slick-active {
        @include sm {
            min-width: auto;
        }
    }
    .slick-track {
        min-width: max-content;
    }
    .slider--left {
        @include sm {
            left: 16px;
        }
        @include mdFix {
            left: 4px;
        }
    }
    .slider--right {
        @include sm {
            right: 16px;
        }
        @include mdFix {
            right: 4px;
        }
    }
}

.d-n {
    display: none;
}

.detail-vip {
    background: #e7d186;
    border-radius: 0px 0px 20px 20px;
    padding: 40px 64px;
    border: 2px solid #50c878;
    border-top: none;
    margin-bottom: 32px;
    @include lg {
        padding: 28px 32px;
        margin-bottom: 20px;
    }
    @include md {
        padding: 24px 32px;
        margin-bottom: 16px;
    }
    @include sm {
        padding: 20px 32px;
        margin-bottom: 12px;
    }
    @include mdFix {
        padding: 16px;
        border-radius: 0px 0px 10px 10px;
    }
    &__label {
        padding: 4px 16px;
        background: #50c878;
        border-radius: 20px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #051838;
        margin-bottom: 20px;
        width: max-content;
        @include lg {
            font-size: 14px;
            line-height: 16px;
            padding: 4px 10px;
        }
        @include md {
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 16px;
        }
        @include mdFix {
            width: 100%;
            text-align: center;
        }
    }
    &__list {
        padding-bottom: 32px;
        margin-bottom: 32px;
        border-bottom: 1px solid #051838;
        @include lg {
            padding-bottom: 20px;
            margin-bottom: 20px;
        }
        @include md {
            padding-bottom: 16px;
            margin-bottom: 16px;
        }
        @include sm {
            margin-bottom: 12px;
            padding-bottom: 12px;
        }
        @include mdFix {
            padding-bottom: 0;
            margin-bottom: 16px;
        }
        > li {
            color: #051838;
            width: calc(33% - 12px);
            display: flex;
            font-size: 20px;
            line-height: 32px;
            @include lg {
                font-size: 16px;
                line-height: 24px;
            }
            @include md {
                width: calc(33% - 6px);
            }
            @include sm {
                width: calc(50% - 6px);
            }
            @include mdFix {
                width: 100%;
                margin-bottom: 16px;
            }
            &::before {
                content: url(../pic/star-dark.svg);
                margin-right: 16px;
            }
            &:not(:nth-child(n + 3)) {
                margin-bottom: 20px;
                @include sm {
                    margin-bottom: 0;
                }
            }
            &:not(:nth-child(n + 5)) {
                @include sm {
                    margin-bottom: 16px;
                }
            }
        }
    }
    &__bottom {
        @include mdFix {
            flex-direction: column;
        }
    }
    &__price {
        font-weight: 700;
        font-size: 32px;
        line-height: 44px;
        color: #051838;
        @include lg {
            font-size: 28px;
            line-height: 36px;
        }
        @include sm {
            font-size: 20px;
            line-height: 28px;
        }
        @include mdFix {
            margin-bottom: 16px;
        }
    }
    &__btn.btn {
        color: #051838;
        border: 2px solid #051838;
        margin-left: 88px;
        padding-left: 65px;
        padding-right: 65px;
        @include lg {
            margin-left: 58px;
            padding-left: 44px;
            padding-right: 44px;
            border-radius: 10px;
        }
        @include md {
            border-radius: 8px;
        }
        @include mdFix {
            margin-bottom: 12px;
            margin-left: 0;
            width: 100%;
            max-width: 252px;
            justify-content: center;
        }
    }
    &__installment {
        font-weight: 400;
        font-size: 20px;
        line-height: 28px;
        color: #051838;
        margin-left: 16px;
        @include lg {
            font-size: 16px;
            line-height: 24px;
        }
        @include mdFix {
            margin-left: 0;
            display: flex;
        }
    }
}
