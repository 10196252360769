.modal {
    &--forgot {
        max-width: 712px;
        max-height: 500px;
        width: 100%;
        background: #E7D186;
        padding: 36px 84px 40px;
        @include lg {
            max-width: 587px;
            padding: 36px 76px 40px;
        }
        @include md {
            max-width: 548px;
            padding: 32px 56px;
        }
        @include sm {
            max-width: 488px;
            padding: 38px 56px;
        }
        @include mdFix {
            max-width: 288px;
            padding: 32px 0;
        }
        @include vm {
            max-width: 100%;
            height: 100%;
            max-height: 100%;
            padding: 160px 16px;
        }
        &-success {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: #E7D186;
            z-index: 20;
            opacity: 0;
            visibility: hidden;
            -webkit-transition: all 0.3s ease;
            transition: all 0.3s ease;
            .more--btn {
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 9px;
                @include sm {
                    width: 100%;
                }
            }
            .modal--callback-main {
                width: 100%;
                max-width: 507px;
            }
            &.active {
                opacity: 1;
                visibility: visible;
            }
        }
        &-wrapper {
            background: #E7D186;
            height: 100%;
            .ms--title {
                margin-bottom: 0;
            }
        }
        .ms--text {
            padding: 0;
        }
        .inputs--block {
            width: 100%;
            border-radius: 12px;
            .phone-mask {
                color: #051838;
                border: none;
            }
            input {
                border: none;
            }
        }
        .inpCheck {
            margin-top: 24px;
        }
    }
    &--callback-main {
        padding: 0;
    }
    &--auth-register {
        font-size: 14px;
    }
    &--auth-pass {
        font-size: 14px;
    }
    &--auth-footer {
        @include mdFix {
            flex-direction: column;
            align-items: center;
        }
    }
    &--auth-register {
        @include mdFix {
            &::before {
                display: none;
            }
            width: 100%;
            text-align: center;
        }
    }
    &--auth-pass {
        @include mdFix {
            margin-right: 0;
            width: 100%;
            text-align: center;
            border-bottom: 1px solid #051838;
            padding-bottom: 8px;
            margin-bottom: 8px;
        }
    }
}

.modal {
    &--online-success {
        width: 712px;
        height: 500px;
        @include lg {
            width: 584px;
            height: 411px;
        }
        @include md {
            width: 436px;
            height: 367px;
            .ms--text {
                padding: 0;
            }
        }
        @include sm {
            width: 406px;
            height: 315px;
        }
        @include mdFix {
            width: 288px;
            height: 348px;
        }
    }
}
