.btn.online--more-link {
    @include md {
        padding: 10px 24px 10px;
        border-radius: 8px;
    }
}

.online {
    &--wrap {
        @include md {
            padding: 0 56px;
        }
        @include sm {
            padding: 4px 0 0 0;
        }
        @include vm {
            padding: 0;
            flex-direction: column;
            align-items: center;
        }
    }

    &--container {
        border-bottom: 1px solid #50c878;
        padding-bottom: 60px;
        @include lg {
            padding-bottom: 40px;
        }
        @include md {
            padding-bottom: 32px;
        }
        @include sm {
            padding-bottom: 28px;
        }
        @include vm {
            padding-bottom: 24px;
        }

        &.last {
            border: 0;
            padding: 0;
        }
    }

    &--main {
        &-wrap {
            display: none;

            .section--main {
                padding: 0;
            }

            .event--card-wrap {
                background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%);
                border: 1px solid #52739a;
                @include sm {
                    border-width: 1px;
                }
                @include mdFix {
                    width: 100%;
                    margin-bottom: 4px;
                }
            }

            &.active {
                margin-top: 40px;
                @include lg {
                    margin-top: 28px;
                }
                @include md {
                    margin-top: 24px;
                }
                @include sm {
                    margin-top: 20px;
                }
                @include vm {
                    margin-top: 16px;
                }
            }
        }
    }

    &--left {
        @include lg {
            width: 78px;
            svg {
                width: 100%;
                height: auto;
            }
        }
        @include sm {
            width: 71px;
        }
        @include vm {
            width: 52px;
        }
    }

    &--right {
        width: calc(100% - 209px);
        margin: 20px 0 0 auto;
        @include lg {
            width: calc(100% - 150px);
            margin: 17px 0 0 auto;
        }
        @include md {
            width: calc(100% - 110px);
            margin: 16px 0 0 auto;
        }
        @include sm {
            width: calc(100% - 85px);
        }
        @include vm {
            width: 100%;
            text-align: center;
        }
        &-descr {
            font-size: 20px;
            line-height: 32px;
            @include lg {
                font-size: 16px;
                line-height: 24px;
            }
        }
    }

    &--title {
        font-family: "Oranienbaum", sans-serif;
        &.big {
            font-size: 36px;
            line-height: 48px;
            color: #e7d186;
            display: block;
            margin-bottom: 16px;
            @include lg {
                font-size: 32px;
                line-height: 44px;
                margin-bottom: 12px;
            }
            @include md {
                font-size: 24px;
                line-height: 32px;
            }
            @include sm {
                font-size: 24px;
                line-height: 32px;
                margin-bottom: 8px;
            }
        }

        &.small {
            font-size: 24px;
            line-height: 32px;
            margin-bottom: 8px;
            @include lg {
                font-size: 20px;
                line-height: 28px;
            }
            @include md {
                font-size: 16px;
                line-height: 24px;
            }
            @include sm {
                margin-bottom: 4px;
            }
        }

        &-closest {
            font-weight: bold;
            font-size: 20px;
            line-height: 24px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #50c878;
            margin-left: 209px;
            display: block;
            margin-bottom: 30px;
            @include lg {
                margin-left: 150px;
                font-size: 14px;
                line-height: 17px;
                margin-bottom: 20px;
            }
            @include md {
                margin-left: 110px;
                margin-bottom: 16px;
                padding-left: 56px;
            }
            @include sm {
                margin-left: 85px;
                padding: 0;
                font-size: 12px;
                line-height: 15px;
                margin-bottom: 12px;
            }
            @include vm {
                margin-left: 0;
                text-align: center;
            }
        }
    }

    &--btns {
        margin-left: 209px;
        margin-top: 40px;
        font-family: "Oranienbaum", sans-serif;
        color: #50c878;
        @include lg {
            margin-left: 150px;
            margin-top: 28px;
        }
        @include md {
            margin-left: 110px;
            margin-top: 24px;
            padding-left: 56px;
        }
        @include sm {
            margin-left: 85px;
            padding: 0;
        }
        @include vm {
            flex-direction: column;
            align-items: center;
            margin-left: 0;
        }

        &.active {
            margin-top: 32px;
            @include lg {
                margin-top: 20px;
            }
            @include md {
                margin-top: 16px;
            }
            @include sm {
                margin-top: 12px;
            }
            @include vm {
                margin-top: 8px;
            }
        }

        .slide--toggle {
            + a {
                margin-left: 24px;
                @include lg {
                    margin-left: 16px;
                }
                @include vm {
                    margin-left: 0;
                    margin-top: 8px;
                }
            }
        }
    }
    &--more-link.btn {
        padding: 12px 42px;
        @include lg {
            padding: 9px 42px;
        }
    }
}

.slide--toggle {
    position: relative;
    overflow: hidden;
    @include md {
        border-width: 1px;
        border-radius: 8px;
    }

    &.active {
        svg {
            transform: translate(0, -50%) rotate(180deg);
        }
    }

    span {
        font-size: 20px;
        line-height: 28px;
        padding: 12px 42px;
        text-transform: none;
        letter-spacing: normal;
        font-weight: normal;
        color: #50c878;
        position: relative;
        z-index: 2;
        display: block;
        transition: 0.3s all ease;
        border-radius: 12px;
        border: 2px solid #50c878;
        @include lg {
            font-size: 16px;
            line-height: 20px;
            padding: 10px 30px;
        }
        @include md {
            font-size: 14px;
            line-height: 14px;
            padding: 11px 24px 11px;
            border-radius: 8px;
        }
    }

    svg {
        right: 44px;
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        color: #50c878;
        @include lg {
            width: 15px;
            height: auto;
            right: 32px;
        }
        @include md {
            right: 28px;
        }
    }
    @include mdMax {
        &:hover {
            background: #50c878;

            span {
                color: #051838;
            }

            svg {
                color: #051838;
            }
        }
    }
    @include md {
        &:active {
            background: #50c878;

            span {
                color: #051838;
            }

            svg {
                color: #051838;
            }
        }
    }
}

.modal {
    &--event {
        &-wrap {
            height: 100%;
        }
        width: 1128px;
        padding: 12px 48px 80px 12px;
        height: calc(100vh - 366px);
        overflow: hidden;
        @include lg {
            height: calc(100vh - 74px);
            padding: 8px 24px 58px 18px;
            width: 961px;
        }
        @include md {
            height: calc(100vh - 146px);
            width: 880px;
            padding: 8px 20px 44px 8px;
        }
        @include sm {
            height: calc(100vh - 32px);
            padding: 4px 10px 32px 0;
            width: calc(100% - 32px);
        }
        @include mdFix {
            padding: 20px 10px 32px 24px;
        }

        &-tags {
            margin-bottom: 4px;
            @include lg {
                margin-bottom: 0;
            }
            @include md {
                margin-bottom: 4px;
            }
            @include mdFix {
                justify-content: center;
            }

            > * {
                margin: 0 2px;
                @include lg {
                    margin: 0 4px;
                    .stars {
                        svg {
                            width: 12px;
                            height: 12px;
                        }
                    }
                }

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }

        &-title {
            font-size: 44px;
            line-height: 52px;
            color: #051838;
            font-family: "Oranienbaum", sans-serif;
            @include lg {
                font-size: 32px;
                line-height: 44px;
            }
            @include md {
                font-size: 28px;
                line-height: 36px;
            }
            @include sm {
                font-size: 24px;
                line-height: 32px;
            }
        }

        &-top_main {
            padding-left: 52px;
            @include lg {
                padding-left: 38px;
            }
            @include md {
                padding-left: 33px;
            }
            @include sm {
                padding-left: 0;
            }
        }

        &-top_left {
            @include lg {
                width: 78px;
                svg {
                    width: 100%;
                    height: auto;
                }
            }
            @include sm {
                width: 71px;
            }
            @include mdFix {
                display: none;
            }
        }

        &-top_right {
            margin-top: 20px;
            @include lg {
                margin-top: 13px;
                margin-left: 15px;
            }
            @include md {
                margin-top: 12px;
                margin-left: -15px;
            }
            @include sm {
                margin-left: 8px;
            }
            @include mdFix {
                margin-left: 0;
                width: 100%;
                padding-right: 12px;
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }

        &-top_bottom {
            margin-top: 8px;
            @include lg {
                margin-top: 4px;
            }
            @include md {
                margin-top: 0;
            }
            @include mdFix {
                justify-content: center;
            }

            p {
                font-size: 20px;
                line-height: 32px;
                color: #000000;
                margin-left: 12px;
                @include lg {
                    font-size: 14px;
                    line-height: 20px;
                }
                @include mdFix {
                    width: 100%;
                    margin: 4px 0 0 0;
                    text-align: center;
                }
            }
        }

        &-icon {
            margin-right: 12px;
            @include lg {
                margin-left: 5px;
                svg {
                    width: 18px;
                    height: 12px;
                }
            }
            @include sm {
                margin-left: 0;
            }
        }

        &-date {
            span {
                font-size: 20px;
                line-height: 32px;
                font-weight: bold;
                color: #051838;
                @include lg {
                    font-size: 14px;
                    line-height: 20px;
                }
            }

            div {
                width: 16px;
                height: 1px;
                background: #051838;
                margin: 0 12px;
                @include lg {
                    width: 14px;
                }
            }
        }

        &-block {
            margin: 0 0 40px 75px;
            @include lg {
                margin: 0 0 28px 41px;
            }
            @include md {
                margin: 0 0 24px 41px;
            }
            @include sm {
                margin: 0 0 20px 34px;
            }
            @include mdFix {
                margin: 0 0 16px 0;
            }

            &:last-child {
                margin-bottom: 0;
            }

            &_title {
                font-family: "Oranienbaum", sans-serif;
                font-size: 32px;
                line-height: 40px;
                color: #051838;
                display: block;
                margin: 0 0 12px 44px;
                @include lg {
                    font-size: 24px;
                    line-height: 28px;
                    margin-left: 40px;
                }
                @include md {
                    font-size: 20px;
                    line-height: 24px;
                    margin-left: 34px;
                }
                @include mdFix {
                    text-align: center;
                    margin: 0 0 12px 0;
                }
            }

            p,
            li {
                font-size: 20px;
                line-height: 32px;
                color: #000712;
                @include lg {
                    font-size: 14px;
                    line-height: 20px;
                }
                @include md {
                    font-size: 12px;
                    line-height: 17px;
                }
            }

            .mst {
                padding: 0 16px;
                @include md {
                    padding: 0 8px 0 8px;
                }
                @include mdFix {
                    padding: 0;
                }
                span {
                    margin-left: 12px;
                    @include lg {
                        margin-left: 10px;
                    }
                    @include md {
                        margin-left: 0;
                    }
                }

                div {
                    margin-left: 12px;
                    @include lg {
                        margin-left: 10px;
                    }
                    @include md {
                        margin-left: 0;
                    }
                }

                p {
                    margin-bottom: 12px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                    @include mdFix {
                        margin-bottom: 8px;
                    }
                }
            }

            ul {
                padding: 0 16px 12px 16px;
                margin-bottom: 32px;
                @include lg {
                    padding-bottom: 8px;
                    margin-bottom: 20px;
                }
                @include md {
                    padding: 0 8px 8px 8px;
                    margin-bottom: 16px;
                }
                @include mdFix {
                    padding: 0 0 4px 0;
                }

                border-bottom: 1px solid #051838;

                &.nt {
                    border-bottom: 0;
                    margin: 0 0 12px 0;
                    @include lg {
                        margin: 0 0 8px 0;
                    }

                    li {
                        width: 100%;
                    }
                }

                li {
                    position: relative;
                    width: calc(33.33333% - 8px);
                    margin: 0 0 20px 0;
                    padding-left: 28px;
                    @include lg {
                        margin: 0 0 12px 0;
                        padding-left: 26px;
                    }
                    @include mdFix {
                        width: 100%;
                        margin-bottom: 8px;
                    }

                    &:before {
                        content: "";
                        background-image: url(../pic/star_green.svg);
                        width: 12px;
                        height: 11px;
                        background-size: cover;
                        display: block;
                        position: absolute;
                        left: 0;
                        top: 8px;
                        @include lg {
                            top: 4px;
                            width: 11px;
                            height: 10px;
                        }
                    }
                }
            }

            &.block_1 {
                border: 1px solid #50c878;
                border-radius: 20px;
                padding: 12px 12px;

                p {
                    display: inline-flex;
                    margin: 0 12px 8px 12px;
                }

                @include lg {
                    padding: 12px 16px 4px 16px;
                    border-radius: 12px;
                    .br_1 {
                        display: none;
                    }
                    .br_2 {
                        display: none;
                    }
                    .br_3 {
                        display: none;
                    }
                    p {
                        margin: 0 10px 8px 10px;
                        @include mdFix {
                            margin: 0 0 8px 0;
                            width: 100%;
                            text-align: center;
                            justify-content: center;
                        }
                    }
                }
                @include mdFix {
                    padding: 12px 12px 4px 12px;
                }
            }

            &.block_3 {
                p {
                    font-family: "Oranienbaum", sans-serif;
                    font-size: 32px;
                    line-height: 40px;
                    color: #50c878;
                    padding: 24px 28px 32px 28px;
                    border: 1px solid #50c878;
                    border-width: 1px 0 1px 0;
                    @include lg {
                        font-size: 24px;
                        line-height: 28px;
                        padding: 20px 26px 27px 26px;
                    }
                    @include md {
                        font-size: 20px;
                        line-height: 24px;
                        padding: 16px 8px 23px 8px;
                    }
                    @include mdFix {
                        font-size: 18px;
                        line-height: 20px;
                        padding: 12px 0 20px 0;
                    }
                }
            }
        }

        &-main {
            margin-top: 24px;
            overflow-y: auto;
            height: 100%;
            max-height: 100%;
            padding-right: 32px;
            @include lg {
                margin-top: 16px;
                padding-right: 28px;
            }
            @include md {
                margin-top: 8px;
                padding-right: 20px;
                height: auto;
            }
        }
    }
}
