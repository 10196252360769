@import "../mixins";

.question-form {
    &__form {
        background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #000712;
        border: 1px solid #52739a;
        border-radius: 20px;
        padding: 40px 104px 60px;
        @include lg {
            padding: 28px 75px 40px;
        }
        @include md {
            padding: 24px 63px 32px;
        }
        @include sm {
            padding: 20px 42px 28px;
        }
        @include mdFix {
            padding: 16px 12px 24px;
        }
    }
    &__title {
        font-family: Oranienbaum;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        color: #e7d186;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
        @include lg {
            font-size: 24px;
            line-height: 28px;
        }
        @include md {
            font-size: 20px;
            line-height: 24px;
        }
    }
    &__quest {
        display: block;
        margin-top: 20px;
        margin-bottom: 32px;
        background: #000712;
        border: 2px solid #e7d186;
        border-radius: 12px;
        width: 100%;
        min-height: 148px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #e7d186;
        padding: 20px;
        resize: none;
        &.parsley-error {
            border-color: #e84e1d;
        }
        @include lg {
            font-size: 10px;
            line-height: 12px;
            margin-top: 16px;
            margin-bottom: 24px;
        }
        @include md {
            padding: 12px;
            margin-top: 12px;
            margin-bottom: 16px;
            border: 1px solid #e7d186;
        }
    }
    &__btn.btn {
        background: #50c878;
        color: #051838;
        margin: 0 auto;
        display: block;
        &:hover {
            color: #50c878;
            background: transparent;
        }
        @include vm {
            width: auto;
        }
    }
}

.question-modal {
    background: #e7d186;
    &__wrap {
    }
    .modal--callback-success {
        min-height: 492px;
    }
}
