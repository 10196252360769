@import "../mixins";

.datepicker {
    &__wrapper {
        width: 100%;
        margin-top: 16px;
        background: #000712;
        color: #e7d186;
        padding: 16px 12px 24px 20px;
        border: 2px solid #e7d186;
        border-radius: 12px;
        @include lg {
            margin-top: 12px;
        }
        @include md {
            margin-top: 8px;
        }
    }
    &__day {
        width: 36px;
        height: 36px;
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        border: 1px solid #e7d186;
        text-align: center;
        cursor: pointer;
        font-family: Noah;
        &.is-otherMonth {
            opacity: 0;
            border: none;
        }
        &.is-today {
            background: #e7d186;
            color: #051838;
        }
        &:hover {
            background: #50c878;
            color: #fff;
        }
        &.is-selected {
            background: #50c878;
            color: #fff;
        }
    }
    &__header {
        text-align: center;
        margin-bottom: 22px;
        display: flex;
        justify-content: space-around;
    }
    &__cal {
        margin: 0 auto;
        th {
            font-size: 12px;
            line-height: 16px;
            color: #fff;
            padding-bottom: 22px;
            text-transform: uppercase;
        }
    }
    &__title {
        display: flex;
        align-items: center;
        font-family: Oranienbaum;
        font-size: 20px;
        line-height: 32px;
        select {
            width: 100px !important;
        }
        > span {
            margin-right: 18px;
            position: relative;
            &::after {
                content: "";
                display: block;
                width: 20px;
                height: 12px;
                background: url(../pic/datepicker-date-bottom.svg) center center/cover no-repeat;
                position: absolute;
                right: -32px;
                top: 50%;
                transform: translateY(-50%);
                pointer-events: none;
                @include lg {
                    width: 13px;
                    height: 8px;
                }
            }
        }
    }
    &.filled ~ label {
        font-size: 9px;
        line-height: 16px;
        font-weight: bold;
        color: #004a30;
        border-color: #004a30;
        top: 3px;
        transform: translate(-50%, 0);
        @include mdFix {
            top: 0;
        }
        > svg {
            position: relative;
            top: 14px;
            @include md {
                top: 9px;
            }
        }
        .date-block--text {
            position: absolute;
            top: 4px;
            left: 50%;
            transform: translateX(-50%);
            @include md {
                font-size: 9px;
                top: 3px;
                line-height: 1;
            }
        }
    }
}
