.personally {
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
    }
    &__link.more--btn {
        position: relative;
        background: #50c878;
        color: #051838;
        max-width: max-content;
        margin: 0;
    }
    &__link-wrap {
        border: 1px solid #e7d186;
        box-sizing: border-box;
        border-radius: 13px;
        padding: 4px;
        margin-bottom: 20px;
        text-align: center;
        margin-top: 32px;
        display: inline-block;
        @include lg {
            margin-top: 24px;
            margin-bottom: 12px;
        }
        @include md {
            margin-top: 24px;
            margin-bottom: 8px;
        }
        @include sm {
            margin-top: 20px;
        }
    }
    &__item {
        flex: 1 0 calc(33% - 56px);
        text-align: center;
        padding-right: 56px;
        position: relative;
        border-bottom: 1px solid #50c878;
        padding-bottom: 60px;
        margin-bottom: 60px;
        @include lg {
            flex: 1 0 calc(33% - 48px);
            padding-right: 48px;
        }
        @include md {
            flex: 1 0 calc(50% - 40px);
            padding-right: 40px;
            padding-bottom: 32px;
            margin-bottom: 24px;
        }
        @include mdFix {
            padding-right: 0;
            flex: 1 0 100%;
            padding-bottom: 24px;
            margin-bottom: 16px;
            padding-left: 16px;
            padding-right: 16px;
        }
        &:nth-child(3n) {
            padding-right: 0;
            @include md {
                padding-right: 40px;
            }
            @include mdFix {
                padding-right: 0;
            }
        }
        &:nth-child(2n) {
            @include md {
                padding-right: 0;
            }
        }
        &:nth-last-child(-n + 3) {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
            @include md {
                border-bottom: 1px solid #50c878;
                padding-bottom: 32px;
                margin-bottom: 24px;
            }
        }
        &:nth-last-child(-n + 2) {
            @include md {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
            @include mdFix {
                border-bottom: 1px solid #50c878;
                padding-bottom: 24px;
                margin-bottom: 16px;
            }
        }
        &:nth-last-child(-n + 1) {
            @include mdFix {
                border-bottom: none;
                padding-bottom: 0;
                margin-bottom: 0;
            }
        }
    }
    &__descr {
        color: #fff;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        @include lg {
            font-size: 14px;
            line-height: 20px;
        }
    }
    &__hours {
        max-width: max-content;
        margin: 0 auto;
        padding: 4px 12px;
        background: #1c4d4b;
        border-radius: 16px;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.12em;
        text-transform: uppercase;
        color: #50c878;
        margin-bottom: 20px;
        @include lg {
            line-height: 14px;
            margin-bottom: 12px;
        }
        @include md {
        }
    }
    &__image {
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        svg {
            margin: 0 auto 34px;
            @include lg {
                max-width: 78px;
                max-height: 72px;
                margin: 0 auto 26px;
            }
            @include md {
                margin: 0 auto 17px;
            }
            @include sm {
                margin: 0 auto 13px;
            }
            @include mdFix {
                margin: 0 auto 5px;
            }
        }
    }
    &__subtitle {
        font-size: 16px;
        line-height: 20px;
        color: #50c878;
        margin-bottom: 30px;
        min-height: 40px;
        @include lg {
            font-size: 12px;
            margin-bottom: 22px;
            line-height: 16px;
        }
        @include md {
            min-height: 32px;
            margin-bottom: 12px;
        }
    }
    &__title {
        font-family: Oranienbaum;
        font-weight: normal;
        font-size: 44px;
        line-height: 52px;
        color: #e7d186;
        margin-bottom: 8px;
        @include lg {
            font-size: 32px;
            line-height: 44px;
        }
        @include md {
            font-size: 28px;
            line-height: 36px;
            margin-bottom: 4px;
        }
    }
    &__warn {
        font-size: 16px;
        line-height: 20px;
        color: #50c878;
        @include lg {
            font-size: 12px;
            line-height: 16px;
        }
    }
    &__line {
        border-bottom: 1px solid #50c878;
        margin: 60px 0;
        flex: 1 0 100%;
        @include lg {
            margin: 40px 0;
        }
    }
}

.personally-descr {
    margin-bottom: 0;
    @include lg {
        font-size: 14px;
        line-height: 20px;
    }
}
.personally-archive {
    max-width: max-content;
    margin: 40px auto 60px;
    @include lg {
        margin: 28px auto 40px;
    }
    @include md {
        margin: 24px auto 24px;
    }
    @include sm {
        margin: 20px auto 20px;
    }
    @include mdFix {
        margin: 16px auto 16px;
    }
    .more--btn {
        margin-top: 0;
        margin-left: 0;
    }
}
