.taplink {
    &__page {
        margin: 0 auto;
        padding: 20px 0;
        width: 360px;
        height: 650px;
        background: #051838;
    }
    &__list-item {
        border: 2px solid #50c878;
        border-radius: 20px;
        padding: 8px 0;
        display: block;
        &:not(:last-child) {
            margin-bottom: 8px;
        }
    }
    &__list-link {
        font-family: Noah;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        color: #ffffff;
        text-align: center;
        display: block;
    }
    &__list-name {
        font-family: Noah;
        font-style: normal;
        font-weight: bold;
        font-size: 10px;
        line-height: 14px;
        letter-spacing: 0.22em;
        text-transform: uppercase;
        color: #50c878;
        text-align: center;
    }
    &__logo {
        border: 4px solid #e7d186;
        border-radius: 50%;
        width: 129px;
        width: 129px;
        overflow: hidden;
        margin: 0 auto 16px;
    }
    &__title {
        font-family: Oranienbaum;
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #e7d186;
        margin-bottom: 24px;
    }
}
