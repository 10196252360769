@font-face {
  font-family: 'Noah';
  src: local('Noah Regular'), local('Noah-Regular'),
  url('../fonts/Noahregular.woff2') format('woff2'),
  url('../fonts/Noahregular.woff') format('woff'),
  url('../fonts/Noahregular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Noah';
  src: local('Noah Medium'), local('Noah-Medium'),
  url('../fonts/Noah-Medium.woff2') format('woff2'),
  url('../fonts/Noah-Medium.woff') format('woff'),
  url('../fonts/Noah-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Noah';
  src: local('Noah Bold'), local('Noah-Bold'),
  url('../fonts/Noah-Bold.woff2') format('woff2'),
  url('../fonts/Noah-Bold.woff') format('woff'),
  url('../fonts/Noah-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Oranienbaum';
  src: local('Oranienbaum Regular'), local('Oranienbaum-Regular'),
  url('../fonts/Oranienbaum-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}