.about {
  &--page {
    &-top {
      position: relative;
      margin-bottom: 32px;
      video {
        height: 100%;
      }
      @include lg {
        width: 660px;
        margin-bottom: 20px;
        video {
          width: 100%;
        }
      }
      @include md {
        margin-bottom: 16px;
      }
      @include sm {
        margin-bottom: 12px;
      }
      @include mdFix {
        width: 100%;
        video {
          width: 100%;
        }
      }

      .video--play {
        width: 60px;
        height: 60px;
        @include lg {
          width: 40px;
          height: 40px;
          svg {
            width: 9px;
            height: auto;
          }
        }
        @include md {
          width: 38px;
          height: 38px;
        }
        @include sm {
          width: 34px;
          height: 34px;
        }
      }
    }
    &-description {
      @include lg {
        font-size: 16px;
        line-height: 24px;
      }
    }
    &-title {
      font-size: 32px;
      line-height: 40px;
      color: #ffffff;
      margin-bottom: 16px;
      font-family: "Oranienbaum", sans-serif;
      @include lg {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 12px;
      }
      @include md {
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      @include vm {
        font-size: 18px;
        line-height: 20px;
      }
    }

    &-bottom {
      text-align: center;

      span {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #50c878;
        display: block;
        margin-bottom: 40px;
        @include lg {
          font-size: 10px;
          line-height: 16px;
          margin-bottom: 28px;
        }
        @include md {
          line-height: 12px;
          margin-bottom: 20px;
        }
        @include sm {
          line-height: 12px;
        }
      }
    }
  }

  &--bottom {
    &-bg {
      margin-left: -120px;
      width: 100%;
      position: relative;
      @include lg {
        margin-left: -91px;
      }
      @include md {
        margin-left: -143px;
      }
      @include sm {
        margin-left: -115px;
      }
      @include vm {
        margin-left: -65px;
      }

      img {
        width: calc(100% + 120px);
        height: auto;
        right: 0;
        top: 0;
        max-width: initial;
        @include lg {
          width: calc(100% + 91px);
        }
        @include md {
          width: calc(100% + 143px);
        }
        @include sm {
          width: calc(100% + 115px);
        }
        @include vm {
          width: calc(100% + 65px);
        }
      }
    }
  }

  &--gallery {
    &-wrap {
      padding: 0 107px;
      position: relative;
      @include lg {
        padding: 0 72px;
      }
      @include md {
        padding: 0 16px;
      }
      @include vm {
        padding: 0 36px;
      }
      .slick {
        &-list {
          overflow: visible;
        }
        &-track {
          display: flex;
        }
      }
      .slider {
        &--left {
          left: 20px;
        }
        &--right {
          right: 20px;
        }
      }
    }
    &-main {
      overflow: hidden;
      @include vm {
        overflow: visible;
      }
      div.gallery--slide {
        width: calc(100% / 8);

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
