@mixin chatMd {
    @media screen and (max-width: 767px) {
        @content;
    }
}

@import "../mixins";
.chat-container {
    &.lk-container {
        @include sm {
            padding: 0 16px;
        }
    }
    &__subtitle {
        font-family: "Oranienbaum";
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #50c878;
        margin-bottom: 8px;
        display: none;
        @include mdFix {
            display: block;
        }
    }
}
.chat {
    border: 1px solid #50c878;
    border-radius: 20px;
    max-height: 754px;
    min-height: 754px;
    @include lg {
        max-height: 590px;
        min-height: 570px;
    }
    @include mdFix {
        border: none;
        border-radius: 0;
        max-height: initial;
        min-height: initial;
    }
    &__bottom {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        color: #051838;
        padding: 8px 16px;
        background: #50c878;
        border: 1px solid #50c878;
        border-radius: 0px 0px 20px 20px;
        text-align: center;
        @include lg {
            font-size: 10px;
            line-height: 14px;
        }
        @include mdFix {
            display: none;
        }
    }
}
.chat-sidebar {
    padding: 24px 0 40px 24px;
    background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #000712;
    border-radius: 20px 0px 0px 20px;
    max-width: 254px;
    width: 100%;
    max-height: 718px;
    min-height: 718px;
    @include lg {
        padding: 20px 0 20px 20px;
        max-height: 550px;
        min-height: 550px;
        max-width: 220px;
    }
    @include mdFix {
        max-height: initial;
        min-height: auto;
        max-width: 100%;
        border-radius: 0;
        border: none;
        background: transparent;
        padding: 0;
    }
    .simplebar-vertical {
        background: #f2f2f2;
        width: 4px;
    }
    .simplebar-scrollbar,
    .simplebar-scrollbar:before {
        background: #e7d186;
    }
    &__wrap {
        height: 100%;
        padding-right: 24px;
        @include mdFix {
            padding-right: 0;
        }
    }
    &__title {
        margin-bottom: 12px;
        font-family: "Oranienbaum";
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        color: #50c878;
        text-align: center;
        @include lg {
            font-size: 24px;
            line-height: 28px;
        }
        @include mdFix {
            display: none;
        }
    }
    &__subtitle {
        padding: 6px 6px 6px 12px;
        background: rgba(82, 115, 154, 0.3);
        border-radius: 20px;
        margin-bottom: 20px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        color: #50c878;
        cursor: pointer;
        @include lg {
            font-size: 10px;
            line-height: 14px;
            padding: 4px 0;
            margin-bottom: 16px;
        }
        @include mdFix {
            display: none;
        }
        &-new {
            padding: 0px 4px;
            background: #50c878;
            border-radius: 32px;
            font-size: 10px;
            line-height: 16px;
            color: #ffffff;
            margin-left: auto;
        }
    }
}

.chat-list {
    border-top: 1px solid #52739a;
    @include mdFix {
        border: 1px solid #52739a;
        border-radius: 10px;
        margin-bottom: 4px;
    }
    &_feedback-list {
        min-height: initial;
        .chat-list__item:last-child {
            border-bottom: none;
        }
    }
    &_private {
        .chat-list__item {
            cursor: pointer;
        }
    }
    &:not(.opened) {
        .chat-list__title-arrow > svg {
            transform: rotate(180deg);
        }
        > .chat-list__item {
            display: none;
        }
        .chat-list__open {
            @include mdFix {
                display: none;
            }
        }
        .chat-list__edit {
            @include mdFix {
                display: none;
            }
        }
    }
    &.opened {
        padding-bottom: 20px;
        @include lg {
            padding-bottom: 16px;
        }
        @include mdFix {
            padding-bottom: 4px;
        }
        .chat-list__edit {
            display: flex;
        }
        .chat-list__title {
            padding-bottom: 8px;
        }
    }
    &__title {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        color: #ffffff;
        padding: 20px 0 20px 8px;
        transition: none;
        @include lg {
            font-size: 10px;
            line-height: 14px;
            padding: 16px 0 16px 8px;
        }
        @include mdFix {
            letter-spacing: 0.22em;
            padding: 12px 20px;
        }
        &-arrow {
            transition: 0.3s;
        }
    }
    &__new-mess {
        height: 16px;
        padding: 0px 5px 0px 7px;
        background: #50c878;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        color: #ffffff;
        border-radius: 32px;
        margin: 0 12px;
        @include lg {
            font-size: 8px;
            line-height: 12px;
            height: 12px;
            padding: 0px 4px;
        }
    }
    &__item {
        padding: 8px 0 0 8px;
        min-height: 44px;
        display: flex;
        align-items: center;
        @include mdFix {
            padding: 8px 20px;
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }
    }
    &__name {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        color: #50c878;
        @include lg {
            font-size: 10px;
            line-height: 14px;
        }
        @include mdFix {
            letter-spacing: 0.22em;
        }
        &-role {
            font-size: 12px;
            line-height: 16px;
            color: #52739a;
            text-transform: initial;
            letter-spacing: 0;
        }
    }
    &__image {
        margin-right: 16px;
        border: 2px solid #ffffff;
        border-radius: 50%;
        position: relative;
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 7px;
            height: 7px;
            background: #50c878;
            border-radius: 50%;
        }
        &-new {
            padding: 0px 6px;
            background: #50c878;
            border-radius: 32px;
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            text-align: center;
            color: #ffffff;
            position: absolute;
            top: -6px;
            right: -6px;
        }
    }
    &__open {
        border: 1px solid #50c878;
        box-sizing: border-box;
        border-radius: 20px;
        font-size: 12px;
        line-height: 16px;
        color: #50c878;
        padding: 8px 12px;
        width: 100%;
        display: flex;
        margin-top: 8px;
        display: none;
        @include mdFix {
            display: flex;
        }
    }
    &__add.lk-btn {
        width: 100%;
        background: transparent;
        color: #50c878;
        &:hover {
            background: #50c878;
            color: #051838;
            border: 2px solid #50c878;
        }
    }
    &__edit {
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #50c878;
        padding: 8px 12px;
        background: #1c4d4b;
        border-radius: 20px;
        width: 100%;
        margin-top: 8px;
        display: none;
        @include mdFix {
            display: flex;
        }
    }
}

.chat-window {
    padding: 24px 32px 24px 32px;
    width: 100%;
    position: relative;
    @include lg {
        padding: 20px 32px 20px 20px;
    }
    @include mdFix {
        display: none;
        padding: 0;
        padding-right: 16px;
        &.opened {
            display: block;
            position: fixed;
            z-index: 99;
            width: 100%;
            top: 44px;
            bottom: 50px;
            left: 0;
            right: 0;
            background: #051838;
        }
    }
    &__form {
        padding-top: 32px;
        position: absolute;
        bottom: 24px;
        width: calc(100% - 88px);
        left: 32px;
        background: #051838;
        @include lg {
            left: 20px;
            padding-top: 20px;
            width: calc(100% - 52px);
        }
        @include mdFix {
            padding-top: 8px;
            bottom: 8px;
            left: 16px;
            width: calc(100% - 48px);
        }
    }
    &__wrapper {
        max-height: 574px;
        padding-right: 32px;
        @include lg {
            padding-right: 24px;
            max-height: 430px;
        }
        @include mdFix {
            max-height: calc(100% - 92px);
            padding-right: 16px;
            padding-left: 16px;
        }
        .simplebar-vertical {
            background: #f2f2f2;
            width: 4px;
        }
        .simplebar-scrollbar,
        .simplebar-scrollbar:before {
            background: #e7d186;
        }
    }
    &__mess {
        &:not(:last-child) {
            margin-bottom: 24px;
            @include mdFix {
                margin-bottom: 12px;
            }
        }
        &.received {
            .chat-window__mess {
                &-header {
                    flex-direction: row-reverse;
                }
                &-text {
                    background: rgba(82, 115, 154, 0.3);
                    border-radius: 0px 12px 12px 12px;
                    margin-left: auto;
                }
                &-avatar {
                    flex-direction: row-reverse;
                }
                &-date,
                &-name {
                    text-align: left;
                }
                &-img {
                    margin-right: 12px;
                    margin-left: 0;
                }
            }
        }
        &-status {
            font-size: 12px;
            line-height: 16px;
            color: #52739a;
            padding: 6px 12px;
            border: 1px solid #52739a;
            border-radius: 20px;
            display: flex;
            align-items: center;
            svg {
                margin-right: 6px;
                @include mdFix {
                    margin-right: 0;
                }
            }
            @include mdFix {
                padding: 2px 8px;
                height: 20px;
                span {
                    display: none;
                }
            }
            &_new {
                background: #50c878;
                color: #051838;
                border-color: #50c878;
                svg {
                    display: none;
                    @include mdFix {
                        display: block;
                        color: #051838;
                    }
                }
            }
        }
        &-name {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            text-align: right;
            letter-spacing: 0.16em;
            text-transform: uppercase;
            color: #50c878;
            @include lg {
                font-size: 10px;
                line-height: 14px;
            }
        }
        &-img {
            border: 2px solid #ffffff;
            border-radius: 50%;
            margin-left: 12px;
        }
        &-header {
            margin-bottom: 12px;
            align-items: center;
        }
        &-date {
            font-weight: 500;
            font-size: 10px;
            line-height: 16px;
            text-align: right;
            color: #52739a;
        }
        &-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
            padding: 16px;
            background: rgba(80, 200, 120, 0.3);
            border-radius: 12px 0px 12px 12px;
            max-width: calc(100% - 44px);
            @include lg {
                font-size: 14px;
                line-height: 16px;
            }
            @include mdFix {
                max-width: 100%;
            }
        }
    }
    &__head {
        display: none;
        font-family: "Oranienbaum";
        font-weight: 400;
        font-size: 20px;
        line-height: 24px;
        text-align: right;
        color: #50c878;
        padding: 4px;
        border-bottom: 1px solid #52739a;
        margin-bottom: 12px;
        @include mdFix {
            display: flex;
        }
        &-title {
            padding: 4px 8px;
        }
    }
}

.message-form {
    .lk-btn {
        background: transparent;
        padding: 0 44px;
        color: #50c878;
        height: 56px;
        svg {
            display: none;
        }
        &:hover {
            background: #50c878;
            color: #051838;
        }
        @include lg {
            height: 42px;
        }
        @include md {
            height: 36px;
            padding: 0 24px;
        }
        @include mdFix {
            background: #50c878;
            padding: 0 12px;
        }
        @include mdFix {
            span {
                display: none;
            }
            svg {
                display: block;
            }
        }
    }
    &__file {
        position: absolute;
        visibility: hidden;
        opacity: 0;
        width: 0;
        height: 0;
        &-label {
            position: absolute;
            top: 20px;
            right: 20px;
            cursor: pointer;
            @include lg {
                top: 11px;
            }
            @include md {
                top: 7px;
            }
            @include mdFix {
                right: 9px;
            }
        }
    }
    &__wrap {
        align-items: flex-end;
    }
    &__input {
        width: 100%;
        margin-right: 10px;
        position: relative;
        max-width: calc(100% - 180px);
        @include md {
            max-width: calc(100% - 128px);
        }
        @include mdFix {
            margin-right: 4px;
            max-width: calc(100% - 36px);
        }
        .textarea {
            padding: 16px 12px 16px 20px;
            background: #000712;
            border: 2px solid #e7d186;
            box-sizing: border-box;
            border-radius: 12px;
            width: 100%;
            color: #e7d186;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.2em;
            min-height: 56px;
            max-height: 200px;
            overflow: auto;
            &:focus,
            &.not-empty {
                + label {
                    display: none;
                }
            }
            @include lg {
                min-height: 42px;
                padding: 11px 44px 11px 20px;
            }
            @include md {
                min-height: 36px;
                padding: 8px 44px 8px 20px;
                max-height: 120px;
            }
            @include mdFix {
                padding: 8px 34px 8px 9px;
            }
        }
        &-label {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #e7d186;
            position: absolute;
            top: 20px;
            left: 20px;
            pointer-events: none;
            @include lg {
                font-size: 10px;
                line-height: 12px;
                top: 15px;
            }
            @include md {
                top: 12px;
            }
        }
    }
}

.chat-modal {
    padding: 20px 60px 40px 60px;
    background: #e7d186;
    max-width: 712px;
    width: 100%;
    @include lg {
        max-width: 509px;
    }
    @include md {
        max-width: 436px;
        padding: 16px 56px 32px;
    }
    @include vm {
        max-width: 100%;
        height: 100%;
        padding: 16px;
    }
    .inputs--block {
        background: #fff;
        border-radius: 12px;
        margin-bottom: 20px;
        @include lg {
            margin-bottom: 12px;
            border-radius: 10px;
        }
        @include md {
            margin-bottom: 8px;
            border-radius: 8px;
        }
        input {
            padding: 20px 10px;
            @include lg {
                padding: 14px 10px;
            }
            @include md {
                padding: 11px 10px;
            }
        }
        input,
        label {
            border: none;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #051838;
            @include lg {
                font-size: 10px;
                line-height: 12px;
            }
        }
    }
    &__wrap {
        padding: 16px 24px;
        @include lg {
            padding: 16px;
        }
        @include md {
            padding: 16px 0 0;
        }
        @include vm {
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }
    .lk-btn {
        background: #051838;
        padding: 0 44px;
        border: 2px solid #051838;
        font-size: 20px;
        line-height: 28px;
        color: #ffffff;
        margin-top: 12px;
        @include lg {
            margin-top: 8px;
            font-size: 16px;
            padding: 0 32px;
            line-height: 20px;
        }
        @include md {
            font-size: 14px;
            line-height: 14px;
        }
        &:hover {
            border: 2px solid #051838;
            color: #051838;
        }
    }
    .custom-select-opener {
        padding: 20px 0;
        background: #ffffff;
        border: none;
        &::before {
            background-image: url(../pic/select-b.svg);
            @include md {
                width: 13px;
                height: 8px;
                right: 12px;
            }
        }
        @include lg {
            border-radius: 10px;
            padding: 14px 0;
        }
        @include md {
            padding: 11px 10px;
        }
    }
    .custom-select-panel {
        border: none;
        background: #ffffff;
    }
    .custom-select-container.is-open .custom-select-panel::before {
        background: #051838;
    }
    .custom-select-option {
        justify-content: center;
        color: #051838;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.2em;
        padding: 10px 26px 10px 26px;
        margin: 0;
        @include lg {
            font-size: 10px;
            line-height: 12px;
        }
    }
    .custom-select-opener > span {
        justify-content: center;
        color: #051838;
        font-size: 12px;
        line-height: 16px;
        @include lg {
            font-size: 10px;
            line-height: 12px;
        }
    }
}
