.modal {
    &--callback {
        background: #e7d186;
        max-width: 1128px;
        padding: 78px 164px 98px 164px;
        width: 100%;
        @include lg {
            max-width: 812px;
            padding: 55px 0 75px 0;
        }
        @include md {
            max-width: 770px;
            padding: 70px 0 86px 0;
        }
        @include sm {
            max-width: 654px;
            padding: 65px 0 81px 0;
        }
        @include vm {
            max-width: 100%;
            padding: 128px 16px;
            .modal--callback-main {
                padding: 0;
            }
        }

        &-title {
            font-size: 44px;
            line-height: 52px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #051838;
            display: block;
            text-align: center;
            margin-bottom: 32px;
            font-family: "Oranienbaum", sans-serif;
            @include lg {
                font-size: 40px;
                line-height: 48px;
                margin-bottom: 20px;
            }
            @include md {
                font-size: 36px;
                line-height: 44px;
                margin-bottom: 16px;
            }
            @include sm {
                font-size: 28px;
                line-height: 32px;
                margin-bottom: 20px;
            }
            @include mdFix {
                font-size: 24px;
                line-height: 28px;
            }
        }
        &-subtitle {
            display: block;
            text-align: center;
            font-style: normal;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            color: #051838;
            text-transform: uppercase;
            @include mdFix {
                letter-spacing: 0.2em;
            }
        }

        &-main {
            padding: 0 104px;
            @include lg {
                padding: 0 74px;
            }
            @include md {
                padding: 0 56px;
            }
            @include sm {
                padding: 0 41px;
            }
            @include mdFix {
                padding: 0 24px;
            }

            .inputs--wrap {
                width: 100%;
                @include mdFix {
                    flex-direction: column;
                }
            }

            .inputs--block {
                width: calc(33.33333% - 8px);
                background: #ffffff;
                @include mdFix {
                    width: 100%;
                    margin-bottom: 12px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }

                input {
                    color: #051838;
                    border-color: #051838;
                }

                label {
                    color: #051838;
                }
            }

            .inputs--textarea {
                background: #ffffff;
                margin-top: 16px;
                width: 100%;
                border-radius: 12px;
                border: none;
                @include lg {
                    margin-top: 20px;
                }
                @include mdFix {
                    margin-top: 8px;
                }

                textarea {
                    color: #051838;
                    height: 112px;
                    resize: none;
                    @include lg {
                        height: 80px;
                    }
                    @include mdFix {
                        height: 290px;
                    }
                }

                label {
                    color: #051838;
                    transform: translate(-50%, 0);
                    top: 20px;
                    @include mdFix {
                        top: 12px;
                    }
                }
            }

            .inpCheck {
                @include lg {
                    margin-top: 20px;
                }
                @include md {
                    margin-top: 16px;
                }
                @include sm {
                    margin-top: 12px;
                }
            }
            .inpCheck p {
                color: #051838;
            }

            .more--btn {
                background: #051838;
                color: #ffffff;
                border-color: #051838;
                margin: 32px 0 0 0;
                @include lg {
                    margin-top: 20px;
                }
                @include md {
                    margin-top: 16px;
                }
                @include sm {
                    margin-top: 12px;
                }

                &:hover {
                    background: #50c878;
                    color: #051838;
                    border-color: #50c878;
                }
            }

            .inpCheck {
                color: #051838;

                span {
                    &:before {
                        border-color: #051838;
                    }

                    &:after {
                        background-image: url(../pic/check_black.svg);
                    }
                }
            }
        }

        &-success {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            background: #e7d186;
            z-index: 20;
            opacity: 0;
            visibility: hidden;
            transition: 0.3s all ease;
            &.active {
                opacity: 1;
                visibility: visible;
            }
            > div {
                width: 100%;
                height: 100%;
            }
        }
    }
}

.ms {
    &--title {
        font-size: 44px;
        line-height: 52px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #051838;
        font-family: "Oranienbaum", sans-serif;
        display: block;
        margin-bottom: 16px;
        @include lg {
            font-size: 40px;
            line-height: 48px;
        }
        @include md {
            font-size: 36px;
            line-height: 44px;
        }
        @include sm {
            font-size: 28px;
            line-height: 32px;
            margin-bottom: 12px;
        }
        @include mdFix {
            font-size: 24px;
            line-height: 28px;
        }
    }

    &--text {
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #051838;
        padding: 0 207px;
        margin-bottom: 32px;
        @include lg {
            font-size: 14px;
            line-height: 20px;
            padding: 0 145px;
            margin-bottom: 20px;
        }
        @include md {
            margin-bottom: 16px;
            padding: 0 125px;
        }
        @include sm {
            padding: 0 66px;
            margin-bottom: 12px;
        }
        @include mdFix {
            padding: 0 24px;
        }

        a {
            font-weight: bold;
        }
    }
    &--inst {
        svg {
            @include lg {
                width: 18px;
                height: auto;
            }
        }
    }
}
