.home {
    &--main {
        height: 918px;
        position: relative;
        overflow: hidden;
        margin-left: auto;
        background: radial-gradient(67.35% 67.35% at 50% 0%, #083d79 0%, #051838 100%), #051c38;
        max-width: 1920px;
        border-bottom: 1px solid #52739a;
        @include xlMax {
            margin: 0 auto;
            overflow: visible;
        }
        @include lg {
            height: 700px;
        }
        @include mdFix {
            height: 630px;
        }
        @media (max-width: 430px) {
            height: 660px;
        }
    }

    &--menu {
        position: relative;
        @include sm {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
        }
        ul {
            flex: 1 auto;
            margin: 0 -8px 0 -8px;
            @include md {
                width: 100%;
                margin: 0;
            }
            @include vm {
                justify-content: center;
                margin: 0 -9px -12px -9px;
            }
        }

        &-wrap {
            bottom: -100px;
            position: absolute;
            left: 0;
            right: 0;
            @include md {
                bottom: calc(-40px + 10%);
            }
            @include sm {
                bottom: 32px;
            }
            @include vm {
                bottom: -30px;
            }
            @media (max-width: 430px) {
                bottom: -100px;
            }
        }
    }

    &--question {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        width: 144px;
        height: 144px;
        background: rgba(5, 24, 56, 0.5);
        border-radius: 50%;
        top: 75px;
        z-index: 2;
        &:hover {
            span {
                color: #e7d186;
            }
            svg {
                color: #e7d186;
            }
        }
        svg {
            color: #50c878;
        }
        @include lg {
            width: 132px;
            height: 132px;
            top: calc(35px + 6%);
        }
        @include md {
            width: 120px;
            height: 120px;
            top: calc(35px + 17%);
            svg {
                width: 29px;
                height: 29px;
            }
        }
        @include sm {
            top: -196px;
        }
        @include vm {
            width: 95px;
            height: 95px;
            top: -180px;
        }

        span {
            display: block;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.21em;
            text-transform: uppercase;
            color: #50c878;
            margin-top: 12px;
            @include lg {
                font-size: 10px;
                line-height: 16px;
                letter-spacing: 0.26em;
            }
            @include md {
                line-height: 12px;
                letter-spacing: 0.2em;
            }
        }

        a {
            width: 100%;
            height: 100%;
            padding: 0 42px;
            text-align: center;
            @include lg {
                padding: 0 39px;
            }
            @include md {
                padding: 0 34px;
            }
            @include vm {
                padding: 0 20px;
            }
        }
    }
}

.menu {
    &--desc {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #ffffff;
        display: inline-flex;
        margin: 0 32px 0 32px;
        width: 100%;
        @include lg {
            font-size: 10px;
            line-height: 16px;
            margin: 0 24px;
        }
        @include md {
            line-height: 12px;
            margin: 0 16px;
        }
    }

    &--line {
        width: 100%;
        height: 1px;
        flex: 1 auto;
        background: #ffffff;
        display: block;
    }

    &--center {
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        @include sm {
            position: relative;
            left: auto;
            top: auto;
            transform: none;
            margin-bottom: 27px;
        }
        @include vm {
            margin-bottom: 20px;
        }
        div {
            margin: 15px 0 0 0;
            width: 100%;
            @include lg {
                margin-top: 12px;
            }
            @include sm {
                margin-top: 8px;
            }
        }

        p {
            font-family: "Oranienbaum", sans-serif;
            font-size: 96px;
            line-height: 96px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #e7d186;
            @include lg {
                font-size: 72px;
                line-height: 72px;
            }
            @include md {
                font-size: 62px;
                line-height: 62px;
            }
            @include sm {
                font-size: 52px;
            }
            @include vm {
                font-size: 40px;
            }
        }
    }

    &--item {
        width: 192px;
        height: 163px;
        margin: 0 8px;
        &:nth-child(2) {
            margin-right: 360px;
        }
        &:nth-child(3) {
            margin-left: 360px;
        }
        @include lg {
            width: 105px;
            height: 118px;
            &:nth-child(2) {
                margin-right: 240px;
            }
            &:nth-child(3) {
                margin-left: 240px;
            }
            svg {
                width: 70px;
                height: 76px;
            }
        }
        @include md {
            width: 103px;
            height: 95px;
            &:nth-child(2) {
                margin-right: 200px;
            }
            &:nth-child(3) {
                margin-left: 200px;
            }
            svg {
                width: 52px;
                height: 58px;
                margin-top: 10px;
            }
        }
        @include sm {
            &:nth-child(2) {
                margin-right: 0;
            }
            &:nth-child(3) {
                margin-left: 0;
            }
            svg {
                margin-top: 0;
            }
            margin: 0;
            width: calc(25% - 4px);
        }
        @include vm {
            min-width: 70px;
            width: auto;
            height: auto;
            margin: 0 9px 12px 9px;
            &:nth-child(2) {
                margin-right: 9px;
            }
            &:nth-child(3) {
                margin-left: 9px;
            }
            svg {
                width: 35px;
                height: 39px;
            }
        }

        a {
            width: 100%;
            height: 100%;
        }

        span {
            display: block;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.21em;
            text-transform: uppercase;
            color: #50c878;
            margin-top: 12px;
            @include lg {
                font-size: 10px;
                line-height: 16px;
                margin-top: 8px;
            }
            @include md {
                line-height: 12px;
            }
            @include sm {
                margin-top: 12px;
            }
        }
    }
}

.skull {
    position: absolute;
    top: -60px;
    left: 50%;
    transform: translate(-50%, 0);
    min-width: 2727px;
    img {
        width: 100%;
        height: 100%;
    }
    @include lg {
        min-width: calc((0.1 * 1988px) + 1988px);
        top: 37%;
        transform: translate(-50%, -50%);
    }
    //10% - 153
    @include md {
        min-width: 1785px;
        top: 39%;
        height: 100%;
    }
    @include sm {
        min-width: 100%;
        transform: none;
        height: auto;
        left: 0;
        right: 0;
        img {
            min-width: calc((1275px * 0.1) + 1275px);
            transform: translate(-50%, -50%);
            left: 50%;
            top: 50%;
            position: relative;
        }
    }
    @include vm {
        top: 40%;
        img {
            min-width: calc((857px * 0.1) + 857px);
        }
    }
    @media (max-width: 430px) {
        top: 37%;
    }
}

.about {
    &--left {
        width: grid(6);

        > div {
            padding-right: 113px;
            padding-top: 16px;
            @include lg {
                padding-right: 75px;
                padding-top: 32px;
            }
            @include md {
                padding-right: 44px;
                padding-top: 20px;
            }
            @include sm {
                padding: 0;
                display: flex;
                width: 100%;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 28px;
            }
            @include vm {
                flex-direction: column;
                margin-bottom: 24px;
            }
        }
    }

    &--top {
        font-family: "Oranienbaum", sans-serif;
        position: relative;
        a.about--link {
            font-family: "Noah", sans-serif;
        }
        a.about--inst {
            position: absolute;
            right: 0;
            top: 18px;
            svg {
                width: 20px;
                height: 20px;
            }
            @include lg {
                top: 13px;
            }
            @include sm {
                display: none;
            }
        }
        a.about--youtube {
            position: absolute;
            right: 0;
            top: 60px;
            svg {
                width: 20px;
                height: 20px;
            }
            @include md {
                top: 50px;
            }
            @include sm {
                display: none;
            }
        }

        svg {
            color: #e7d186;
            &:hover {
                color: #50c878;
            }
            @include lg {
                width: 20px;
                height: 20px;
            }
        }
        @include sm {
            width: gridCustom(16, 7);
        }
        @include vm {
            width: 100%;
            text-align: center;
        }
    }

    &--title {
        font-size: 44px;
        line-height: 52px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #e7d186;
        display: block;
        @include lg {
            font-size: 40px;
            line-height: 48px;
        }
        @include md {
            font-size: 36px;
            line-height: 44px;
        }
        @include sm {
            font-size: 28px;
            line-height: 32px;
        }
        @include mdFix {
            font-size: 24px;
            line-height: 28px;
        }
    }

    &--text {
        font-size: 32px;
        line-height: 40px;
        color: #e7d186;
        margin-top: 8px;
        display: block;
        @include lg {
            font-size: 24px;
            line-height: 28px;
            margin-top: 4px;
        }
        @include md {
            font-size: 20px;
            line-height: 24px;
        }
    }

    &--right {
        width: grid(9);
        position: relative;
        display: flex;

        video {
            max-height: 517px;
            width: 100%;
            height: 100%;
            @include vm {
                min-height: 172px;
            }
        }
    }

    &--link {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.14em;
        text-transform: uppercase;
        color: #50c878;
        display: inline-flex;
        margin-top: 24px;
        @include lg {
            font-size: 10px;
            line-height: 14px;
            margin-top: 24px;
        }
        @include vm {
            margin-top: 16px;
        }
    }

    &--links {
        &-wrap {
            margin-top: 16px;
            font-size: 20px;
            line-height: 28px;
            color: #50c878;
            font-family: Oranienbaum, sans-serif;
            @include lg {
                font-size: 16px;
                line-height: 20px;
                margin-top: 12px;
            }
            @include md {
                font-size: 14px;
                line-height: 14px;
            }
            @include vm {
                margin-top: 8px;
            }

            span {
                margin: 0 24px;
                display: block;
                @include lg {
                    margin: 0 16px;
                }
            }
        }
    }

    &--bottom {
        border: 2px solid #50c878;
        border-radius: 20px;
        padding: 32px 32px 44px 32px;
        margin-top: 44px;
        @include lg {
            padding: 24px 24px 32px 24px;
            margin-top: 32px;
            border-radius: 12px;
        }
        @include sm {
            width: gridCustom(16, 8);
            margin: 0;
        }
        @include vm {
            width: 100%;
            margin-top: 16px;
            padding: 16px 24px 20px 24px;
        }

        svg {
            color: #e7d186;
            margin-bottom: 24px;
            @include lg {
                margin-bottom: 16px;
            }
            @include md {
                width: 7px;
                height: 13px;
            }
            @include vm {
                margin-bottom: 12px;
            }
        }

        > span {
            font-size: 32px;
            line-height: 40px;
            color: #e7d186;
            font-family: Oranienbaum, sans-serif;
            @include lg {
                font-size: 24px;
                line-height: 28px;
            }
            @include md {
                font-size: 20px;
                line-height: 24px;
            }
            @include mdFix {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }
}

.reviews {
    &--main {
        @include vm {
            flex-wrap: wrap;
            width: 100%;
            max-width: 100%;
        }
        &-wrap {
            display: flex;
            flex-direction: column;
            position: relative;
            width: 100%;
            height: 100%;
            @include vm {
                width: auto;
                height: auto;
                display: block;
                margin: 0 -8px;
            }
            .slider--left {
                @include sm {
                    left: 16px;
                }
            }
            .slider--right {
                @include sm {
                    right: 16px;
                }
            }
            .container {
                @include lg {
                    position: relative;
                }
            }
        }

        &-bg {
            position: absolute;
            width: grid(3.5);
            right: 0;
            top: 0;
            bottom: 0;
            background: linear-gradient(270deg, #051838 0%, rgba(5, 24, 56, 0) 100%);
            @include vm {
                display: none;
            }
        }

        .slick-track {
            margin: 0 -60px;
            @include md {
                margin: 0 -32px;
            }
            @include vm {
                margin: 0;
            }
        }

        .slick-track {
            display: flex;
        }

        .slick-list {
            padding-right: grid(3);
            overflow: visible !important;
            @include sm {
                padding-right: gridCustom(16, 7);
            }
            @include vm {
                padding: 0 32px;
            }

            &:before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background: #051838;
                left: -100%;
                z-index: 2;
                @include vm {
                    display: none;
                }
            }
        }
    }

    &--block {
        margin: 0 60px;
        @include md {
            margin: 0 32px;
        }
        @include sm {
            width: 100%;
            max-width: 380px;
        }
        @include vm {
            margin: 0 8px;
            width: 100%;
            text-align: center;
        }

        p {
            font-size: 20px;
            line-height: 32px;
            color: #ffffff;
            margin-top: 14px;
            @include lg {
                font-size: 16px;
                line-height: 24px;
            }
            @include vm {
                padding: 0 5px;
            }
        }
    }

    &--name {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.14em;
        text-transform: uppercase;
        color: #50c878;
        @include lg {
            font-size: 10px;
            line-height: 14px;
        }
    }

    &--tag {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.14em;
        text-transform: uppercase;
        color: #50c878;
        padding: 6px 16px;
        background: #1c4d4b;
        border-radius: 12px;
        margin-top: 24px;
        @include lg {
            font-size: 10px;
            line-height: 14px;
            margin-top: 20px;
            padding: 5px 16px;
        }
        @include sm {
            padding: 3px 16px;
        }
        @include vm {
            margin-top: 16px;
        }
    }
}

.callback {
    &--text {
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
        margin: 16px auto 32px auto;
        max-width: 1130px;
        @include lg {
            font-size: 16px;
            line-height: 24px;
            margin: 12px 0 20px 0;
            max-width: 810px;
        }
        @include md {
            max-width: gridMobile(12);
            margin: 8px 0 20px 0;
        }
        @include sm {
            max-width: gridCustom(16, 14);
        }
        @include vm {
            max-width: 100%;
            margin: 8px 0 12px 0;
        }
    }

    &--main {
        > p {
            font-family: "Oranienbaum", sans-serif;
            font-size: 32px;
            line-height: 40px;
            text-align: center;
            color: #e7d186;
            text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
            margin-bottom: 20px;
            @include lg {
                font-size: 24px;
                line-height: 28px;
                margin-bottom: 16px;
            }
            @include md {
                font-size: 20px;
                line-height: 24px;
                margin-bottom: 12px;
            }
        }

        width: grid(9);
        text-align: center;
        @include md {
            width: gridMobile(12);
        }
        @include sm {
            width: gridCustom(16, 14);
        }
        @include vm {
            width: 100%;
        }

        .custom--select {
            width: 100%;
            margin: 0 auto;
        }

        .inputs--wrap {
            width: 100%;
            margin-top: 24px;
            margin-bottom: 32px;
            @include lg {
                margin: 16px 0 24px 0;
            }
            @include sm {
                margin: 16px 0;
            }
            @include vm {
                flex-wrap: wrap;
                margin: 12px 0;
            }

            > div {
                width: calc(33.33333% - 8px);
                @include vm {
                    width: 100%;
                    margin-bottom: 12px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        &-wrap {
            padding: 40px 0 60px 0;
            background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #000712;
            border: 1px solid #52739a;
            border-radius: 20px;
            @include lg {
                padding: 28px 0 40px 0;
            }
            @include md {
                padding: 20px 0 32px 0;
            }
            @include sm {
                padding: 20px 0 28px 0;
            }
            @include vm {
                border-radius: 14px;
                padding: 16px 12px 24px 12px;
                text-align: center;
            }
        }

        .more--btn {
            background: #50c878;
            color: #051838;
            &:hover {
                color: #50c878;
                background: transparent;
            }
        }
    }
}

.video {
    &--slider {
        &-wrap {
            position: relative;
            .slick-list {
                overflow: visible !important;
                @include sm {
                    padding: 0;
                }
                &:before {
                    content: "";
                    position: absolute;
                    display: block;
                    width: 100%;
                    height: 100%;
                    background: #051838;
                    left: -100%;
                    z-index: 2;
                    @include sm {
                        display: none;
                    }
                }
            }
            video {
                width: 100%;
                height: 100%;
                object-fit: cover;
                @include sm {
                    min-height: 162px;
                }
                @include vm {
                    min-height: auto;
                }
            }
            .container {
                position: relative;
                @include md {
                    position: static;
                }
            }
            .slider--left {
                @media (max-width: 767px) {
                    left: 16px;
                }
                @include vm {
                    left: 4px;
                }
            }
            .slider--right {
                @media (max-width: 767px) {
                    right: 16px;
                }
                @include vm {
                    right: 4px;
                }
            }
        }
        &-main {
            @include mdFix {
                max-width: 240px;
                margin: auto;
            }
            .slick-slide {
                max-width: 240px;
            }
        }
    }

    &--block {
        position: relative;
        margin: 0 8px;
        @include md {
            margin: 0 4px;
        }
        @include mdFix {
            width: 100%;
            margin: 0 2px;
        }

        .video--play {
            width: 60px;
            height: 60px;
            opacity: 0;
            visibility: hidden;
            transition: 1.5s opacity ease, 0.3s background-color ease;
            @include lg {
                width: 40px;
                height: 40px;
                svg {
                    width: 14px;
                    height: 12px;
                }
            }
        }

        > .video--main {
            display: flex;
            min-height: 343px;
            @include lg {
                min-height: 220px;
            }
            @include md {
                min-height: auto;
            }
            @include sm {
                height: auto;
                width: 100%;
                margin: 0 2px;
            }
        }

        .no-video {
            background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #051838;
            border: 1px solid #52739a;
            overflow: hidden;
            position: relative;
            @include sm {
                width: 98%;
            }
            &--title {
                font-family: Oranienbaum;
                font-weight: normal;
                font-size: 32px;
                line-height: 40px;
                text-align: center;
                color: #e7d186;
                padding: 0 0 40px 0;
                margin: 20px auto 0;
                display: block;
                max-width: 90%;
                &:hover {
                    color: #50c878;
                }
                @include lg {
                    font-size: 24px;
                    line-height: 28px;
                    padding-bottom: 28px;
                    margin-top: 16px;
                }
                @include md {
                    font-size: 20px;
                    line-height: 24px;
                    margin-top: 0;
                }
                @include sm {
                    font-size: 20px;
                    line-height: 24px;
                    padding: 0 0 29px 0;
                }
                @include mdFix {
                    margin-top: 10px;
                    padding: 0px 0 29px;
                }
            }
            &--image {
                margin-top: 20px;
                img {
                    max-height: 180px;
                    @include md {
                        margin-top: 20px;
                    }
                    @include vm {
                        max-height: 100px;
                        width: 100%;
                    }
                }
            }
            &--counter {
                position: absolute;
                font-size: 32px;
                line-height: 40px;
                text-align: center;
                color: #051838;
                background: #50c878;
                border-radius: 50%;
                width: 52px;
                height: 52px;
                font-family: Oranienbaum;
                font-weight: normal;
                padding-top: 6px;
                transform: translateX(-50%);
                top: 53px;
                left: 50%;
                @include lg {
                    width: 36px;
                    height: 36px;
                    top: 40px;
                    font-size: 22px;
                    line-height: 25px;
                }
                @include md {
                    font-size: 20px;
                    line-height: 23px;
                    width: 32px;
                    height: 32px;
                    padding-top: 4px;
                    top: 30px;
                }
                @include sm {
                    width: 24px;
                    height: 24px;
                    font-size: 14px;
                    line-height: 16px;
                    top: 22px;
                }
            }
        }
    }
}

.video-modal-window .fancybox-content {
    max-width: 712px;
    width: 100%;
    max-height: 500px;
    .fancybox-button--close {
        right: 0;
        z-index: 1;
        position: absolute;
    }
}
