@import "../mixins";

.profile {
    .lk-btn {
        @include lg {
            padding: 0 40px;
        }
    }

    &__wrap {
        max-width: 1128px;
        margin: 0 auto;
    }

    .custom-select-opener {
        @include md {
            padding: 8px 62px 8px 12px;
            font-size: 12px;
        }
    }
}

.profile-personal {
    &__title {
        font-family: "Oranienbaum";
        font-weight: 400;
        font-size: 32px;
        line-height: 40px;
        color: #ffffff;
        text-shadow: 0px 0px 4px rgba(0, 0, 0, 0.8);
        margin-bottom: 20px;

        @include lg {
            margin-bottom: 16px;
            font-size: 24px;
            line-height: 28px;
        }

        @include md {
            margin-bottom: 12px;
            font-size: 20px;
            line-height: 24px;
        }
    }

    &__section {
        margin-bottom: 32px;

        @include lg {
            margin-bottom: 20px;
        }

        @include md {
            margin-bottom: 16px;
        }

        @include sm {
            margin-bottom: 20px;
        }

        .inputs--wrap {
            @include mdFix {
                flex-direction: column;
            }
        }
    }

    &__selects {
        .select-block {
            width: 100%;
            border-radius: 12px;
            z-index: 4;

            &:not(:last-child) {
                margin-right: 16px;

                @include md {
                    margin-right: 8px;
                }

                @include mdFix {
                    margin-right: 0;
                    margin-bottom: 12px;
                }
            }

            .custom-select-opener,
            .custom-select-option {
                font-size: 11px;
                line-height: 16px;
            }
        }

        .custom-select-container.is-open .custom-select-panel {
            max-height: 200px;
            overflow: visible;
        }

        .simplebar-track {
            background: #f2f2f2;
            right: 25px;
            top: 15px;
            bottom: 15px;

            .simplebar-scrollbar::before {
                border-radius: 0;
                background: #e7d186;
                left: 0;
                right: 0;
                opacity: 1;
                top: 0;
                bottom: 0;
            }
        }
    }

    &__inputs {
        .inputs--block {
            width: 100%;
            border-radius: 12px;
            z-index: initial;

            &:not(:last-child) {
                margin-right: 16px;

                @include md {
                    margin-right: 8px;
                }

                @include mdFix {
                    margin-right: 0;
                    margin-bottom: 12px;
                }
            }

            &.filled {
                input {
                    border-color: #e7d186;
                    color: #e7d186;
                    font-size: 12px;
                    line-height: 16px;

                    @include lg {
                        font-size: 10px;
                        line-height: 12px;
                    }
                }

                label {
                    top: 10px;

                    @include lg {
                        top: 3px;
                    }
                }
            }
        }

        .inputs--block {
            input {
                padding: 22px 20px 14px;

                @include lg {
                    padding: 16px 20px 8px;
                    font-size: 10px;
                    line-height: 12px;
                }

                @include md {
                    padding: 15px 20px 5px;
                }
            }

            label {
                width: 100%;
                text-align: center;
                font-size: 10px;
                line-height: 12px;
            }
        }

        .date-block {
            input {
                @include lg {
                    padding: 12px 20px 12px;
                }

                @include md {
                    padding: 10px 20px 10px;
                }
            }

            label {
                width: 90%;
                justify-content: end;
            }

            .datepicker.filled~label {
                color: #e7d186;

                @include lg {
                    top: -3px;
                }
            }
        }
    }

    &__radio {
        margin-top: 32px;

        @include lg {
            margin-top: 20px;
        }

        &-label {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.16em;
            text-transform: uppercase;
            color: #e7d186;
            margin-left: 28px;
            position: relative;
            margin-right: 12px;
            cursor: pointer;

            &::after {
                content: "";
                border: 1px solid #e7d186;
                position: absolute;
                left: -28px;
                width: 16px;
                height: 16px;
                display: block;
                border-radius: 50%;
                top: 0;
            }

            &::before {
                content: "";
                background: transparent;
                width: 8px;
                height: 8px;
                position: absolute;
                left: -24px;
                top: 4px;
                border-radius: 50%;
            }
        }

        &-input {
            position: absolute;
            width: 0;
            height: 0;
            overflow: hidden;

            &:checked+label::before {
                background: #e7d186;
            }
        }

        &.inputs--wrap {
            @include mdFix {
                flex-direction: row;
            }
        }
    }

    &__submit {
        padding: 0 70px;
    }
}

.profile-about {
    padding-bottom: 44px;
    margin-bottom: 44px;
    border-bottom: 1px solid #52739a;

    @include lg {
        margin-bottom: 32px;
        padding-bottom: 32px;
    }

    @include md {
        margin-bottom: 28px;
        padding-bottom: 28px;
    }

    @include sm {
        margin-bottom: 24px;
        padding-bottom: 24px;
    }

    @include mdFix {
        margin-bottom: 20px;
        padding-bottom: 20px;
    }

    &__photo {
        max-width: 244px;

        @include lg {
            max-width: 176px;
            margin-right: 50px;
        }

        @include sm {
            max-width: 158px;
            width: 100%;
            height: 158px;
            margin-right: 48px;
        }

        @include mdFix {
            height: auto;
            margin-right: 0;
            max-width: 100%;
            display: flex;
            align-items: center;
            padding-bottom: 16px;
            border-bottom: 1px solid #52739a;
            margin-bottom: 16px;
        }

        &-input {
            display: none;
        }

        &-label {
            position: absolute;
            width: 60px;
            height: 60px;
            cursor: pointer;
            top: 50%;
            left: 50%;
            color: #50c878;
            transform: translate(-50%, -50%);

            @include lg {
                width: 40px;
                height: 40px;

                svg {
                    width: 40px;
                    height: 40px;
                }
            }

            &:hover {
                color: #e7d186;
            }
        }

        &-wrap {
            position: relative;
            margin-bottom: 26px;
            border-radius: 50%;
            overflow: hidden;
            border: 2px solid #fff;
            aspect-ratio: 1/1;

            @include lg {
                margin-bottom: 20px;
            }

            @include mdFix {
                max-width: 95px;
                margin-right: 20px;
                margin-bottom: 0;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
    }

    &__wrap {
        margin-bottom: 44px;

        @include lg {
            margin-bottom: 32px;
        }

        @include md {
            margin-bottom: 28px;
        }

        @include sm {
            margin-bottom: 24px;
        }

        @include mdFix {
            flex-direction: column;
            margin-bottom: 20px;
        }
    }

    &__info {
        max-width: 816px;
        width: 100%;
    }

    &__nik {
        margin-bottom: 32px;

        @include lg {
            margin-bottom: 20px;
        }

        @include sm {
            flex-direction: column;
            margin-bottom: 12px;
        }

        .inputs--block {
            width: 100%;
            max-width: 400px;
            margin-right: 16px;
            border-radius: 8px;

            @include lg {
                max-width: 284px;
            }

            @include sm {
                max-width: 100%;
                margin-bottom: 12px;
            }
        }
    }

    &__textarea.inputs--textarea {
        border-radius: 8px;

        textarea {
            height: 146px;
            padding: 20px;
            text-align: left;

            @include sm {
                height: 80px;
                padding: 12px;
            }

            &:focus~label {
                left: 20px;
                top: 5px;
                transform: none;
            }
        }

        &.filled label {
            left: 20px;
            top: 5px;
            transform: none;
        }

        label {
            left: 20px;
            top: 20px;
            transform: none;

            @include sm {
                left: 12px;
                top: 12px;
            }
        }
    }

    &__warn {
        color: #52739a;

        &-text {
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;

            @include lg {
                font-size: 14px;
                line-height: 16px;
            }

            @include sm {
                font-size: 12px;
                line-height: 16px;
            }

            &_big {
                font-weight: 700;
                margin-bottom: 4px;
            }

            &_error {
                font-size: 14px;
                display: none;

                @include lg {
                    font-size: 12px;
                }

                &.active {
                    margin: 0px 0px 12px 0px;
                    color: #E84E1D;
                    display: block;

                    @include lg {
                        margin: 0px 0px 8px 0px;
                    }
                }
            }
        }

        &-ico {
            margin-right: 16px;
        }



    }

    .inputs--block label,
    .inputs--textarea label {
        @include lg {
            font-size: 10px;
            line-height: 12px;
        }
    }
}

.profile-personal__section {
    @media screen and (max-width: 1280px) {
        .inputs--block input {
            padding: 12px 20px 5px;

        }

        .inputs--block label {
            font-size: 10px;
            line-height: 14px;
        }
    }
}