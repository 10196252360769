@import "profile";
@import "chat.scss";
@import "basket";
@import "friends";
@import "../mixins";

.lk {
    @include lg {
        // padding-bottom: 66px;
    }

    &__wrap {
        padding-top: 80px;
    }

    .header--logo {
        @include md {
            margin-right: auto;
        }
    }

    .header--menu {
        @include sm {
            margin-left: 20px;
        }

        @include mdFix {
            margin-left: 12px;
        }
    }

    .header {
        @include lg {
            width: 110px;
        }

        @include md {
            width: 100%;

            .lk-soc__item .lk-menu__link {
                padding: 0;
            }
        }
    }
}

.lk-container {
    margin: 0 auto;
    max-width: 1331px;

    @include lg {
        max-width: 1036px;
    }

    @include md {
        max-width: 880px;
    }

    @include sm {
        max-width: 1024px;
        padding: 0 57px;
    }

    @include mdFix {
        max-width: 100%;
    }

    @include vm {
        padding: 0 16px;
    }
}

.lk-not-main {
    .lk-banner {
        margin-bottom: 44px;

        @include lg {
            margin-bottom: 32px;
        }

        @include md {
            margin-bottom: 28px;
        }

        @include sm {
            margin-bottom: 24px;
        }

        @include mdFix {
            margin-bottom: 20px;
        }
    }
}

.lk-main {
    &__greet {
        font-family: "Oranienbaum";
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #ffffff;
        margin-bottom: 20px;

        @include lg {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 8px;
        }

        @include md {
            font-size: 16px;
            line-height: 24px;
        }

        @include mdFix {
            text-align: center;
        }
    }

    &__descr {
        font-size: 20px;
        line-height: 32px;
        color: #ffffff;

        @include lg {
            font-size: 16px;
            line-height: 24px;
        }

        @include mdFix {
            text-align: center;
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;

        &-item {
            padding: 21px 22px;
            border: 2px solid #50c878;
            border-radius: 20px;
            width: calc(33% - 8px);
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-bottom: 16px;
            position: relative;

            &:hover {
                background: #50c878;

                .lk-main__list-title {
                    color: #051838;
                }

                .lk-main__list-count {
                    color: #051838;
                    border: 1px solid #051838;
                }

                .lk-main__list-mess {
                    color: #051838;
                    border: 1px solid #051838;
                }

                .lk-main__list-mess_new {
                    background: #051838;
                }

                .lk-main__list-count_new {
                    background: #051838;
                }
            }

            @include lg {
                border: 1px solid #50c878;
            }

            @include md {
                width: calc(33% - 4px);
                margin-bottom: 8px;
            }

            @include sm {
                width: calc(50% - 4px);
            }

            @include mdFix {
                width: 100%;
                min-height: 94px;
            }

            &:not(:nth-child(3n)) {
                margin-right: 16px;

                @include md {
                    margin-right: 8px;
                }

                @include sm {
                    margin-right: 0;
                }
            }

            &:not(:nth-child(2n)) {
                @include sm {
                    margin-right: 8px;
                }

                @include mdFix {
                    margin-right: 0;
                }
            }
        }

        &-title {
            font-family: "Oranienbaum";
            font-weight: 400;
            font-size: 24px;
            line-height: 32px;
            color: #e7d186;

            @include lg {
                font-size: 20px;
                line-height: 28px;
            }

            @include md {
                font-size: 16px;
                line-height: 24px;
            }
        }

        &-count {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            text-align: center;
            color: #50c878;
            border: 1px solid #50c878;
            width: 72px;
            height: 72px;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;

            @include lg {
                width: 52px;
                height: 52px;
                font-size: 14px;
                line-height: 16px;
            }

            &_new {
                background: #50c878;
                border-radius: 32px;
                font-weight: 500;
                font-size: 10px;
                line-height: 16px;
                text-align: center;
                color: #ffffff;
                padding: 0 9px;
                position: absolute;
                top: 12px;
                right: -4px;
                transform: translateY(-50%);

                @include lg {
                    top: 8px;
                    right: -6px;
                }
            }
        }

        &-mess {
            padding: 9px 25px 9px 16px;
            border-radius: 16px;
            border: 1px solid #50c878;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.16em;
            text-transform: uppercase;
            color: #50c878;
            position: relative;

            @include lg {
                font-size: 10px;
                line-height: 14px;
                padding: 8px 25px 8px 16px;
            }

            &:not(:last-child) {
                margin-bottom: 4px;

                @include lg {
                    margin-bottom: 8px;
                }
            }

            &-wrap {
                position: absolute;
                right: 22px;
            }

            &_count {
                margin-left: 12px;
                font-size: 16px;
                line-height: 20px;

                @include lg {
                    font-size: 14px;
                    line-height: 16px;
                }
            }

            &_new {
                background: #50c878;
                border-radius: 32px;
                font-weight: 500;
                font-size: 10px;
                line-height: 16px;
                text-align: center;
                color: #ffffff;
                padding: 0 9px;
                position: absolute;
                top: 50%;
                right: -12px;
                transform: translateY(-50%);
            }
        }
    }
}

.lk-banner {
    padding: 24px;
    background: #e7d186;
    border-radius: 20px;

    @include lg {
        padding: 20px 16px;
    }

    @include mdFix {
        padding: 8px 12px;
    }

    &__text {
        width: 100%;
    }

    &__subtitle {
        font-size: 16px;
        line-height: 20px;
        color: #051838;

        @include lg {
            font-size: 14px;
            line-height: 16px;
        }

        @include md {
            font-size: 12px;
            line-height: 16px;
        }

        @include mdFix {
            text-align: center;
        }
    }

    &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: 28px;
        color: #051838;
        border-bottom: 1px solid #051838;
        padding-bottom: 8px;
        margin-bottom: 8px;

        @include lg {
            font-size: 16px;
            line-height: 24px;
        }

        @include mdFix {
            text-align: center;
        }
    }

    &__ico {
        margin-right: 16px;
        margin-top: 4px;

        @include lg {
            margin-right: 11px;
        }

        @include mdFix {
            display: none;
        }
    }
}

.lk-menu {
    @include md {
        display: flex;
        position: fixed;
        left: 0;
        bottom: 0;
        background: #e7d186;
        width: 100%;
        padding: 8px 15px;
    }

    @include mdFix {
        padding: 4px;
    }

    &__item {
        border: 1px solid #051838;
        border-radius: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        min-height: 80px;
        min-width: 96px;

        &:hover {
            background: #fff;
            border-color: #fff;
        }

        @include lg {
            min-width: 86px;
            min-height: 68px;
        }

        @include md {
            width: 100%;
            min-height: 40px;
            border-radius: 4px;
            min-width: auto;
        }

        &:not(:last-child) {
            margin-bottom: 4px;

            @include md {
                margin-bottom: 0;
                margin-right: 8px;
            }

            @include mdFix {
                margin-right: 2px;
            }
        }

        &_home {
            display: none;

            @include md {
                display: flex;
            }
        }

        &_friends,
        &_mess {
            @include md {
                display: none;
            }
        }
    }

    &__link {
        padding: 8px 4px;
        display: flex;
        align-items: center;
        flex-direction: column;
        width: 100%;
        height: 100%;

        @include lg {
            padding: 4px;
        }

        @include md {
            position: relative;
        }

        @include mdFix {
            padding: 4px 0;
        }

        &_soc {
            display: none;

            @include md {
                display: flex;
                flex-direction: row;
                padding: 4px;

                .lk-menu__link-ico {
                    display: block;
                    margin-right: 0px;
                    margin-bottom: 0;
                    flex: 0 0 26px;
                    height: 20px;


                    svg {
                        max-width: 100%;
                        object-fit: contain;
                    }
                }

                .lk-menu__link-count {
                    display: block;
                    padding: 0 3px;
                    min-width: 12px;
                    height: 12px;
                    font-size: 8px;
                    line-height: 12px;
                }
            }
        }

        &_md {
            display: none;

            @include md {
                display: flex;
            }
        }
    }

    &__link-count {
        background: #f2f2f2;
        border-radius: 32px;
        font-weight: 500;
        font-size: 10px;
        line-height: 16px;
        text-align: center;
        color: #051838;
        padding: 0 11px;

        &_green {
            background: #50c878;
            color: #fff;
        }

        @include md {
            display: none;
        }

        &_md {
            font-size: 10px;
            line-height: 16px;
            text-align: center;
            color: #051838;
            padding: 0px 4px;
            background: #f2f2f2;
            border-radius: 32px;
            margin-left: 10px;
        }
    }

    &__link-ico {
        margin-bottom: 6px;

        @include lg {
            margin-bottom: 0;
            max-height: 24px;
        }

        @include md {
            display: none;
        }

        &_md {
            @include md {
                width: 21px;
            }
        }
    }

    &__link-name {
        font-weight: 500;
        font-size: 12px;
        line-height: 16px;
        text-align: center;
        color: #051838;
        margin-bottom: 8px;

        @include lg {
            margin-bottom: 4px;
        }

        @include md {
            margin-bottom: 0;
            font-size: 10px;
            line-height: 16px;
        }
    }
}

.lk-soc {
    &__item:first-child {
        margin-right: 24px;

        // @include mdFix {
        //     margin-right: 12px;
        // }
    }
}

.lk-avatar {
    @include md {
        margin-left: 28px;
    }

    @include mdFix {
        margin-left: 24px;
    }

    &__image {
        border: 2px solid #ffffff;
        border-radius: 50%;
        display: block;
        overflow: hidden;
        width: 52px;
        height: 52px;

        @include md {
            width: 28px;
            height: 28px;
        }
    }

    &__logout {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        color: #051838;
        margin-top: 16px;
        display: block;

        @include md {
            display: none;
        }
    }
}

.lk-btn {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    min-height: 56px;
    font-family: "Oranienbaum";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    border-radius: 12px;
    background: #50c878;
    color: #051838;
    border: 2px solid #50c878;

    &:hover {
        background: transparent;
        color: #50c878;
    }

    @include lg {
        min-height: 42px;
        font-size: 16px;
        line-height: 20px;
        padding: 0 34px;
        border-radius: 10px;
    }

    @include md {
        min-height: 36px;
        padding: 0 26px;
        font-size: 14px;
        line-height: 14px;
        border-width: 1px;
        border-radius: 8px;
    }
}

.no-events {
    border: 1px solid #e7d186;
    border-radius: 20px;
    padding: 60px;

    @include lg {
        padding: 40px;
    }

    @include mdFix {
        padding: 24px 23px;
    }

    &__link {
        padding: 0 44px;
    }

    &__wrap {
        display: flex;

        @include mdFix {
            justify-content: center;
        }
    }

    &__title {
        font-family: "Oranienbaum";
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #ffffff;
        margin-bottom: 20px;

        @include lg {
            font-size: 20px;
            line-height: 28px;
            margin-bottom: 16px;
        }

        @include md {
            font-size: 16px;
            line-height: 24px;
        }

        @include mdFix {
            text-align: center;
        }
    }
}

.lk-events {
    &__list-item {
        @include mdFix {
            flex-direction: column;
            align-items: center;
        }

        &:not(:last-child) {
            border-bottom: 1px solid #52739a;
            padding-bottom: 32px;
            margin-bottom: 32px;

            @include lg {
                margin-bottom: 20px;
                padding-bottom: 20px;
            }

            @include md {
                margin-bottom: 16px;
                padding-bottom: 16px;
            }

            @include sm {
                margin-bottom: 12px;
                padding-bottom: 12px;
            }
        }

        &_archive {
            .lk-events__list {
                &-leader {
                    background: #52739a;
                    color: #051838;
                }

                &-name {
                    color: #52739a;
                }

                &-date {
                    border: none;

                    &_start {
                        background: #52739a;
                        padding-right: 4px;
                    }

                    &_day {
                        background: #52739a;
                        padding-left: 4px;
                    }
                }
            }
        }
    }

    &__list-info {
        @include mdFix {
            margin-bottom: 16px;
        }
    }

    &__list-date {
        display: flex;
        border: 1px solid #ffffff;
        margin-left: 12px;
        border-radius: 20px;

        @include mdFix {
            margin-top: 12px;
        }

        &_start {
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            color: #051838;
            border-radius: 20px 0 0 20px;
            background: #fff;
            padding: 1px 8px 1px 12px;
            display: flex;
            align-items: center;

            @include lg {
                font-size: 14px;
                line-height: 16px;
            }

            @include sm {
                font-size: 12px;
                line-height: 16px;
            }
        }

        &_day {
            font-size: 16px;
            line-height: 20px;
            display: flex;
            align-items: center;
            text-align: right;
            color: #051838;
            background: #50c878;
            border-radius: 0px 20px 20px 0px;
            padding: 0 12px 0 8px;
            display: flex;
            align-items: center;

            @include lg {
                font-size: 14px;
                line-height: 16px;
            }

            @include sm {
                font-size: 12px;
                line-height: 16px;
            }
        }
    }

    &__list-tags {
        @include mdFix {
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__list-btn {
        padding: 0 47px;

        @include mdFix {
            min-width: 140px;
            padding: 0;
        }

        &_notbg {
            background: transparent;
            color: #50c878;
            padding: 0 24px;
            margin-left: 16px;

            &:hover {
                background: #50c878;
                color: #051838;
            }

            @include mdFix {
                margin-left: 8px;
                min-width: 140px;
                padding: 0;
            }
        }

        &_sent {
            padding: 0 24px;
            margin-left: 16px;
            color: #52739a;
            border: 2px solid #52739a;
            background: none;

            &:hover {
                color: #52739a;
            }

            @include mdFix {
                margin-left: 8px;
                min-width: 140px;
                padding: 0;
            }
        }
    }

    &__list-leader {
        padding: 6px 12px;
        background: #1c4d4b;
        border-radius: 20px;
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        color: #50c878;

        @include lg {
            letter-spacing: 0.22em;
            font-size: 10px;
            line-height: 14px;
            padding: 5px 10px;
        }

        @include sm {
            padding: 3px 8px;
        }

        @include mdFix {
            margin-top: 12px;
        }
    }

    &__list-level {
        border: 1px solid #e7d186;
        border-radius: 14px;
        padding: 5px 12px;
        margin-right: 12px;

        @include lg {
            padding: 4px 12px;
        }

        @include sm {
            margin-right: 8px;
            padding: 2px 8px;
        }

        @include mdFix {
            margin-top: 12px;
        }

        >span {
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.16em;
            text-transform: uppercase;
            color: #50c878;
            margin-left: 12px;

            @include lg {
                font-size: 10px;
                line-height: 14px;
            }
        }
    }

    &__list-name {
        font-family: "Oranienbaum";
        font-weight: 400;
        font-size: 24px;
        line-height: 32px;
        color: #e7d186;
        margin-bottom: 12px;
        display: block;

        &:hover {
            color: #50c878;
        }

        @include lg {
            font-size: 20px;
            line-height: 28px;
        }

        @include md {
            font-size: 16px;
            line-height: 24px;
        }

        @include mdFix {
            text-align: center;
            margin-bottom: 0;
        }
    }

    &__list-status {
        font-weight: 700;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        color: #051838;
        padding: 6px 12px;
        background: #e7d186;
        border-radius: 14px;
        margin-right: 12px;

        @include lg {
            font-size: 10px;
            line-height: 14px;
            padding: 5px 10px;
            letter-spacing: 0.22em;
        }

        @include sm {
            padding: 3px 8px;
            margin-right: 8px;
        }

        @include mdFix {
            margin-top: 12px;
        }

        &_going {
            background: #50c878;
        }

        &_closed {
            background: #52739a;
        }
    }
}