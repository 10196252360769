@import "../mixins";

.partners {
    .container {
        @include sm {
            max-width: 1024px;
            padding: 0px 57px;
        }
        @include vm {
            padding: 0 16px;
        }
    }
    &__descr {
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        text-align: center;
        color: #ffffff;
        margin-bottom: 67px;
        @include lg {
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 40px;
        }
        @include sm {
            margin-bottom: 28px;
        }
        @include mdFix {
            margin: 0 4px 24px;
        }
    }
    &__container {
        padding-bottom: 80px;
        @include lg {
            padding-bottom: 58px;
        }
        @include md {
            padding-bottom: 44px;
        }
        @include sm {
            padding-bottom: 38px;
        }
        @include vm {
            padding-bottom: 32px;
        }
    }
    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 32px;
        @include lg {
            margin-bottom: 20px;
        }
        @include md {
            margin-bottom: 16px;
        }
        @include sm {
            margin-bottom: 12px;
        }
    }
    &__card {
        background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%);
        border: 1px solid #52739a;
        border-radius: 20px;
        padding: 44px 32px 28px;
        margin-bottom: 16px;
        width: calc(20% - 16px);
        position: relative;
        &::after {
            content: "";
            pointer-events: none;
            position: absolute;
            width: calc(100% + 2px);
            height: calc(100% + 2px);
            left: -1px;
            right: -1px;
            top: -1px;
            bottom: -1px;
            border-radius: 20px;
            transition: all 0.3s ease 0s;
            border: 2px solid transparent;
        }
        &:hover {
            border-color: transparent;
            &::after {
                border-color: #e7d186;
            }
        }
        &:not(:nth-child(5n)) {
            margin-right: 16px;
            @include md {
                margin-right: 8px;
            }
            @include sm {
                margin-right: 8px;
            }
            @include vm {
                margin-right: 4px;
            }
        }
        &:not(:nth-child(4n)) {
            @include md {
                margin-right: 8px;
            }
            @include vm {
                margin-right: 4px;
            }
        }
        @include lg {
            padding: 32px 24px 36px;
        }
        @include md {
            margin-bottom: 8px;
            width: calc(25% - 8px);
        }
        @include sm {
            padding: 28px 16px 20px;
            width: calc(33% - 8px);
        }
        @media (max-width: 580px) {
            width: calc(50% - 8px);
        }
        @include vm {
            margin-bottom: 4px;
            margin-right: 4px;
        }
        &-img {
            border-radius: 8px;
            overflow: hidden;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
        }
        &-title {
            font-family: Oranienbaum;
            font-weight: normal;
            font-size: 20px;
            line-height: 28px;
            display: flex;
            word-break: break-word;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: #e7d186;
            min-height: 64px;
            margin-top: 28px;
            @include lg {
                font-size: 16px;
                line-height: 20px;
                min-height: 40px;
                margin-top: 24px;
            }
            @include sm {
                font-size: 14px;
                line-height: 14px;
                margin-top: 16px;
            }
        }
    }
}
