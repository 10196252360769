.basket {

    // .basket__wrap
    &__wrap {
        gap: 120px;
        justify-content: space-between;
        align-items: start;

        @include lg {
            gap: 90px;
        }

        @include md {
            gap: 64px;
        }

        @include sm {
            flex-direction: column;
            gap: 24px;
        }

        @include mdFix {
            gap: 20px;
        }
    }

    #form-basket {
        .inputs--block {


            .iText {
                color: #52739A;
                border-color: #52739A;
            }
        }
    }


    // .basket__num
    &__num {
        font-family: 'Oranienbaum';
        font-size: 32px;
        line-height: calc(40 / 32);
        color: #50C878;
        margin-top: 36px;

        @include lg {
            margin-top: 28px;
            font-size: 24px;
        }

        @include md {
            font-size: 20px;
            margin-top: 12px;
        }

        @include sm {
            margin-top: 16px;
        }

        @include xsm {
            margin-top: 12px;
        }
    }

    // .basket__btn
    &__btn.lk-btn {
        margin-top: 40px;
        width: 269px;
        margin-bottom: 116px;

        @include lg {
            width: 209px;
            margin-bottom: 130px;
            padding: 0;
            margin-top: 28px;
        }

        @include md {
            margin-bottom: 25px;
            width: 175px;
        }

        @include sm {
            margin-bottom: 130px;
        }

        @include xsm {
            margin-bottom: 8px;
        }
    }
}

.product-basket {
    flex: 1 1 auto;

    @include sm {
        width: 100%;
    }

    // .product-basket__items
    &__items {}

    // .product-basket__item
    &__item {
        justify-content: space-between;
        position: relative;
        border-bottom: 1px solid #52739a;
        padding-bottom: 32px;
        padding-right: 200px;

        @include lg {
            padding-bottom: 24px;
            padding-right: 160px;
        }

        @include md {
            padding-bottom: 16px;
        }

        @include sm {
            padding-bottom: 12px;
            padding: 0px 0px 12px 0px;
        }

        &:not(:first-child) {
            margin-top: 32px;

            @include lg {
                margin-top: 24px;
            }

            @include md {
                margin-top: 16px;
            }

            @include sm {
                margin-top: 12px;
            }
        }
    }

    // .product-basket__section
    &__section {
        font-weight: 700;
        font-size: 12px;
        line-height: calc(16 / 12);
        letter-spacing: 0.16em;
        text-transform: uppercase;
        color: #50c878;

        @include lg {
            font-size: 10px;
        }
    }

    // .product-basket__name
    &__name {
        margin-top: 4px;
        font-family: "Oranienbaum";
        font-size: 24px;
        line-height: calc(32 / 24);
        color: #e7d186;
        display: inline-flex;
        transition: all 0.3s ease 0s;

        @include lg {
            font-size: 20px;
        }

        @include md {
            font-size: 16px;
        }

        @include sm {
            padding-right: 20px;
        }

        @media (any-hover: hover) {
            &:hover {
                color: #50c878;
            }
        }
    }

    // .product-basket__bottom
    &__bottom {
        display: flex;
        justify-content: space-between;
        gap: 32px
    }

    // .product-basket__tags
    &__tags {
        margin-top: 16px;
        gap: 16px 6px;
        flex-wrap: wrap;

        @include sm {
            gap: 6px;
            flex-wrap: wrap;
            // flex-direction: column;
            align-items: start;
        }

        .event--leading,
        .event--leading-online,
        .event--date {
            margin: 0;

            .event--date-time {}

            .event--date-days {}
        }

        .event--leading-online {}
    }

    // .product-basket__control
    &__control {
        align-items: flex-end;
        justify-content: flex-end;
        padding-right: 52px;
        flex: 0 0 auto;
        position: absolute;
        right: 0;
        bottom: 32px;

        @include lg {
            padding-right: 48px;
            bottom: 24px;
        }

        @include md {
            bottom: 16px;
        }

        @include sm {
            bottom: 12px;
            padding-right: 0;
            position: static;
        }

        .event--leading {
            display: inline-flex;
            width: max-content;
        }

        .event--price-old {
            margin-top: 12px;

            @include lg {}
        }

        .event--price-price {}

        .event--leading {
            line-height: calc(16 / 12);
            margin: 0px 0px 12px 0px;
        }

        .event--price-old {
            margin: 0;
        }
    }

    // .product-basket__del
    &__del {
        width: 20px;
        height: 20px;
        position: absolute;
        top: 0;
        right: 0;

        svg {
            width: 100%;
            height: 100%;
            stroke: #52739a;
        }
    }
}

#form-basket {
    margin-top: 44px;

    @include lg {
        margin-top: 32px;
    }

    @include md {
        margin-top: 28px;
    }

    @include sm {
        margin-top: 24px;
    }

    @include mdFix {
        margin-top: 20px;
    }

    .inputs--wrap {
        gap: 16px;

        @include lg {
            gap: 12px;
        }

        @include mdFix {
            flex-direction: column;
            gap: 8px;
        }

        &:not(:last-child) {
            margin-bottom: 16px;

            @include lg {
                margin-bottom: 12px;
            }

            @include md {
                margin-bottom: 16px;
            }

            @include sm {
                margin-bottom: 8px;
            }
        }
    }

    .register--inputs-block .inputs--block input {
        @include md {
            padding: 14px 11px 4px;
        }
    }

    .register--inputs-block .inputs--block label {
        @include md {
            font-size: 10px;
        }
    }

    .register--inputs-block .inputs--block {
        margin: 0;
    }

    .register--inputs-block .inputs--block:not(:last-child) {
        margin: 0;
    }
}

.order-basket {
    flex: 0 0 400px;
    background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #051838;
    border: 1px solid #52739a;
    border-radius: 20px;
    padding: 24px;
    position: sticky;
    top: 68px;

    @include lg {
        flex: 0 0 286px;
        padding: 20px;
    }

    @include md {
        flex: 0 0 269px;
    }

    @include sm {
        width: 100%;
        text-align: center;
    }

    // .order-basket__title
    &__title {
        font-weight: 700;
        font-size: 20px;
        line-height: calc(32 / 20);
        color: #50c878;
        text-align: center;

        @include lg {
            font-size: 16px;
        }
    }

    // .order-basket__list
    &__list {
        margin-top: 28px;
        padding-top: 28px;
        border-top: 1px solid #52739a;

        @include lg {
            margin-top: 20px;
            padding-top: 20px;
        }
    }

    // .order-basket__item
    &__item {
        font-weight: 700;
        justify-content: space-between;

        &:not(:last-child) {
            margin-bottom: 8px;
        }

        &:last-child {
            div {
                color: #50c878;
                font-size: 20px;
                line-height: calc(32 / 20);

                @include lg {
                    font-size: 16px;
                }
            }
        }

        div {
            color: #52739a;

            &:nth-child(1) {
                font-size: 16px;
                line-height: calc(20 / 16);

                @include lg {
                    font-size: 14px;
                }
            }

            &:nth-child(2) {
                font-size: 20px;
                line-height: calc(28 / 20);

                @include lg {
                    font-size: 16px;
                }
            }
        }
    }

    // .order-basket__btn
    &__btn {
        margin-top: 28px;
        width: 100%;

        @include lg {
            margin-top: 20px;
        }

        @include sm {
            max-width: 109px;
            margin: 20px auto 0px auto;
        }

        @include mdFix {
            max-width: 240px;
        }
    }

    .inpCheck {
        margin-top: 28px;

        @include lg {
            margin-top: 20px;
        }

        @include md {
            margin-right: -4px;
        }

        p {
            white-space: nowrap;

            @include lg {
                white-space: normal;
            }
        }
    }
}