@import "../mixins";

.detail-partner {
    .container.smallx2 {
        @include md {
            padding: 0 16px;
        }
    }
    &__info {
        background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #000712;
        border: 1px solid #52739a;
        border-radius: 20px;
        padding: 60px 104px;
        display: flex;
        @include lg {
            padding: 40px 75px;
        }
        @include md {
            padding: 32px 55px;
        }
        @include sm {
            flex-direction: column;
            align-items: center;
            padding: 28px 42px;
        }
        @include mdFix {
            padding: 24px;
        }
    }
    &__title {
        font-family: Oranienbaum;
        font-weight: normal;
        font-size: 32px;
        line-height: 40px;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        color: #e7d186;
        margin-bottom: 8px;
        @include lg {
            font-size: 24px;
            line-height: 28px;
            margin-bottom: 6px;
        }
        @include md {
            font-size: 20px;
            line-height: 24px;
        }
        @include sm {
            text-align: center;
            margin-top: 28px;
            font-size: 24px;
            line-height: 32px;
        }
        @include vm {
            margin-top: 24px;
        }
    }
    &__image {
        min-width: 400px;
        margin-right: 120px;
        @include lg {
            min-width: 285px;
            margin-right: 90px;
        }
        @include md {
            margin-right: 60px;
        }
        @include sm {
            margin-right: 0;
            min-width: auto;
            width: 158px;
        }
        @include vm {
            width: 132px;
        }
    }
    &__post {
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #e7d186;
        margin-bottom: 24px;
        @include lg {
            font-size: 14px;
            line-height: 16px;
        }
        @include md {
            margin-bottom: 16px;
        }
        @include sm {
            text-align: center;
            margin-bottom: 12px;
            font-size: 12px;
            line-height: 16px;
        }
    }
    &__descr {
        font-weight: normal;
        color: #ffffff;
        text-shadow: 0px 0px 2.8801px rgba(0, 0, 0, 0.8);
        margin-bottom: 32px;
        font-size: 20px;
        line-height: 32px;
        @include lg {
            margin-bottom: 28px;
            font-size: 16px;
            line-height: 24px;
        }
        @include md {
            margin-bottom: 24px;
        }
        @include sm {
            margin-bottom: 20px;
        }
        > p {
            &:not(:last-child) {
                margin-bottom: 20px;
            }
            @include lg {
                font-size: 16px;
                line-height: 24px;
            }
            @include sm {
                text-align: center;
            }
        }
    }
    &__link {
        a {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #e7d186;
            border-bottom: 1px solid currentColor;
            padding-bottom: 6px;
            margin-bottom: 32px;
            &:hover {
                color: #50c878;
            }
            @include lg {
                font-size: 10px;
                line-height: 16px;
            }
        }
        @include sm {
            text-align: center;
        }
    }
    &__video {
        position: relative;
        margin-top: 80px;
        video {
            object-fit: cover;
            height: 392px;
            @include lg {
                height: 284px;
            }
            @include md {
                height: 260px;
            }
            @include sm {
                height: 212px;
            }
            @include vm {
                height: 180px;
            }
        }
        @include lg {
            margin-top: 58px;
        }
        @include md {
            margin-top: 44px;
        }
        @include sm {
            margin-top: 38px;
        }
        @include vm {
            margin-top: 32px;
        }
    }
}
