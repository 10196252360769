@import "../mixins";

.skull {
    &--pages {
        &-wrap {
            min-height: 500px;
            position: relative;
            background: radial-gradient(67.35% 67.35% at 50% 0, #083d79 0, #051838 100%), #051c38;
            margin-bottom: 44px;

            &.detail_event {
                min-height: 590px;

                @include lg {
                    min-height: 520px;
                }

                @include vm {
                    min-height: 350px;
                }
            }

            @include lg {
                min-height: 470px;
                margin-bottom: 32px;
            }

            @include md {
                margin-bottom: 28px;
            }

            @include sm {
                margin-bottom: 24px;
            }

            @include vm {
                margin-bottom: 20px;
                min-height: 300px;
            }
        }

        position: absolute;
        top: -65px;
        left: 48%;
        transform: translate(-50%, 0);
        min-width: 1978px;

        @include lg {
            left: 47.5%;
        }

        @include md {
            min-width: 1731px;
            top: 0;
            left: 47%;
        }

        @include sm {
            min-width: 1660px;
            left: 46.8%;
        }

        @include mdFix {
            left: 45%;
        }

        @include vm {
            min-width: 767px;
            top: 52px;
            left: 47%;
        }

        img {
            width: 100%;
            height: 100%;
        }
    }
}

.pages {
    &--title {
        width: 100%;

        h1 {
            margin: 0 32px;
            font-size: 44px;
            line-height: 52px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #e7d186;
            font-weight: normal;
            font-family: "Oranienbaum", sans-serif;
            text-align: center;

            @include lg {
                font-size: 40px;
                line-height: 48px;
                margin: 0 24px;
            }

            @include md {
                font-size: 36px;
                line-height: 44px;
            }

            @include sm {
                font-size: 28px;
                line-height: 32px;
            }

            @include vm {
                font-size: 24px;
                line-height: 28px;
                margin: 0;
                width: 100%;
                text-align: center;
            }
        }

        span {
            display: block;
            flex: 1 auto;
            height: 1px;
            background: #e7d186;

            @include sm {
                width: 115px;
            }

            @include mdFix {
                width: auto;
            }

            @include vm {
                display: none;
            }
        }

        &_white {
            color: #ffffff;
            background-color: transparent;
            display: block;
        }
    }

    &--bottom {
        position: absolute;
        bottom: 0;
        width: 100%;
    }

    &--question {
        position: absolute;
        left: 50%;
        transform: translate(-50%, 0);
        width: 144px;
        height: 144px;
        background: rgba(5, 24, 56, 0.5);
        border-radius: 50%;
        top: -68px;
        z-index: 2;

        &:hover {
            span {
                color: #e7d186;
            }

            svg {
                color: #e7d186;
            }
        }

        svg {
            color: #50c878;
            opacity: 0;
        }

        @include lg {
            width: 120px;
            height: 120px;
            top: -58px;

            svg {
                width: 29px;
                height: 29px;
            }
        }

        @include md {
            top: -8px;
        }

        @include mdFix {
            top: -24px;
        }

        @include vm {
            width: 95px;
            height: 95px;
            top: -4px;

            svg {
                display: none;
            }
        }

        span {
            display: block;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.21em;
            text-transform: uppercase;
            color: #50c878;
            margin-top: 12px;

            @include lg {
                font-size: 10px;
                line-height: 16px;
                letter-spacing: 0.26em;
            }

            @include md {
                line-height: 12px;
                letter-spacing: 0.2em;
            }

            @include vm {
                margin-top: 28px;
            }
        }

        a {
            width: 100%;
            height: 100%;
            padding: 0 42px;
            text-align: center;

            @include lg {
                padding: 0 39px;
            }

            @include md {
                padding: 0 34px;
            }

            @include vm {
                padding: 0;
            }
        }
    }

    &--desc {
        &-wrap {
            font-weight: normal;
            font-size: 20px;
            line-height: 32px;
            text-align: center;
            color: #ffffff;
            margin-bottom: -16px;

            @include lg {
                margin-bottom: -12px;
            }

            p {
                margin-bottom: 16px;

                @include lg {
                    margin-bottom: 12px;
                }
            }
        }
    }
}