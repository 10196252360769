@import "../mixins";

.reviews {
    color: #fff;
    &__descr {
        max-width: 1333px;
        margin: 0 auto;
        font-family: Oranienbaum;
        font-weight: normal;
        font-size: 32px;
        line-height: 40px;
        text-align: center;
        @include lg {
            font-size: 24px;
            line-height: 28px;
            max-width: 961px;
        }
        @include md {
            font-size: 20px;
            line-height: 24px;
        }
        @include vm {
            font-size: 18px;
            line-height: 20px;
        }
    }
    &__tags {
        margin-top: 24px;
        @include vm {
            justify-content: center;
        }
        &-item {
            display: flex;
            align-items: center;
            padding: 6px 16px;
            background: #1c4d4b;
            border-radius: 20px;
            font-weight: 700;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.16em;
            text-transform: uppercase;
            color: #50c878;
            margin-bottom: 4px;
            &:not(:last-child) {
                margin-right: 12px;
                @include lg {
                    margin-right: 8px;
                }
            }
            @include lg {
                padding: 5px 10px;
                font-size: 10px;
                letter-spacing: 0.22em;
                line-height: 14px;
            }
            @include sm {
                padding: 3px 8px;
            }
        }
    }
    &__warn {
        text-align: center;
        margin: 32px 0;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #50c878;
        @include lg {
            font-size: 10px;
            line-height: 16px;
            margin: 20px 0;
        }
        @include md {
            line-height: 12px;
            margin: 12px -1px;
        }
    }
    &__item {
        padding: 32px;
        border: 1px solid #e7d186;
        border-radius: 20px;
        &:not(:last-child) {
            margin-bottom: 20px;
            @include lg {
                margin-bottom: 16px;
            }
            @include md {
                margin-bottom: 8px;
            }
        }
        @include lg {
            padding: 20px 24px;
        }
        @include md {
            padding: 16px 24px;
        }
    }
    &__date {
        font-size: 16px;
        line-height: 20px;
        color: #e7d186;
        @include lg {
            font-size: 12px;
            line-height: 16px;
        }
        @include vm {
            text-align: center;
        }
    }
    &__name {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.14em;
        text-transform: uppercase;
        color: #50c878;
        margin-top: 24px;
        @include lg {
            font-size: 10px;
            line-height: 14px;
            margin-top: 20px;
        }
        @include md {
            margin-top: 16px;
        }
        @include sm {
            margin-top: 12px;
        }
        @include vm {
            text-align: center;
        }
    }
    &__text {
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        color: #ffffff;
        margin-top: 12px;
        @include lg {
            font-size: 14px;
            line-height: 20px;
            margin-top: 8px;
        }
        @include vm {
            text-align: center;
        }
    }
    &__wrapper {
        max-width: 1333px;
        margin: 0 auto;
        padding: 40px 0 60px;
        @include lg {
            padding: 28px 0 40px;
            max-width: 961px;
        }
        @include md {
            padding-bottom: 32px;
        }
        @include sm {
            padding-top: 20px;
            padding-bottom: 28px;
        }
        @include vm {
            padding-top: 16px;
            padding-bottom: 24px;
        }
    }
    &__link {
        text-align: center;
        a {
            @include sm {
                padding: 12px 27px;
            }
        }
    }
    &__form {
        padding-bottom: 80px;
        @include lg {
            padding-bottom: 58px;
        }
        @include md {
            padding-bottom: 44px;
        }
        @include sm {
            padding-bottom: 38px;
        }
        @include vm {
            padding-bottom: 32px;
        }
        .inputs--wrap .inputs--block {
            width: 100%;
        }
        button {
            background: #50c878;
            color: #051838;
        }
        .custom-select-option,
        .custom-select-opener > span {
            justify-content: center;
        }
    }
    &__block-inputs {
        > div:last-child {
            margin-left: 16px;
            @include vm {
                margin-left: 0;
            }
        }
    }
    &__textarea {
        height: 148px;
        @include lg {
            height: 108px;
        }
        @include vm {
            height: 88px;
        }
    }
    &__block-textarea.inputs--wrap {
        margin-top: 0;
        label {
            top: 20px;
        }
    }
    .pagination {
        @include lg {
            margin-top: 20px;
        }
        @include md {
            margin-top: 16px;
        }
        @include sm {
            margin-top: 0;
        }
        @include mdFix {
            margin-top: 12px;
        }
    }
    &__answer {
        padding-top: 32px;
        margin-top: 32px;
        border-top: 1px solid #52739a;
        width: calc(100% - 76px);
        margin-left: auto;
        @include lg {
            margin-top: 20px;
            padding-top: 24px;
            width: calc(100% - 68px);
        }
        @include sm {
            padding-top: 16px;
            margin-top: 16px;
        }
        @include vm {
            width: 100%;
            text-align: center;
        }
        &-name {
            font-weight: bold;
            font-size: 12px;
            position: relative;
            line-height: 16px;
            letter-spacing: 0.14em;
            text-transform: uppercase;
            color: #50c878;
            margin-bottom: 12px;
            @include lg {
                font-size: 10px;
                line-height: 14px;
            }
            @include vm {
                margin-bottom: 17px;
            }
            &::before {
                content: "";
                background: url(../pic/reviews-name-arrow.svg) center/cover;
                width: 30px;
                height: 20px;
                position: absolute;
                left: -59px;
                top: -5px;
                @include sm {
                    top: -3px;
                }
                @include mdFix {
                    top: -2px;
                    width: 22px;
                    height: 13px;
                }
                @include vm {
                    left: 4px;
                }
            }
        }
        &-descr {
            font-size: 20px;
            line-height: 32px;
            color: #ffffff;
            @include lg {
                font-size: 14px;
                line-height: 20px;
            }
        }
    }
}

.modal--feedback {
    width: 1128px;
    height: 492px;
    @include lg {
        width: 811px;
        height: 360px;
    }
    @include md {
        width: 658px;
        height: 352px;
    }
    @include sm {
        width: 570px;
        height: 342px;
    }
    @include mdFix {
        width: 288px;
        height: 360px;
    }
    &-success {
        width: 100%;
        height: 100%;
    }
    &-wrapper {
        width: 100%;
        height: 100%;
    }
}
