@import "../mixins";

.pagination {
    font-family: Oranienbaum;
    font-weight: normal;
    margin: 0 auto;
    font-size: 20px;
    line-height: 28px;
    color: #e7d186;
    display: flex;
    justify-content: center;
    align-items: center;
    // max-width: 1020px;
    // width: 100%;
    @include lg {
        // max-width: 735px;
        font-size: 16px;
        line-height: 20px;
    }
    @include md {
        // max-width: 604px;
        font-size: 14px;
        line-height: 14px;
    }
    @include sm {
        font-size: 16px;
        line-height: 28px;
        // max-width: 447px;
    }
    @include vm {
        font-size: 12px;
        line-height: 28px;
    }
    &--pages {
        margin: 0px 110px;
        @include lg {
            margin: 0px 60px;
        }
        @include sm {
            margin: 0;
        }
        &-list {
            display: flex;
            > li {
                width: 32px;
                height: 28px;
                display: inline-flex;
                align-items: center;
                justify-content: center;
                margin: 0px 40px;
                &._activ-page {
                    color: #50c878;
                }
                @include lg {
                    margin: 0px 28px;
                }
                @include md {
                    height: 14px;
                    &:last-child {
                        margin-right: 0;
                    }
                    &:first-child {
                        margin-left: 0;
                    }
                }
                @include xsm {
                    width: 20px;
                    margin: 0px 2px;
                }
                // &:not(:last-child) {
                //     margin-right: 80px;
                //     @include lg {
                //         margin-right: 56px;
                //     }
                //     @include sm {
                //         margin-right: 46px;
                //     }
                //     @include mdFix {
                //         margin-right: 30px;
                //     }
                // }
            }
        }
        &-link {
            &:hover,
            &.selected {
                color: #50c878;
            }
        }
    }
    &-left {
        &--link {
            display: flex;
            align-items: center;
            @include sm {
                width: 36px;
                height: 36px;
                justify-content: center;
            }
            span {
                margin-left: 32px;
                @include lg {
                    margin-left: 15px;
                    margin-right: auto;
                }
                @include sm {
                    display: none;
                }
            }
            svg {
                @include md {
                    height: 10px;
                }
                @include sm {
                    width: 6px;
                    height: 12px;
                    // margin-right: 20px;
                }
            }
        }
        &:hover {
            color: #50c878;
        }
    }
    &-right {
        &--link {
            display: flex;
            align-items: center;
            @include sm {
                width: 36px;
                height: 36px;
                justify-content: center;
            }
            span {
                margin-right: 32px;
                @include lg {
                    margin-left: auto;
                    margin-right: 15px;
                }
                @include sm {
                    display: none;
                }
            }
            svg {
                @include md {
                    height: 10px;
                }
                @include sm {
                    width: 6px;
                    height: 12px;
                    // margin-left: 20px;
                }
            }
        }
        &:hover {
            color: #50c878;
        }
    }
}

.show-more-cards {
    white-space: nowrap;
    @include lg {
        border-radius: 10px;
    }
    @include md {
        border-radius: 8px;
    }
    @include sm {
        margin-right: 30px;
        padding: 10px 13px;
    }
    @include mdFix {
        margin: 0;
        width: 100%;
        padding: 10px 13px;
        justify-content: center;
    }
}

.page-bottom {
    margin-top: 32px;
    @include lg {
        margin-top: 20px;
    }
    @include md {
        margin-top: 16px;
    }
    @include sm {
        margin-top: 12px;
    }
    @include mdFix {
        flex-direction: column;
    }
    .pagination {
        margin-left: auto;
        margin-right: 0;
        @include mdFix {
            margin: 12px 0px 0px 0px;
        }
    }
    .pagination--pages-list > li {
        @include lg {
            margin: 0px 16px;
        }
        @include md {
            margin: 0px 6px;
        }
    }
    .pagination--pages {
        @include md {
            margin: 0px 92px;
        }
        @include sm {
            margin: 0;
        }
    }
}
