.group {
  &--top {
    border: 1px solid #e7d186;
    border-radius: 20px;
    @include sm {
      flex-direction: column;
    }
  }

  &--bottom {
    padding: 0 gridCustom(15, 1);
    margin-top: 32px;
    @include lg {
      margin-top: 20px;
    }
    @include md {
      margin-top: 16px;
      padding: 0 56px;
    }
    @include sm {
      margin-top: 12px;
      padding: 0 40px;
    }
    @include vm {
      padding: 0 12px;
    }
  }

  &--wrap {
    //@include lg {
    //  padding-bottom: 28px;
    //}
    //@include md {
    //  padding-bottom: 24px;
    //}
    //@include sm {
    //  padding-bottom: 20px;
    //}
    //@include vm {
    //  padding-bottom: 16px;
    //}
  }

  &--image {
    @include lg {
      width: 78px;
      svg {
        width: 100%;
        height: auto;
      }
    }
    @include sm {
      width: 70px;
    }
    @include mdFix {
      width: 53px;
    }
  }

  &--block {
    margin: 0 10px;
    min-height: 180px;
    &:first-child {
      margin-left: 0;
      @include mdFix {
        margin-left: 10px;
      }
    }

    &:last-child {
      margin-right: 0;
      @include mdFix {
        margin-right: 10px;
      }
    }
    &-desc {
      margin-top: 12px;
      padding: 0 10%;
      text-align: center;
    }
    &-row {
      padding: 0 24px;
      @include mdFix {
        margin: -6px 0;
        padding: 0;
        &.row_2 {
          > div {
            &:first-child {
              width: 100%;
            }

            &:nth-child(2) {
              width: auto;
            }

            &:last-child {
              width: auto;
            }
          }
        }
        //justify-content: space-between;
      }

      &.row_1 {
        @include mdFix {
          > div {
            &:last-child {
              width: 100%;
            }
          }
        }

        &:before {
          content: "";
          width: 1px;
          height: 100%;
          position: absolute;
          right: -1px;
          background: #e7d186;
          top: 0;
          bottom: 0;
          @include sm {
            width: 100%;
            height: 1px;
            bottom: -1px;
            right: 0;
            left: 0;
            top: auto;
          }
          @include mdFix {
            left: 10px;
            right: 10px;
            width: auto;
          }
        }
      }
    }

    @include lg {
      min-height: 110px;
      margin: 0 10px;
    }
    @media screen and (max-width: 1340px) {
      margin: 10px;
      &:first-child {
        margin-left: 10px;
      }
      &:last-child {
        width: 100%;
        margin-right: 10px;
      }
    }
    @include sm {
      width: calc(33.3333% - 10px);
      margin: 0 auto;
      min-height: 103px;
      &:first-child {
        margin-left: auto;
      }
      &:last-child {
        width: calc(33.3333% - 10px);
        margin-right: auto;
      }
    }
    @include mdFix {
      width: auto;
      margin: 6px 10px;
      min-height: 86px;
      &:first-child {
        margin: 6px 10px;
      }
      &:last-child {
        width: auto;
        margin: 6px 10px;
      }
    }

    span {
      font-weight: bold;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.14em;
      text-transform: uppercase;
      color: #50c878;
      padding: 4px 16px;
      display: inline-flex;
      background: #1c4d4b;
      border-radius: 12px;
      margin-top: auto;
      @include lg {
        font-size: 10px;
        line-height: 14px;
        padding: 4px 12px;
      }
      @include mdFix {
        padding: 4px 10px;
      }
    }

    &-wrap {
      width: 50%;
      position: relative;
      padding: 32px 0;
      @include lg {
        padding: 20px 0;
      }
      @include md {
        padding: 16px 0;
      }
      @include sm {
        padding: 12px 0;
        width: 100%;
      }
      @include mdFix {
        padding: 12px 24px;
        margin: 0 -10px;
        width: auto;
      }
    }
  }

  &--title {
    &-main {
      font-size: 32px;
      line-height: 44px;
      color: #e7d186;
      font-family: "Oranienbaum", sans-serif;
      display: block;
      text-align: center;
      margin-bottom: 8px;
      @include lg {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 4px;
      }
      @include mdFix {
        margin-bottom: 0;
      }
    }
  }
}

.mbg {
  margin-bottom: -60px;
  @include lg {
    margin-bottom: -28px;
  }
  @include md {
    margin-bottom: -20px;
  }
  @include sm {
    margin-bottom: -16px;
  }
  @include vm {
    margin-bottom: -24px;
  }

  .itog {
    font-family: "Oranienbaum", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 32px;
    line-height: 40px;
    color: #50c878;
    width: calc(100% - 208px);
    margin-left: auto;
    @include lg {
      font-size: 24px;
      line-height: 28px;
      width: calc(100% - 152px);
    }
    @include lg {
      width: calc(100% - 112px);
    }
    @include sm {
      width: calc(100% - 123px);
    }
    @include vm {
      width: auto;
      font-size: 18px;
      line-height: 20px;
      text-align: center;
    }
  }

  &--image {
    @include lg {
      width: 78px;
      svg {
        width: 100%;
        height: auto;
      }
    }
    @include sm {
      width: 71px;
    }
    @include vm {
      width: 53px;
    }
  }

  &--title {
    font-family: "Oranienbaum", sans-serif;
    font-size: 44px;
    line-height: 52px;
    color: #e7d186;
    display: block;
    margin-bottom: 20px;
    @include lg {
      font-size: 32px;
      line-height: 44px;
      margin-bottom: 12px;
    }
    @include md {
      font-size: 28px;
      line-height: 36px;
    }
    @include sm {
      font-size: 24px;
      line-height: 32px;
    }
  }

  &--block {
    width: calc(50% - 60px);
    margin-bottom: 60px;
    @include lg {
      width: calc(50% - 44px);
      margin-bottom: 28px;
    }
    @include md {
      width: calc(50% - 32px);
      margin-bottom: 20px;
    }
    @include sm {
      width: 100%;
      margin-bottom: 16px;
    }
    @include vm {
      flex-direction: column;
      align-items: center;
      margin-bottom: 24px;
    }
  }

  &--text {
    width: calc(100% - 208px);
    margin-top: 24px;
    margin-left: auto;
    @include lg {
      margin-top: 12px;
      width: calc(100% - 152px);
    }
    @media screen and (max-width: 1300px) {
      width: calc(100% - 112px);
    }
    @include md {
      width: calc(100% - 112px);
    }
    @include sm {
      width: calc(100% - 123px);
    }
    @include vm {
      width: 100%;
      text-align: center;
      margin-top: 8px;
    }
  }
}

.abp {
  padding: 60px gridCustom(15, 1);
  background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%), #000712;
  border: 1px solid #52739a;
  border-radius: 20px;
  @include lg {
    border-radius: 16px;
    padding: 40px gridCustom(15, 1);
  }
  @include md {
    padding: 32px gridCustom(18, 1);
  }
  @include sm {
    padding: 28px 56px;
  }
  @include vm {
    padding: 28px 12px;
  }

  &--title {
    font-family: "Oranienbaum", sans-serif;
    font-size: 44px;
    line-height: 52px;
    letter-spacing: 0.04em;
    text-transform: uppercase;
    color: #e7d186;
    display: block;
    margin-bottom: 32px;
    @include lg {
      font-size: 40px;
      line-height: 48px;
      margin-bottom: 20px;
    }
    @include md {
      font-size: 36px;
      line-height: 44px;
      margin-bottom: 16px;
    }
    @include sm {
      font-size: 28px;
      line-height: 32px;
      margin-bottom: 12px;
    }
    @include vm {
      font-size: 24px;
      line-height: 28px;
      text-align: center;
    }
  }

  &--block {
    color: #ffffff;

    span {
      font-size: 32px;
      line-height: 40px;
      font-family: "Oranienbaum", sans-serif;
      display: block;
      margin-bottom: 20px;
      @include lg {
        font-size: 24px;
        line-height: 28px;
        margin-bottom: 16px;
      }
      @include md {
        font-size: 20px;
        line-height: 24px;
      }
      @include vm {
        font-size: 18px;
        line-height: 20px;
      }
    }
    @include vm {
      text-align: center;
    }
  }
}
