.modal-basket {

    // .modal-basket__wrap
    &__wrap {
        width: 712px;
        background: #E7D186;
        padding: 36px 60px 40px;
        text-align: center;
        color: #051838;

        @include lg {
            width: 509px;
            padding: 36px 76px 40px;
        }

        @include md {
            width: 436px;
            padding: 32px 56px;
        }

        @include sm {
            width: 406px;
            padding: 48px 56px 32px;
        }

        @include mdFix {
            width: 100%;
            height: 100vh;
            display: inline-flex;
            flex-direction: column;
            justify-content: center;
            padding: 16px;
        }
    }

    // .modal-basket__title
    &__title {
        font-family: Oranienbaum, sans-serif;
        font-size: 44px;
        line-height: calc(52 / 44);
        letter-spacing: 0.04em;
        text-transform: uppercase;

        @include lg {
            font-size: 40px;
        }

        @include md {
            font-size: 36px;
        }
    }

    // .modal-basket__btns
    &__btns {
        margin-top: 20px;
        gap: 24px;
        padding: 0px 44px;

        @include lg {
            padding: 0;
            gap: 20px
        }

        @include md {
            margin-top: 16px;
            gap: 12px;
        }

        @include sm {
            gap: 8px
        }

        .btn-n {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            padding: 0px;
            height: 56px;
            border-radius: 12px;
            font-family: Oranienbaum, sans-serif;
            font-size: 20px;
            transition: all 0.3s ease 0s;

            @include lg {
                height: 42px;
                font-size: 16px;
                border-radius: 10px;
            }

            @include md {
                height: 36px;
                font-size: 14px;
                border-radius: 8px;
            }

            &-default {
                background: #50C878;
                color: #051838;
                border: 2px solid #50C878;


                @media (any-hover: hover) {
                    &:hover {
                        color: #50C878;
                        background: none;
                    }
                }
            }

            &-dark {
                background: #051838;
                color: #FFFFFF;
                border: 2px solid #051838;

                @media (any-hover: hover) {
                    &:hover {
                        background: #50c878;
                        color: #051838;
                        border-color: #50c878;
                    }
                }
            }
        }
    }

    // .modal-basket__bottom
    &__bottom {
        margin-top: 40px;
        padding-top: 24px;
        border-top: 1px solid #000000;

        @include lg {
            margin-top: 28px;
            padding-top: 20px;
        }

        @include sm {
            padding: 20px 20px 0px 20px;
        }

        div {
            font-weight: 700;
            font-size: 20px;
            line-height: calc(28 / 20);

            @include lg {
                font-size: 16px;
            }
        }

        p {
            margin-top: 8px;
            font-size: 16px;
            line-height: calc(20 / 16);
            padding: 0px 20px;

            @include lg {
                font-size: 14px;
                padding: 0px 30px;
            }

            @include md {
                padding: 0px 20px;
            }

            @include sm {
                padding: 0;
            }
        }
    }
}