.modal {
    background: #ffffff;

    &--auth {
        background: #e7d186;
        max-width: 816px;
        padding: 36px 84px 40px;
        width: 100%;
        @include lg {
            max-width: 587px;
            padding: 36px 76px 40px;
        }
        @include md {
            max-width: 548px;
            padding: 32px 56px;
        }
        @include sm {
            max-width: 488px;
            padding: 38px 56px;
        }
        @include mdFix {
            max-width: 288px;
            padding: 32px;
        }
        @include vm {
            max-width: 100%;
            height: 100vh;
            padding: 160px 16px;
        }

        &-block {
            display: none;

            &.active {
                display: block;
            }
        }
        &-footer {
            margin-top: 44px;
        }
        &-pass {
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            margin-right: 24px;
            @include vm {
                font-size: 14px;
                margin-right: 8px;
            }
        }
        &-register {
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            &::before {
                content: "|";
                margin-right: 24px;
                font-size: 20px;
                @include vm {
                    font-size: 14px;
                    margin-right: 8px;
                }
            }
            @include vm {
                font-size: 14px;
            }
        }
    }

    &--enter {
        &-form {
            @include mdFix {
                flex-direction: column;
                .inputs--wrap {
                    flex-direction: column;
                }
            }
            .inputs--block {
                margin: 0 8px;
                background: #ffffff;
                border-radius: 12px;
                width: 100%;
                @include lg {
                    margin: 0 6px;
                }
                @include md {
                    margin: 0 4px;
                }
                @include mdFix {
                    margin-bottom: 8px;
                    &:last-child {
                        margin-bottom: 0;
                    }
                }
                &:not(:last-child) {
                    margin-bottom: 20px;
                    @include lg {
                        margin-bottom: 12px;
                    }
                }

                input {
                    color: #051838;
                    border: none;
                    width: 100%;
                }

                label {
                    color: #051838;
                }
            }

            .more--btn {
                background: #051838;
                color: #ffffff;
                border-color: #051838;
                margin: 32px 0 0 0;
                justify-content: center;
                @include lg {
                    margin-top: 20px;
                }
                @include md {
                    margin-top: 16px;
                }
                &:hover {
                    background: #50c878;
                    color: #051838;
                    border-color: #50c878;
                }
            }

            .inpCheck {
                color: #051838;
                @include lg {
                    margin-top: 20px;
                }
                @include md {
                    margin-top: 16px;
                }
                @include sm {
                    margin-top: 12px;
                }

                span {
                    &:before {
                        border-color: #051838;
                    }

                    &:after {
                        background-image: url(../pic/check_black.svg);
                    }
                }
                > p {
                    color: #051838;
                }
            }
        }
    }
}
