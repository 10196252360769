@import "my_reset";
@import "variables";
@import "mixins";
@import "fonts";

//libs
@import "components/datepicker";
@import "libs/fancy";
@import "libs/simple-bar";
@import "libs/slick";

// modals
@import "components/modal_auth";
@import "components/modal_callback";
@import "components/modal-forgot";
@import "components/modal_add_basket";

// components
@import "components/header";
@import "components/home";
@import "components/pages_top";
@import "components/psihotehnolog";
@import "components/online";
@import "components/about";
@import "components/pagination";
@import "components/detail-event";
@import "components/register";
@import "components/reviews";
@import "components/partners";
@import "components/detail-partner.scss";
@import "components/personall";
@import "components/question.scss";

@import "taplink.scss";
@import "404.scss";

// lk
@import "lk/index.scss";

body {
    background: $mainBg;
    font-family: "Noah", sans-serif;
    will-change: scroll-position;

    &._lock {
        overflow: hidden;
    }
}

section {
    p {
        font-size: 20px;
        line-height: 32px;
        color: #ffffff;

        @include lg {
            font-size: 14px;
            line-height: 20px;
        }
    }
}

.container {
    max-width: 1784px;
    margin: 0 auto;
    padding: 0 120px;

    @include lg {
        max-width: 1290px;
        padding: 0 88px;
    }

    @include md {
        max-width: 912px;
        width: 100%;
        padding: 0 16px;

        &--menu {
            max-width: 972px;
        }

        &.small {
            padding-left: 72px;
        }

        &.smallxR,
        &.smallxR2 {
            padding-right: 72px;
        }

        &.smallx2 {
            padding: 0 72px;
        }
    }

    @include sm {
        max-width: 1024px;
        padding: 0 57px;

        &.small {
            padding: 0 57px;
        }

        &.smfull {
            padding: 0 16px;
        }

        &.smallxR {
            padding: 0 16px;
        }

        &.smallx2 {
            padding: 0 56px;
        }
    }

    @include vm {
        padding: 0 16px;

        &.small {
            padding: 0 16px;
        }

        &.smallx2 {
            padding: 0 16px;
        }

        &.smallxR2 {
            padding-right: 16px;
        }
    }
}

.main {
    &--wrap {
        width: calc(100% - 120px);
        margin-left: auto;
        // overflow: hidden;

        @include lg {
            width: calc(100% - 91px);
        }

        @include md {
            width: 100%;
        }
    }
}

.footer {
    width: calc(100% - 120px);
    margin-left: auto;
    overflow: hidden;

    @include lg {
        width: calc(100% - 91px);
    }

    @include md {
        width: 100%;
        padding: 0 56px;
    }

    @include sm {
        padding: 0;
    }

    @include mdFix {
        border-top: 1px solid #50c878;
    }

    &--wrap {
        padding: 32px 0 80px 0;
        border-top: 1px solid #50c878;

        @include lg {
            padding: 20px 0 58px 0;
        }

        @include md {
            padding: 16px 0 44px 0;
        }

        @include sm {
            padding: 12px 0 38px 0;
        }

        @include mdFix {
            padding: 24px 0 32px 0;
            border-top: 0;
        }

        &-main {}
    }

    &--bottom {
        width: 100%;
        margin-top: 40px;
        font-weight: normal;
        font-size: 20px;
        line-height: 32px;
        color: #50c878;

        @include lg {
            margin-top: 28px;
            font-size: 14px;
            line-height: 20px;
        }

        @include md {
            margin-top: 24px;
        }

        @include sm {
            font-size: 12px;
            line-height: 16px;
            margin-top: 20px;
        }

        @include mdFix {
            order: 3;
            margin-top: 32px;
            flex-direction: column;
            text-align: center;
        }
    }

    &--btn {
        width: grid(3);
        margin-left: grid(1);

        @include md {
            align-items: flex-start;
        }

        @include sm {
            margin-left: 0;
            width: auto;
            flex-direction: row;
        }

        @include mdFix {
            order: 1;
            width: 100%;
            flex-direction: row;
            justify-content: space-between;
            margin-bottom: 12px;
        }

        a {
            margin: 0;
            text-align: center;
            justify-content: center;

            +a {
                margin-top: 18px;

                @include md {
                    margin-top: 12px;
                }

                @include sm {
                    margin-top: 0;
                    margin-left: 4px;
                }
            }

            @include mdFix {
                width: calc(50% - 6px);
                padding: 10px 0;
            }
        }
    }

    &--contacts {
        margin-left: auto;

        @include mdFix {
            order: 2;
            margin: 0;
            width: 100%;
            align-items: center;
        }

        &-icons a {
            display: none;
            color: #e7d186;
            margin-top: 16px;

            &:first-child {
                margin-right: 24px;
            }

            @include vm {
                display: flex;
            }
        }
    }

    &--phone {
        font-family: "Oranienbaum", sans-serif;
        font-size: 36px;
        line-height: 60px;
        letter-spacing: 0.05em;
        color: #50c878;

        @include lg {
            font-size: 28px;
            line-height: 36px;
        }

        @include sm {
            font-size: 20px;
            line-height: 28px;
        }
    }

    &--mail {
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.2em;
        text-transform: uppercase;
        color: #50c878;
        margin-top: 12px;

        @include lg {
            font-size: 10px;
            line-height: 16px;
        }

        @include sm {
            line-height: 12px;
            margin-top: 8px;
        }
    }

    &--copy {
        width: grid(4);
        max-width: 704px;

        @include md {
            width: gridCustom(10, 2);
            max-width: 140px;
        }

        @include sm {
            max-width: 110px;
        }

        @include mdFix {
            max-width: 100%;
            width: 100%;
            margin-bottom: 8px;
        }
    }

    &--policy {
        width: grid(3);
        margin-left: grid(1.1);

        @include md {
            width: gridCustom(10, 1.5);
        }

        @include mdFix {
            width: 100%;
            margin-left: 0;
        }
    }

    &--db {
        margin-left: auto;
        text-align: right;

        @include mdFix {
            width: 100%;
            margin-top: 16px;
        }

        a {
            @include mdFix {
                align-items: center;
                flex-direction: column-reverse;
            }

            p {
                transition: 0.3s all ease;
                color: #e7d186;

                @include mdFix {
                    br {
                        display: none;
                    }
                }
            }

            svg {
                color: #e7d186;

                @include lg {
                    width: 62px;
                    height: 43px;
                }

                @include md {
                    width: 46px;
                    height: 32px;
                    margin-left: 20px;
                }

                @include mdFix {
                    margin: 0 0 8px 0;
                    width: 35px;
                    height: 24px;
                }
            }
        }

        &:hover {
            a {
                p {
                    color: #50c878;
                }

                svg {
                    color: #50c878;
                }
            }
        }

        svg {
            margin-left: 16px;
        }
    }

    &--menu {
        font-weight: bold;
        font-size: 20px;
        line-height: 32px;
        color: #50c878;
        width: grid(7.5);
        max-width: 763px;

        @include lg {
            font-size: 14px;
            line-height: 24px;
        }

        @include md {
            max-width: gridCustom(17, 7.5);
            margin-bottom: 36px;
        }

        @include sm {
            display: none;
        }

        li {
            margin-bottom: 20px;
            page-break-inside: avoid;
            break-inside: avoid;

            @include md {
                margin-bottom: 12px;
            }
        }

        ul {
            margin-bottom: -20px;
            column-count: 3;

            @include sm {
                column-count: 2;
            }
        }
    }

    &--app {
        width: auto;
        display: flex;
        margin-left: grid(0.5);
        margin-right: grid(0.5);

        @include md {
            margin-left: auto;
            margin-right: 0;
        }

        @include mdFix {
            margin: 16px auto;
        }
    }

    &--google-play {
        display: block;
        background: url(../pic/google-play.svg) center center/contain no-repeat;
        width: 155px;
        height: 52px;
        margin-right: 29px;

        @include lg {
            width: 107px;
            height: 36px;
        }

        @include md {
            width: 88px;
            height: 28px;
            margin-right: 8px;
        }
    }

    &--app-store {
        display: block;
        background: url(../pic/app-store.svg) center center/contain no-repeat;
        width: 155px;
        height: 52px;

        @include lg {
            width: 107px;
            height: 36px;
        }

        @include md {
            width: 88px;
            height: 28px;
        }
    }
}

.m {
    &-wrap {
        margin: 0 -6px;

        @include sm {
            margin: 0;
            width: 100%;
            flex-direction: column;
        }
    }

    &-block {
        margin: 0 6px;

        @include sm {
            width: 100%;
            margin: 0;
        }
    }
}

.video {
    &--play {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 54px;
        height: 54px;
        border-radius: 50%;
        background: #e7d186;
        transition: 0.3s all ease;
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            background: #50c878;
        }

        @include vm {
            width: 32px;
            height: 32px;

            svg {
                width: 9px;
                height: 11px;
            }
        }

        &.hide {
            opacity: 0;
            visibility: hidden;
            z-index: 0;
        }

        svg {
            color: #051838;
            margin-left: 4px;
        }
    }
}

.section {
    &--title {
        @include vm {
            text-align: center;
        }

        &-small {
            font-weight: bold;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #50c878;
            margin-bottom: 12px;

            @include lg {
                font-size: 10px;
                line-height: 16px;
                margin-bottom: 8px;
            }

            @include md {
                line-height: 12px;
            }
        }

        &-big {
            font-size: 44px;
            line-height: 52px;
            letter-spacing: 0.04em;
            text-transform: uppercase;
            color: #e7d186;
            font-family: "Oranienbaum", sans-serif;

            @include lg {
                font-size: 40px;
                line-height: 48px;
            }

            @include md {
                font-size: 36px;
                line-height: 44px;
            }

            @include sm {
                font-size: 28px;
                line-height: 32px;
            }

            @include mdFix {
                font-size: 24px;
                line-height: 28px;
            }
        }
    }

    &--main {
        margin-bottom: -16px;

        @include md {
            margin-bottom: -8px;
        }

        @include vm {
            margin-bottom: 0;
            padding: 0;
        }
    }
}

.inpCheck {
    display: inline-block;
    vertical-align: top;
    position: relative;
    z-index: 1;
    padding: 0 0 0 32px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 20px;
    color: #e7d186;
    margin-top: 32px;

    p {
        color: #e7d186;
        font-size: 16px;
        line-height: 20px;

        @include lg {
            font-size: 12px;
            line-height: 16px;
        }
    }

    label {
        &.parsley-error {
            span {
                &:before {
                    border-color: #e84e1d;
                }
            }
        }
    }

    a {
        text-decoration: underline;

        &:hover {
            color: #50c878;
        }
    }

    @include lg {
        font-size: 12px;
        line-height: 16px;
        margin-top: 24px;
    }

    @include md {
        margin-top: 16px;
    }

    @include vm {
        margin-top: 8px;
    }

    &.parsley-error {
        span {
            &:before {
                border-color: #e84e1d;
            }
        }
    }

    &.active {
        color: #c7ab6f;

        span {
            &:before {
                border-color: #c7ab6f;
            }
        }
    }

    input {
        position: absolute;
        z-index: -1;
        left: 0;
        top: 0;
        opacity: 0;

        &:disabled {
            +span {
                &:before {
                    background: #f3f3f3;
                }
            }
        }

        &:checked {
            +span {
                //&:before {
                //  background: #6271BE;
                //  border-color: #6271BE;
                //}

                &:after {
                    transform: scale(1) translate(0, -50%);
                }
            }
        }
    }

    span {
        cursor: pointer;

        &:before {
            content: "";
            width: 16px;
            height: 16px;
            border: 1px solid #e7d186;
            position: absolute;
            z-index: 1;
            left: 0;
            top: 50%;
            transform: translate(0, -50%);
            transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
        }

        &:after {
            content: "";
            background-image: url("../pic/check.svg");
            background-repeat: no-repeat;
            background-position: center;
            position: absolute;
            z-index: 1;
            left: 3px;
            top: 50%;
            transform: scale(0) translate(0, -50%);
            display: block;
            transition: all 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
            width: 11px;
            height: 8px;
        }
    }
}

.gh {
    &:hover {
        color: #e7d186;
    }
}

.more {
    &--btn {
        font-family: "Oranienbaum", sans-serif;
        font-size: 20px;
        line-height: 28px;
        color: #50c878;
        padding: 12px 42px;
        border: 2px solid #50c878;
        border-radius: 12px;
        margin-top: 40px;
        display: inline-flex;

        @include lg {
            margin-top: 28px;
            font-size: 16px;
            line-height: 20px;
            padding: 9px 32px 9px;
            border-radius: 10px;
        }

        @include md {
            font-size: 14px;
            line-height: 14px;
            margin-top: 24px;
            border-width: 1px;
            padding: 10px 24px 10px;
            border-radius: 8px;
        }

        @include sm {
            margin-left: 0;
            margin-top: 20px;
        }

        @include vm {
            text-align: center;
            display: block;
        }

        &.form--btn {
            margin: 0 auto 0 auto;
        }

        &.mt32 {
            margin: 32px auto 0 auto;
        }
    }
}

.hg {
    &:hover {
        background: #50c878;
        color: #051838;
    }
}

.custom-select-container {
    position: relative;
    box-sizing: border-box;
    transition: none;
}

.custom-select-container * {
    box-sizing: border-box;
}

.custom-select-container.is-disabled {
    opacity: 0.333;
}

.custom-select-opener {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #e7d186;
    display: block;
    cursor: pointer;
    width: 100%;
    background: #000712;
    border: 2px solid #e7d186;
    box-sizing: border-box;
    border-radius: 12px;
    padding: 18px 62px 18px 26px;
    position: relative;
    transition: none;
    text-align: center;

    @include lg {
        padding: 10px 62px 10px 26px;
        font-size: 12px;
        line-height: 16px;
    }

    @include md {
        padding: 11px 62px 11px 26px;
        border-width: 1px;
        font-size: 10px;
        line-height: 14px;
        border-radius: 8px;
    }

    @include mdFix {
        padding: 11px 37px 11px 12px;
        align-items: center;
    }

    @include vm {
        width: 100%;
        padding: 11px 30px 11px 13px;

        .select--text {
            text-align: left;
        }
    }

    &:before {
        content: "";
        display: block;
        background-image: url("../pic/select.svg");
        background-repeat: no-repeat;
        background-position: center;
        position: absolute;
        right: 22px;
        top: 50%;
        transform: translate(0, -50%);
        width: 18px;
        height: 11px;
        background-size: cover;

        @include mdFix {
            width: 13px;
            height: 8px;
            right: 14px;
        }

        @include vm {
            width: 10px;
            right: 10px;
            height: 5px;
        }
    }

    .select--price {
        &-price {
            font-size: 16px;
            line-height: 20px;
            text-transform: capitalize;
            letter-spacing: 0;

            @include mdFix {
                font-size: 14px;
                line-height: 16px;
                text-align: right;
            }

            @include vm {
                margin-left: 0;
            }
        }

        &-old {
            font-size: 16px;
            line-height: 20px;
            text-transform: capitalize;
            letter-spacing: 0;

            @include mdFix {
                font-size: 12px;
                line-height: 16px;
                text-align: right;
            }

            @include vm {
                margin-left: 0;
            }
        }
    }

    .select {
        &--wrapper {
            @include mdFix {
                display: flex;
                flex-direction: column;
                flex: 1 0 35%;
            }
        }
    }

    >span {
        display: flex;
        justify-content: space-between;
        align-items: center;

        @include mdFix {
            display: flex;
        }
    }
}

.custom-select-container select {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
}

.custom-select-panel {
    max-height: 0;
    overflow: hidden;
    background: #000712;
    position: absolute;
    top: calc(100% - 2px);
    width: 100%;
    border-width: 0 2px 2px 2px;
    border-style: solid;
    border-color: #e7d186;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    visibility: hidden;
    z-index: 2;

    @include md {
        border-width: 0 1px 1px 1px;
    }

    .select--price {
        &-price {
            font-size: 16px;
            line-height: 20px;
            text-transform: capitalize;
            letter-spacing: 0;
        }

        &-old {
            font-size: 16px;
            line-height: 20px;
            text-transform: capitalize;
            letter-spacing: 0;
        }
    }
}

.custom-select-container.is-open {
    .custom-select-panel {
        max-height: 400px;
        overflow-y: auto;
        visibility: visible;

        &:before {
            content: "";
            display: block;
            width: calc(100% - 40px);
            height: 1px;
            background: #e7d186;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            margin: 0 auto;

            @include mdFix {
                width: calc(100% - 22px);
            }
        }
    }

    .custom-select-opener {
        border-bottom-color: transparent;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
    }
}

#add_group_chat {
    .custom-select-container.is-open {
        .custom-select-panel {
            max-height: 68px;
        }
    }
}

.custom-select-option {
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    letter-spacing: 0.2em;
    text-transform: uppercase;
    color: #e7d186;
    margin: 14px 0;
    padding: 10px 64px 10px 26px;
    text-align: center;
    display: flex;
    justify-content: space-between;

    @include mdFix {
        padding: 0 33px 0 13px;
        align-items: center;
    }

    @include lg {
        font-size: 12px;
        line-height: 16px;
        margin: 12px 0;
    }

    @include md {
        font-size: 10px;
        line-height: 14px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    &:hover {
        background: rgba(82, 115, 154, 0.2);
        border-radius: 4px;
    }

    &:last-child {
        margin-bottom: 0;
    }

    .select--text,
    .select--wrapper {
        position: relative;
        z-index: -1;
        text-align: left;
        flex: 1;

        @include mdFix {
            display: flex;
            flex-direction: column;
        }
    }

    .select--wrapper {
        text-align: right;
    }

    .select--price-price {
        @include sm {
            font-size: 14px;
            line-height: 16px;
        }

        @include mdFix {
            text-align: right;
        }
    }

    .select--price-old {
        @include sm {
            font-size: 12px;
            line-height: 16px;
        }

        @include mdFix {
            text-align: right;
        }
    }

    cursor: pointer;
}

.custom-select-optgroup>.custom-select-option {
    padding-left: 2em;
}

.custom-select-optgroup::before {
    content: attr(data-label);
    display: block;
    padding: 0.5em;
    color: #888;
}

.slider {
    &--btn {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background: rgba(80, 200, 120, 0.3);
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        z-index: 2;
        display: none;

        &.slick-arrow {
            display: flex;
        }

        @include lg {
            width: 40px;
            height: 40px;

            svg {
                width: 9px;
                height: 14px;
            }
        }

        @include sm {
            width: 32px;
            height: 32px;

            svg {
                width: 7px;
                height: 11px;
            }
        }

        @include mdMax {
            &:hover {
                background: rgb(80, 200, 120);
            }
        }

        &:active {
            background: rgb(80, 200, 120);
        }
    }

    &--left {
        left: 30px;
        padding-right: 2px;

        @include lg {
            left: 26px;
        }

        @include md {
            left: -36px;
        }
    }

    &--right {
        right: 30px;
        transform: translate(0, -50%) rotate(180deg);
        padding-right: 2px;

        @include lg {
            right: 26px;
        }

        @include md {
            right: -36px;
        }
    }
}

.event {
    &--card {
        &-wrap {
            justify-content: space-between;
            padding: 24px 40px 26px 32px;
            border-radius: 20px;
            overflow: visible;
            width: calc(33.33333% - 8px);
            margin-bottom: 16px;
            position: relative;
            background: linear-gradient(180deg, #051838 0%, rgba(0, 7, 18, 0.5) 100%);
            border: 1px solid #52739a;

            @include lg {
                padding: 18px 20px;
                border-radius: 12px;
            }

            @include md {
                margin-bottom: 8px;
                padding: 16px;
                width: calc(33.33333% - 4px);
            }

            @include sm {
                width: calc(50% - 4px);
            }

            @include mdFix {
                padding: 12px;
            }

            @include vm {
                width: 100%;
                margin: 0;
                position: relative;
            }

            &_archive {
                .event--archive {
                    padding: 6px 24px;
                    background: #e84e1d;
                    border-radius: 14px;
                    font-weight: bold;
                    font-size: 12px;
                    line-height: 16px;
                    letter-spacing: 0.16em;
                    text-transform: uppercase;
                    color: #fff;

                    @include lg {
                        padding: 4px 24px;
                    }

                    @include sm {
                        padding: 2px 24px;
                    }

                    @media (max-width: 1348px) {
                        font-size: 10px;
                        line-height: 14px;
                    }
                }

                .event--type,
                .event--name,
                .event--leading-online,
                .event--price-price,
                .event--card-more {
                    color: #52739a;

                    &:hover {
                        color: #52739a;
                    }
                }

                .event--leading {
                    background: #52739a;
                    color: #051838;

                    &-online {
                        border: 1px solid #52739a;
                    }
                }
            }
        }

        &-more {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.16em;
            text-transform: uppercase;
            color: #50c878;

            &:hover,
            &:active {
                color: #e7d186;
            }

            @include lg {
                font-size: 10px;
                line-height: 14px;
            }
        }
    }

    &--type {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.16em;
        text-transform: uppercase;
        color: #50c878;

        @include lg {
            font-size: 10px;
            line-height: 14px;
        }

        @include md {
            line-height: 12px;
        }
    }

    &--date {
        background: #ffffff;
        border: 1px solid #ffffff;
        border-radius: 20px;
        font-size: 14px;
        line-height: 16px;

        @include sm {
            font-size: 12px;
        }

        &-time {
            font-weight: bold;
            color: #051838;
            font-size: 16px;
            line-height: 20px;
            padding: 0 8px 0 12px;

            @include lg {
                font-size: 14px;
                line-height: 16px;
                padding: 1px 4px 1px 8px;
            }
        }

        &-days {
            color: #051838;
            padding: 5px 10px 5px 6px;
            background: #50c878;
            border-radius: 0px 20px 20px 0px;

            @include lg {
                padding: 3px 10px 3px 6px;
            }

            @include sm {
                padding: 1px 10px 1px 6px;
            }
        }
    }

    &--right {
        &-top {
            margin-bottom: 24px;
            width: 100%;

            @include lg {
                margin-bottom: 16px;
            }

            @include vm {
                margin-bottom: 8px;
            }
        }
    }

    &--name {
        font-size: 32px;
        line-height: 40px;
        color: #e7d186;
        font-family: "Oranienbaum", sans-serif;
        display: block;
        min-height: 80px;

        &:hover {
            color: #50c878;
        }

        @include lg {
            min-height: 60px;
            font-size: 24px;
            line-height: 28px;
        }

        @include md {
            font-size: 20px;
            line-height: 24px;
            min-height: 48px;
        }

        @include vm {
            font-size: 18px;
            line-height: 20px;
        }
    }

    &--leading {
        font-weight: bold;
        font-size: 12px;
        line-height: 16px;
        letter-spacing: 0.14em;
        text-transform: uppercase;
        color: #50c878;
        padding: 6px 16px;
        display: inline-flex;
        background: #1c4d4b;
        border-radius: 12px;
        margin-top: 12px;

        @include lg {
            font-size: 10px;
            padding: 4px 10px;
            line-height: 14px;
            margin-top: 12px;
            align-items: center;
        }

        @include mdFix {
            padding: 2px 8px;
            margin-top: 12px;
        }

        &-online {
            margin-top: 12px;
            margin-left: 6px;
            padding: 5px 12px;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #50c878;
            box-sizing: border-box;
            border-radius: 20px;
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.16em;
            text-transform: uppercase;
            color: #e7d186;

            svg {
                margin-right: 12px;

                @include lg {
                    width: 17px;
                    height: 12px;
                    margin-right: 6px;
                }
            }

            @include lg {
                padding: 4px 10px;
                font-size: 10px;
                line-height: 14px;
                margin-top: 12px;
            }

            @include mdFix {
                padding: 2px 8px;
            }
        }
    }

    &--left {
        width: 40px;
        background-image: url("../pic/sprite.svg#star");

        @include md {
            width: 32px;
        }

        svg {
            margin-bottom: 16px;
            width: 100%;
            height: auto;

            @include lg {
                margin-bottom: 12px;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &--right {
        a.gh {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.14em;
            text-transform: uppercase;
            color: #50c878;
            margin-top: auto;
            display: inline-flex;
            position: relative;

            @include vm {
                display: none;
            }

            &:before {
                content: "";
                transition: 0.3s all ease;
                width: 100%;
                height: 1px;
                position: absolute;
                top: 100%;
                background: #50c878;
                opacity: 0;
                visibility: hidden;
            }

            &:hover {
                &:before {
                    opacity: 1;
                    visibility: visible;
                }
            }

            @include lg {
                font-size: 10px;
                line-height: 14px;
            }
        }
    }

    &--time {
        font-size: 20px;
        line-height: 28px;
        color: #e7d186;
        font-weight: normal;
        margin-left: 8px;

        @include lg {
            font-size: 16px;
            line-height: 20px;
        }

        @include md {
            font-size: 14px;
        }

        @include vm {
            font-size: 12px;
            line-height: 16px;
        }
    }

    &--price {
        &-info {
            height: 100%;
            align-items: flex-end;
        }

        &-wrapper {
            display: flex;
            align-items: flex-end;
            margin-left: -32px;
            margin-right: -40px;
            margin-top: 24px;
            min-height: 56px;
            padding-right: 24px;

            @include lg {
                min-height: 36px;
                margin-left: -20px;
                margin-right: -24px;
                margin-top: 20px;
            }

            @include md {
                margin-left: -16px;
                padding-right: 22px;
                margin-top: 12px;
            }

            @include mdFix {
                margin-left: -12px;
            }

            &_not-timer {
                padding-left: 80px;

                @include lg {
                    padding-left: 60px;
                }

                @include md {
                    padding-left: 40px;
                }
            }
        }

        &-timer {
            background: #52739a;
            border-radius: 0px 22px 22px 0px;
            padding: 1px 0 1px 32px;
            display: flex;
            align-items: center;
            min-height: 48px;
            min-width: 185px;

            @include lg {
                padding: 1px 0 1px 20px;
                min-width: 128px;
                min-height: 36px;
            }

            @include md {
                padding: 1px 0 1px 16px;
                min-width: 100px;
            }

            @include sm {
                padding: 1px 0 1px 16px;
                min-height: 36px;
            }

            @include vm {
                padding: 0 0 0 12px;
                min-width: 100px;
            }

            svg {
                margin-right: 20px;

                @include lg {
                    width: 13px;
                    height: 13px;
                }

                @include md {
                    margin-right: 4px;
                }
            }

            .price-timer {
                font-weight: bold;
                font-size: 20px;
                line-height: 28px;
                color: #ffffff;

                @include lg {
                    font-size: 16px;
                    line-height: 20px;
                }

                @include md {
                    font-size: 14px;
                    margin-top: 1px;
                }

                @include vm {
                    font-size: 12px;
                    line-height: 16px;
                }
            }
        }

        &-price {
            font-weight: bold;
            font-size: 20px;
            line-height: 28px;
            color: #50c878;
            margin-left: 24px;

            @include lg {
                font-size: 16px;
                line-height: 20px;
                margin-left: 8px;
            }

            @include sm {
                line-height: 20px;
            }

            @include vm {
                font-size: 14px;
                line-height: 16px;
            }
        }

        &-old {
            font-weight: normal;
            font-size: 16px;
            line-height: 20px;
            text-decoration-line: line-through;
            color: #52739a;
            margin-left: 24px;

            @include lg {
                font-size: 12px;
                line-height: 16px;
                margin-left: 8px;
            }

            @include vm {
                font-size: 10px;
                line-height: 14px;
            }
        }
    }
}

.events {
    @include vm {
        .more--btn {
            width: 100%;
        }
    }
}

.esl {
    &--wrap {
        position: relative;

        .slick-list {
            overflow: visible !important;

            &:before {
                content: "";
                position: absolute;
                display: block;
                width: 100%;
                height: 100%;
                background: #051838;
                left: -100%;
                z-index: 2;

                @include vm {
                    display: none;
                }
            }

            @media (max-width: 767px) {
                max-width: 325px;
            }

            @include vm {
                max-width: 288px;
            }

            @media (max-width: 420px) {
                margin: 0 auto;
            }
        }

        .slick-slider {
            margin: 0 -2px;
        }

        .slider--left {
            left: -16px;

            @media (max-width: 767px) {
                left: -40px;
            }

            @include vm {
                left: -12px;
            }
        }

        .slider--right {
            right: -16px;

            @media (max-width: 767px) {
                right: -40px;
            }

            @include vm {
                right: -12px;
            }
        }

        .slick-slide {
            margin: 0 2px;
            display: flex;

            >div {
                width: 100%;
            }

            @media (max-width: 767px) {
                max-width: 325px;
            }
        }

        @media (max-width: 768px) {
            .event--price-wrapper {
                position: absolute;
                width: 100%;
                bottom: 12px;
            }
        }
    }
}

.mg80 {
    margin-bottom: 80px;

    @include lg {
        margin-bottom: 58px;
    }

    @include md {
        margin-bottom: 44px;
    }

    @include sm {
        margin-bottom: 38px;
    }

    @include vm {
        margin-bottom: 32px;
    }
}

.mg60 {
    margin-bottom: 60px;

    @include lg {
        margin-bottom: 40px;
    }

    @include md {
        margin-bottom: 32px;
    }

    @include sm {
        margin-bottom: 28px;
    }

    @include vm {
        margin-bottom: 20px;
    }
}

.mg20 {
    margin-bottom: 20px;

    @include lg {
        margin-bottom: 12px;
    }

    @include md {
        margin-bottom: 8px;
    }

    @include sm {
        margin-bottom: 4px;
    }

    @include vm {
        margin-bottom: 16px;
    }
}

.mg32 {
    margin-bottom: 32px;

    @include lg {
        margin-bottom: 20px;
    }

    @include md {
        margin-bottom: 16px;
    }

    @include sm {
        margin-bottom: 12px;
    }
}

.mt32 {
    margin-top: 32px;

    @include lg {
        margin-top: 20px;
    }

    @include md {
        margin-top: 16px;
    }

    @include sm {
        margin-top: 12px;
    }
}

.inputs {
    &--wrap {
        position: relative;
        width: 100%;
    }

    &--block,
    &--textarea {
        background: #000712;
        box-sizing: border-box;
        position: relative;
        z-index: 1;

        &.filled {
            label {
                font-size: 10px;
                line-height: 16px;
                font-weight: bold;
                color: #004a30;
                top: 5px;
                transform: translate(-50%, 0);

                @include md {
                    font-size: 9px;
                    top: 3px;
                    line-height: 1;
                }
            }

            input,
            textarea {
                border-color: #50c878;
                color: #50c878;
            }
        }

        label {
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            position: absolute;
            top: 50%;
            left: 50%;
            color: #e7d186;
            transform: translate(-50%, -50%);
            transition: 0.3s all ease;
            z-index: 1;
            font-weight: bold;

            @include lg {
                font-size: 12px;
                line-height: 16px;
            }

            @include md {
                font-size: 10px;
                line-height: 14px;
            }
        }

        textarea {
            display: block;
        }

        input,
        textarea {
            font-weight: bold;
            border: 2px solid #e7d186;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #e7d186;
            padding: 18px 10px;
            text-align: center;
            width: 100%;
            border-radius: 12px;
            z-index: 2;
            position: relative;

            @include lg {
                border-radius: 10px;
            }

            @include md {
                border-radius: 8px;
            }

            &:focus {
                ~label {
                    font-size: 10px;
                    line-height: 16px;
                    font-weight: bold;
                    color: #004a30;
                    top: 5px;
                    transform: translate(-50%, 0);

                    @include md {
                        font-size: 9px;
                        top: 3px;
                        line-height: 1;
                    }
                }
            }

            @include lg {
                padding: 10px;
                font-size: 12px;
                line-height: 16px;
            }

            @include md {
                border-width: 1px;
                font-size: 10px;
                line-height: 14px;
                padding: 11px 10px;
            }

            &.parsley-error {
                border-color: #e84e1d;
            }

            &::placeholder {
                font-weight: bold;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: 0.2em;
                color: #e7d186;

                @include lg {
                    font-size: 10px;
                    line-height: 14px;
                }

                @include md {
                    font-size: 10px;
                    line-height: 14px;
                }
            }
        }
    }
}

.mask--bg {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    background: #051838;
    z-index: 100;
    opacity: 0;
    visibility: hidden;
    user-select: none;
    cursor: pointer;
    transition: 0.3s all ease;

    &.active {
        opacity: 0.5;
        visibility: visible;
        user-select: auto;
    }
}

.btn {
    font-size: 20px;
    line-height: 28px;
    color: #50c878;
    font-family: "Oranienbaum", sans-serif;
    padding: 12px 44px 12px 44px;
    border: 2px solid #50c878;
    border-radius: 12px;
    display: inline-flex;
    align-items: center;

    @include lg {
        font-size: 16px;
        line-height: 20px;
        padding: 9px 30px 9px;
    }

    @include md {
        font-size: 14px;
        line-height: 14px;
        border-width: 1px;
        padding: 10px 30px 10px;
    }

    @include mdMax {
        &:hover {
            background: #50c878;
            color: #051838;
        }
    }

    @include md {
        &:active {
            background: #50c878;
            color: #051838;
        }
    }
}

.btn {
    &--close {
        position: absolute;
        right: 23px;
        top: 23px;
        z-index: 21;

        @include lg {
            width: 24px;
            height: 24px;
            right: 15px;
            top: 15px;

            svg {
                width: 100%;
                height: auto;
            }
        }

        @include md {
            width: 20px;
            height: 20px;
            top: 5px;
            right: 5px;
        }

        @include mdFix {
            width: 16px;
            height: 16px;
            top: 2px;
            right: 2px;
        }

        @include vm {
            width: 24px;
            height: 24px;
            top: 27px;
            right: 27px;
        }

        svg {
            color: #051c38;

            &:hover {
                color: #50c878;
            }
        }
    }
}

.parsley {
    &-errors {
        &-list {
            position: absolute;
            top: calc(100% - 9px);
            left: 0;
            right: 0;
            margin: 0 auto;
            display: inline-flex;
            text-align: center;
            align-items: center;
            justify-content: center;
            z-index: 3;

            li {
                font-weight: 500;
                font-size: 10px;
                line-height: 16px;
                display: flex;
                align-items: flex-end;
                color: #ffffff;
                padding: 1px 12px;
                background: #e84e1d;
                border-radius: 8px;

                @include md {
                    padding: 0 8px;
                }
            }

            @include vm {
                top: calc(100% - 12px);
            }
        }
    }
}

*::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}

*::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none;
}

/* Old shadow dom for play button */

*::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none;
}

/* New shadow dom for play button */

/* This one works! */

*::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none;
}

.scrolled_el {
    .simplebar {
        &-track {
            &.simplebar-vertical {
                background: #f2f2f2;
                width: 8px;

                .simplebar-scrollbar {
                    &:before {
                        top: 0;
                        bottom: 0;
                        opacity: 1;
                        background: #e7d186;
                        left: 0;
                        right: 0;
                        border-radius: 0;
                    }
                }
            }
        }
    }
}

.section {
    &--tag {
        padding: 5px 11px;
        border: 1px solid #e7d186;
        border-radius: 12px;

        &.green {
            border-color: #50c878;
        }

        @include lg {
            padding: 3px 11px;
        }

        @include md {
            padding: 3px 7px;
        }

        @include sm {
            padding: 1px 7px;
        }

        span {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.16em;
            text-transform: uppercase;
            color: #50c878;
            display: block;
            margin-left: 12px;

            @include lg {
                font-size: 10px;
                line-height: 16px;
                margin-left: 10px;
            }
        }
    }

    &--tags {
        >div {
            @include sm {
                flex-direction: column;
            }

            @include mdFix {
                align-items: flex-start;
            }
        }

        &-text {
            font-size: 20px;
            line-height: 32px;
            color: #ffffff;
            margin-right: 86px;
            flex: 1 auto;

            >p {
                font-size: 16px;
                line-height: 24px;
            }

            @include lg {
                font-size: 14px;
                line-height: 20px;
                margin-right: 16px;
            }

            @include sm {
                margin: 0;
            }

            @include vm {
                text-align: center;
            }
        }

        &-main {
            @include sm {
                margin-top: 20px;
            }

            @include mdFix {
                margin-top: 16px;
                flex-wrap: wrap;
                margin-bottom: -8px;
                justify-content: space-between;

                .section--tag {
                    width: calc(50% - 4px);
                    margin: 0 0 8px 0;
                    justify-content: center;
                }
            }

            >div {
                margin: 0 8px;

                &:first-child {
                    margin-left: 0;
                }

                &:last-child {
                    margin-right: 0;
                }
            }
        }
    }
}

.stars {
    height: 14px;

    @include lg {
        height: 10px;
    }

    svg {
        margin: 0 2px;

        @include lg {
            width: 8px;
            height: 8px;
        }

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.breadcrumbs {
    margin-bottom: 32px;
    position: relative;

    @include lg {
        margin-bottom: 24px;
    }

    @include md {
        margin-bottom: 20px;
    }

    @include sm {
        margin-bottom: 16px;
    }

    @include mdFix {
        max-width: 100%;
        max-width: 94%;
        padding-left: 5px;
        overflow: hidden;
    }

    &--item {
        white-space: nowrap;

        a,
        span {
            font-weight: bold;
            font-size: 12px;
            line-height: 16px;
            letter-spacing: 0.2em;
            text-transform: uppercase;
            color: #e7d186;

            @include md {
                font-size: 10px;
                line-height: 16px;
            }
        }

        a {
            &:hover {
                color: #50c878;
            }
        }
    }

    &--dot {
        width: 4px;
        height: 4px;
        background: #e7d186;
        border-radius: 50%;
        margin: 0 40px;

        @include lg {
            margin: 0 28px;
        }

        @include sm {
            margin: 0 24px;
        }

        @include md {
            width: 3px;
            height: 3px;
            margin: 2px 8px 0;
            padding: 3px 3px;
        }

        @include mdFix {
            margin: 2px 8px 0;
        }
    }

    &--dots {
        position: absolute;
        right: 2px;
        font-size: 25px;
        color: #e7d186;
        margin-top: -7px;
        background: #051838;
    }
}

.section-cards {
    .event--card-wrap {
        @include mdFix {
            width: 100%;

            &:not(:last-child) {
                margin-bottom: 4px;
            }
        }
    }
}

.bottom-btn-top {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    background: rgba(80, 200, 120, 0.3);
    display: block;
    transform: rotate(90deg);
    padding: 0 0 0 22px;
    position: fixed;
    bottom: 76px;
    right: 38px;
    display: none;

    &:hover {
        background: #50c878;
    }

    @include lg {
        width: 40px;
        height: 40px;
        padding: 0 0 0 15px;
        bottom: 56px;
        right: 33px;
    }

    @include md {
        bottom: 40px;
        right: 15px;
    }

    @include sm {
        width: 33px;
        height: 33px;
        padding: 0px 0px 1px 12px;
        bottom: 60px;
        right: 10px;
    }

    @include md {
        bottom: 66px;
        right: 16px;
    }

    >svg {
        @include lg {
            height: 13px;
            width: 9px;
        }

        @include sm {
            height: 10px;
            width: 8px;
        }
    }
}

.cookie {
    position: fixed;
    width: 100%;
    max-width: 608px;
    background: #e7d186;
    right: 32px;
    bottom: 20px;
    padding: 32px 40px;
    display: flex;
    align-items: center;

    @include lg {
        max-width: 510px;
        padding: 24px 32px;
    }

    @include md {
        max-width: 492px;
        padding: 24px 28px;
        right: 16px;
    }

    @include sm {
        padding: 20px 28px;
    }

    @include mdFix {
        right: 0;
        bottom: 0;
        width: 100%;
        max-width: 100%;
        padding: 16px;
    }

    &__descr {
        font-size: 16px;
        line-height: 20px;
        color: #051838;

        @include lg {
            font-size: 12px;
            line-height: 16px;
        }

        @include md {
            font-size: 12px;
            line-height: 16px;
        }

        @include vm {
            font-size: 10px;
            line-height: 12px;
            text-align: left;
        }
    }

    &__btn {
        font-family: Oranienbaum;
        margin-left: 16px;
        width: 110px;
        height: 56px;
        background: #051838;
        border-radius: 12px;
        font-weight: normal;
        font-size: 20px;
        line-height: 28px;
        display: flex;
        padding: 12.5px 44px;
        color: #ffffff;

        @include lg {
            padding: 8.5px 41px 11.5px;
            height: 40px;
            font-size: 16px;
            line-height: 20px;
        }

        @include md {
            font-size: 14px;
            line-height: 14px;
            padding: 12.5px 32px 13.5px;
        }

        @include mdFix {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 0;
            width: 60px;
        }
    }
}

#pages_main,
#reviews,
#events-slider,
.skull--pages-wrap {
    overflow: hidden;
}