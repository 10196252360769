@import "../mixins";

.register {
    padding-bottom: 80px;

    @include lg {
        padding-bottom: 58px;
    }

    @include md {
        padding-bottom: 44px;
    }

    @include sm {
        padding-bottom: 38px;
    }

    &--title {
        font-family: Oranienbaum;
        font-weight: normal;
        font-size: 32px;
        line-height: 40px;
        color: #e7d186;
        margin-bottom: 20px;

        @include lg {
            font-size: 24px;
            line-height: 28px;
        }

        @include md {
            font-size: 20px;
            line-height: 24px;
        }

        @include mdFix {
            margin-top: 28px;
            margin-bottom: 12px;
        }
    }

    &--form {
        padding: 0 208px;

        @include lg {
            padding: 0;
        }
    }

    &--inputs-block {
        .inputs--block {
            width: 100%;
            margin-bottom: 32px;
            border-radius: 12px;

            @include mdFix {
                margin-bottom: 8px;
            }

            &:not(:last-child) {
                margin-right: 16px;

                @include sm {
                    margin-right: 8px;
                }

                @include mdFix {
                    margin-right: 0;
                }
            }

            input {
                padding: 22px 11px 14px;

                @include md {
                    padding: 14px 11px 10px;
                }
            }

            .custom-select-opener {
                padding: 18px 62px 18px 26px;

                @include md {
                    padding: 11px 62px 11px 26px;
                }
            }

            &.select-block {
                z-index: 1;

                &:not(:last-child) {
                    z-index: 2;
                }
            }

            label {
                @include md {
                    font-size: 12px;
                    line-height: 16px;
                }
            }

            &.inputs--block_col {
                flex: 0 0 calc(50% - 8px);

                @include sm {
                    flex: 0 0 calc(50% - 4px);
                }

                @include mdFix {
                    flex: 0 0 100%;
                }

            }
        }
    }

    &--submit {
        text-align: center;
    }

    &--wrap {
        @include md {
            >div {

                &:nth-child(1),
                &:nth-child(2) {
                    width: 28%;
                }

                &:nth-child(3) {
                    width: 44%;
                }
            }
        }

        @include mdFix {
            >div {

                &:nth-child(1),
                &:nth-child(2),
                &:nth-child(3) {
                    width: 100%;
                }
            }
        }
    }

    &--agree {
        .parsley-errors-list {
            display: none;
        }
    }

    .more--btn {
        margin-top: 0;
        color: #051838;
        background: #50c878;

        &:hover {
            background: transparent;
            color: #50c878;
        }

        @include md {
            margin-left: 0;
        }

        @include vm {
            width: 100%;
        }
    }

    .custom-select-opener,
    .custom-select-option {
        text-align: left;
        font-size: 12px;
        line-height: 16px;
    }

    .inputs--block,
    .inputs--textarea,
    .inpCheck {
        z-index: 0;
    }

    .inpCheck {
        @include md {
            margin-top: 28px;
        }
    }

    label {
        width: 90%;
        text-align: center;
        justify-content: center;

        >svg {
            margin-left: auto;

            @include mdFix {
                margin-left: 18%;
                margin-right: -18%;
                width: 20px;
                height: 16px;
            }
        }
    }

    .date-block {
        z-index: 3;
    }

    .custom-select-container.is-open .custom-select-panel {
        max-height: 200px;
        overflow: visible;

        @include lg {
            max-height: 145px;
        }

        @include md {
            max-height: 130px;
        }

        @include sm {
            max-height: 130px;
        }
    }

    .custom-select-option {
        @include mdFix {
            padding: 0 33px 0 26px;
        }
    }

    .simplebar-track {
        background: #f2f2f2;
        right: 25px;
        top: 15px;
        bottom: 15px;
    }

    .simplebar-track .simplebar-scrollbar::before {
        border-radius: 0;
        background: #e7d186;
        left: 0;
        right: 0;
        opacity: 1;
        top: 0;
        bottom: 0;
    }

    .inputs--wrap {
        @include mdFix {
            flex-direction: column;
        }
    }
}

.datepicker {
    &__wrapper {
        @include sm {
            left: auto !important;
            right: 0 !important;
            width: 150% !important;
        }

        @include mdFix {
            left: 0 !important;
            right: 0 !important;
            width: 100% !important;
        }
    }
}

.modal-register {
    height: 500px;
    width: 712px;

    @include lg {
        width: 584px;
        height: 411px;
    }

    @include md {
        width: 436px;
        height: 367px;
    }

    @include mdFix {
        width: 406px;
        height: 315px;
    }

    @include sm {
        width: 288px;
        height: 348px;
    }

    &--success {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
    }

    .ms--title {
        margin-bottom: 0;
    }

    .ms--text {
        @include md {
            padding: 0;
        }
    }
}

.drop-help {

    &.inputs--block {
        position: relative;
        z-index: 2;
    }

    &._active {
        z-index: 4;

        input {
            border-bottom-color: #000712 !important;
            border-radius: 12px 12px 0 0;

            @include lg {
                border-radius: 10px 10px 0 0;
            }

            @include md {
                border-radius: 8px 8px 0 0;
            }
        }

        .drop-help__list {
            border-width: 0 2px 2px 2px;
            border-style: solid;
            border-color: #50c878;
            border-bottom-right-radius: 12px;
            border-bottom-left-radius: 12px;
            max-height: 200px;


            @include lg {
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
            }

            @include md {
                border-bottom-right-radius: 8px;
                border-bottom-left-radius: 8px;
            }

            &:before {
                content: "";
                display: block;
                width: calc(100% - 40px);
                height: 1px;
                background: #50c878;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                margin: 0 auto;
            }
        }
    }

    // .drop-help__list
    &__list {
        position: absolute;
        left: 0;
        right: 0;
        top: calc(100% - 1px);
        background: #000712;
        max-height: 0;
        overflow: hidden;
        z-index: 2;


        overflow: visible;

        // transition: all 4s ease 0s;
        .simplebar-content {
            div {
                font-weight: 700;
                font-size: 12px;
                line-height: 16px;
                letter-spacing: .2em;
                text-transform: uppercase;
                color: #e7d186;
                margin: 14px 0;
                padding: 10px 64px 10px 26px;
                cursor: pointer;
                transition: all 0.3s ease 0s;

                @include md {
                    font-size: 10px;
                    line-height: 14px;
                    padding-top: 5px;
                    padding-bottom: 5px;
                }

                @media (any-hover: hover) {
                    &:hover {
                        background: rgba(82, 115, 154, .2);
                        border-radius: 4px;
                    }
                }
            }
        }

    }


}